import React, {useRef, useEffect} from 'react'

const CanvasBox = props => {
  
  const canvasRef = useRef(null)
  
  const datapointSize = 1.5;
  
  const memoizedDatapoints = React.useMemo(() => {
    if(!!props.data_points && props.data_points.length > 0 ){
      
      const tmpDatapoints=props.data_points.map( (datapoint, datapointIndex) => {
        let percentX = 0;
        percentX = (datapoint.LOC-props.visible_window[0]+1 ) / (props.visible_window[1]-props.visible_window[0]);
        let x_pos = Math.round(percentX * props.track_width ) || -1;

        let percentY = 0;
        let y_AxisLength = Math.abs(props.visible_window[3] - props.visible_window[2]);
        //* needs to get rounded to the nearest pixel!
        percentY=Math.abs(props.visible_window[3] - datapoint.LOG_RATIO )/y_AxisLength; // props.track_height
        let y_pos = Math.round(props.track_height*percentY) || -1;

        return({x_pos:x_pos, y_pos:y_pos});
      });

      return(tmpDatapoints);
    }
  }, [props.data_points, props.visible_window]);


  const draw = (ctx, frameCount) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
    ctx.fillStyle = '#000000'
    ctx.beginPath()
    ctx.arc(props.track_width/2, props.track_height/2, 20*Math.sin(frameCount*0.05)**2, 0, 2*Math.PI)
    ctx.fill()
  }

  const drawPoints = (ctx) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
    ctx.fillStyle = '#888888';
    memoizedDatapoints.forEach( datapoint => {
      ctx.fillRect(props.track_x+datapoint.x_pos, props.track_y+datapoint.y_pos, datapointSize, datapointSize);
    });
  }


  useEffect(() => {
    let frameCount = 0;
    let animationFrameId;
    if(!!canvasRef && !!canvasRef.current){
      const context = canvasRef.current.getContext('2d');

      const render = () => {
        frameCount++;
        draw(context, frameCount);
        animationFrameId = window.requestAnimationFrame(render);
      }

      if(!!memoizedDatapoints){
        drawPoints(context);
      }
      else{
        render();
      }
    }
    
    return () => {
      window.cancelAnimationFrame(animationFrameId)
    }
  }, [draw, drawPoints]);

  return <canvas ref={canvasRef} {...props}/>
}

export default CanvasBox