import React from "react";
import {prettySize} from '../../HelperFunctions'

const Axis = (props) => {
  
  // const visibleWindowLength_x = ( Number.isFinite(props.visibleWindow[1]-props.visibleWindow[0]) && (props.visibleWindow[1]-props.visibleWindow[0]>1) ? props.visibleWindow[1]-props.visibleWindow[0]+1 : 1);
  // const visibleWindowLength_y = ( Number.isFinite(props.visibleWindow[3]-props.visibleWindow[2]) && (props.visibleWindow[3]-props.visibleWindow[2]>1) ? props.visibleWindow[3]-props.visibleWindow[2]+1 : 1);

  const fullSizeTickLength = 10;
  const minorTickLength = 6;
  const yAxis_tickAndText_margin = 10;
  const xAxis_tickAndText_margin = 15;
  // const trackUnitText_margin_left = 0;//25;
  // const trackUnitText_x = props.x; // props.length+trackUnitText_margin_left;
  // const trackUnitText_y = props.y; // props.y+x_numbers_margin_top;

  const findTickPositions = (lower, upper, numberOfFullTicks=15, numberOfHalfTicks=5) => {
    
    const potentialTickIntervals = [1/1000, 1/500, 1/200, 1/100, 1/50, 1/20, 1/10, 1/5, 1/2, 1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000, 50000, 100000, 200000, 500000, 1000000, 5000000, 10000000];
    
    let tickInterval = potentialTickIntervals.find(tickIntrvl => (( (upper-lower) / tickIntrvl) <= numberOfFullTicks ))
    if (!tickInterval) tickInterval=10000000
    
    let halfTickInterval = potentialTickIntervals.find(tickIntrvl => ( (tickInterval/tickIntrvl) <= numberOfHalfTicks ));
    if (!halfTickInterval) halfTickInterval=10000000

    // * p=posistion, l=label, f=fullLengthTick or majorTicks
    let tickPositions = [{p:lower, l:lower, f:true}, {p:upper, l:upper, f:true}]; 
    if((upper-lower)>0){
      tickPositions = [];

      let calcedNumberOfFullTicks = Math.floor((upper-lower)/tickInterval);
      const fullTickPositions = Array.from(Array(calcedNumberOfFullTicks), (_, x) => (lower+((x+1)*tickInterval)) );
      //! do we need to do it this way?! ===>> const fullTickPositions = Array.from(Array(calcedNumberOfFullTicks), (_, x) => (lower-(lower%tickInterval)+((x+1)*tickInterval)) );
      // console.log('fullTickPositions', fullTickPositions);
      const minWidthBetweenTicks = tickInterval*0.6; // (2 * tickInterval) / 3)
      fullTickPositions.forEach( (thisTick, index) => {
        let thisTickLabel = String(thisTick);
        if(fullTickPositions.length>2 && thisTick===fullTickPositions[0] && (thisTick-lower < minWidthBetweenTicks) )
          thisTickLabel = '';
        if(fullTickPositions.length>2 && thisTick===fullTickPositions[fullTickPositions.length-1] && (upper-thisTick < minWidthBetweenTicks) )
          thisTickLabel = '';

        if(thisTick!==lower && thisTick!==upper )
          tickPositions.push({p:thisTick, l:thisTickLabel, f:true});
      })
      // if(tickPositions[0]-lower < minWidthBetweenTicks) tickPositions.splice(0, 1); 
      // if(upper - tickPositions[tickPositions.length-1] < minWidthBetweenTicks) tickPositions.pop(); 
      // if( (upper - tickPositions[tickPositions.length-1]) < (tickInterval/3) ) tickPositions.pop();

      // tickPositions = [lower, ...tickPositions, upper];
      tickPositions = [{p:lower, l:String(lower), f:true}, ...tickPositions, {p:upper, l:String(upper), f:true}];
      
      tickPositions.forEach( (thisTick, index) => {
        // ? START of half tick section
        // let halfTicks = [];
        let previousFullTick = lower;
        if(index >= 1) previousFullTick = tickPositions[index-1].p;
        if((thisTick.p-previousFullTick)>0){
          let numberOfHalfTicksForThisRegion = Math.floor((thisTick.p-previousFullTick)/halfTickInterval);
          Array.from(Array(numberOfHalfTicksForThisRegion), (_, ind) => 
            (previousFullTick+((ind+1)*halfTickInterval)) ).forEach(tick => tickPositions.push({p:tick, l:'', f:false}));
        }
        // ? END of half tick section
      });
    }
    
    return tickPositions;
  }


  const value2relativePosition = (inPosition, axisVisibleWindow, axisLengthInPixel, direction) =>{
    if(inPosition===null || inPosition===undefined) return 0;
    // let visibleWindowLength=props.visibleWindow[1]-props.visibleWindow[0]+1;
    // console.log(inPosition, '-', props.visibleWindow[0], '-', inPosition-props.visibleWindow[0]);
    let axisVisibleWindowLength = Math.abs(axisVisibleWindow[1] - axisVisibleWindow[0]);
    //* needs to get rounded to the nearest pixel!
    inPosition = direction==="high2low" ? Math.abs(axisVisibleWindow[1] - inPosition) : Math.abs(inPosition-axisVisibleWindow[0]);
    let calculatedPosition = Math.round(axisLengthInPixel*( (inPosition)/axisVisibleWindowLength));
    if(!Number.isFinite(calculatedPosition)) calculatedPosition = 0; //! or -1??
    return(calculatedPosition);
    // return(Math.round(winLengthInPxl*( (inPosition-props.visibleWindow[0])/visibleWindowLength) || 0 ));
  }

  const getTicksX = () => {
    let positions = [];
    let ticks = [];
    let xVisWin = [props.visibleWindow[0], props.visibleWindow[1]];//[-1000,1000]
    positions = findTickPositions(xVisWin[0], xVisWin[1]);
    ticks = positions.map(tick => (
      {
        position: props.x+value2relativePosition(tick.p, xVisWin, props.width, "low2high"),
        label: (!!tick.l ? prettySize(tick.p,'','') : ''),
        fullSize: tick.f
      }
    ));
    return ticks;
  }
  // ticks = positions.map(t => ({position: props.x+props.margin+value2relativePosition(t, xVisWin, props.width-props.margin-props.margin, "low2high"), label:prettySize(t)}));


  const getTicksY = () => {
    let positions = [];
    let ticks = [];
    let yVisWin = [props.visibleWindow[2], props.visibleWindow[3]];//[-3,3]
    positions = findTickPositions(yVisWin[0], yVisWin[1], 6);
    ticks = positions.sort((a, b) => b - a).map(tick => (
      {
        position:props.y+value2relativePosition(tick.p, yVisWin, props.width, "high2low"),
        label: (!!tick.l ? tick.l : ''),
        fullSize: tick.f
      }
    ));
    return ticks;
  }
  // ticks = positions.sort((a, b) => b - a).map(t => ({position:props.y+props.margin+value2relativePosition(t, yVisWin, props.width-props.margin-props.margin, "high2low"), label:t}));


  const processedTicks = React.useMemo(() => {
    if(props.orientation==="y")
      return(getTicksY());
    else
      return(getTicksX());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.orientation, props.visibleWindow , getTicksX, getTicksY]);  // visibleWindow in X and Y


  const renderTicks_X = () => {
    const lastFullTickIndex = processedTicks.filter(t => t.fullSize).length-1;
    return( processedTicks.map( (tick, index) => {
      let tickLength = (tick.fullSize ? fullSizeTickLength : minorTickLength);
      return (
      <g key={index}>
        <line
          stroke="#000000"
          strokeWidth={(tick.fullSize ? 2  : 1)}
          x1={tick.position}
          y1={props.y}
          x2={tick.position}
          y2={props.y+tickLength}
        />
        <text
          x={tick.position}
          y={props.y+xAxis_tickAndText_margin}
          dx={(index===lastFullTickIndex? `${props.margin[1]}px` : "0px")}
          dy='10px'
          textAnchor={(index===lastFullTickIndex ? "end" : "middle")}
          // dx='.34em'
          // dy='.1em'
          // textAnchor="end"
          // style={}
        >
        {(tick.fullSize ? tick.label  : '')}
        </text>
      </g>);
    }))
  }

  const renderTicks_Y = () => {
    return( processedTicks.map( (tick, index) => {
      let tickLength = (tick.fullSize ? fullSizeTickLength : minorTickLength);
      return (
      <g key={index}>
        <line
          stroke="#000000"
          strokeWidth={(tick.fullSize ? 2  : 1)}
          x1={props.x+props.height-tickLength}
          y1={tick.position}
          x2={props.x+props.height}
          y2={tick.position}
        />
        <text
          x={props.x+props.height-tickLength-yAxis_tickAndText_margin}
          y={tick.position}
          dx='0px'
          dy="5px"
          textAnchor= "middle"
          // dy={(index===processedTicks.length-1 ? "10px" : "0px")}
          // textAnchor={(index===processedTicks.length-1 ? "end" : "middle")}
          // dx='.34em'
          // dy='.1em'
          // textAnchor="end"
          // style={}
        >
        {(tick.fullSize ? tick.label  : '')}
        </text>
      </g>);
    }))
  }

  // const getTrackUnit= () => {
  //   switch (props.selectedView) {
  //     case 'Protein':
  //       return 'aa (Protein)';
  //     case 'RNA':
  //       return 'bp (RNA)';
  //     case 'Genome':
  //       return 'bp (DNA)';
  //   }
  //   return 'bp';
  // }

  
  if(props.orientation==="y"){
    return(
      <g>
        {/* <rect
          fill="lightgreen"
          // fill="none"
          // stroke="black"
          // strokeWidth="1"
          x={props.x}
          y={props.y}
          height={props.width}
          width={props.height}
          // x={props.x-props.margin}
          // y={props.y-props.margin}
          // height={props.width+props.margin}
          // width={props.height+props.margin}
        /> */}
        {/* <line
          stroke="#353535"
          strokeWidth="2"
          x1={props.x+props.height-1}
          y1={processedTicks[0].position-1}
          x2={props.x+props.height-1}
          y2={processedTicks[processedTicks.length-1].position+1}
        /> */}
        {/* //* This rect is to have a white axis background (no transparent as it shows data during dragging) */}
        <rect
          fill="#F9F9F9"
          stroke="#F9F9F9"
          strokeWidth="1"
          opacity="1"
          x={props.x}
          y={props.y}
          width={props.height}
          height={props.width}
        /> 
        <line
          stroke="#353535"
          strokeWidth="2"
          x1={props.x+props.height-1} // * to account for strokeWidth="2" [it occupies both sides]
          // x1={props.x+processedTicks[0].position-1}
          y1={props.y}
          // x2={props.x+processedTicks[processedTicks.length-1].position+1}
          x2={props.x+props.height-1} // * to account for strokeWidth="2" [it occupies both sides]
          y2={props.y+props.width}
        />
        {renderTicks_Y()}

        {/* <rect
          // fill="#F9F9F9"
          fill="red"
          stroke="#ededed"
          strokeWidth="2"
          x={props.x}
          y={props.y}
          height={props.width}
          width={props.height}
        /> */}
        
      </g>
    );
  }
  // console.log('Axis props', props);
  return(
    <g>
      {/* <rect
        fill="red"
        // fill="none"
        // stroke="black"
        // strokeWidth="1"
        x={props.x}
        y={props.y}
        height={props.height}
        width={props.width}
        // height={props.height+props.margin+props.margin}
        // width={props.width+props.margin}
      /> */}
      {/* //* This rect is to have a white axis background (no transparent as it shows data during dragging) */}
      <rect
          fill="#F9F9F9"
          stroke="#F9F9F9"
          strokeWidth="1"
          opacity="1"
          x={props.x}
          y={props.y}
          height={props.height}
          width={props.width}
        /> 
      <line
        stroke="#353535"
        strokeWidth="2"
        x1={props.x} // props.margin
        // x1={props.x+processedTicks[0].position-1}
        y1={props.y+1} // * to account for strokeWidth="2" [it occupies both sides]
        // x2={props.x+processedTicks[processedTicks.length-1].position+1}
        x2={props.x+props.width} //-props.margin-props.margin
        y2={props.y}
      />
      {renderTicks_X()}
    </g>
  );
}

// const Axis = (props) => (<h1>Axis!</h1>)
export default Axis;
