/* xlsx.js (C) 2013-present  SheetJS -- http://sheetjs.com */
/* Notes:
   - usage: `ReactDOM.render( <XlsxImporter />, document.getElementById('app') );`
   - xlsx.full.min.js is loaded in the head of the HTML page
   - this script should be referenced with type="text/babel"
   - babel.js in-browser transpiler should be loaded before this script
*/

import React, {useState} from 'react'; 
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';

// export default function XlsxImporter(props) {
const XlsxImporter = (props)=> {

	let history = useHistory();
	const [hgRefVersion, setHgRefVersion] = useState('hg19');
	const [useSheetName, setUseSheetName] = useState(true);
  const [sheetName, setSheetName] = useState('Filtered');
  const [sheetIndex, setSheetIndex] = useState(1);
	const [useMaxGnomad, setUseMaxGnomad] = useState(true);

	const handleSelectedFile = (file) => {
    // console.log(file);
		// console.log(useSheetName, sheetName, sheetIndex);
		props.setXlsxFile( {filePath:file, useMaxGnomad:useMaxGnomad, useSheetName: useSheetName, sheetName: sheetName, sheetNumber: (sheetIndex-1), vanexOutputedFile: false, hgRefVersion:hgRefVersion});
		history.push('/xlsx');
	}

	return(
		<DragDropFile handleFile={handleSelectedFile}>
			<div className="row m-1 p-1 mt-0">
				<div className="col-xs-12">

					<label htmlFor="file"> <h4 className="card-title my-2">Drag or choose a spreadsheet file</h4></label>
					<div className="mx-3 mt-0 d-flex flex-column align-items-start " style={{marginBottom:'.7rem'}}>
						<label className="my-1" >
							<div className="form-check">
								<input className="form-check-input me-1" type="radio" name="getBy" 
								// value="sheetName"
									onChange={(e) => setUseSheetName(e.target.checked)} 
									// defaultChecked={useSheetName}
									checked={useSheetName}/>
									Sheet Name
									<input className="mx-1 small" type="text" name="sheetName" placeholder="Filtered" aria-label="" 
										onChange={(e) => setSheetName(e.target.value)} value={sheetName}
										disabled={!useSheetName}
										// onChange={e => { const page = e.target.value ? Number(e.target.value) - 1 : 0 }}  />
										style={{ maxWidth: '5rem' }}/>
							</div>
						</label>
						<label className="my-1">
							<div className="form-check">
								<input className="form-check-input me-1" type="radio" name="getBy"
								// value="sheetNumber"
									onChange={(e) => setUseSheetName(!e.target.checked)}
									// defaultChecked={!useSheetName}
									checked={!useSheetName} />
									Sheet Index
									<input className="ms-4 me-3 small" type="number" name='filterTerm' style={{ maxWidth: '3rem' }}
										value={sheetIndex}
										// defaultValue="1"
										onChange={(e) => setSheetIndex(e.target.value)} 
										disabled={useSheetName}
										min="1" step="1" />
							</div>
						</label>
						<label className="my-1">
							<div className="form-check">
								<input className="form-check-input" type="checkbox" name="useMaxGnomad"
									onChange={(e) => setUseMaxGnomad(e.target.checked)}
									defaultChecked={useMaxGnomad}
								/>Max gnomAD = 0.1%
							</div>
						</label>
						<label className="m-0">
							<div className="form-check m-0">
								Reference <select 
								value={hgRefVersion}
								onChange={(e) => setHgRefVersion(e.target.value)}>
									<option value="hg19">hg19</option>
									<option value="hg38">GRCh38</option>
								</select>
							</div>
						</label>
					</div>
					<DataInput handleFile={handleSelectedFile} />
				</div>
			</div>
			<div className="row m-0">
				<div className="col-xs-12">
					{/* <button disabled={!data.length} className="btn btn-success" onClick={exportFile}>Export</button> */}
				</div>
			</div>
		</DragDropFile>
	);
}


export default withRouter(XlsxImporter);

/* -------------------------------------------------------------------------- */

/*
  Simple HTML5 file drag-and-drop wrapper
  usage: <DragDropFile handleFile={handleFile}>...</DragDropFile>
    handleFile(file:File):void;
*/

function DragDropFile({ handleFile, children }) {

	
	const suppress = (e) => { e.stopPropagation(); e.preventDefault(); };
	const handleDrop = (e) => { e.stopPropagation(); e.preventDefault();
		const files = e.dataTransfer.files;
		if(files && files[0]) handleFile(files[0]);
	};

	return (
		<div className="drag-drop-div card-with-min-height" 
			onDrop={handleDrop} 
			onDragEnter={suppress} 
			onDragOver={suppress}
		>
		{children}
		</div>
	);
}

/*
  Simple HTML5 file input wrapper
  usage: <DataInput handleFile={callback} />
    handleFile(file:File):void;
*/

function DataInput({ handleFile }) {



	const handleFileSelectionChange = (e) => {
		const files = e.target.files;
		if(files && files[0]) handleFile(files[0]);
	};

	return (
		<form className="form-inline">
			<div className="form-group">
				{/* <label className="list-group-item"><input className="form-check-input ms-4 me-1" type="radio" name="getBy" 
          onChange={handleRadioChange} defaultChecked={(presetFilterType==='sheetName')} value="sheetName"></input> Read Sheet Name</label>
				<label className="list-group-item"><input className="form-check-input ms-4 me-1" type="radio" name="getBy" 
          onChange={handleRadioChange} defaultChecked={(presetFilterType==='sheetNumber')} value="sheetNumber"></input> Use Sheet Index</label> */}

				<input 
					type="file" 
					className="form-control file-choose" 
					id="file" 
					accept={SheetJSFT} 
					onChange={handleFileSelectionChange} 
				/>
			</div>
		</form>
	)
}



/* list of supported file types */
const SheetJSFT = [
	"xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"
].map(x => `.${x}`).join(",");


