import React, { useState, useEffect } from 'react';
// import { useState } from 'react';   // , useEffect
import { withRouter } from 'react-router';
// !!!! wrong way of using it? import { withRouter, useParams } from 'react-router';
import { useHistory, useParams } from 'react-router-dom'; // , useRouteMatch, useLocation,Switch, Route
// import DyLoP from '../DyLoP/DyLoP';
import Firefly from '@nch-igm/firefly';
import Autosuggest from 'react-autosuggest';
import genes_to_ids from "../resources/GRCh38.p14_Genenames_to_IDs.json";

const DynamicPlots = (props) => {

  let history = useHistory();
  let params = useParams();
  // let routeMatch = useRouteMatch(); // { path, url }
  const [variantToHighlight, setVariantToHighlight] = useState('');
  const [isShownImportPanel, setIsShownImportPanel] = useState(false);
  const [variantsToImport, setVariantsToImport] = useState(
    [
      // {
      //   // "cDNA_POS": 246,
      //   "cDNA_POS": 614,
      //   // "PRT_POS": 150,
      //   "EFFECT": "missense",
      //   "CLNSIG": "pathogenic",
      //   "HGVSc": "eg. HGVSc",
      //   "HGVSp": "p.Gly46Val",
      //   "DESC": "RD0104",
      //   "CLINVAR_CLNDN_LIST": ["not specified" ],
      //   "CLINVAR_SI_NUM_SUBMISSIONS": 1,
      // }
    ]
  );
  const [variantsAsText, setVariantsAsText] = useState('');
  const [importFormat, setImportFormat] = useState('cdot');
  const [highlightInputFormat, setHighlightInputFormat] = useState('aa');
  const [inputPositions, setInputPositions] = useState("");

  function importVariants(){

    let lines=variantsAsText.split("\n");
    let result = [];
    let headers=["cDNA_POS","EFFECT","CLNSIG","HGVSc","HGVSp","DESC"];
    if(importFormat==="gdot")
      headers=["POSITION","REF","ALT","EFFECT","CLNSIG","HGVSc","HGVSp","DESC"];
    else if(importFormat==="pdot")
      headers=["PRT_POS","EFFECT","CLNSIG","HGVSc","HGVSp","DESC"];
    else if(importFormat==="hgvs")
      headers=["EFFECT","CLNSIG","HGVSc","HGVSp","DESC"];
    for(let i=0;i<lines.length;i++){
      if(!!lines[i]){
        let obj = {};
        let currentline= lines[i].split(",");
        for(let j=0;j<headers.length;j++){
          if(headers[j]==="cDNA_POS")
            obj[headers[j]] = parseInt(currentline[j]);
          else
            obj[headers[j]] = currentline[j];
        }

        if(importFormat==="hgvs"){
          let cdotMatch= obj.HGVSc.match(/c\.([^A-Za-z]+)[A-Za-z]/);
          let cdot = 0;
          if ( cdotMatch!== null && cdotMatch!==undefined && !!cdotMatch[1]){
            if(cdotMatch[1].startsWith('-'))
              cdot =-3;
            // else if (cdotMatch[1].startsWith('*'))
            //   cdot = (!!varData.PRT_LEN  ? 3*(varData.PRT_LEN+3) : -9);
            else
              cdot = (!!parseInt(cdotMatch[1]) ? parseInt(cdotMatch[1]) : 0);
          }
          let pdot = ( !!obj.HGVSp ? obj.PRT_POS : cdot/3 );
          obj["cDNA_POS"]=cdot;
          obj["PRT_POS"]=pdot;
        }

        // obj["VAR_ID"]=null;
        // obj["POSITION"]=null;
        // obj["REF"]=null;
        // obj["ALT"]=null;
        // obj["CLNVC"]="single_nucleotide_variant";
        obj["CLINVAR_CLNDN_LIST"]=["not specified"];
        // obj["CLINVAR_CLNREVSTAT_TYPE"]=null;
        obj["CLINVAR_SI_NUM_SUBMISSIONS"]=1;
        // obj["cDNA_POS"]=null;
        // obj["CDS_POS"]=null;
        // obj["PRT_POS"]=null;

        result.push(obj);
      }
    }
    // console.log("variantsToImport",result);
    setVariantsToImport(result);
  }
  const [geneNameIDs, setGeneNameIDs] = useState(Object.keys(genes_to_ids['ENTREZ_ID']).map(i => ({ name:i, entrez_id: genes_to_ids['ENTREZ_ID'][i]}) ));
  
  const [selectedGene, setSelectedGene] = useState({name:null, entrez_id:null});
  const [autosuggestValue, setAutosuggestValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
     
  useEffect(() => {
    let vanexTitle = 'Firefly'
    if(!!selectedGene && !!selectedGene.name) {
      vanexTitle = `${selectedGene.name} - Firefly`;
    }

    document.title = vanexTitle;
  }, [selectedGene]);

  // // https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
  // function escapeRegexCharacters(str) {
  //   return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  // }
  
  function getSuggestions(value) {
    // const escapedValue = escapeRegexCharacters(value.trim());
    // if (escapedValue === '') return [];
    if (!value || value.length < 2) return [];
    // const regex = new RegExp('^' + escapedValue, 'i');
    // return languages.filter(language => regex.test(language.name));
    return geneNameIDs.filter(gn => gn.name.startsWith(value.toUpperCase()))
  }
  
  function getSuggestionValue(suggestion) {
    return suggestion.name;
  }
  
  function renderSuggestion(suggestion) {
    return (<span>{suggestion.name}</span>);
  }

  function onChange(event, { newValue, method }) {
    setAutosuggestValue(newValue);
  };
  
  function onSuggestionsFetchRequested({ value }) {
    setSuggestions(getSuggestions(value));
  };

  function onSuggestionsClearRequested() {
    setSuggestions([]);
  };

  function onSuggestionSelected(e, value) {
    // console.log(value.suggestion);
    if(!!value && !!value.suggestion && !!value.suggestion.name){
      setSelectedGene(value.suggestion);
      history.push(`/dynamicplots/${value.suggestion.name}`);
    } else {
      console.error('no value.suggestion.name in: ', value);
    }
  };

  const AutosuggestInputProps = {
    placeholder: "Type a gene name",
    value: autosuggestValue,
    onChange: onChange
  };

  useEffect(() => {
    if(!!params && !!params.geneName){
      let geneInfo = geneNameIDs.filter(gn => gn.name===params.geneName.toUpperCase());
      if(!!geneInfo && !!geneInfo[0]){
        setSelectedGene(geneInfo[0]);
        setAutosuggestValue(params.geneName);
      } else {
        setSelectedGene({name:params.geneName, entrez_id:null});
      }
    }
  }, [])

  const updateHighlightedVariants = (inputVal) => {
    if (inputVal!==null && inputVal!==undefined && inputVal!=="" && inputVal.length>0)
      if(highlightInputFormat==="genomic")
        setVariantToHighlight(inputVal.split(',').map( pos => ({POSITION:parseInt(pos)}) ))
      else if(highlightInputFormat==="rna")
        setVariantToHighlight(inputVal.split(',').map( pos => ({RNA_POS:parseInt(pos)}) ))
      else if(highlightInputFormat==="cdna")
        setVariantToHighlight(inputVal.split(',').map( pos => ({CDNA_POS:parseInt(pos)}) ))
      else if(highlightInputFormat==="aa")
        setVariantToHighlight(inputVal.split(',').map( pos => ({PRT_POS:parseInt(pos)}) ))
  }

  useEffect(() => {
    updateHighlightedVariants(inputPositions);
  }, [highlightInputFormat, inputPositions])
  
  return (
    <div >
    {/* <div className='m-5 p-5'> */}
      <div className="d-flex flex-row my-2 mx-3">
        <Autosuggest 
          suggestions={suggestions}
          // alwaysRenderSuggestions={true}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          highlightFirstSuggestion={true}
          inputProps={AutosuggestInputProps}
        />
        <div className="mx-5" >
          <label className="mx-2" >
            <input className="form-check-input me-1" type="radio" name="highlight_input_format" 
              onChange={(e) => setHighlightInputFormat("genomic")} 
              checked={highlightInputFormat==="genomic"}
            />Genomic
          </label>
          <label className="mx-2" >
            <input className="form-check-input me-1" type="radio" name="highlight_input_format" 
              onChange={(e) => setHighlightInputFormat("rna")} 
              checked={highlightInputFormat==="rna"}
            />RNA
          </label>
          <label className="mx-2" >
            <input className="form-check-input me-1" type="radio" name="highlight_input_format" 
              onChange={(e) => setHighlightInputFormat("cdna")} 
              checked={highlightInputFormat==="cdna"}
            />cDNA
          </label>
          <label className="mx-2" >
            <input className="form-check-input me-1" type="radio" name="highlight_input_format" 
              onChange={(e) => setHighlightInputFormat("aa")} 
              checked={highlightInputFormat==="aa"}
            />Amino Acid
          </label>
          <input className="my-0 mx-2" type="text" name="variant_to_highlight" 
            placeholder="Position(s) to highlight. e.g.: 10,25,50" title="use , to separate positions" aria-label="" size="40"
            // onChange={(e => setVariantToHighlight({POSITION:parseInt(e.target.value), PRT_POS:parseInt(e.target.value)}))}
            onChange={e => setInputPositions(e.target.value)}
          />
        </div>
      </div>
      {/* {(!!selectedGene.entrez_id ?  */}
        <Firefly 
          // geneName={selectedGene.name}
          // geneEntrezId={selectedGene.entrez_id}
          // geneNameEntrezId={{name: null, entrez_id:null}}
          // geneNameEntrezId={selectedGene}
          entrezId={selectedGene.entrez_id}
          // geneName={(!!params.geneName ? params.geneName.toUpperCase() : null)}
          highlight={variantToHighlight}
          // highlight={{POSITION:50, REF:'A', ALT:'C', HGVSc:'HGVSc', HGVSp:'HGVSp', PRT_POS:50}}
          extraVariants={variantsToImport}
          useStagingEnv = {process.env.REACT_APP_ENV!=='prod'}
        // tracks={[]}
        />
      {/* : <h3 className='flex-center'>no Entrez ID found for {params.geneName}</h3>)} */}
      {(!!selectedGene && !!selectedGene.entrez_id ?
      <div className="mt-3 mx-2">
        <h5 className="cursor-pointer" onClick={e => setIsShownImportPanel(prevVal => !prevVal)}>
          {(isShownImportPanel ? "Import Variants" : "Click to import custom variants")} {(isShownImportPanel ? <i className="fas fa-angle-down"></i> : <i className="fas fa-angle-right"></i>)}
        </h5>
        {(isShownImportPanel && !!selectedGene && !!selectedGene.entrez_id ? 
          <>
          <div className="ms-2 mb-0" >
            Input Format:
            <label className="mx-2" >
              <input className="form-check-input me-1" type="radio" name="input_format" 
                onChange={(e) => setImportFormat("gdot")} 
                checked={importFormat==="gdot"}
              />G dot
            </label>
            <label className="mx-2" >
              <input className="form-check-input me-1" type="radio" name="input_format" 
                onChange={(e) => setImportFormat("cdot")} 
                checked={importFormat==="cdot"}
              />C dot
            </label>
            <label className="mx-2" >
              <input className="form-check-input me-1" type="radio" name="input_format" 
                onChange={(e) => setImportFormat("pdot")} 
                checked={importFormat==="pdot"}
              />P dot
            </label>
            <label className="mx-2" >
              <input className="form-check-input me-1" type="radio" name="input_format" 
                onChange={(e) => setImportFormat("hgvs")} 
                checked={importFormat==="hgvs"}
              />Infer from HGVS (Not Recommended)
            </label>
          </div>
          <div className="ms-2 mb-0" >Columns:
            {(importFormat==="gdot" ? " G dot position,REF,ALT,Effect,Significance,HGVSc,HGVSp,Description" :
            (importFormat==="cdot" ? " C dot position,Effect,Significance,HGVSc,HGVSp,Description": 
            (importFormat==="pdot" ? " P dot position,Effect,Significance,HGVSc,HGVSp,Description": 
            (importFormat==="hgvs" ? " Effect,Significance,HGVSc,HGVSp,Description": "N/A"))))}
          </div>
          <textarea className="m-2 p-1" value={variantsAsText} 
            placeholder={(importFormat==="gdot" ? "e.g: 112450700,G,T,missense,pathogenic,c.235C>A,p.Gly46Val,Description" :
            (importFormat==="cdot" ? "e.g: 179,missense,pathogenic,c.179C>A,p.Gly46Val,Description": 
            (importFormat==="pdot" ? "e.g: 46,missense,pathogenic,c.235C>A,p.Gly46Val,Description": 
            (importFormat==="hgvs" ? "e.g: missense,pathogenic,c.235C>A,p.Gly46Val,Description":""))))}
            rows="10" style={{width:'70%'}} onChange={e => setVariantsAsText(e.target.value)}
          />
          <br/>
          <button type="button" className="btn btn-secondary ms-2" aria-label="Add" onClick={() => importVariants()}>Import</button>
          <br/>
          <div className="mx-5">
            <br/>Important points:<br/>
            <ul>
              <li>The minimum input is a cDot position, e.g: <code>500</code><br/>This draws a lollipop (when the "Significance Filter" is set to "All Variants")
              under "Other" effect category which is not shown by default (click on "Other" to toggle the display).<br/><br/>
              </li>
              <li>However, we strongly suggest to provide values for the "Effect" and "Significance" classes to benefit from all Firefly features, e.g:</li>
                <p><code>500,missense,vus<br/>250,inframe,benign<br/>450,frameshift,pathogenic</code></p>
              <li>Provide the "Effect" class (exactly as below since it is case-sensitive) for your variants if you want to see them color coded .
                <ul>
                  <li>splice_region</li>
                  <li>splice_site</li>
                  <li>stop_gained</li>
                  <li>frameshift</li>
                  <li>stop_lost</li>
                  <li>inframe</li>
                  <li>missense</li>
                  <li>synonymous</li>
                  <li>initiator_codon</li>
                  <li>UTR</li>
                  <li>intron</li>
                </ul>
                <br/>
              </li>
              <li>If you like to use "Significance Filters", provide values for significance column (exactly as below since it is case-sensitive).
                <ul>
                  <li>benign</li>
                  <li>pathogenic</li>
                  <li>vus</li>
                  <li>conflicting</li>
                  <li>not_provided</li>
                  <li>others</li>
                </ul>
                <br/>
              </li>
              <li>All other columns are optional.<br/></li>
              <li>Use the "Track" selector on the top right corner to show "ClinVar", "Imported Varinats", or both!<br/></li>
            </ul>
            <br/><br/><br/><br/>
          </div>
        </> : null)}
      </div>
      : null)}
    </div>
  )
  }

// export default DynamicPlots;
export default withRouter(DynamicPlots);
