import React, { useState, useEffect, useMemo } from "react"; // , Fragment
import { useTable, useSortBy, useFlexLayout, useColumnOrder, useFilters,
    //  useGlobalFilter, useAsyncDebounce, useRowSelect, useBlockLayout, 
     useResizeColumns,  usePagination } from "react-table";
// import matchSorter from 'match-sorter'
import Dropdown from 'react-bootstrap/Dropdown';
// import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
// import Form from 'react-bootstrap/Form';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import {CaseStatusOptions} from '../HelperFunctions' 

export default function CaseTable({inputData, inputColumns, visibleColumnsIds, currentCaseId, caseClickAction,
   updateCaseTableData, caseTableTagFilter, setCaseTableTagFilter }) {

  // const [rowIdInEditMode, setRowIdInEditMode] = useState(null);

  // // Define a default UI for filtering
  // function GlobalFilter({
  //   preGlobalFilteredRows,
  //   globalFilter,
  //   setGlobalFilter,
  // }) {
  //   const count = preGlobalFilteredRows.length
  //   const [value, setValue] = React.useState(globalFilter)
  //   const onChange = useAsyncDebounce(value => {
  //     setGlobalFilter(value || undefined)
  //   }, 200)

  //   return (
  //     <span>
  //       Search:{' '}
  //       <input
  //         value={value || ""}
  //         onChange={e => {
  //           setValue(e.target.value);
  //           onChange(e.target.value);
  //         }}
  //         placeholder={`${count} records...`}
  //         style={{
  //           fontSize: '1.1rem',
  //           border: '0',
  //         }}
  //       />
  //     </span>
  //   )
  // }

  // function testColumnFilter(props) {
  //   console.log(props);
  //   return (<></>)
  // }

  // Define a default UI for filtering
  function DefaultColumnFilter() {return (<></>)}

  // Define a default UI for filtering
  function SimpleColumnFilter({
    column: { filterValue, preFilteredRows, setFilter, Header },
  }) {
    const count = preFilteredRows.length

    return (
      <input style={{width:'100%', maxWidth:'20rem',lineHeight:'normal' }}
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${Header} (${count} records)`}
        // placeholder={`Search ${count} records...`}
      />
    )
  }

  // This is a custom filter UI for selecting
  // a unique option from a list
  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set()
      preFilteredRows.forEach(row => {
        options.add(row.values[id])
      })
      return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    )
  }

  const colourStyles= {
    input: (styles) => ({ ...styles, display:'none' }),
    placeholder: (styles) => ({ ...styles, marginTop:'-5px' }),
    control: (base, state) => ({
      ...base,
      height: '30px',
      minHeight: '30px',
    }),
    multiValue: (base) => ({
      ...base,
      padding:'0px',
      marginTop:'-5px',
    }),
    valueContainer: (base) => ({
        ...base,
        webkitFlexWrap:'nowrap',
        flexWrap:'nowrap',
    })
  };

  const [useORforSearch, setUseORforSearch]=useState(false);
  
  // This is a custom filter UI for selecting
  // a unique option from a list
  function TagColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id }, useORforSearch, // setCaseTableTagFilter, caseTableTagFilter
  }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      if (!useORforSearch ) preFilteredRows=filterTagsFunction(preFilteredRows, id, filterValue, useORforSearch)
      preFilteredRows.forEach(row => {
        try{
          row.values[id].forEach( t => options.add(t))
        } catch (e) {
          options.add(String(row.values[id]));
        }
      })
      return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
      <>
        <Select className="m-0 text-dark xx" 
          isMulti
          closeMenuOnSelect={true}
          hideSelectedOptions={false}
          options={options.map(i => ({value: i, label:i}) )}
          onChange={selectedOption => {
            let newFilter = [useORforSearch,...selectedOption.map(k => k.value)];
            setFilter(newFilter);
            // setCaseTableTagFilter(newFilter);
          }}
          styles={colourStyles}
        />
      </>
    );
  }


  // This is a custom filter UI for selecting
  // a unique option from a list
  function MutiSelectColumnFilter_OR_opration({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set()
      preFilteredRows.forEach(row => {
        try{
          row.values[id].forEach( t => options.add(t))
        } catch (e) {
          options.add(String(row.values[id]));
        }
      })
      return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
      <Select className="m-0 text-dark xx" 
        isMulti
        closeMenuOnSelect={true}
        hideSelectedOptions={false}
        options={options.map(i => ({value: i, label:i}) )}
        onChange={selectedOption => setFilter(selectedOption.map(k => k.value))}
        styles={colourStyles}
      />
    );
  }


  // function EnrollmentColumnFilter({
  //   column: { filterValue, setFilter, preFilteredRows, id },
  // }) {
  //   // Calculate the options for filtering
  //   // using the preFilteredRows
  //   const options = React.useMemo(() => {
  //     const options = new Set()
  //     preFilteredRows.forEach(row => {
  //       options.add(row.values[id])
  //     })
  //     return [...options.values()]
  //   }, [id, preFilteredRows])

  //   // Render a multi-select box
  //   return (
  //     <select
  //       value={filterValue}
  //       onChange={e => {
  //         setFilter(e.target.value || undefined)
  //       }}
  //     >
  //       <option value='Enrolled'>Enrolled</option>
  //       {options.map((option, i) => (
  //         <option key={i} value={option}>
  //           {option}
  //         </option>
  //       ))}
  //     </select>
  //   )
  // }

  function CaseIDColumnFilter({
    column: { filterValue, preFilteredRows, setFilter, Header },
  }) {
    const count = preFilteredRows.length

    return (
      <input style={{width:'100%', maxWidth:'20rem',lineHeight:'normal' }}
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${Header} (${count} records)`}
        // placeholder={`Search ${count} records...`}
      />
    )
  }

  // identical to SelectColumnFilter but a - in the value!!
  function PriorityColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set()
      preFilteredRows.forEach(row => {
        options.add(row.values[id])
      })
      return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {-option}
          </option>
        ))}
      </select>
    )
  }

  // This is a custom filter UI for selecting
  // a unique option from a list
  function AnalysisSelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set()
      preFilteredRows.forEach(row => {
        // // console.log(row.values[id].map(t => t.ANALYSIS_ID));
        try{
          row.values[id].forEach( t => options.add(t.ANALYSIS_TYPE))
        } catch (e) {
          options.add(String(row.values[id]));
        }
      })
      return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
      <select
        style={{maxWidth:"100%"}}
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    )
  }

  // // This is a custom filter UI that uses a
  // // slider to set the filter value between a column's
  // // min and max values
  // function SliderColumnFilter({
  //   column: { filterValue, setFilter, preFilteredRows, id },
  // }) {
  //   // Calculate the min and max
  //   // using the preFilteredRows

  //   const [min, max] = React.useMemo(() => {
  //     let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
  //     let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
  //     preFilteredRows.forEach(row => {
  //       min = Math.min(row.values[id], min)
  //       max = Math.max(row.values[id], max)
  //     })
  //     return [min, max]
  //   }, [id, preFilteredRows])

  //   return (
  //     <>
  //       <input
  //         type="range"
  //         min={min}
  //         max={max}
  //         value={filterValue || min}
  //         onChange={e => {
  //           setFilter(parseInt(e.target.value, 10))
  //         }}
  //       />
  //       <button onClick={() => setFilter(undefined)}>Off</button>
  //     </>
  //   )
  // }

  // // This is a custom UI for our 'between' or number range
  // // filter. It uses two number boxes and filters rows to
  // // ones that have values between the two
  // function NumberRangeColumnFilter({
  //   column: { filterValue = [], preFilteredRows, setFilter, id },
  // }) {
  //   const [min, max] = React.useMemo(() => {
  //     let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
  //     let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
  //     preFilteredRows.forEach(row => {
  //       min = Math.min(row.values[id], min)
  //       max = Math.max(row.values[id], max)
  //     })
  //     return [min, max]
  //   }, [id, preFilteredRows])

  //   return (
  //     <div
  //       style={{
  //         display: 'flex',
  //       }}
  //     >
  //       <input
  //         value={filterValue[0] || ''}
  //         type="number"
  //         onChange={e => {
  //           const val = e.target.value
  //           setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
  //         }}
  //         placeholder={`Min (${min})`}
  //         style={{
  //           width: '70px',
  //           marginRight: '0.5rem',
  //         }}
  //       />
  //       to
  //       <input
  //         value={filterValue[1] || ''}
  //         type="number"
  //         onChange={e => {
  //           const val = e.target.value
  //           setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
  //         }}
  //         placeholder={`Max (${max})`}
  //         style={{
  //           width: '70px',
  //           marginLeft: '0.5rem',
  //         }}
  //       />
  //     </div>
  //   )
  // }

  // function fuzzyTextFilterFn(rows, id, filterValue) {
  //   return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
  // }

  // // Let the table remove the filter if the string is empty
  // fuzzyTextFilterFn.autoRemove = val => !val


  // // Define a custom filter filter function!
  // function filterGreaterThan(rows, id, filterValue) {
  //   return rows.filter(row => {
  //     const rowValue = row.values[id]
  //     return rowValue >= filterValue
  //   })
  // }

  // // This is an autoRemove method on the filter function that
  // // when given the new filter value and returns true, the filter
  // // will be automatically removed. Normally this is just an undefined
  // // check, but here, we want to remove the filter if it's not a number
  // filterGreaterThan.autoRemove = val => typeof val !== 'number'

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      // fuzzyText: fuzzyTextFilterFn,

      // Or, override the default text filter to use
      // "startWith"
      // text: (rows, id, filterValue) => {
      //   return rows.filter(row => {
      //     const rowValue = row.values[id];
      //     return rowValue !== undefined
      //       ? String(rowValue)
      //           .toLowerCase()
      //           .startsWith(String(filterValue).toLowerCase())
      //       : true
      //   })
      // },

      // * define a custom search for analysis
      analysisTypeFilter: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? rowValue.map(t => t.ANALYSIS_TYPE).includes(filterValue)
            : true
        })
      },
    }),
    []
  );



  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 50,
      // width: 100,
      // maxWidth: 800,
      Filter: DefaultColumnFilter,
    }),
    []
  )
  
  const data = useMemo(() => inputData, [inputData]);

  const VanexCaseIDCell = ({
    value: initialValue,
    row: { values },
    column: { id }
  }) => {
    const [altID, setAltID] = useState('');
    useEffect(() => {
      let tmpAltId = values['ALT_ID'];
      if(!!tmpAltId) setAltID(`(${values['ALT_ID']})`);
    }, [initialValue]);

    return(<div style={{alignSelf:'baseline', marginLeft:'0.5em'}}>{initialValue}&nbsp;&nbsp;{altID}</div>)
  }

  const VanexStarCell = ({
    value: initialValue,
    row: { values },
    column: { id },
    // rowIdInEditMode,
    updateCaseTableData
  }) => {
    // const [newValue, setNewValue] = useState(initialValue === 0 ? 5 : 0); //useState(initialValue === 1 ? 5 : (initialValue === 5 ? 0 : 1 ) );
    const [newValue, setNewValue] = useState(initialValue === 0 ? 5 : (initialValue === 5 ? 2 : 0) ); //useState(initialValue === 1 ? 5 : (initialValue === 5 ? 0 : 1 ) );
    const [isEditing, setIsEditing] = useState(false);

    function onPointerLeave() {
      if(newValue!==initialValue){
        // let newValue = (initialValue === 1 ? 5 : (initialValue === 5 ? 0 : 1 ) )
        updateCaseTableData(values, id, newValue);
      }
      setIsEditing(false);
    }

    if(isEditing)
      return(<span onPointerLeave={onPointerLeave} onDoubleClick={() => setNewValue((newValue === 0 ? 5 : (newValue === 5 ? 2 : 0)))} >{( newValue === 1 ? <i className="fas fa-star text-primary"></i> :  ( newValue=== 2 ? <i className="fas fa-star green-light"></i> : (newValue=== 5 ? <i className="fas fa-star red-mid"></i> : <i className="far fa-star text-secondary"></i>) ) )}</span>)
      // return(<span onPointerLeave={onPointerLeave} onClick={() => setNewValue((newValue === 0 ? 5 : 0))} >{( newValue === 1 ? <i className="fas fa-star text-primary"></i> :  (newValue=== 5 ? <i className="fas fa-star red-mid"></i> : <i className="far fa-star text-secondary"></i>))}</span>)
      // return(<span onPointerLeave={onPointerLeave} onClick={() => setNewValue((newValue === 1 ? 5 : (newValue === 5 ? 0 : 1 )))} >{( newValue === 1 ? <i className="fas fa-star text-primary"></i> :  (newValue=== 5 ? <i className="fas fa-star red-mid"></i> : <i className="far fa-star text-secondary"></i>))}</span>)
    else
      // return(<span  >{( initialValue === 1 ? <i className="fas fa-star text-primary"></i> :  (initialValue=== 5 ? <i className="fas fa-star red-mid"></i> : <i className="far fa-star text-secondary"></i>))}</span>)
      // return(<span  className="w-100 cursor-pointer" onDoubleClick={()=> setIsEditing(true)}>{( initialValue === 1 ? <i className="fas fa-star text-primary"></i> :  (initialValue=== 5 ? <i className="fas fa-star red-mid"></i> : <i className="far fa-star text-secondary"></i>))}</span>)
      return(<span  className="w-100 cursor-pointer" onDoubleClick={()=> setIsEditing(true)}>{( initialValue === 1 ? <i className="fas fa-star text-primary"></i> :  (initialValue=== 2 ? <i className="fas fa-star green-light"></i> :  (initialValue=== 5 ? <i className="fas fa-star red-mid"></i> : <i className="far fa-star text-secondary"></i> ) ))}</span>)
  }

  const VanexPriorityCell = ({
    value: initialValue,
    row: { values },
    column: { id },
    // rowIdInEditMode,
    updateCaseTableData
  }) => {
    const [newValue, setNewValue] = useState(-initialValue);
    const [isEditing, setIsEditing] = useState(false);

    function saveOnPointerLeave() {
      if(newValue!==initialValue){
        // let newValue = (initialValue === 1 ? 5 : (initialValue === 5 ? 0 : 1 ) )
        updateCaseTableData(values, id, -newValue);
        // updateCaseTableData(values, id, newValue);
      }
      setIsEditing(false);
    }

    // function getStepText(inVal) { //, isNegative=true
    //   // if(isNegative) inVal = -inVal;
    //   switch (inVal) {
    //     case 1:
    //       return "pre QC"
    //     case 2:
    //       return "QC"
    //     case 3:
    //       return "QC done/ready 1st"
    //     case 4:
    //       return "1st pass done"
    //     case 5:
    //       return "needs more digging"
    //     case 6:
    //       return "needs follow up"
    //     case 7:
    //       return "ready for SC"
    //     case 8:
    //       return "Waiting on GM"
    //     case 9:
    //       return "Unsolved & Closed"
    //     case 10:
    //       return "Solved & Closed"
    //     case 11:
    //       return "Partial Solved & Closed"
    //     default:
    //       return "NA"
    //   }
    // } 
    

    if(isEditing)
      return(<input className="w-100" style={{maxWidth:"3rem"}} type="number" min="0" max="99" step="1" value={newValue}
        onChange={e => setNewValue(e.target.value)} 
        onPointerLeave={saveOnPointerLeave}
      />)
      // return(
      //   <div className="d-flex flex-row">
      //     <span  className="w-100">{(newValue===null || newValue ===undefined || newValue==='' || newValue===0) ? '-' : getStepText(newValue,false)}</span>
      //     <input className="w-100" style={{maxWidth:"3rem"}} type="number" min="0" max="99" step="1" value={newValue}
      //     onChange={e => setNewValue(e.target.value)} 
      //     // onPointerLeave={saveOnPointerLeave}
      //     />
      //     <button onClick={saveOnPointerLeave}>S</button>
      //   </div>)
    else
      // return(<span  className="w-100" onDoubleClick={()=> setIsEditing(true)}>{(initialValue===null || initialValue ===undefined || initialValue==='' || initialValue===0) ? '-' : getStepText(initialValue,true)}
      //   {/* {(initialValue ? <i className="fas fa-star"></i> : <i className="far fa-star"></i>)} */}
      //   </span>)
      return(<span  className="w-100" onDoubleClick={()=> setIsEditing(true)}>{(initialValue===null || initialValue ===undefined || initialValue==='' || initialValue===0) ? '-' : -initialValue}
        {/* {(initialValue ? <i className="fas fa-star"></i> : <i className="far fa-star"></i>)} */}
        </span>)
  }

  const VanexCaseStatusCell = ({
    value: initialValue,
    row: { values },
    column: { id },
    // rowIdInEditMode,
    updateCaseTableData
  }) => {
    const [newValue, setNewValue] = useState(initialValue);
    const [isEditing, setIsEditing] = useState(false);
    const [caseStatusSelectOptions, setCaseStatusSelectOptions] = useState(CaseStatusOptions);

    useEffect( () => {
      if(!caseStatusSelectOptions.includes(initialValue)){
        let tmpOptns = [...caseStatusSelectOptions]
        tmpOptns.push(initialValue)
        setCaseStatusSelectOptions(tmpOptns)
      }
    }, [initialValue])

    // function saveOnPointerLeave() {
    //   // if(newValue!==initialValue){
    //   //   // let newValue = (initialValue === 1 ? 5 : (initialValue === 5 ? 0 : 1 ) )
    //   //   updateCaseTableData(values, id, newValue);
    //   // }
    //   // setIsEditing(false);
    //   console.log("Dddd")
    // }

    function saveOnChange(e) {
      if(e!==null && e!==undefined && e.value!==null && e.value!==undefined && e.value.trim()!==''){
        setNewValue(e.value)
        if(e.value!==initialValue){
          // let newValue = (initialValue === 1 ? 5 : (initialValue === 5 ? 0 : 1 ) )
          updateCaseTableData(values, id, e.value);
        }
        setIsEditing(false);
      }
    }

    if(isEditing){
      return(
        <div className="w-100" onPointerLeave={()=>setIsEditing(false)}>

        <CreatableSelect
          isClearable
          className="w-100 px-1" 
          styles={{
            menu: (provided, state) => ({
              ...provided,
              marginTop:1
            }), 
          }}
          options={caseStatusSelectOptions.map(i => ({value: i, label:i}) )}
          // onCreateOption={createNewFilterSet}
          defaultValue={caseStatusSelectOptions.filter(k => k===initialValue).map(i => ({value: i, label:i}) )[0] || null}
          onChange={saveOnChange} 
          // onChange={e => ( (e!==null && e!==undefined) ? setNewValue(e.value) : '')} 
          // onBlure={()=>console.log("onBlure")}
          // onFocus={()=>console.log("onfocus")}
          // onFocusout={()=>console.log("onfocusout")}
          // onChange={()=>console.log("onChange")}
          // onInputChange={()=>console.log("onInputChange")}
          // onMenuClose={()=>console.log("onMenuClose")}
          // onSelect={()=>console.log("onSelect")}
          // onSelectCapture={()=>console.log("onSelectCapture")}
          // onPointerLeave={()=>console.log("onPointerLeave")}
          // onPointerLeaveCapture={()=>console.log("onPointerLeaveCapture")}
          // onMouseLeave={()=>console.log("onMouseLeave")}
        />
        </div>
      )

      // return(
      //   <Select
      //     className="w-100 px-1" 
      //     styles={{
      //       menu: (provided, state) => ({
      //         ...provided,
      //         marginTop:1
      //       }), 
      //     }}
      //     options={caseStatusSelectOptions.map(i => ({value: i, label:i}) )}
      //     // onCreateOption={createNewFilterSet}
      //     defaultValue={caseStatusSelectOptions.filter(k => k===initialValue).map(i => ({value: i, label:i}) )[0]}
      //     // onChange={e => console.log(e)} 
      //     onChange={e => ( (e!==null && e!==undefined) ? setNewValue(e.value) : '')} 
      //     // onPointerLeave={saveOnPointerLeave}
      //   />
      // )


      // return(
      //   <input className="" style={{width:"100%"}} type="text" value={newValue}
      //     onChange={e => setNewValue(e.target.value)} 
      //     onPointerLeave={saveOnPointerLeave}
      //   />
      // )
    }
    else
      // return(<span  >{(initialValue===null || initialValue ===undefined || initialValue==='' || initialValue===0) ? '-' : initialValue}</span>)
      return(<span className="w-100" onDoubleClick={()=> setIsEditing(true)}>{(initialValue===null || initialValue ===undefined || initialValue==='' || initialValue===0) ? '-' : initialValue}</span>)
  }

  const VanexCaseOutcomeCell = ({
    value: initialValue,
    row: { values },
    column: { id },
    // rowIdInEditMode,
    updateCaseTableData
  }) => {
    const [newValue, setNewValue] = useState(initialValue);
    const [isEditing, setIsEditing] = useState(false);

    function saveOnPointerLeave() {
      if(newValue!==initialValue){
        // let newValue = (initialValue === 1 ? 5 : (initialValue === 5 ? 0 : 1 ) )
        updateCaseTableData(values, id, newValue);
      }
      setIsEditing(false);
    }

    if(isEditing)
      return(<input className="" style={{width:"100%"}} type="text" value={newValue}
        onChange={e => setNewValue(e.target.value)} 
        onPointerLeave={saveOnPointerLeave}
      />)
    else
      // return(<span  >{(initialValue===null || initialValue ===undefined || initialValue==='' || initialValue===0) ? '-' : initialValue}</span>)
      return(<span  className="w-100" onDoubleClick={()=> setIsEditing(true)}>{(initialValue===null || initialValue ===undefined || initialValue==='' || initialValue===0) ? '-' : initialValue}</span>)
  }

  const VanexTagsCell = ({
    value: initialValue,
    row: { values },
    column: { id },
    // rowIdInEditMode,
    updateCaseTableData
  }) => {
    // const [newValue, setNewValue] = useState(initialValue);
    // const [isEditing, setIsEditing] = useState(false);
    // let TagOptions = ['Red','Green','Blue','Yellow','Gray'].map(t => ({value: t, label:t}));
    // // TagOptions = [...new Set(projectDb.map(t => t.TAGS).flat().concat(TagOptions.map(m => m.label)).filter(z => z.trim()!=="") )].map(t => ({value: t, label:t}));

    // function saveOnPointerLeave() {
    //   if(newValue!==initialValue){
    //     // let newValue = (initialValue === 1 ? 5 : (initialValue === 5 ? 0 : 1 ) )
    //     updateCaseTableData(values, id, newValue);
    //   }
    //   setIsEditing(false);
    // }

    // if(isEditing)
    //   return(
    //     <CreatableSelect
    //       isMulti
    //       closeMenuOnSelect={false}
    //       className="w-100"
    //       // defaultValue={values.TAGS.map(tagVal => TagOptions.find(t => t['value']===tagVal))}
    //       // onChange={ selectedOption => setValues({ ...values, TAGS: selectedOption.map(m => m.value)})}
    //       // onChange={ selectedOption => console.log(selectedOption.map(m => m.value))}
    //       options={TagOptions}
    //       placeholder='Select Tags'
    //     // onChange={e => setNewValue(e.target.value)} 
    //     // onPointerLeave={saveOnPointerLeave}
    //   />)
    // else
    //   return(<span className="w-100" onDoubleClick={()=> setIsEditing(true)}>{(initialValue===null || initialValue ===undefined || initialValue==='' || initialValue===0) ? '-' : initialValue.join(', ')}</span>)

    return(<span className="w-100">{(initialValue===null || initialValue ===undefined || initialValue==='' || initialValue===0) ? '-' : initialValue.join(', ')}</span>)
  }

  const VanexPrimaryPhenotypeCell = ({
    value: initialValue,
    row: { values },
    column: { id }
  }) => {
    return(<span className="primary-phenotype-cell">{(initialValue===null || initialValue ===undefined || initialValue==='') ? '-' : initialValue}</span>)
  }

  const VanexCaseTableDefaultCell = ({
    value: initialValue,
    row: { values },
    column: { id }
  }) => {
    return(<span className="w-100">{(initialValue===null || initialValue ===undefined || initialValue==='')
      ?
        '-' 
      : 
        ((typeof(initialValue)==="object" && initialValue!== null) ? JSON.stringify(initialValue) : initialValue) }</span>)
  }

  // Define a custom filter function!
  function filterTagsFunction(rows, id, filterValue, useORforSearch) {
    let useORforSearch_inFilter = useORforSearch;
    if (!!filterValue && filterValue.length >0){
      useORforSearch_inFilter=filterValue[0];
      filterValue=filterValue.slice(1)
    } else {
      useORforSearch_inFilter =false;
    }

    return rows.filter(row => {
      if (!filterValue || filterValue.length ===0)
        return true;
      else{
        const rowValue = row.values[id]
        if(useORforSearch_inFilter)
          return filterValue.some(r => rowValue.includes(r))
        else
          return filterValue.every(r => rowValue.includes(r))
      }
    });
  }

  // Define a custom filter function!
  function filterMutiSelectFunction_OR_operation(rows, id, filterValue) {
    return rows.filter(row => {
      if (!filterValue || filterValue.length ===0)
        return true;
      else{
        const rowValue = row.values[id]
        return filterValue.some(r => rowValue===r)
      }
    });
  }

  // ["ID", "CASE_ID", "PROTOCOL", "PRIMARY_PHENOTYPE", "CASE_STATUS",  "STUDY_TYPE", "CASE_NOTE", "CASE_OUTCOME", "INDIVIDUALS", "ANALYSES",]
  // ['ID', 'CASE_ID', 'ALT_ID', 'PROTOCOL', 'PRIMARY_PHENOTYPE', 'CASE_STATUS', 'STUDY_TYPE', 'CASE_NOTE', 'CASE_OUTCOME', 'INDIVIDUALS', 'ANALYSES', 
  // 'MRPHENE_SUBJECT_ID', 'STAR', 'PRIORITY', 'CANDIDATES', 'TAGS', 'ENROLLMENT', 'LINK_JIRA', 'LINK_CONFLUENCE','NOTIFIED', 'GENEMATCHER', 'PUBLICATION']
  const columns = useMemo(() => {
    if(!!inputColumns)
      return inputColumns;
    if(!!inputData && !!inputData[0])
      return(
        Object.keys(inputData[0]).map( (key) => {
          if(key==="STAR")
            return {
              Header: '',
              accessor: key,
              // width: 100,
              style: {width: '2rem', margin:'0px', padding:'0px'},
              columnStyle: {width: '2rem', margin:'0px', padding:'0px'},
              // Cell: (props) => ( [...new Set(props.cell.value.map(t => t.ANALYSIS_TYPE))].join(', ')),
              // Filter: AnalysisSelectColumnFilter,
              // filter: 'analysisTypeFilter',
              Cell: VanexStarCell, // (props) => (   <span>{( props.cell.value === 1 ? <i className="fas fa-star text-primary"></i> :  (props.cell.value=== 5 ? <i className="fas fa-star red-mid"></i> : <i className="far fa-star text-secondary"></i>)  )}</span>   )
              // style: {fontWeight: 'bolder',textAlign: 'center'},
              // columnStyle: {textAlign: 'center'},
              // setColWidth: function(inWidth){ this.width = inWidth;}
            }
          if(key==="PRIORITY")
            return {
              Header: 'Priority',
              accessor: key,
              // width: 80,
              style: {width: '3.3rem', cursor: "default"}, //width: '10rem'
              columnStyle: {width: '3.3rem'}, // width: '10rem'
              Cell: VanexPriorityCell,
              Filter: PriorityColumnFilter,
              // Cell: (props) => ( [...new Set(props.cell.value.map(t => t.ANALYSIS_TYPE))].join(', ')),
              // Filter: AnalysisSelectColumnFilter,
              // filter: 'analysisTypeFilter',
              // Cell: (props) => ((typeof(props.cell.value)==="object" && props.cell.value!== null) ? JSON.stringify(props.cell.value) : props.cell.value)
              // style: {fontWeight: 'bolder',textAlign: 'center'},
              // columnStyle: {textAlign: 'center'},
              // setColWidth: function(inWidth){ this.width = inWidth;}
            }
          if(key==="CASE_ID")
            return {
              Header: 'Case ID',
              accessor: key,
              style: {minWidth: '7rem', cursor: "pointer"},
              columnStyle: {minWidth: '7rem'},
              Cell: VanexCaseIDCell,
              Filter: CaseIDColumnFilter,
              // Filter: SimpleColumnFilter,
              // filter: 'text',
              // width: 150,
              // style: {fontWeight: 'bolder',textAlign: 'center'},
              // columnStyle: {textAlign: 'center'},
              // setColWidth: function(inWidth){ this.width = inWidth;}
            }
          if(key==="ALT_ID")
            return {
              Header: 'Alternative ID',
              accessor: key,
              style: {minWidth: '7rem', cursor: "pointer"},
              columnStyle: {minWidth: '7rem'},
              Filter: SimpleColumnFilter,
              // Cell: VanexCaseTableDefaultCell,
              // width: 600,
              // setColWidth: function(inWidth){ this.width = inWidth;}
            }
          if(key==="PROTOCOL")
            return {
              Header: 'Protocol',
              accessor: key,
              style: {minWidth: '10rem'},
              columnStyle: {minWidth: '10rem'},
              Cell: VanexCaseTableDefaultCell,
              Filter: SelectColumnFilter,
            }
          if(key==="PRIMARY_PHENOTYPE")
            return {
              Header: 'Primary Phenotype',
              accessor: key,
              // style: {minWidth: '20rem', cursor: "pointer", whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis' },
              style: {minWidth: '15rem', cursor: "pointer", flexGrow:'2000' }, // minWidth: '20rem',
              columnStyle: {minWidth: '15rem', flexGrow:'2000'}, // minWidth: '20rem',
              Filter: SimpleColumnFilter,
              Cell: VanexPrimaryPhenotypeCell,
              // width: 600,
              // setColWidth: function(inWidth){ this.width = inWidth;}
            }
          if(key==="CASE_STATUS")
            return {
              Header: 'Status',
              accessor: key,
              style: {minWidth: '8rem', cursor: "default"},
              columnStyle: {minWidth: '8rem'},
              Cell: VanexCaseStatusCell,
              // Filter: SelectColumnFilter,
              Filter: MutiSelectColumnFilter_OR_opration,
              filter: filterMutiSelectFunction_OR_operation,
              // width: 100,
              // setColWidth: function(inWidth){ this.width = inWidth;}
            }
          if(key==="STUDY_TYPE")
            return {
              Header: 'Study Type',
              accessor: key,
              style: {minWidth: '10rem'},
              columnStyle: {minWidth: '10rem'},
              Cell: VanexCaseTableDefaultCell,
              // Filter: SelectColumnFilter,
              Filter: MutiSelectColumnFilter_OR_opration,
              filter: filterMutiSelectFunction_OR_operation,
            }
          if(key==="ANALYSES")
            return {
              Header: 'Analysis',
              accessor: key,
              // width: 100,
              style: {minWidth: '10rem', cursor: "pointer"},
              columnStyle: {minWidth: '10rem'},
              Cell: (props) => ( [...new Set(props.cell.value.map(t => t.ANALYSIS_TYPE))].join(', ')),
              Filter: AnalysisSelectColumnFilter,
              filter: 'analysisTypeFilter',
              // Cell: (props) => ((typeof(props.cell.value)==="object" && props.cell.value!== null) ? JSON.stringify(props.cell.value) : props.cell.value)
              // style: {fontWeight: 'bolder',textAlign: 'center'},
              // columnStyle: {textAlign: 'center'},
              // setColWidth: function(inWidth){ this.width = inWidth;}
            }
          if(key==="CASE_NOTE")
            return {
              Header: 'Case Note',
              accessor: key,
              style: {minWidth: '12rem', cursor: "pointer", maxHeight:"3rem"},
              columnStyle: {minWidth: '12rem'},
              Filter: SimpleColumnFilter,
              Cell: (props) => <div className="case-note-cell">{((typeof(props.cell.value)==="object" && props.cell.value!== null) ? JSON.stringify(props.cell.value) : props.cell.value)}</div>
            }
          if(key==="CASE_OUTCOME")
            return {
              Header: 'Outcome',
              accessor: key,
              width: 10,
              style: {minWidth: '6rem', cursor: "pointer"},
              columnStyle: {minWidth: '6rem'},
              Cell: VanexCaseOutcomeCell,
              // Filter: SelectColumnFilter,
              Filter: MutiSelectColumnFilter_OR_opration,
              filter: filterMutiSelectFunction_OR_operation,
            }
          if(key==="CANDIDATES")
            return {
              Header: 'Candidates',
              accessor: key,
              style: {minWidth: '10rem'},
              columnStyle: {minWidth: '10rem'},
              Cell: VanexCaseTableDefaultCell,
            }
          if(key==="TAGS")
            return {
              Header: 'Tags',
              accessor: key,
              // width: 100,
              style: {minWidth: '10rem', cursor: "pointer"},
              columnStyle: {minWidth: '10rem'},
              Cell: VanexTagsCell,
              Filter: TagColumnFilter,
              filter: filterTagsFunction,
              // filter: 'includes',
              // filter: 'analysisTypeFilter',
              // Cell: (props) => ((typeof(props.cell.value)==="object" && props.cell.value!== null) ? JSON.stringify(props.cell.value) : props.cell.value)
              // style: {fontWeight: 'bolder',textAlign: 'center'},
              // columnStyle: {textAlign: 'center'},
              // setColWidth: function(inWidth){ this.width = inWidth;}
            }
          if(key==="LINK_JIRA")
            return {
              Header: 'Jira',
              accessor: key,
              width: 50,
              // style: {minWidth: '3rem'},
              // columnStyle: {minWidth: '3rem'},
              Cell: (props) => (
                <span className="w-100">{(props.value===null || props.value ===undefined || props.value==='') ? '-' : 
                ((typeof(props.value)==="object" && props.value!== null) ? JSON.stringify(props.value) : 
                  <a href={props.value} target="_blank" rel="noreferrer" >Jira</a>) }</span>
              )
            }
          if(key==="LINK_CONFLUENCE")
            return {
              Header: 'Confl.',
              accessor: key,
              width: 50,
              // style: {minWidth: '3rem'},
              // columnStyle: {minWidth: '3rem'},
              Cell: (props) => (
                <span className="w-100">{(props.value===null || props.value ===undefined || props.value==='') ? '-' : 
                ((typeof(props.value)==="object" && props.value!== null) ? JSON.stringify(props.value) : 
                  <a href={props.value} target="_blank" rel="noreferrer" >Confl.</a>) }</span>
              )
            }
          if(key==="ENROLLMENT")
            return {
              Header: 'Enrollment',
              accessor: key,
              style: {minWidth: '8rem'},
              columnStyle: {minWidth: '8rem'},
              Cell: VanexCaseTableDefaultCell,
              Filter: SelectColumnFilter,
            }
          if(key==="NOTIFIED")
            return {
              Header: 'Notified',
              accessor: key,
              style: {minWidth: '10rem'},
              columnStyle: {minWidth: '10rem'},
              Cell: VanexCaseTableDefaultCell,
              // Filter: SelectColumnFilter,
            }
          if(key==="GENEMATCHER")
            return {
              Header: 'GeneMatcher',
              accessor: key,
              style: {minWidth: '10rem'},
              columnStyle: {minWidth: '10rem'},
              Cell: VanexCaseTableDefaultCell,
              // Filter: SelectColumnFilter,
            }
          if(key==="PUBLICATION")
            return {
              Header: 'Publication',
              accessor: key,
              style: {minWidth: '10rem'},
              columnStyle: {minWidth: '10rem'},
              Cell: VanexCaseTableDefaultCell,
              // Filter: SelectColumnFilter,
            }
          return {
            Header: key.replaceAll('_', ' ').toLowerCase().trim().split(" ").map(s => s[0].toUpperCase() + s.substring(1)).join(" "),
            accessor: key,
            style: {minWidth: '10rem'},
            columnStyle: {minWidth: '10rem'},
            Cell: VanexCaseTableDefaultCell,
            // Cell: (props) => ((typeof(props.cell.value)==="object" && props.cell.value!== null) ? JSON.stringify(props.cell.value) : props.cell.value)
          }
        }
      ));
      
    return [];
  }, [inputColumns, inputData]);

  // const columnOrderForUser = ["STAR", "PRIORITY", "CASE_ID", "ALT_ID", "PRIMARY_PHENOTYPE", "CASE_STATUS", "ANALYSES", "LINK_JIRA","LINK_CONFLUENCE","CASE_OUTCOME","TAGS"];
  const allTimeInvisibleColumns = ["ID", "STAR", "INDIVIDUALS", "MRPHENE_SUBJECT_ID"];

  const [hiddenColumnsIds, setHiddenColumnsIds] = useState( () => {
    let defaultVisibleColumns = visibleColumnsIds || ["STAR", "PRIORITY", "CASE_ID", "PRIMARY_PHENOTYPE", "CASE_STATUS", "ANALYSES", "CASE_NOTE" ,"LINK_JIRA","LINK_CONFLUENCE","CASE_OUTCOME", "TAGS", "ENROLLMENT"]; // "CASE_OUTCOME", "ALT_ID", 
    let hiddenColIds = [];
    if(!!inputData && !!inputData[0])
      hiddenColIds = Object.keys(inputData[0]).filter(t => !defaultVisibleColumns.includes(t) ).map( k => k);
      // console.log(hiddenColIds);
    return(hiddenColIds);
  });


  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    setFilter,
    // allColumns,
    // toggleHideColumn,
    // getToggleHideAllColumnsProps,
    // toggleHideAllColumns,
    // setColumnOrder,
    // state,
    // resetResizing,
    // preGlobalFilteredRows,
    // setGlobalFilter,

    // state,
    visibleColumns,
    // preGlobalFilteredRows,
    // setGlobalFilter,
    allColumns,
    // toggleHideColumn,
    // getToggleHideAllColumnsProps,
    // setHiddenColumns,
    // page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // pageCount,
    // gotoPage,
    // nextPage,
    // previousPage,
    // setPageSize,
    // state,
    // state: { pageIndex, pageSize, sortBy }
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      initialState: { 
        // pageIndex: 0,
        // pageSize: 100,
        columnOrder: visibleColumnsIds || ["STAR", "PRIORITY", "CASE_ID", "ALT_ID", "PRIMARY_PHENOTYPE", "CASE_STATUS", "ANALYSES", "LINK_JIRA","LINK_CONFLUENCE","CASE_OUTCOME", "CASE_NOTE", "TAGS", "ENROLLMENT"], //["STAR", "PRIORITY", "CASE_ID", "ALT_ID", "PRIMARY_PHENOTYPE", "CASE_STATUS", "ANALYSES", "TAGS"],
        hiddenColumns: hiddenColumnsIds,
        sortBy: [ {
            id: 'STAR',
            desc: true,
          },
          {
            id: 'PRIORITY',
            desc: false,
          },
          {
            id: 'ID',
            desc: true,
          } 
        ]
      }, // Array<ColumnId: String>
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      // rowIdInEditMode,
      useORforSearch,
      // setCaseTableTagFilter,
      updateCaseTableData
    },
    useColumnOrder,
    useResizeColumns,
    // useBlockLayout,
    useFlexLayout,
    useFilters,
    useSortBy,
    usePagination
    // useGlobalFilter
    );
  
  // const [filterInput, setFilterInput] = useState("");
  // const handleFilterChange = e => {
  //   const value = e.target.value || undefined;
  //   setFilter("Loc In Gene", value);
  //   setFilterInput(value);
  // };
  
  useEffect(() => {
    if(allColumns.map(k => k.id).includes("ENROLLMENT"))
      setFilter("ENROLLMENT", 'Enrolled');
    // if(!! caseTableTagFilter && caseTableTagFilter.length > 1)
    //   setFilter("TAGS", caseTableTagFilter);
    //   setHiddenColumnsIds(["ID", "PROTOCOL", "STUDY_TYPE", "CASE_NOTE", "CASE_OUTCOME", "INDIVIDUALS", "ANALYSES"]);
  }, [])

  // const [columnsToUse, setColumnsToUse] = useState(columns);
  // useEffect(() => {
  //   setColumnsToUse(
  //     Object.values(columns).filter(t => !allTimeInvisibleColumns.includes(t.accessor) )
  //       // .sort( (elA,elB) => {
  //       //   if(!columnOrderForUser.includes(elA.accessor) && !columnOrderForUser.includes(elB.accessor)) return 1;
  //       //   if(!columnOrderForUser.includes(elA.accessor) && columnOrderForUser.includes(elB.accessor)) return 1;
  //       //   if(columnOrderForUser.includes(elA.accessor) && !columnOrderForUser.includes(elB.accessor)) return -1;
  //       //   return(columnOrderForUser.indexOf(elA.accessor) - columnOrderForUser.indexOf(elB.accessor));
  //       // })
  //       );
  // }, [columns])
  
  const getRowColor = (values) => {
    if(!!values.TAGS){
      if(values.TAGS.includes('Red'))
        return 'bg-red';
      else if(values.TAGS.includes('Yellow'))
        return 'bg-orange';
      else if(values.TAGS.includes('Green'))
        return 'bg-green';
      // else if(values.TAGS.includes('Green'))
      //   return 'bg-green';
      else if(values.TAGS.includes('Blue'))
        return 'bg-info-blue';
      else if(values.TAGS.includes('Gray'))
        return 'bg-grey-lighter';
      else if(values.TAGS.includes('Pink'))
        return 'bg-pink';
      else if(values.TAGS.includes('Purple'))
        return 'row-bg-purple';
      else if(values.TAGS.includes('Dark Green'))
        return 'row-bg-dark-green';
      else if(values.TAGS.includes('Dark Blue'))
        return 'row-bg-dark-blue';
      else if(values.TAGS.includes('Dark Gray'))
        return 'row-bg-dark-gray';

    }

    if(!!values.CASE_STATUS && values.CASE_STATUS.toLowerCase()==="waiting")
      return 'bg-orange';
    if (!!values.CASE_OUTCOME){
      switch(values.CASE_OUTCOME.toLowerCase()) {
        case 'candidate':
          return 'bg-green';
        case 'solved':
          return 'bg-info-blue';
        case 'negative':
          return 'bg-grey-lighter';
        default:
          return 'bg-white';
      }
    }
    return 'bg-white';
  }


  // const caseListColumnSelectMenu = React.forwardRef(
  //   ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
  //     const [value, setValue] = useState('');
  //     // let columnsToUse = Object.values(columns).filter(t => !allTimeInvisibleColumns.includes(t.accessor) )
  //     //   .sort( (elA,elB) => {
  //     //     if(!columnOrderForUser.includes(elA.accessor) && !columnOrderForUser.includes(elB.accessor)) return 1;
  //     //     if(!columnOrderForUser.includes(elA.accessor) && columnOrderForUser.includes(elB.accessor)) return 1;
  //     //     if(columnOrderForUser.includes(elA.accessor) && !columnOrderForUser.includes(elB.accessor)) return -1;
  //     //     return(columnOrderForUser.indexOf(elA.accessor) - columnOrderForUser.indexOf(elB.accessor));
  //     //   });
  //     return (
  //       <div
  //         ref={ref}
  //         style={style}
  //         className={className}
  //         aria-labelledby={labeledBy}
  //       >
  //       {/* {columnsToUse.map( (column, index) => {
  //         return(
  //           <div className="no-white-space-wrap mx-1" key={index}>
  //             <label>
  //               <input type="checkbox" className="me-1"
  //                 checked={!hiddenColumnsIds.includes(column.accessor)}
  //                 onChange={(e) => handleToggleColumnVisibility(e, column.accessor)}
  //                 // disabled={(!hiddenColumnsIds.includes(column.accessor) && hiddenColumnsIds.length >= (Object.keys(columnData).length)-3)}
  //               />
  //               {column.Header}
  //             </label>
  //           </div>
  //       )} )} */}

  //         {/* <Form.Control
  //           autoFocus
  //           className="mx-3 my-2 w-auto"
  //           placeholder="Type to filter..."
  //           onChange={(e) => setValue(e.target.value)}
  //           value={value}
  //         /> */}
  //         <ul className="list-unstyled">
  //           {React.Children.toArray(children).filter(
  //             (child) =>
  //               !value || child.props.children.toLowerCase().startsWith(value),
  //           )}
  //         </ul>
  //       </div>
  //     );
  //   },
  // );

  const exportCaseTable = (scope) => {

    // console.log(scope);
    if (scope==="clipboard"){
      // setTrigerExportPreparation(true);
      // navigator.clipboard.writeText(preparedDataFromVariantTable);
      // navigator.clipboard.writeText(createTable());

      // console.log(allColumns);
      // console.log(data);
      const excludedColumns = ["ID","PROTOCOL","STAR","PRIORITY","ANALYSES","INDIVIDUALS","MRPHENE_SUBJECT_ID"];

      const header = allColumns.filter(col => !excludedColumns.includes(col.id)).map(k => k.Header).join('\t')+"\n";

      const rowData = data.map(caseInfo => 
        allColumns.filter(col => !excludedColumns.includes(col.id)).map(column => column.id).map(colId => {
          let cellValue = caseInfo[colId]; // let cellValue = caseInfo.values[colId]; if used "rows" 
          if (cellValue!== null && typeof(cellValue)==='string' && cellValue.trim()!== ''){
            // if(colId=="LINK_JIRA"){
            //   return("=HYPERLINK('"+cellValue+"';'JIRA')")
            // }
            // if(colId=="LINK_CONFLUENCE"){ 
            //   return("=HYPERLINK('"+cellValue+"';'JIRA')")
            // }
            cellValue = cellValue.replaceAll('\t', ' ').replaceAll('\n', ' ').trim()
          }
          return(cellValue)
         } ).join('\t')
      ).join("\n");

      navigator.clipboard.writeText(header + rowData);
      return;
    }

    // // ! find a better way to update progress bar?
    // const updateExportProgress = (value, final=false) => {
    //   setExportProgressbarValue(value);
    //   // setTimeout(() => {setExportProgressbarValue(value)}, 100); //! fix this hack!
    //   // const interval = setInterval(() => { // ! fix this!
    //   //   setExportProgressbarValue(value);
    //   //   if(final || value > 90) {
    //   //     setIsExporting(false);
    //   //     clearInterval(interval);
    //   //   }
    //   // }, 400);
    // }

    // setIsExporting(true);
    
    // let outputFileName = "VaNex-output";
    // let xlsxSheetData= [];

    // updateExportProgress(1);
    // let deepCopiedCaseData = JSON.parse(JSON.stringify(props.caseData));
    // updateExportProgress(10);

    // let totalTabs = Object.keys(tabs).length;
    // Object.keys(tabs).forEach( (tabId, index) => {
    //   if(shouldExportThisTab(scope, tabId)){
    //     // * apply filteres for each tab!
    //     let [tmpVariantData, filterSpec]= applyFilter(deepCopiedCaseData, tabId); //! [...props.caseData], tabId);  // ! [...props.caseData] or  props.caseData ?
    //     Object.keys(tmpVariantData).forEach( rId => {tmpVariantData[rId].VANEX_NOTES = tmpVariantData[rId].VANEX_NOTES['CURRENT_USER_NOTE']});
        
    //     // * add a sheet for each tab!
    //     xlsxSheetData.push([tabs[tabId].label , XLSX.utils.json_to_sheet(tmpVariantData, {header:['VANEX_TAGS', 'VANEX_NOTES', 'CHROMOSOME' ]})]);
    //     // xlsxSheetData.push([tabs[tabId].label , XLSX.utils.json_to_sheet(tmpVariantData, {header:['CHROMOSOME' POSITION	STOP_POSITION	REF	ALT	SAMPLES	INHERIT_MODEL	QUAL	FILTER	GENE	ENTREZ_ID	LOC_IN_GENE	DIST_TO_SPLICE_SITE	DIST_TO_CODING_REGION	EFFECT	IMPACT	MANE_HGVS	HGVS	HGVS_ALL_PRIORITIZED	INFORMATION, 	ROW_INDEX	VANEX_TAGS	VANEX_NOTES']})]);
    //   }
    //   updateExportProgress(10+((index+1)*(60/totalTabs)));
    // });

    // updateExportProgress(70);

    // //! add this? xlsxSheetData.push(['Filtered (raw)' , XLSX.utils.json_to_sheet(props.caseData)]);
    // //! add this? xlsxSheetData.push(['Filtered (Global)' , XLSX.utils.json_to_sheet(globalFilteredVariantData)]);
    // //! add this? xlsxSheetData.push(['Selected' , XLSX.utils.json_to_sheet(variantData)]);

		// const wb = XLSX.utils.book_new();
    // xlsxSheetData.forEach( element => {
    //   // console.log('appending: ', element);
    //   XLSX.utils.book_append_sheet(wb, element[1], element[0]);  
    // });

    // updateExportProgress(80);

    // if(!!props.currentCaseInformation){
    //   XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet([props.currentCaseInformation]), "Case Info");
    //   if(!!props.currentCaseInformation.CASE_ID){
    //     outputFileName = props.currentCaseInformation.CASE_ID;
    //   }
    // }
    // if(!!props.currentAnalysisInfo){
    //   XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet([props.currentAnalysisInfo]), "Analysis Info");
    // }

    // updateExportProgress(90);
    // /* generate XLSX file and send to client */
		// XLSX.writeFile(wb, outputFileName + ".xlsx");
    // toast.success('Export Completed!');
    // setIsExporting(false); // updateExportProgress(99, true);
  }

  // console.log(data);
  // console.log(columns);
  if(!!columns && !!data)
    return (
    <React.Fragment>
      <table disabled className="case-table bg-white flex-grow-1" {...getTableProps()}>
        <thead className="">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                // Add the sorting props to control sorting.
                <th {...column.getHeaderProps({
                    style: column.columnStyle,
                  })} 
                className={` d-flex flex-column 
                  ${ column.isSorted //! maybe use font awesome? <i className="fas fa-sort text-gray"></i>  <i className="fas fa-sort-up text-gray"></i>  <i className="fas fa-sort-down text-gray"></i>
                    ? column.isSortedDesc
                      ? "sort-desc"
                      : "sort-asc"
                    : ""
                }`}>
                  <div {...column.getHeaderProps(column.getSortByToggleProps())}
                  // className={" " + ( (column.addMargin) && "ms-4")}
                  style={( column.id==="PRIORITY" ? {flex:'1 auto', textAlign:"start"} : {flex:'1 auto'})}
                  >{column.render('Header')}</div>
                  {/* {column.addFilter ? 
                    <input style={{fontSize:'0.5rem'}} // <input className="form-control form-control-sm "
                    value={filterInput}
                    onChange={handleFilterChange}
                    placeholder={"Search ..."}
                    /> : null} */}
                    {(column.id==="TAGS" ? <div className="use-or-for-search-icon-panel" onClick={e => setUseORforSearch((current) => !current) }>{(useORforSearch ? <i className="fas fa-expand-arrows-alt" ></i> : <i className="fas fa-angle-double-down "></i>)}</div> : <></>)}
                    <div>{column.canFilter ? column.render('Filter') : <></>}</div>
                </th>
              ))}
              <th style={{width:'0px', display:"contents"}}>
              {/* <DropdownButton className="column-select-icon" size="sm" title={<i className="fas fa-bars text-white "></i>}>  */}
               {/* onSelect={(evtKey, evt) => setSubjectProject(evtKey)}> */}
                {/* <Dropdown.Item as="button" eventKey="Unfiltered">Unfiltered</Dropdown.Item> */}
                {/* {projects.map(project => {
                  return <Dropdown.Item as="button" key={project} eventKey={project}>{project}</Dropdown.Item>
                })} */}
              {/* </DropdownButton> */}

              <Dropdown as={ButtonGroup} onSelect={exportCaseTable} className="column-select-icon-panel" > 
                <Dropdown.Toggle id="dropdown-column-select-1" size="sm" variant="info" className="text-white bg-lighter-blue column-select-icon"><i className="fas fa-bars text-white "></i></Dropdown.Toggle>
                <Dropdown.Menu >
                  <Dropdown.Item as="button" eventKey="clipboard">Copy to Clipboard</Dropdown.Item>
                  {/* <Dropdown.Item as="button" onClick={exportCaseTable}>to Clipboard</Dropdown.Item> */}
                  <Dropdown.Divider />
                  {allColumns.filter(t => !allTimeInvisibleColumns.includes(t.id) ).map(clmn => (
                    <div className="no-white-space-wrap mx-1" key={clmn.id} >
                      <label>
                        <input type="checkbox" className="me-1"
                          {...clmn.getToggleHiddenProps()}
                        />
                        {clmn.Header}
                      </label>
                    </div>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              {/* <i className="fas fa-bars text-white column-select-icon"></i> */}
              {/* <i className="fas fa-ellipsis-v columns bars list list-alt filter-icons"></i> */}
              {/* <i className="fas fa-filter filter-icon" onClick={(e, selColumn=column) => (!!setDataForFilterSelectionModal) ? setDataForFilterSelectionModal(e, selColumn) : console.log("no setDataForFilterSelectionModal function found")}></i> */}
              </th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, ind) => {
          // {page.map((row, ind) => {
            prepareRow(row); // This line is necessary to prepare the rows and get the row props from react-table dynamically
            // Each row can be rendered directly as a string using the react-table render method
            return (
              <tr {...row.getRowProps()} 
                className={ getRowColor(row.values) }
                // className={ (currentCaseId===row.values.index) ? 'blue' : '' } 
                // onClick={() => caseClickAction(row.values)}
                // onDoubleClick={() => console.log(row.values.ID)}
                // onDoubleClick={() => setRowIdInEditMode(row.values.ID)}
                // onPointerLeave={() => setRowIdInEditMode(null)}
              >
                {row.cells.map(cell => {
                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                      ])}
                      onClick={() => ( (cell.column.id==="CASE_ID") || (cell.column.id==="ALT_ID") || (cell.column.id==="PRIMARY_PHENOTYPE") || (cell.column.id==="ANALYSES") || (cell.column.id==="CASE_OUTCOME") || (cell.column.id==="CASE_NOTE") || (cell.column.id==="TAGS") ? caseClickAction(row.values, (cell.column.id==="TAGS")) : null)}
                    >{cell.render("Cell")}</td>);
                    //{/* {(typeof(cell.value)==="object" && cell.value!== null) ? JSON.stringify(cell.value) : cell.render("Cell")} */}
                })}
                {/* <div className="case-table-save-row-info-panel bg-grey-light">
                  <i className="far fa-save text-secondary fa-lg p-1 me-1 cursor-pointer case-table-save-row-info-save-btn"></i><i className="fas fa-times text-secondary fa-lg p-1 cursor-pointer case-table-save-row-info-cancel-btn"></i>
                </div> */}
                {/* <div className="row-edit-button ">
                  <i className="fas fa-edit " onClick={ () => console.log("xx")}></i>
                  <br/>Edit
                </div> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </React.Fragment>
    );

  return <></>
}
