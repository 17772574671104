import React, { useState, useEffect } from 'react'; // , useCallback    Component, useMemo, useRef // const { useState, useEffect, useRef } = React; 
import { Auth } from 'aws-amplify'; //Amplify, { Auth, Hub }
import toast from 'react-hot-toast';
import igv from 'igv/dist/igv.esm.js'
// import igv from 'igv/dist/igv'

const ENDPOINT_BASE = process.env.REACT_APP_API_URI;


// ? another example: https://github.com/igvteam/igv.js/issues/891#issuecomment-552225941

const IgvVaNex = (props) => {

  // const igvComponent = React.useRef();
  // const [igvURI, setIgvURI] = useState('');       // ! temp variable!
  const [igvBrowser, setIgvBrowser] = useState(null);
  const [igvContainer, setIgvContainer] = useState(null);
  // const [igvIsLoaded, setIgvIsLoaded] = useState(false);
  const [igvOptions, setIgvOptions] = useState({
    // genome: 'hg38',
    // locus: ["myc", "chr1:155,157,300-155,163,706"],
    // locus: "chr1:12345-54321",
    // locus: 'BRCA1',
    // locus: `${chr}:${pos}-${pos+1}`,
    // flanking: 100,
    // locus: '1:67,655,272-67,684,468',
    // locus: `GPSM1`,
    locus: (!!props.chromosome && !!props.position ? `${props.chromosome}:${props.position}` : null),
    showKaryo: true,
    showIdeogram: true,
    showNavigation: true,
    showRuler: true,
    showCenterGuide: true,// ! the position of the center line will be of in igv@^2.10 so we are using igv@2.9.4
    showCursorTrackingGuide: false,
    showCommandBar: true,
    showSequence: true,
    reference: {
      id: "hg38",
      name: "Human (GRCh38/hg38)",
      fastaURL: "https://s3.amazonaws.com/igv.broadinstitute.org/genomes/seq/hg38/hg38.fa",
      indexURL: "https://s3.amazonaws.com/igv.broadinstitute.org/genomes/seq/hg38/hg38.fa.fai",
      cytobandURL: "https://s3.amazonaws.com/igv.org.genomes/hg38/annotations/cytoBandIdeo.txt.gz",
      aliasURL: "https://s3.amazonaws.com/igv.org.genomes/hg38/hg38_alias.tab",
      tracks: [
        {
          name: "Refseq",
          format: "refgene",
          url: "https://s3.amazonaws.com/igv.org.genomes/hg38/ncbiRefSeq.sorted.txt.gz",
          indexURL: "https://s3.amazonaws.com/igv.org.genomes/hg38/ncbiRefSeq.sorted.txt.gz.tbi",
          // url: "https://s3.amazonaws.com/igv.org.genomes/hg38/refGene.sorted.txt.gz",
          // indexURL: "https://s3.amazonaws.com/igv.org.genomes/hg38/refGene.sorted.txt.gz.tbi",
          displayMode: "expanded",
          height:100,
          // expandedRowHeight:30,
          // squishedRowHeight:15,
          visibilityWindow: -1,
          removable: false,
          order: 1000000
        }
        // ,{
        //   type: "variant1",
        //   format: "vcf",
        //   url: "https://s3.amazonaws.com/1000genomes/release/20130502/ALL.chr22.phase3_shapeit2_mvncall_integrated_v5a.20130502.genotypes.vcf.gz",
        //   indexURL: "https://s3.amazonaws.com/1000genomes/release/20130502/ALL.chr22.phase3_shapeit2_mvncall_integrated_v5a.20130502.genotypes.vcf.gz.tbi",
        //   name: "1KG variants (chr22)",
        //   squishedCallHeight: 1,
        //   expandedCallHeight: 4,
        //   displayMode: "squished",
        //   visibilityWindow: 1000
        // }
        // ,{
        //   type: 'variant2',
        //   name: 'Variants - NA12878',
        //   sourceType: 'htsget',
        //   format: "VCF",
        //   url: 'https://htsget.ga4gh.org/variants/giab.NA12878',
        //   height: 50
        // }
        // ,{
        //   url: "https://s3.amazonaws.com/igv.org.demo/nstd186.GRCh38.variant_call.vcf.gz",
        //   indexURL: "https://s3.amazonaws.com/igv.org.demo/nstd186.GRCh38.variant_call.vcf.gz.tbi",
        //   name: "Color by function, SVTYPE",
        //   visibilityWindow: -1,
        //   color: function (variant) {
        //       const svtype = variant.info["SVTYPE"];
        //       switch (svtype) {
        //           case 'DEL':
        //               return "#ff2101";
        //           case 'INS':
        //               return "#001888";
        //           case 'DUP':
        //               return "#028401";
        //           case 'INV':
        //               return "#008688";
        //           case 'CNV':
        //               return "#8931ff";
        //           case 'BND':
        //               return "#891100";
        //            default:
        //               return "#002eff";
        //        }
        //    }
        // }
        // ,{
        //   type: "wig",
        //   name: "CTCF",
        //   url: "https://www.encodeproject.org/files/ENCFF356YES/@@download/ENCFF356YES.bigWig",
        //   min: "0",
        //   max: "30",
        //   color: "rgb(0, 0, 150)",
        //   guideLines: [
        //     {color: 'green', dotted: true, y: 25}, 
        //     {color: 'red', dotted: false, y: 5}
        //   ]
        // }
        // ,{
        //   type: "seg",
        //   format: "seg",
        //   url: "https://s3.amazonaws.com/igv.org.demo/GBM-TP.seg.gz",
        //   indexed: false,
        //   isLog: true,
        //   name: "GBM Copy # (TCGA Broad GDAC)",
        //   sort: {
        //      direction: "DESC",     // ASC | DESC
        //      chr : "chr7",
        //      start: 55174641,
        //      end: 55175252
        //   }
        // }
        // ,{
        //   type: 'spliceJunctions',
        //   format: 'bed',
        //   url: `${rootUrl}/${prefix}.SJ.out.bed.gz`,
        //   indexURL: `${rootUrl}/${prefix}.SJ.out.bed.gz.tbi`,
        //   minUniquelyMappedReads: 1,
        //   minTotalReads: 1,
        //   maxFractionMultiMappedReads: 1,
        //   minSplicedAlignmentOverhang: 0,
        //   thicknessBasedOn: 'numUniqueReads', //options: numUniqueReads (default), numReads, isAnnotatedJunction
        //   bounceHeightBasedOn: 'random', //options: random (default), distance, thickness
        //   colorBy: 'isAnnotatedJunction', //options: numUniqueReads (default), numReads, isAnnotatedJunction, strand, motif
        //   labelUniqueReadCount: true,
        //   labelMultiMappedReadCount: true,
        //   labelTotalReadCount: false,
        //   labelMotif: false,
        //   labelAnnotatedJunction: " [A]",
        //   hideStrand: '-',
        //   hideAnnotatedJunctions: false,
        //   hideUnannotatedJunctions: false,
        //   hideMotifs: ['GT/AT', 'non-canonical'], //options: 'GT/AG', 'CT/AC', 'GC/AG', 'CT/GC', 'AT/AC', 'GT/AT', 'non-canonical',
        // }
        // ,{
        //   type: "gwas",
        //   format: "gwas",
        //   name: "GWAS sample",
        //   url: "https://s3.amazonaws.com/igv.org.demo/gwas_sample.tsv.gz",
        //   indexed: false,
        //   columns: {
        //      chromosome: 12,
        //      position: 13,
        //      value: 28
        //   }
        // }
        // ,{
        //   "url": "https://s3.amazonaws.com/igv.org.test/data/gm12878_loops.bedpe.gz",
        //   "type": "interaction",
        //   "format": "bedpe",
        //   "name": "GM12878 Loops (bedpe)"
        // }
        // ,{
        //   type: 'mut',
        //   format: 'maf',
        //   url: 'https://s3.amazonaws.com/igv.org.demo/TCGA.BRCA.mutect.995c0111-d90b-4140-bee7-3845436c3b42.DR-10.0.somatic.maf.gz',
        //   indexed: false,
        //   height: 700,
        //   displayMode: "EXPANDED",
        // }
        // ,{
        //   name: "Merged",
        //   height: 50,
        //   type: "merged",
        //   tracks: [
        //       {
        //           "type": "wig",
        //           "format": "bigwig",
        //           "url": "https://www.encodeproject.org/files/ENCFF000ASJ/@@download/ENCFF000ASJ.bigWig",
        //           "color": "red"
        //       },
        //       {
        //           "type": "wig",
        //           "format": "bigwig",
        //           "url": "https://www.encodeproject.org/files/ENCFF351WPV/@@download/ENCFF351WPV.bigWig",
        //           "color": "green"
        //       }
        //   ]
        // }
      ],
      chromosomeOrder: "chr1, chr2, chr3, chr4, chr5, chr6, chr7, chr8, chr9, chr10, chr11, chr12, chr13, chr14, chr15, chr16, chr17, chr18, chr19, chr20, chr21, chr22, chrX, chrY"
    } // ,
    // tracks: [
    //   {
    //     name: "Refseq",
    //     format: "refgene",
    //     url: "https://s3.amazonaws.com/igv.org.genomes/hg38/ncbiRefSeq.sorted.txt.gz",
    //     indexURL: "https://s3.amazonaws.com/igv.org.genomes/hg38/ncbiRefSeq.sorted.txt.gz.tbi",
    //     // url: "https://s3.amazonaws.com/igv.org.genomes/hg38/refGene.sorted.txt.gz",
    //     // indexURL: "https://s3.amazonaws.com/igv.org.genomes/hg38/refGene.sorted.txt.gz.tbi",
    //     displayMode: "SQUISHED",
    //     visibilityWindow: -1,
    //     removable: false,
    //     order: 1000000
    //   }
    // ]
  });
  // flanking: 1000,
  // locus: '',
  // url: "https://s3.amazonaws.com/igv.org.genomes/hg38/ncbiRefGene.txt.gz",


  // const tmpIgvOptions =
  // {
  //   genome: 'hg38',
  //   locus: ["myc", "chr1:155,157,300-155,163,706"],
  //   locus: "chr1:12345-54321",
  //   locus: 'BRCA1',
  //   locus: `${chr}:${pos}-${pos+1}`,
  //   flanking: 100,
  //   locus: '1:67,655,272-67,684,468',

  //   reference: {
  //     id: "hg38",
  //     name: "Human (GRCh38/hg38)",
  //     url: "https://s3.amazonaws.com/igv.org.genomes/hg38/refGene.sorted.txt.gz",
  //     fastaURL: "https://s3.amazonaws.com/igv.broadinstitute.org/genomes/seq/hg38/hg38.fa",
  //     indexURL: "https://s3.amazonaws.com/igv.broadinstitute.org/genomes/seq/hg38/hg38.fa.fai",
  //     cytobandURL: "https://s3.amazonaws.com/igv.org.genomes/hg38/annotations/cytoBandIdeo.txt.gz",
  //     aliasURL: "https://s3.amazonaws.com/igv.org.genomes/hg38/hg38_alias.tab",
  //     },
  //   tracks: [
  //     {
  //       type: 'alignment',
  //       format: 'bam',
  //       url: 'https://1000genomes.s3.amazonaws.com/phase3/data/HG01879/exome_alignment/HG01879.mapped.ILLUMINA.bwa.ACB.exome.20120522.bam',
  //       indexURL: 'https://1000genomes.s3.amazonaws.com/phase3/data/HG01879/exome_alignment/HG01879.mapped.ILLUMINA.bwa.ACB.exome.20120522.bam.bai',
  //       // url: 'https://nch-igm-research-rare-disease.s3.us-east-2.amazonaws.com/2021/CD200129/churchill/2021-04-20/alignment/BAMs/CD200129_A435_P_01D_S-2021-002804.Recalibrated.bam?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIATYENYDZEMRWWGDHG%2F20210810%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20210810T184045Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEKv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMiJIMEYCIQDnqUtNd3%2BDSO6EmfZQ0pG311mY7JrqNXXh%2BWxZS7OWoAIhAK%2BhEItMjHwndOPDdGPtop0ANuAOseb9%2BzCbKMWnU2RbKrACCMT%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMjU3OTk1MzE2ODA4IgxkZai1Zj4JN2i%2FcYsqhAIBuGVDqKvdUjweQabx33H%2BLswiI4WT8E6Ot%2FbZBHEyl2WpDP4tM3Jwiu%2B8ALHyOzNtODP0QCPnMlsC89m3Rbi7E7u1hy4PmM%2Bq%2B9Iw%2F%2FbYIAE%2F84Q3Rk%2B%2FnX%2FVK4rTDjoXlpdTI3RNizB9VCOQra1qJk6STrsJCCDP3B0of8nz8AwJ%2BNVjKUkaBLX7kaXkKhb%2Fxza%2F4B08s9xMsWPGWHrC1ioE7pphg0H8sjZwbBFGM6olG0%2FSDD49SIOc964xqsqldLS24FSZzcqlvAwKm%2BvNPBlDxq7W3fGOiC1HZ1RZPXcCKMR8eTYIhtRR%2BwPXcLU%2FIWWY0d4ZB4gvSZYgA7HBvXKsEDCrkMuIBjqZAac1eF%2BP7H8rIVfgkoSll3TdKP7FGEomXhOvW%2FUc4E0qs%2B8OzBGPWZ7OWbwZkikMTy9c%2F1RxTlo5Gxq4TxMNtckJG1tYqsT%2Fxv%2Bgx1OsBO1X7z1NTB%2B2ME%2Fw2OcFmt%2BzwyU7Ml5xhX9%2F6yiwaTyWum4SfE9E%2Bsv%2Bs7LKBfAxEsmPcWnhPa6ADFX%2FD92kAH9cVTlbSt%2BGXEPhxw%3D%3D&X-Amz-Signature=aa43422d6b85182d0ab385235fef3b6a51799adb89b377a8c50316a1fa704181',
  //       // indexURL: 'https://nch-igm-research-rare-disease.s3.us-east-2.amazonaws.com/2021/CD200129/churchill/2021-04-20/alignment/BAMs/CD200129_A435_P_01D_S-2021-002804.Recalibrated.bai?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIATYENYDZEMRWWGDHG%2F20210810%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20210810T184142Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEKv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMiJIMEYCIQDnqUtNd3%2BDSO6EmfZQ0pG311mY7JrqNXXh%2BWxZS7OWoAIhAK%2BhEItMjHwndOPDdGPtop0ANuAOseb9%2BzCbKMWnU2RbKrACCMT%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMjU3OTk1MzE2ODA4IgxkZai1Zj4JN2i%2FcYsqhAIBuGVDqKvdUjweQabx33H%2BLswiI4WT8E6Ot%2FbZBHEyl2WpDP4tM3Jwiu%2B8ALHyOzNtODP0QCPnMlsC89m3Rbi7E7u1hy4PmM%2Bq%2B9Iw%2F%2FbYIAE%2F84Q3Rk%2B%2FnX%2FVK4rTDjoXlpdTI3RNizB9VCOQra1qJk6STrsJCCDP3B0of8nz8AwJ%2BNVjKUkaBLX7kaXkKhb%2Fxza%2F4B08s9xMsWPGWHrC1ioE7pphg0H8sjZwbBFGM6olG0%2FSDD49SIOc964xqsqldLS24FSZzcqlvAwKm%2BvNPBlDxq7W3fGOiC1HZ1RZPXcCKMR8eTYIhtRR%2BwPXcLU%2FIWWY0d4ZB4gvSZYgA7HBvXKsEDCrkMuIBjqZAac1eF%2BP7H8rIVfgkoSll3TdKP7FGEomXhOvW%2FUc4E0qs%2B8OzBGPWZ7OWbwZkikMTy9c%2F1RxTlo5Gxq4TxMNtckJG1tYqsT%2Fxv%2Bgx1OsBO1X7z1NTB%2B2ME%2Fw2OcFmt%2BzwyU7Ml5xhX9%2F6yiwaTyWum4SfE9E%2Bsv%2Bs7LKBfAxEsmPcWnhPa6ADFX%2FD92kAH9cVTlbSt%2BGXEPhxw%3D%3D&X-Amz-Signature=5ca9ac1dd03ed60288a9d04a4d8c4459eaabca3f28097b661bc71312c001645d',
  //       name: 'HG01879'
  //     },
  //     {
  //       type: 'alignment',
  //       format: 'bam',
  //       url: 'https://nch-igm-research-rare-disease.s3.us-east-2.amazonaws.com/2021/CD200129/churchill/2021-04-20/alignment/BAMs/CD200129_A435_P_01D_S-2021-002804.Recalibrated.bam?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIATYENYDZEMRWWGDHG%2F20210810%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20210810T184045Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEKv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMiJIMEYCIQDnqUtNd3%2BDSO6EmfZQ0pG311mY7JrqNXXh%2BWxZS7OWoAIhAK%2BhEItMjHwndOPDdGPtop0ANuAOseb9%2BzCbKMWnU2RbKrACCMT%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMjU3OTk1MzE2ODA4IgxkZai1Zj4JN2i%2FcYsqhAIBuGVDqKvdUjweQabx33H%2BLswiI4WT8E6Ot%2FbZBHEyl2WpDP4tM3Jwiu%2B8ALHyOzNtODP0QCPnMlsC89m3Rbi7E7u1hy4PmM%2Bq%2B9Iw%2F%2FbYIAE%2F84Q3Rk%2B%2FnX%2FVK4rTDjoXlpdTI3RNizB9VCOQra1qJk6STrsJCCDP3B0of8nz8AwJ%2BNVjKUkaBLX7kaXkKhb%2Fxza%2F4B08s9xMsWPGWHrC1ioE7pphg0H8sjZwbBFGM6olG0%2FSDD49SIOc964xqsqldLS24FSZzcqlvAwKm%2BvNPBlDxq7W3fGOiC1HZ1RZPXcCKMR8eTYIhtRR%2BwPXcLU%2FIWWY0d4ZB4gvSZYgA7HBvXKsEDCrkMuIBjqZAac1eF%2BP7H8rIVfgkoSll3TdKP7FGEomXhOvW%2FUc4E0qs%2B8OzBGPWZ7OWbwZkikMTy9c%2F1RxTlo5Gxq4TxMNtckJG1tYqsT%2Fxv%2Bgx1OsBO1X7z1NTB%2B2ME%2Fw2OcFmt%2BzwyU7Ml5xhX9%2F6yiwaTyWum4SfE9E%2Bsv%2Bs7LKBfAxEsmPcWnhPa6ADFX%2FD92kAH9cVTlbSt%2BGXEPhxw%3D%3D&X-Amz-Signature=aa43422d6b85182d0ab385235fef3b6a51799adb89b377a8c50316a1fa704181',
  //       // indexURL: 'https://nch-igm-research-rare-disease.s3.us-east-2.amazonaws.com/2021/CD200129/churchill/2021-04-20/alignment/BAMs/CD200129_A435_P_01D_S-2021-002804.Recalibrated.bai?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIATYENYDZEMRWWGDHG%2F20210810%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20210810T184142Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEKv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMiJIMEYCIQDnqUtNd3%2BDSO6EmfZQ0pG311mY7JrqNXXh%2BWxZS7OWoAIhAK%2BhEItMjHwndOPDdGPtop0ANuAOseb9%2BzCbKMWnU2RbKrACCMT%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMjU3OTk1MzE2ODA4IgxkZai1Zj4JN2i%2FcYsqhAIBuGVDqKvdUjweQabx33H%2BLswiI4WT8E6Ot%2FbZBHEyl2WpDP4tM3Jwiu%2B8ALHyOzNtODP0QCPnMlsC89m3Rbi7E7u1hy4PmM%2Bq%2B9Iw%2F%2FbYIAE%2F84Q3Rk%2B%2FnX%2FVK4rTDjoXlpdTI3RNizB9VCOQra1qJk6STrsJCCDP3B0of8nz8AwJ%2BNVjKUkaBLX7kaXkKhb%2Fxza%2F4B08s9xMsWPGWHrC1ioE7pphg0H8sjZwbBFGM6olG0%2FSDD49SIOc964xqsqldLS24FSZzcqlvAwKm%2BvNPBlDxq7W3fGOiC1HZ1RZPXcCKMR8eTYIhtRR%2BwPXcLU%2FIWWY0d4ZB4gvSZYgA7HBvXKsEDCrkMuIBjqZAac1eF%2BP7H8rIVfgkoSll3TdKP7FGEomXhOvW%2FUc4E0qs%2B8OzBGPWZ7OWbwZkikMTy9c%2F1RxTlo5Gxq4TxMNtckJG1tYqsT%2Fxv%2Bgx1OsBO1X7z1NTB%2B2ME%2Fw2OcFmt%2BzwyU7Ml5xhX9%2F6yiwaTyWum4SfE9E%2Bsv%2Bs7LKBfAxEsmPcWnhPa6ADFX%2FD92kAH9cVTlbSt%2BGXEPhxw%3D%3D&X-Amz-Signature=5ca9ac1dd03ed60288a9d04a4d8c4459eaabca3f28097b661bc71312c001645d',
  //       name: 'S3'
  //     }
  //   ]
  // };
  
  // const renderLabel =() => {
  //   let maxFitChars = props.width/10;
  //   return props.label.substring(0, Math.min(maxFitChars,props.label.length));
  // }

  useEffect(() => {
    if(!!props.chromosome && !!props.position){
      setIgvOptions(prevIgvOptions => ({
        ...prevIgvOptions,
        // locus: `GPSM1`
        locus: `${props.chromosome}:${props.position}`
        })
      );
    }
  }, [props.chromosome, props.position]);
  

  //? --------------------------------------------- IGV ----------------------------------

  const loadEnsemblTrack = () => {
    let newTrakc = {
      name: "Ensembl [Color by biotype]",
      type: "annotation",
      format: "gff3",
      displayMode: "squished", //expanded
      height: 150,
      url: "https://s3.amazonaws.com/igv.org.genomes/hg38/Homo_sapiens.GRCh38.94.chr.gff3.gz",
      indexURL: "https://s3.amazonaws.com/igv.org.genomes/hg38/Homo_sapiens.GRCh38.94.chr.gff3.gz.tbi",
      visibilityWindow: 1000000,
      removable: true,
      colorBy: "biotype",
      colorTable: {
          "antisense": "blueviolet",
          "protein_coding": "blue",
          "retained_intron": "rgb(0, 150, 150)",
          "processed_transcript": "purple",
          "processed_pseudogene": "#7fff00",
          "unprocessed_pseudogene": "#d2691e",
          "*": "black"
      }
    }
    addTrack(newTrakc);
    // setIgvOptions(prevIgvOptions => ({ // !  do we need to add this?!!!! 
    //   ...prevIgvOptions,
    //   tracks: [...prevIgvOptions.tracks || [], newTrakc]
    // }));
  }

  const renderIGV = () => {

    const loadBamFiles = (s3uri, sampleLabel, baiExtension) => {
      console.log(`loading ${sampleLabel}...`);
      // s3uri = "s3://bucket-name/and/some/key.txt"
      let s3Bucket = s3uri.replace("s3://", "").split("/", 1)[0]
      let s3Key = s3uri.replace(`s3://${s3Bucket}/`, "")

      Auth.currentSession().then(data => {
        let id_token = data.getIdToken().getJwtToken();
        // 'nch-igm-vanex-client/ClinVarPlots/GRCH38-Varhouse/'+gene_name+'/'+gene_name+'.ALL.png')
        //! const url_to_fetch = ENDPOINT_BASE+`get_signed_url?bucket=nch-igm-vanex-client&key=ClinVarPlots/GRCH38-Varhouse/${geneName.toUpperCase()}/${geneName.toUpperCase()}.ALL.png`;
        // 's3://nch-igm-research-rare-disease/ 2021%2FCD200129%2Fchurchill%2F2021-04-20%2Falignment%2FBAMs%2FCD200129_A435_P_01D_S-2021-002804.Recalibrated.bam'
        // https://nch-igm-research-rare-disease.s3.us-east-2.amazonaws.com/2021/CD200129/churchill/2021-04-20/alignment/BAMs/CD200129_A435_P_01D_S-2021-002804.Recalibrated.bam?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIATYENYDZEMRWWGDHG%2F20210810%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20210810T184045Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEKv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMiJIMEYCIQDnqUtNd3%2BDSO6EmfZQ0pG311mY7JrqNXXh%2BWxZS7OWoAIhAK%2BhEItMjHwndOPDdGPtop0ANuAOseb9%2BzCbKMWnU2RbKrACCMT%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMjU3OTk1MzE2ODA4IgxkZai1Zj4JN2i%2FcYsqhAIBuGVDqKvdUjweQabx33H%2BLswiI4WT8E6Ot%2FbZBHEyl2WpDP4tM3Jwiu%2B8ALHyOzNtODP0QCPnMlsC89m3Rbi7E7u1hy4PmM%2Bq%2B9Iw%2F%2FbYIAE%2F84Q3Rk%2B%2FnX%2FVK4rTDjoXlpdTI3RNizB9VCOQra1qJk6STrsJCCDP3B0of8nz8AwJ%2BNVjKUkaBLX7kaXkKhb%2Fxza%2F4B08s9xMsWPGWHrC1ioE7pphg0H8sjZwbBFGM6olG0%2FSDD49SIOc964xqsqldLS24FSZzcqlvAwKm%2BvNPBlDxq7W3fGOiC1HZ1RZPXcCKMR8eTYIhtRR%2BwPXcLU%2FIWWY0d4ZB4gvSZYgA7HBvXKsEDCrkMuIBjqZAac1eF%2BP7H8rIVfgkoSll3TdKP7FGEomXhOvW%2FUc4E0qs%2B8OzBGPWZ7OWbwZkikMTy9c%2F1RxTlo5Gxq4TxMNtckJG1tYqsT%2Fxv%2Bgx1OsBO1X7z1NTB%2B2ME%2Fw2OcFmt%2BzwyU7Ml5xhX9%2F6yiwaTyWum4SfE9E%2Bsv%2Bs7LKBfAxEsmPcWnhPa6ADFX%2FD92kAH9cVTlbSt%2BGXEPhxw%3D%3D&X-Amz-Signature=aa43422d6b85182d0ab385235fef3b6a51799adb89b377a8c50316a1fa704181
        // const url_to_fetch = ENDPOINT_BASE+`get_signed_url?bucket=nch-igm-research-rare-disease&key=2021%2FCD200129%2Fchurchill%2F2021-04-20%2Falignment%2FBAMs%2FCD200129_A435_P_01D_S-2021-002804.Recalibrated.bam`;
        // https://nch-igm-research-rare-disease.s3.us-east-2.amazonaws.com/2021/CD200129/churchill/2021-04-20/alignment/BAMs/CD200129_A435_P_01D_S-2021-002804.Recalibrated.bai?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIATYENYDZEMRWWGDHG%2F20210810%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20210810T184142Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEKv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMiJIMEYCIQDnqUtNd3%2BDSO6EmfZQ0pG311mY7JrqNXXh%2BWxZS7OWoAIhAK%2BhEItMjHwndOPDdGPtop0ANuAOseb9%2BzCbKMWnU2RbKrACCMT%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMjU3OTk1MzE2ODA4IgxkZai1Zj4JN2i%2FcYsqhAIBuGVDqKvdUjweQabx33H%2BLswiI4WT8E6Ot%2FbZBHEyl2WpDP4tM3Jwiu%2B8ALHyOzNtODP0QCPnMlsC89m3Rbi7E7u1hy4PmM%2Bq%2B9Iw%2F%2FbYIAE%2F84Q3Rk%2B%2FnX%2FVK4rTDjoXlpdTI3RNizB9VCOQra1qJk6STrsJCCDP3B0of8nz8AwJ%2BNVjKUkaBLX7kaXkKhb%2Fxza%2F4B08s9xMsWPGWHrC1ioE7pphg0H8sjZwbBFGM6olG0%2FSDD49SIOc964xqsqldLS24FSZzcqlvAwKm%2BvNPBlDxq7W3fGOiC1HZ1RZPXcCKMR8eTYIhtRR%2BwPXcLU%2FIWWY0d4ZB4gvSZYgA7HBvXKsEDCrkMuIBjqZAac1eF%2BP7H8rIVfgkoSll3TdKP7FGEomXhOvW%2FUc4E0qs%2B8OzBGPWZ7OWbwZkikMTy9c%2F1RxTlo5Gxq4TxMNtckJG1tYqsT%2Fxv%2Bgx1OsBO1X7z1NTB%2B2ME%2Fw2OcFmt%2BzwyU7Ml5xhX9%2F6yiwaTyWum4SfE9E%2Bsv%2Bs7LKBfAxEsmPcWnhPa6ADFX%2FD92kAH9cVTlbSt%2BGXEPhxw%3D%3D&X-Amz-Signature=5ca9ac1dd03ed60288a9d04a4d8c4459eaabca3f28097b661bc71312c001645d
        // const url_to_fetch = ENDPOINT_BASE+`get_signed_url?bucket=nch-igm-research-rare-disease&key=2021%2FCD200129%2Fchurchill%2F2021-04-20%2Falignment%2FBAMs%2FCD200129_A435_P_01D_S-2021-002804.Recalibrated.bai`;
        // const bam_url_to_fetch = ENDPOINT_BASE+`get_signed_url?bucket=nch-igm-research-rare-disease&key=${igvURI}.bam`;

        // 2021/CD200129/churchill/2021-04-20/alignment/BAMs/CD200129_A435_P_01D_S-2021-002804.Recalibrated.bam

        // s3://nch-igm-research-rare-disease/2021/GD0277/churchill/2021-09-23/alignment/BAMs/GD0277_F_S-2021-024959.Recalibrated.bam
        // const bam_url_to_fetch = ENDPOINT_BASE+`get_signed_url?bucket=nch-igm-research-rare-disease&key=2021/GD0277/churchill/2021-09-23/alignment/BAMs/GD0277_F_S-2021-024959.Recalibrated.bam`;
        // const bai_url_to_fetch = ENDPOINT_BASE+`get_signed_url?bucket=nch-igm-research-rare-disease&key=2021/GD0277/churchill/2021-09-23/alignment/BAMs/GD0277_F_S-2021-024959.Recalibrated.bai`;
        // ! replace these with GetSignedURL
        const bam_url_to_fetch = ENDPOINT_BASE+`get_signed_url?bucket=${s3Bucket}&key=${s3Key}`;
        const bai_url_to_fetch = ENDPOINT_BASE+`get_signed_url?bucket=${s3Bucket}&key=${s3Key.slice(0,-4)}${baiExtension}`;
        // const bam_url_to_fetch = ENDPOINT_BASE+`get_signed_url?bucket=nch-igm-research-rare-disease&key=${igvURI}`;
        // const bai_url_to_fetch = ENDPOINT_BASE+`get_signed_url?bucket=nch-igm-research-rare-disease&key=${igvURI.slice(0,-1)}i`;
        // console.log('bam_url_to_fetch: ',bam_url_to_fetch);
        // console.log('bai_url_to_fetch: ',bai_url_to_fetch);
        let bam_url='';
        let bai_url='';
        // console.log(bam_url_to_fetch);
        fetch(bam_url_to_fetch, {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Authorization': id_token
          }
        })
        .then(result => {return((result.ok) ? result.json() : null)})
        .then(result_json => {
          // console.log('bam_url_to_fetch: ', result_json);
          bam_url=result_json;
          fetch(bai_url_to_fetch, {
            method: 'GET',
            mode: 'cors',
            headers: {
              'Authorization': id_token
            }
          })
          .then(result2 => {return((result2.ok) ? result2.json() : null)})
          .then(result2_json => {
            // console.log('bai_url_to_fetch: ',result2_json);
            bai_url=result2_json;
            let newTrakc = {
              name: sampleLabel, //'sample_'+igvOptions.tracks.length,
              type: 'alignment',
              format: 'bam',
              colorBy: "strand",
              removable: true,
              height: 200,
              url: bam_url,
              indexURL: bai_url,
              // indexed: false,
              // oauthToken: id_token
            }

            if(bam_url && bai_url){
              addTrack(newTrakc);
              // setIgvOptions(prevIgvOptions => ({  // !  do we need to add this?!!!! 
              //   ...prevIgvOptions,
              //   tracks: [...prevIgvOptions.tracks || [], newTrakc]
              // }));
            }
            else {
              toast.error('Failed to fetch data.');
            }
          }).catch(e => toast.error("Failed to fetch data." + e));

        }).catch(e => toast.error("Failed to fetch data." + e));
        
      });
      
      // setIgvIsLoaded(true);
    }
    if(!!props.currentAnalysisInfo.RNA_SAMPLES){
      props.currentAnalysisInfo.RNA_SAMPLES.forEach(s => {
        if (!!s.FILE_URI)
          loadBamFiles(s.FILE_URI, s.SAMPLE_TYPE, '.bam.bai');
      });
    }
    // const variant = props.currentAnalysisInfo.SAMPLES.forEach(s => s.FILE_URI);
    props.currentAnalysisInfo.SAMPLES.forEach((s, i) => {
      if (!!s.FILE_URI){
        setTimeout(() => {loadBamFiles(s.FILE_URI, s.SAMPLE_TYPE, '.bai')}, i*300);
        // loadBamFiles(s.FILE_URI, s.SAMPLE_TYPE, '.bai');
        // console.log(s.FILE_URI);
      }
    });
  }


  useEffect(() => {
    if (!!igvBrowser) {
      igvBrowser.search(igvOptions.locus)
      // .then(() => {
      //   igvBrowser.zoomOut();
      // });
    }
  }, [igvOptions.locus]);
  
  useEffect(() => {
    if (!!igvContainer) {
      igv.createBrowser(igvContainer, igvOptions).then((browser) => {
        setIgvBrowser(browser);
        // if (!!igvOptions.locus){
        //   browser.search(igvOptions.locus);
          // .then(() => {
          //   browser.zoomOut();
          // });
        // }
      })
    }
  }, [igvContainer]);

  // useEffect(() => {
  //   if (!!igvComponent.current) {
  //     igv.createBrowser(igvComponent.current, igvOptions).then((browser) => {
  //       setIgvBrowser(browser);
  //       if (!!igvOptions.locus);
  //         browser.search(igvOptions.locus)
  //         .then(() => {
  //           browser.zoomOut();
  //         });
  //     })
  //   }
  // }, [igvComponent.current]);


  // const renderIGV = (track) => {
  const addTrack = (track) => {
    if (!!igvBrowser ) {
      // resetIgvTracks();
      // igvOptions.tracks.forEach((track) => {
        igvBrowser.loadTrack(track);
      // })
    }
  }

  const resetIgvTracks = () => {
    if(!!igvBrowser){
      // console.log(igvBrowser);
      // ! not using "igvBrowser.removeAllTracks();" to keep initial seq track  
      // igvOptions.tracks.forEach((track) => {
      //   igvBrowser.loadTrack(track);
      // })
      // console.log(igvBrowser.getTrackOrder());
      igvBrowser.getTrackOrder().forEach( track => {
        if(track !== 'Sequence' && track !== 'Refseq'){
          // console.log('removing ', track);
          igvBrowser.removeTrackByName(track);
        }
      });
      setIgvOptions(prevIgvOptions => ({
        ...prevIgvOptions,
        tracks: []
      }));
    }
  }  

  const setIgvContainerElement = (element) => {
    setIgvContainer(element);
  }

  // function LoadIgvBamFileButton(props) {
  //   const tooltipRef = React.useRef();  

  //   useEffect(() => {
  //     var tooltip = new Tooltip(tooltipRef.current, {
  //       title: "Load Bam Files",
  //       placement: 'right',
  //       trigger: 'hover'
  //     })
  //   })
    
  //   return (<i className="fas fa-file-export fa-2x igv-refresh-button" ref={tooltipRef} onClick={()=>renderIGV(props.varChr,props.varPos)}></i>
  //   // return (<i className="fas fa-file-export fa-2x igv-refresh-button" ref={tooltipRef} onClick={renderIGV}></i>
  //   // return (<i className="fas fa-sync-alt fa-2x igv-refresh-button" ref={tooltipRef}></i>
  //   // {/* <i className="fas fa-cloud-download-alt fa-2x igv-refresh-button"></i> */}
  //   )
  // }

  return (
    <>
      {/* {renderIGV()} */}
      {/* <div className="card m-1 shadow-sm flex-grow-1" style={{minWidth: '10rem'}}> */}
        {/* <h5 className="variant-card-header bg-light-blue">IGV</h5> */}
        {/* <div className="variant-card-body d-flex flex-row flex-nowrap align-items-center justify-content-center"> */}
        {/* <div className="variant-card-body d-flex flex-column m-0 mb-1 p-0"> */}
          {/* {(igvIsLoaded) ? */}
            {/* <IGV igvOptions={igvOptions} ref={igvComponent} /> */}
            <div ref={setIgvContainerElement} style={{width:'100%'}}/>
            {/* <div ref={igvComponent} style={{width:'100%'}}/> */}
            {/* <i className="fas fa-file-export fa-2x igv-refresh-button" onClick={renderIGV}></i> */}
            <div className="d-flex flex-row">
              <i className="fas fa-file-upload igv-refresh-button mx-2 fa-2x cursor-pointer" onClick={renderIGV}></i>
              <i className="fas fa-undo fa-lg mx-2 mt-2 cursor-pointer" onClick={resetIgvTracks}></i>
              <i className="fas fa-align-center green-mid fa-lg mx-2 mt-2 cursor-pointer" onClick={loadEnsemblTrack}></i>
              {/* <i className="fas fa-file-upload fa-2x my-0 mx-2 igv-refresh-button cursor-pointer" onClick={renderIGV}></i> */}
              {/* <input className="form-control form-control-sm ms-5"
                name="igvURI" type="text" style={{maxWidth:'90%'}}
                placeholder="2021/CD200129/churchill/2021-04-20/alignment/BAMs/CD200129_xxxx.bam"
                onChange={e => setIgvURI(e.target.value)} /> */}
              {/* <i className="fas fa-undo fa-lg m-2 px-5 cursor-pointer" onClick={resetIgvTracks}></i> */}
              {/* <i className="fas fa-align-center fa-lg m-2 px-5 cursor-pointer" onClick={loadEnsemblTrack}></i> */}
            </div>
            {/* <LoadIgvBamFileButton varChr={variant.CHROMOSOME} varPos={variant.POSITION}/> */}
          {/* } */}
        {/* </div> */}
      {/* </div> */}
    </>
  );
}

export default IgvVaNex;
