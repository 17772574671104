import React from 'react';
import { Auth } from 'aws-amplify';

// class UserPane extends React.Component {
const UserPane = (props) =>  {

  const login = () => {
    if(props.urlToPush!==undefined){
      Auth.federatedSignIn({provider: 'AzureAD', customState: `?url=${props.urlToPush}`});  //? &redirecting=true
    }
    else{
      Auth.federatedSignIn({provider: 'AzureAD'});  //? , customState: `?redirecting=true`
    }
  }

  const logout = () => {
    Auth.signOut();

    if (props.onChange) {
      props.onChange({
        loggedIn: false,
        userName: ""
      });
    }
  }

  if (props.showMinimum){
    if (!props.loggedIn) {
      return (      
        <div className='user-pane'>
          <button type='button' className='w-100 btn btn-lg btn-primary bg-lighter-blue text-nowrap' onClick={login} ><i className="fas fa-sign-in-alt me-2"></i>Log In</button>
        </div>
      );
    } else {
      return (      
        <div className='user-pane'>
          <button type='button' className='w-100 btn btn-lg btn-primary bg-lighter-blue text-nowrap' onClick={logout} ><i className="fas fa-sign-out-alt me-2"></i>Log out </button>
        </div>
      );
    }
  }
  else{
    if (!props.loggedIn) {
      return (      
        <div className='user-pane'>
          <button type='button' className='text-nowrap btn btn-sm btn-primary bg-lighter-blue text-nowrap' onClick={login} ><i className="fas fa-sign-in-alt me-2"></i>Log In</button>
        </div>
      );
    } 
    else {
      return ( 
        <div className='user-pane'>
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <button type='button' className="btn btn-link nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="true">
                <span className="text-white fw-bold">{props.userName}</span>
              </button>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <li><button type='button' className="btn btn-link dropdown-item" >Settings</button></li>
                {(props.devOptions !== undefined) ? <label className="dropdown-item"><input type="checkbox" defaultChecked={(props.devOptions )} onChange={props.toggleDevOptions} /> Dev. Options</label> : ''}
                <li><div role="separator" className="dropdown-divider"></div></li>
                <li><button type='button' className="btn btn-link dropdown-item text-nowrap" onClick={logout}><i className="fas fa-sign-out-alt me-2"></i>Log out</button></li>
              </ul>
            </li>
          </ul>
          {/* <button id="basic-addon2" className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">xxxnamexx</button> */}
        </div>  
      );
    }
  }
}

export default UserPane