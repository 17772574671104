import React, { useState, useEffect, useRef } from 'react'; // Component, useMemo,   // const { useState, useEffect, useRef } = React; 
import SplitPane from 'react-split-pane'; //, { Pane } 
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import logo from '../logo.png';
// import XLSX from 'xlsx';
import * as XLSX from 'xlsx';
import igv from 'igv/dist/igv.esm.js'

// import variantFormatDefinition from '../resources/OutputDefinitionRareDiseaseGRCh38.json';
import { Modal } from 'bootstrap'; // Collapse, Popover, Toast, Tooltip, Alert, , Dropdown
import {EditableCell, GetHeatedQualityScore, ConstructFilterFunctions, VanexGenotypeCell} from '../HelperFunctions'
import VariantTable from "./VariantTable";
import {GetSignedURL} from '../HelperFunctions'
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field, FieldArray } from 'formik'; // , ErrorMessage

import filterDefinition from '../resources/filters.json';
import IndeterminateCheckbox, {CHECKED, UNCHECKED, INDETERMINATE} from "./IndeterminateCheckbox";
import CreatableSelect from 'react-select/creatable';
import toast from 'react-hot-toast';

// TODO: select proband M and F 
// TODO: fix PMFColNames
// TODO: fix comp. het
// TODO: select a def. width for columns as they add?
// TODO: show all sample columsn at the end?



/**
* @param {string} sampleID
* @return {string}
*/
const getSampleType = (sampleID, short=true, sampleCounter=0) => {
  // console.log(sampleID);
  let label='';
  switch(true){
    case (sampleID.includes("_P_")):
      label= ((short) ? 'P' : 'Proband');
      break;
    case (sampleID.includes("_M_")):
      label= ((short) ? 'M' : 'Mother');
      break;
    case (sampleID.includes("_F_")):
      label= ((short) ? 'F' : 'Father');
      break;
    case (sampleID.includes("_S_")):
      label= ((short) ? 'S' : 'Sibling');
      break;
    case (sampleID.includes("_B_")):
      label= ((short) ? 'B' : 'Brother');
      break;
    default:
      label= 'X';
  }
  
  if(sampleCounter !== 0 && label==='X'){
    let sampleTypeArray = ['P', 'M', 'F'];
    if (sampleCounter < 4){
      label = sampleTypeArray[sampleCounter-1];
    }
    else {
      label += '_' + sampleCounter.toString();
    }
  }

  return label;
}

const XlsxVariantViewer = (props) => {
	
  let history = useHistory();
	const [loadingData, setLoadingData] = useState(true);
	const [processingData, setProcessingData] = useState(true);
	const [xlsxVariantData, setXlsxVariantData] = useState([]);
	const [xlsxVariantCols, setXlsxVariantCols] = useState([]);

	const [initialSortBy, setInitialSortBy] = useState([]);
	const [analysisHiddenColumnsIds, setAnalysisHiddenColumnsIds] = useState([]);

  const [PMFColNames, setPMFColNames] = useState(['','','']);

  const [sampleTypeModal, setModal] = useState(null);
  const refForChangeSampleTypeModal = useRef();


	useEffect(() => {
    const analysisDefaultVisibleColumns = ['Chr','Start','Ref','Alt','Qual','P_GT', 'Filter', 'Gene', 'Loc In Gene', 'Effect', 'ClinVar Significance' ];

		if(!!props.xlsxFile){
      setLoadingData(true);
      setProcessingData(true);
			// console.log( props.xlsxFile);

			let file = props.xlsxFile.filePath;
			const reader = new FileReader();
			const rABS = !!reader.readAsBinaryString;
			reader.onload = (e) => {
				/* Parse data */
				const bstr = e.target.result;
				const wb = XLSX.read(bstr, {type:rABS ? 'binary' : 'array'});

        let wsname;
        if(props.xlsxFile.useSheetName){
          wsname = props.xlsxFile.sheetName;
        } else{
          /* Get first worksheet */
          wsname = wb.SheetNames[Number(props.xlsxFile.sheetNumber)];
        }

        const ws = wb.Sheets[wsname];
				/* Convert array of arrays */
				let tmpVariantData = XLSX.utils.sheet_to_json(ws, {raw:true, defval:null});
        setLoadingData(false);
        if(tmpVariantData.length > 0){
  				toast.success('Loading done!');
        }
        else{
          toast.error('No data to load. Make sure you have set the right parametrs!', {duration:5000});
          setProcessingData(false);
          return;
        }
				
        if(props.xlsxFile.useMaxGnomad){
          tmpVariantData = Object.values(tmpVariantData).filter(t => !(t['AF gnomAD WG'] >= 0.001 || t['AF gnomAD EX']>= 0.001) );
          // tmpVariantData = Object.values(tmpVariantData).filter(t => !(t['95 FAF gnomAD WG '] >= 0.001 || t['95 FAF gnomAD EX ']>= 0.001) );
          // tmpVariantData = Object.values(tmpVariantData).filter(t => !(t['WG FAF95 PopMax gnomAD AF'] >= 0.001 || t['EX FAF95 PopMax gnomAD AF']>= 0.001) );
        }
				// Object.keys(tmpVariantData[0]).forEach((property) => {console.log(property)})
        
      	// console.log(variantFormatDefinition['column-definitions']["ALT"]);
				Object.keys(tmpVariantData).forEach((varIndex) => {
					// Object.keys(tmpVariantData[varIndex]).forEach((property) => {
					// 	if(!property.includes("_P_") && !property.includes("_M_") && !property.includes("_F_") && !property.includes("_S_") && !property.includes("_X_")){
					// 		// console.log(property , '=',tmpVariantData[0][property] );
					// 		let newPropertyName = Object.keys(variantFormatDefinition['column-definitions']).find(k => {
					// 			let hedaderName = variantFormatDefinition['column-definitions'][k]["header"];
					// 			if(hedaderName === undefined){
					// 				// console.log(k, '==', variantFormatDefinition['column-definitions'][k]);
					// 				return false;
					// 			}
					// 			else{
					// 				return(hedaderName.toLowerCase() === property.toLowerCase())
					// 			}
					// 		});
					// 		if(newPropertyName!==undefined){
					// 			if (typeof(newPropertyName).toString()==='string'){
					// 				// console.log(property ,"==", newPropertyName,'; length=' ,typeof(newPropertyName));
					// 				tmpVariantData[varIndex][newPropertyName.toUpperCase()] = tmpVariantData[varIndex][property]
					// 				delete tmpVariantData[varIndex][property];
					// 			}
					// 		}
					// 		// console.log(property , '=',tmpVariantData[varIndex][property] );
					// 		// tmpVariantData[varIndex][getSampleType(sampleName,true) + '_' + property] = tmpVariantData[varIndex].SAMPLES[sampleName][property];
					// 	}
					// });
					tmpVariantData[varIndex]["ROW_INDEX"] = varIndex;
          tmpVariantData[varIndex]["Qual"] = Math.trunc(Number(tmpVariantData[varIndex]["Qual"]));
          let colAltPName = Object.keys(tmpVariantData[varIndex]).find( t => t.endsWith('_Alt_Percent'));
          tmpVariantData[varIndex][colAltPName] = 100*tmpVariantData[varIndex][colAltPName]
          // console.log(tmpVariantData[varIndex][colAltPName])
				});

				toast.success('processing done!');
        let tmpColumns = prepareColumns(tmpVariantData[0]);
				setXlsxVariantCols(tmpColumns);

				let hiddenCols = [];
        // console.log(tmpColumns);
				if(analysisHiddenColumnsIds.length === 0){
					Object.values(tmpColumns).forEach( (colAskey) => {
            let colHeader = colAskey.Header;
						if(colHeader!=='Chr' && !analysisDefaultVisibleColumns.includes(colHeader) && !colHeader.endsWith('_GT') && 
              !colHeader.startsWith('AD_Ref') && !colHeader.startsWith('AD_Alt') && !colHeader.startsWith('AD_Total') && !colHeader.startsWith('Alt_Percent') ){ // the column is hidden
              // !colHeader.endsWith('_AD_Ref') && !colHeader.endsWith('_AD_Alt') && !colHeader.endsWith('_AD_Total') && !colHeader.endsWith('_Alt_Percent') ){ // the column is hidden
							// hiddenCols.push(colHeader);
							hiddenCols.push(colAskey.accessor);
						}
					});
					setAnalysisHiddenColumnsIds(hiddenCols);
				}

				// tmpVariantData = Object.values(tmpVariantData).filter(t => true);
				setXlsxVariantData(tmpVariantData);

        setProcessingData(false);
        
        // set Modal for changing sample type
        setModal( new Modal(refForChangeSampleTypeModal.current));

				// props.setXlsxProcessed(true);
				toast.success(`Done! ${tmpVariantData.length} records loaded`);
			};
			if(rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
		}
	}, [props.xlsxFile])

	/* 
  - Columns is a simple array right now, but it will contain some logic later on. It is recommended by react-table to memoize the columns data
  - Here in this example, we have grouped our columns into two headers. react-table is flexible enough to create grouped table headers
  */
  const prepareColumns = (mydata) => { 
    //! console.log('prepareColumns');
    // TODO: addFilter: true for Loc In Gene
    // let mydata=inVariantData[0];
    // let mydata=this.state.openCases.find(t => t[0] === this.state.activeCaseId)[1][0];

    // ! fix this, add if _P_ was in teh name, it may need to find that column instead of using the first one!
    // ? set initial col names for PMF
    let tmpPMFColNames = [...PMFColNames];

    let sampleCounter = 0;

    let tableColumns = Object.keys(mydata).map( (key, index) => {
      // console.log(variantFormatDefinition['column-definitions'][key.name.toString()]);
      // if(key.endsWith('_AD_Ref') || key.endsWith('_AD_Alt') || key.endsWith('_AD_Total') || key.endsWith('_Alt_Percent') || key.endsWith('_GQ')){
      if( key.endsWith('_Alt_Percent') || key.endsWith('_GQ')){
        let threshold = 80;
        let tmpHeader = key;
        if( key.endsWith('_Alt_Percent') ) {
          threshold = 0.8;
          tmpHeader= key.substring( key.lastIndexOf("_Alt_Percent") + 1) + '_' + key.substring(0, key.lastIndexOf("_Alt_Percent") );
        }
        else{
          tmpHeader= key.substring( key.lastIndexOf("_GQ") + 1) + '_' + key.substring(0, key.lastIndexOf("_GQ") );
        }
        return {
          Header: tmpHeader,
          accessor: key,
          width: 40,
          setColWidth: function(inWidth){ this.width = inWidth; },
          useHeated: true,
          heatedThresholdValue: threshold
        }
      }
      else if(key.endsWith('_AD_Ref') || key.endsWith('_AD_Alt') || key.endsWith('_AD_Total')){
        return {
          Header: key.substring( key.lastIndexOf("_AD_") + 1) + '_' + key.substring(0, key.lastIndexOf("_AD_") ),
          accessor: key,
          width: 40,
          setColWidth: function(inWidth){ this.width = inWidth; },
          // style: { textAlign: 'center'}, //fontWeight: 'bolder',
          columnStyle: {textAlign: 'center'}
        }
      }
      else if (key==="Chr") {
        return {
          Header: key,// "Chr",
          accessor: key,
          width: 40,
          setColWidth: function(inWidth){ this.width = inWidth; },
          // style: {fontWeight: 'bolder',},
          columnStyle: {textAlign: 'center',}
        }
      }
      else if (key==="Qual"){
        return {
          Header: key,// "Qual",
          accessor: key,
          width: 70,
          setColWidth: function(inWidth){ this.width = inWidth; },
          useHeated: true,
          // addFilter: true,
          style: { textAlign: 'center',},
          columnStyle: {textAlign: 'center',}
        }
      }
      else if (key==="Filter"){
        return {
          Header: key,// "Filter",
          accessor: key,
          width: 250,
          setColWidth: function(inWidth){ this.width = inWidth; },
          // addFilter: true,
          Cell: EditableCell,
        }
      }
      else if (key.endsWith("_GT")){
        tmpPMFColNames[sampleCounter]=key;
        sampleCounter += 1;
        return {
          Header: getSampleType(key, true, sampleCounter) + '_' + key,
          accessor: key, // getSampleType(sampleName,true) + '_' + propName,
          // fullColumnName: sampleName + '_' + propName,
          useGenotypeColor:true,
          Cell: VanexGenotypeCell,
          width: 40,
          setColWidth: function(inWidth){ this.width = inWidth; },
          style: { textAlign: 'center',}, //fontWeight: 'bolder',
          columnStyle: {textAlign: 'center',},
          sampleType: getSampleType(key, true, sampleCounter),
        }
      }
      else {
        // let colDefinition = getParameterCaseInsensitive(variantFormatDefinition['column-definitions'],key);
        let colHeader=key;
        let colWidth = 100;
        // if (!!colDefinition){
          // colHeader = colDefinition['header'];
          // colWidth = colDefinition['column_width'];
        // }
				if(colHeader.length > 10 ){
					colWidth = colHeader.length * 10;
				}

        // if(colHeader === 'Effect' ){
				// 	colWidth = 150;
				// }

        // TODO: fix the default withd
        // if(colWidth === undefined){
          // colWidth = 100;
        // }
        // else if (colWidth < 30){
          // colWidth = 50;
        // }

        return {
          Header: colHeader,
          accessor: key,
          width: colWidth,
          setColWidth: function(inWidth){ this.width = inWidth; }
        }
      }
    });

    setPMFColNames(tmpPMFColNames);
    return(tableColumns);
  }

  const handleSampleTypeChange = (fields) =>{
    // console.log(fields);
    if((fields.Proband !== fields.Mother) && (fields.Mother !== fields.Father)  && (fields.Proband !== fields.Father) 
      && (fields.Proband !== '')  && (fields.Mother !== '')  && (fields.Father !== '')) {
        
        // console.log(xlsxVariantCols);

        let tmpVariantCol = [...xlsxVariantCols];
        tmpVariantCol.find(t => t.accessor===fields.Proband).sampleType = "P";
        tmpVariantCol.find(t => t.accessor===fields.Mother).sampleType = "M";
        tmpVariantCol.find(t => t.accessor===fields.Father).sampleType = "F";

        tmpVariantCol.find(t => t.accessor===fields.Proband).Header = "P_" + tmpVariantCol.find(t => t.accessor===fields.Proband).accessor;
        tmpVariantCol.find(t => t.accessor===fields.Mother).Header = "M_" + tmpVariantCol.find(t => t.accessor===fields.Mother).accessor;
        tmpVariantCol.find(t => t.accessor===fields.Father).Header = "F_" + tmpVariantCol.find(t => t.accessor===fields.Father).accessor;
        // getSampleType(key, true, sampleCounter) + '_' + key,
        
        // console.log(xlsxVariantCols[fields.Proband].sampleType);
        // console.log(xlsxVariantCols[fields.Mother].sampleType);
        // console.log(xlsxVariantCols[fields.Father].sampleType);

        // let tmpVariantCol = {...xlsxVariantCols};
        // let tmpColumn = {...tmpVariantCol[fields.Proband]};
        // tmpColumn.sampleType = "P";
        // tmpVariantCol[fields.Proband] = tmpColumn;

        // tmpColumn = {...tmpVariantCol[fields.Mother]};
        // tmpColumn.sampleType = "M";
        // tmpVariantCol[fields.Mother] = tmpColumn;

        // tmpColumn = {...tmpVariantCol[fields.Father]};
        // tmpColumn.sampleType = "F";
        // tmpVariantCol[fields.Father] = tmpColumn;
        

        setPMFColNames([fields.Proband, fields.Mother, fields.Father])
        setXlsxVariantCols(tmpVariantCol);

        //!* if (globalFilters.REMOVE_PROBAND_REF.isApplied){
        //   // ? disable the proband=ref filter
        //   let tmpGlobalFilters = {...globalFilters};
        //   let tmpFilterObj = {...globalFilters.REMOVE_PROBAND_REF};
        //   tmpFilterObj.isApplied = false;
        //   tmpGlobalFilters.REMOVE_PROBAND_REF = tmpFilterObj;
        //   setGlobalFilters(tmpGlobalFilters);
          
        //   tmpGlobalFilters = {...globalFilters};
        //   tmpFilterObj = {...globalFilters.REMOVE_PROBAND_REF};
        //   tmpFilterObj.isApplied = true;
        //   tmpGlobalFilters.REMOVE_PROBAND_REF = tmpFilterObj;
        //   setGlobalFilters(tmpGlobalFilters);
        //   // ! maybe add later to redo the filter after the changes are in place
        //   // globalFilters.REMOVE_PROBAND_REF.filterFunction(PMFColNames);
        // }

        // alert('SUCCESS!! :-)\n\n' + JSON.stringify(fields, null, 4));
        sampleTypeModal.hide();
    }
    else{
      toast.error("Not a valid combination!");
      // alert("Not a valid combination!");
      // showAlert("Not a valid combination!", 1, false);
    }
    
  }


  const renderNavBar = () =>{
    return(
      <nav className="navbar navbar-expand navbar-dark sticky-top flex-md-nowrap p-0 no-shadow bg-dark-blue">
        <div className="container-fluid d-flex flex-row flex-grow-1">
          {/* <span className="navbar-brand fw-semibold me-0 ps-3"  onClick={ () => {props.history.push('/')}} >Variant Ne<img src={logo} className="vanex-navbar-logo" alt="logo" />us</span> */}
          <span className="navbar-brand fw-semibold me-0 ps-1" onClick={ () => history.push('/')} >VaNe<img src={logo} className="vanex-navbar-logo" alt="logo" /></span>
        </div>
      </nav>
    );
  }

	if(props.xlsxFile) {
		if(loadingData === true){
			return (<React.Fragment>
        {renderNavBar()}
				<div className="text-center pt-4 mb-4 d-flex flex-column align-items-center justify-content-center">
					<span>
						<span className="fs-1"><i className="far fa-list-alt px-2 fs-1"></i>Loading</span>
						<span className="mx-2 mt-3 spinner-border spinner-border" role="status" aria-hidden="true"> </span>
          </span>
				</div>
			</React.Fragment>);
		}
		else if(processingData === true){
			return (<React.Fragment>
        {renderNavBar()}
				<div className="text-center pt-4 mb-4 d-flex flex-column align-items-center justify-content-center">
					<span>
						<span className="fs-1"><i className="far fa-list-alt px-2 fs-1"></i>Processing data</span>
						<span className="mx-2 mt-3 spinner-border spinner-border" role="status" aria-hidden="true"> </span>
						</span>
				</div>
			</React.Fragment>);
		}
		else {
      //!  console.log("render!!!"); // check to make sure it is not doing it excessively
			return (
				<React.Fragment>
					{renderNavBar()}

					<div className="main-content container-fluid">
            <div className="absolute-panel d-flex flex-column bg-mid-blue">
              <div className="search-result-variant-panel extra-margin">
                <XlsxTabPanelVariantViewer
                  hgRefVersion={props.xlsxFile.hgRefVersion}
                  defaultFilterSet={2}
                  PMFColNames={PMFColNames}
                  onlyShowOneTab={false}
                  selectedVariantTab={'TAB_CLINVAR'}
                  hiddenColumnsIds={analysisHiddenColumnsIds}
                  setHiddenColumnsIds={(hiddenColumnsIds)=> setAnalysisHiddenColumnsIds(hiddenColumnsIds)}
                  initialSortBy={initialSortBy} setSortBy={(sortBy)=> setInitialSortBy(sortBy)}
                  updateVariantTableData={(variantIndex, columnId, value) => setXlsxVariantData(prevXlsxVariantData => prevXlsxVariantData.map(currentVariant => (currentVariant.ROW_INDEX === variantIndex ? {...currentVariant, [columnId]:value} : currentVariant)))}

                  searchBoxValue='' devOptions={false}
//! -------- fix this  ------ !!!!!!!!!
                  setColumnsSpec={(colId, colWidth) => [...xlsxVariantCols].find(t => t.accessor===colId).setColWidth(colWidth) }
                  // currentCaseInformation={activeCaseInfo}
                  // handleToggleColumnVisibility={handleSampleTypeChange}
                  sampleTypeModal={sampleTypeModal}
                  useMaxGnomad={props.xlsxFile.useMaxGnomad}
                  caseData={xlsxVariantData} dataColumns={xlsxVariantCols}
                  />
                </div>
            </div>
					</div>

					<footer className="footer-small footer fixed-bottom mt-auto py-0 bg-light">
						<div className="footer-container container">
							<span className="text-muted">Status bar</span>
						</div>
					</footer>


          {/* Modal */}
          {/* <div className="modal fade" id="refForChangeSampleTypeModal" tabIndex="-1" aria-labelledby="changeSampleTypeLabel" aria-hidden="true"> */}
          <div className="modal fade" ref={refForChangeSampleTypeModal} tabIndex="-1" aria-labelledby="changeSampleTypeLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="changeSampleTypeLabel">Change Sample Types</h5>
                  <button type="button" className="btn-close" onClick={() => sampleTypeModal.hide()}  aria-label="Close"></button>
                </div>
                {/* <form className="d-flex flex-column align-items-center justify-content-center border fs-6" > */}
                <Formik className="" initialValues={{ Proband: '', Mother: '', Father:'' }} onSubmit={handleSampleTypeChange}>
                  <Form>
                  <div className="modal-body">
                    <table className="change-sample-type-table">
                      <thead>
                        <tr>
                          <th className="">Identified as</th>
                          <th className="">Sample ID</th>
                          <th className="">Sample Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.values(xlsxVariantCols).filter(t => t.Header.endsWith("_GT")).map( (column, colIndex) => (
                        <tr key={colIndex}>
                          <td>
                            <label className="mx-3">{column.sampleType}</label> 
                          </td>
                          <td>
                            <label>{column.Header}</label>
                          </td>
                          <td className="d-flex">
                            <div className="form-check">
                              <label className="form-check-label">
                              <Field className="form-check-input" type="radio" value={column.accessor} name="Proband" />
                                Proband</label>
                            </div>
                            <div className="form-check">
                              <label className="form-check-label">
                              <Field className="form-check-input" type="radio" value={column.accessor} name="Mother"/>
                                Mother</label>
                            </div>
                            <div className="form-check">
                              <label className="form-check-label">
                              <Field className="form-check-input" type="radio" value={column.accessor} name="Father" />
                                Father</label>
                            </div>

                            {/* <div className="form-check">
                              <label className="form-check-label">
                              <input className="form-check-input" type="checkbox" value={column.Header} name="Other" />
                                Other</label>
                            </div> */}
                          </td>
                        </tr>
                        ))}
                      </tbody>
                    </table>

                  </div>
                  <div className="modal-footer">
                    <button className="btn btn-primary" type="submit" ><i className="fas fa-save me-2"></i> Save changes</button>
                    <button type="button" className="btn btn-secondary" onClick={() => sampleTypeModal.hide()}>Close</button>
                  </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
          {/* END Modal */}
				</React.Fragment>
			);
		}
	}
  else{
    return (
			<React.Fragment>
        {renderNavBar()}
				<div className="d-flex align-items-center justify-content-center m-3" ><h1>No file selected!</h1></div>
			</React.Fragment>);
  }
}


export default withRouter(XlsxVariantViewer);






// TODOS: add few more property to the filter JSON (editable, visible....) right now are NOT being read from JSON


/** //! duplicated code! is in RdApp too
// * @param {Object} object
// * @param {string} key
// * @return {any} value
// */
// const getParameterCaseInsensitive = (object, key) => {
// 	return object[Object.keys(object)
// 		.find(k => k.toLowerCase() === key.toLowerCase())
// 	];
// }

const XlsxTabPanelVariantViewer = (props) => {

  const [variantData, setVariantData] = useState(props.caseData);
  const [selectedVariantTab, setSelectedVariantTab] = useState(props.selectedVariantTab || 'TAB_ALL');
  const [tabs, setTabs] = useState({
    'TAB_CLINVAR':
    {
      label: 'ClinVar',
      filters: {}
    },
    'TAB_DENOVO': {
      label: 'De Novo',
      filters: {}
    },
    'TAB_HOMREC': {
      label: 'Hom.Rec.',
      filters: {}
    },
    'TAB_COMPHET': {
      label: 'Comp.Het.',
      filters: {}
    },
    'TAB_X': {
      label: 'X-linked',
      filters: {}
    },
    'TAB_LOF': {
      label: ' LOF ',
      filters: {}
    },
    'TAB_MT': {
      label: 'Mitochondrial',
      filters: {}
    },
    // 'TAB_REST': {
    //   label: 'Other SNVs',
    //   filters: {},
    //   visibleFilterSetIndex: 2
    // },
    'TAB_ALL': {
      label: 'All SNVs',
      filters: {}
    }
  });
  
  // const [igvURI, setIgvURI] = useState('');       // ! temp variable!
  const [igvContainer, setIgvContainer] = useState(null);
  const [igvBrowser, setIgvBrowser] = useState(null);
  // const [igvIsLoaded, setIgvIsLoaded] = useState(false);
  const [igvOptions, setIgvOptions] = useState({
    showKaryo: true,
    showIdeogram: true,
    showNavigation: true,
    showRuler: true,
    showCenterGuide: true,
    showCursorTrackingGuide: false,
    showCommandBar: true,
    showSequence: true,
    reference: {
      id: "hg38",
      name: "Human (GRCh38/hg38)",
      fastaURL: "https://s3.amazonaws.com/igv.broadinstitute.org/genomes/seq/hg38/hg38.fa",
      indexURL: "https://s3.amazonaws.com/igv.broadinstitute.org/genomes/seq/hg38/hg38.fa.fai",
      cytobandURL: "https://s3.amazonaws.com/igv.org.genomes/hg38/annotations/cytoBandIdeo.txt.gz",
      aliasURL: "https://s3.amazonaws.com/igv.org.genomes/hg38/hg38_alias.tab",
      tracks: [
        {
          name: "Refseq",
          format: "refgene",
          url: "https://s3.amazonaws.com/igv.org.genomes/hg38/ncbiRefSeq.sorted.txt.gz",
          indexURL: "https://s3.amazonaws.com/igv.org.genomes/hg38/ncbiRefSeq.sorted.txt.gz.tbi",
          // url: "https://s3.amazonaws.com/igv.org.genomes/hg38/refGene.sorted.txt.gz",
          // indexURL: "https://s3.amazonaws.com/igv.org.genomes/hg38/refGene.sorted.txt.gz.tbi",
          displayMode: "SQUISHED",
          visibilityWindow: -1,
          removable: false,
          order: 1000000
        }
      ],
      chromosomeOrder: "chr1, chr2, chr3, chr4, chr5, chr6, chr7, chr8, chr9, chr10, chr11, chr12, chr13, chr14, chr15, chr16, chr17, chr18, chr19, chr20, chr21, chr22, chrX, chrY"
    }
  });
  // flanking: 1000,
  // locus: '',
  // url: "https://s3.amazonaws.com/igv.org.genomes/hg38/ncbiRefGene.txt.gz",


  const [isExporting, setIsExporting] = useState(false);
  const [exportProgressbarValue, setExportProgressbarValue] = useState(0);
  const [currentVariantId, setCurrentVariantId] = useState(null);
  useEffect(() => {
    setLoadingClinVarPlot(false);
    setClinVarPlotSrc('');
    setErrorMessage('');
    setdbSNPSrc('');

    const currentVariant = props.caseData[currentVariantId];
    if(currentVariantId!==undefined && currentVariantId!==null){
      setIgvOptions(prevIgvOptions => ({
        ...prevIgvOptions,
        // locus: `${currentVariant.GENE}`
        locus: `${currentVariant.Chr}:${currentVariant.Start}`
        })
      );
    }

  }, [currentVariantId])
  

  const [filterSelectionFormik, setFilterSelectionFormik] = useState(null);
  const [filterSelectionTitle, setFilterSelectionTitle] = useState('Filtering');
  const [filterSelectionModal, setFilterSelectionModal] = useState(null);
  const refForFilterSelectionModal = useRef();
  const refForCreatableSelect = useRef();

  const [visibleFilterSets, setVisibleFilterSets] = useState(() => {
    let tmpVisibleSetsObj = [];
    Object.values(filterDefinition.XLS_FILTER_SET).filter(t => t.visible === true).forEach( (myFilter, filterInd) => {
    // Object.keys(Object.values(filterDefinition.XLS_FILTER_SET).filter(t => t.visible==true)).FILTER_DEFINITIONS).forEach( tabFilterId => {
    // Object.keys(filterDefinition.XLS_FILTER_SET[0].FILTER_DEFINITIONS).forEach( filterId => {
      let tmpFilterSet = {};
      let tmpDefinitionObj = {};

      Object.keys(myFilter.FILTER_DEFINITIONS).filter( t => ( myFilter.FILTER_DEFINITIONS[t].visible === true)
      ).forEach( filterId => {
        // * createFilterObjectFromJSON
        // console.log(filterId);
        let filter = myFilter.FILTER_DEFINITIONS[filterId];
        let tmpObj = {
          columnId: filter.column_id,
          label: filter.label,
          value: ( (!!filter.value) ? filter.value : null ),
          visible: true,
          editable: true,
          getFilterDescr: ( (!!filter.value) ? function(){ return(this.label + this.value);} : function(){ return(this.label);} ),
          filterFunction: ConstructFilterFunctions(filter),
          isApplied: filter.isApplied
        }
        tmpDefinitionObj[filterId]=tmpObj;
      });
      tmpFilterSet['value']=filterInd;
      tmpFilterSet['label']=myFilter.label;
      tmpFilterSet['user_defined']=false;
      tmpFilterSet['visible']=myFilter.visible;
      tmpFilterSet['FILTER_DEFINITIONS']=tmpDefinitionObj;
      tmpVisibleSetsObj.push(tmpFilterSet);
    });

    //TODO: fetch user filters and add to this with tmpFilterSet['user_defined']=true; (async? in another method?)

    // console.log('tmpVisibleSetsObj');
    // console.log(tmpVisibleSetsObj);
    return(tmpVisibleSetsObj);
  });


  const [isLoadingVisibleFilterSets, setIsLoadingVisibleFilterSets] = useState(false);
  const [selectedFilterSetIndex, setSelectedFilterSetIndex] = useState(props.defaultFilterSet || 0);
  const [isFilterSetModified, setIsFilterSetModified] = useState(false);
  const [filterDescriptions, setFilterDescriptions] = useState([]);
  const [globalFilters, setGlobalFilters] = useState({});


  //? --------------------------------------------- useEffects ---------------------------------- 

  useEffect(() => {
    
    setFilterSelectionModal( new Modal(refForFilterSelectionModal.current));

    if(!!props.hgRefVersion && props.hgRefVersion==='hg19'){
      setIgvOptions({
        showKaryo: true,
        showIdeogram: true,
        showNavigation: true,
        showRuler: true,
        showCenterGuide: true,
        showCursorTrackingGuide: false,
        showCommandBar: true,
        showSequence: true,
        reference: {
          id: "hg19",
          name: "Human (CRCh37/hg19)",
          fastaURL: "https://s3.amazonaws.com/igv.broadinstitute.org/genomes/seq/hg19/hg19.fasta",
          indexURL: "https://s3.amazonaws.com/igv.broadinstitute.org/genomes/seq/hg19/hg19.fasta.fai",
          cytobandURL: "https://s3.amazonaws.com/igv.broadinstitute.org/genomes/seq/hg19/cytoBand.txt",
          aliasURL: "https://s3.amazonaws.com/igv.org.genomes/hg19/hg19_alias.tab",
          tracks: [
            {
              name: "Refseq Genes",
              format: "refgene",
              url: "https://s3.amazonaws.com/igv.org.genomes/hg19/ncbiRefSeq.sorted.txt.gz",
              indexURL: "https://s3.amazonaws.com/igv.org.genomes/hg19/ncbiRefSeq.sorted.txt.gz.tbi",
              displayMode: "SQUISHED",
              visibilityWindow: -1,
              removable: false,
              order: 1000000
            }
          ],
          chromosomeOrder: "chr1, chr2, chr3, chr4, chr5, chr6, chr7, chr8, chr9, chr10, chr11, chr12, chr13, chr14, chr15, chr16, chr17, chr18, chr19, chr20, chr21, chr22, chrX, chrY"
        }
      });
    }

    Object.keys(tabs).forEach( tabId => {
      let tmpTabFilterObj = {};

      // * add Tab-Filters defined in the JSON for each tab
      let tabFilterDefinition = filterDefinition.XLS_TAB_FILTER_SET.FILTER_DEFINITIONS[tabId];
      if(tabFilterDefinition !== undefined && tabFilterDefinition !==null){
        Object.keys(tabFilterDefinition).forEach( tabFilterId => {
          // * createFilterObjectFromJSON
          let filter = tabFilterDefinition[tabFilterId];
          let tmpObj = {
            columnId: filter.column_id,
            label: filter.label,
            value: null,
            visible: false,
            editable: false,         // tabFilter: true,
            getFilterDescr: function(){ return(this.label)},
            filterFunction: ConstructFilterFunctions(filter),
            isApplied: true
          }
          tmpTabFilterObj[tabFilterId]=tmpObj;
        });
      }

      // * add defaultFilterSet for each tab from visibleFilterSets
      let filterSetIndex = props.defaultFilterSet || 0;
      if (filterSetIndex !==undefined && filterSetIndex !== null &&
        !!visibleFilterSets[filterSetIndex] && !!visibleFilterSets[filterSetIndex].FILTER_DEFINITIONS){
        Object.keys(visibleFilterSets[filterSetIndex].FILTER_DEFINITIONS).forEach( filterId => {
          tmpTabFilterObj[filterId]={...visibleFilterSets[filterSetIndex].FILTER_DEFINITIONS[filterId]};
        });
      }
      
      setTabs( prevTabs => ({
        ...prevTabs,
        [tabId]: {
          ...prevTabs[tabId],
          filters:tmpTabFilterObj
        }
      }));
      // console.log(tabId);
      // console.log(tmpTabFilterObj);

    } );
    // console.log("Original tabs");
    // console.log(tabs);
  }, []);




  const updateFilterSet = (selectedIndex) => {
    let tmpTabFilterObj = {};

    // * add Tab-Filters defined in the JSON for this tab
    let tabFilterDefinition = filterDefinition.XLS_TAB_FILTER_SET.FILTER_DEFINITIONS[selectedVariantTab];
    if(tabFilterDefinition !== undefined && tabFilterDefinition !==null){
      Object.keys(tabFilterDefinition).forEach( tabFilterId => {
        // * createFilterObjectFromJSON
        let filter = tabFilterDefinition[tabFilterId];
        let tmpObj = {
          columnId: filter.column_id,
          label: filter.label,
          value: null,
          visible: false,
          editable: false,         // tabFilter: true,
          getFilterDescr: function(){ return(this.label)},
          filterFunction: ConstructFilterFunctions(filter),
          isApplied: true
        }
        tmpTabFilterObj[tabFilterId]=tmpObj;
      });
    }

    // * add filters from visibleFilterSets
    if (!!visibleFilterSets[selectedIndex] && !!visibleFilterSets[selectedIndex].FILTER_DEFINITIONS){
      Object.keys(visibleFilterSets[selectedIndex].FILTER_DEFINITIONS).forEach( filterId => {
        tmpTabFilterObj[filterId]={...visibleFilterSets[selectedIndex].FILTER_DEFINITIONS[filterId]};
      });
    }

    setTabs( prevTabs => ({
      ...prevTabs,
      [selectedVariantTab]: {
        ...prevTabs[selectedVariantTab],
        filters: tmpTabFilterObj
      }
    }));

  }


  const groupBy = (data, key) => {
    return data.reduce(function(rval, item) {
      (rval[item[key]] = rval[item[key]] || []).push(item);
      return rval;
    }, {});
  };

  const filterByGene = () => {
    let genelist= props.searchBoxValue.split(" ");
    let tmpList = props.caseData;
    tmpList = tmpList.filter(t => genelist.includes(t["Gene"]));
    // console.log(tmpList);
    setVariantData(tmpList)
  }

  useEffect(() => {

    // function notIn(inFunction){ return function(){return !inFunction.apply(this, arguments);};}

    // ! ------ Filter ------
    const applyFilter = () => {
      // console.log("applyFilter");
      let tmpVariantData = [...props.caseData]; // ! this or this->?  props.caseData;
      let filterSpec = [];

      // ! add this:  check if clinvar then add all variants in those genes to make sure there is no comp.het. !
      Object.values(globalFilters).forEach((filterTerm)=> {
        if (filterTerm.isApplied!== undefined && filterTerm.isApplied!== null && filterTerm.isApplied===true){
          tmpVariantData = Object.values(tmpVariantData).filter( filterTerm.filterFunction(props.PMFColNames));
        }
      });
      Object.values(tabs[selectedVariantTab].filters).forEach((filterTerm)=> {
        if (filterTerm.isApplied!== undefined && filterTerm.isApplied!== null && filterTerm.isApplied===true){
          tmpVariantData = Object.values(tmpVariantData).filter( filterTerm.filterFunction(props.PMFColNames));
        }
      });
      
      // if(selectedVariantTab === 'TAB_REST'){
      //     // ! this DOES include Comp.het as it is tough to filter the TRUE Comp.Het from this.
      //     console.log('TAB_REST');
      //     // Object.keys(tabs).forEach( tabId => {
      //     //   Object.values(tabs[tabId].filters).forEach( filterInfo => {
      //     //     console.log(filterInfo);
      //     //     tmpVariantData = Object.values(tmpVariantData).filter( notIn(filterInfo.filterFunction(props.PMFColNames)));
      //     //   });
      //     // });
      //     // // tmpVariantData = Object.values(tmpVariantData).filter( notIn(tabFilterObj.TAB_CLINVAR.MAIN.filterFunction(props.PMFColNames)));
      //     // // tmpVariantData = Object.values(tmpVariantData).filter( notIn(tabFilterObj.TAB_DENOVO.MAIN.filterFunction(props.PMFColNames)));
      //     // // tmpVariantData = Object.values(tmpVariantData).filter( notIn(tabFilterObj.TAB_HOMREC.MAIN.filterFunction(props.PMFColNames)));
      //     // // tmpVariantData = Object.values(tmpVariantData).filter( notIn(tabFilterObj.TAB_X.MAIN.filterFunction(props.PMFColNames)));
      //     // // tmpVariantData = Object.values(tmpVariantData).filter( notIn(tabFilterObj.TAB_LOF.MAIN.filterFunction(props.PMFColNames)));
      //     // // tmpVariantData = Object.values(tmpVariantData).filter( notIn(tabFilterObj.TAB_MT.MAIN.filterFunction(props.PMFColNames)));

      //     // filterSpec.push("Filter out variants in ClinVar, De Novo, Hom.Rec., X-linked, LOF, and Mito. tabs");
      // } else 
      if (selectedVariantTab === 'TAB_COMPHET'){
        // ! fix this, need to have G and M GT!
        // ? Comp. Het. needs special analysis. This code selects TRUE Comp.Hets
        // tmpVariantData = Object.values(tmpVariantData).filter(t => t['Inherit Model'] === 'ch');

        //? remove the genes with only 1 variant
        let groupedByGene=groupBy(tmpVariantData, 'Gene');
        groupedByGene = Object.keys(groupedByGene).filter(t => groupedByGene[t].length > 1);
        tmpVariantData = Object.values(tmpVariantData).filter(t => Object.values(groupedByGene).includes(t['Gene']));

        //? keep the true comp. het. variants
        if (tmpVariantData[0]){
          if(tmpVariantData[0][props.PMFColNames[1]] && tmpVariantData[0][props.PMFColNames[2]]){
            groupedByGene=groupBy(tmpVariantData, 'Gene');
            groupedByGene = Object.keys(groupedByGene).filter(gene => {
              // console.log(`group ${gene} has ${groupedByGene[gene].length} members : `);
              let momGT=[];
              let dadGT=[];
              groupedByGene[gene].forEach ((t,i) => {
                momGT.push(t[props.PMFColNames[1]]!=='ref');
                dadGT.push(t[props.PMFColNames[2]]!=='ref');
              })
              return (momGT.includes(true) && dadGT.includes(true));
            }); 
            tmpVariantData = Object.values(tmpVariantData).filter(t => Object.values(groupedByGene).includes(t['Gene']));
          }
        }

        // filterSpec.push("Inheritance = Comp.Het.");
        filterSpec.push("Only show TRUE Comp.Het.");
      }

      if(props.useMaxGnomad) filterSpec.push("gnomAD frq. < 0.001");
      setFilterDescriptions(filterSpec);

      setVariantData(tmpVariantData);
    }

    applyFilter();
  }, [tabs, globalFilters, selectedVariantTab, props.caseData]);
  //! }, [variantFilterObj]); //! deconstruct props and use individual ones?? 

  const memoizedData = React.useMemo(() => variantData , [variantData]);
  const memoizedColumns = React.useMemo(() => props.dataColumns, [props.dataColumns]);

  const [dbSNPSrc, setdbSNPSrc] = useState('');

  const [loadingClinVarPlot, setLoadingClinVarPlot] = useState(false);
  const [clinVarPlotSrc, setClinVarPlotSrc] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const loadClinVarPlot = (currentVariant) => {
    setLoadingClinVarPlot(true);

    let geneName = currentVariant.Gene;
    GetSignedURL('nch-igm-vanex-client',`ClinVarPlots/GRCH38-Varhouse/${geneName.toUpperCase()}%2F${geneName.toUpperCase()}.ALL.png`) 
    .then(result_json => {
      setClinVarPlotSrc(result_json);
      setErrorMessage('');
      setLoadingClinVarPlot(false);
    }).catch(e => {
      setClinVarPlotSrc('');
      setLoadingClinVarPlot(false);
      setErrorMessage(`Failed to load the image. (${e})`);
    });
  }

  const getClinvarSignificanceStyle = (clinvarSig) => {
    let style = 'rounded-pill px-2 py-1 ';
    if(clinvarSig.toLowerCase()==='pathogenic' || clinvarSig.toLowerCase()==='likely_pathogenic' || clinvarSig.toLowerCase()=== 'pathogenic/likely_pathogenic'){
      style += ' bg-danger text-light ';
    }
    else if(clinvarSig.toLowerCase()==='benign' || clinvarSig.toLowerCase()==='likely_benign' || clinvarSig.toLowerCase()=== 'benign/likely_benign'){
      style += ' bg-success text-light ';
    }
    else{
      style += ' bg-warning text-dark ';
    }
    return style;
  }


  //? --------------------------------------------- Exports ----------------------------------

  const exportSnvAsXlsx = () => {

    // ! find a better way to update progress bar?
    function updateExportProgress(value, final=false){
      setExportProgressbarValue(value);
      // const interval = setInterval(() => {
      //   setExportProgressbarValue(value);
      //   if(final || value > 90) {
      //     setIsExporting(false);
      //     clearInterval(interval);
      //   }
      // }, 400);
    }

    setIsExporting(true);
    
    let outputFileName = "VaNex-output";
    let xlsxSheetData= [];
    let globalFilteredVariantData = props.caseData;

    updateExportProgress(10);

    // ! add this:  check if clinvar then add all variants in those genes to make sure there is no comp.het. !
    Object.values(globalFilters).forEach((filterTerm)=> {
      if (filterTerm.isApplied!== undefined && filterTerm.isApplied!== null && filterTerm.isApplied===true){
        globalFilteredVariantData = Object.values(globalFilteredVariantData).filter( filterTerm.filterFunction(props.PMFColNames));
      }
    });

    Object.keys(tabs).forEach( tabId => {
      // * add a sheet for each tab!
      
      //! ------------ TO DO!!----------:
      // TODO: add this later:  check if clinvar then add all variants in those genes to make sure there is no comp.het. !
      
      // * apply filteres for each tab!
      let tmpVariantData = globalFilteredVariantData;
      Object.values(tabs[tabId].filters).forEach((filterTerm)=> {
        console.log('filtering export: ', tabId, ' - ', filterTerm);
        if (filterTerm.isApplied!== undefined && filterTerm.isApplied!== null && filterTerm.isApplied===true){
          tmpVariantData = Object.values(tmpVariantData).filter( filterTerm.filterFunction(props.PMFColNames));
        }
      });

      if(tabId==='TAB_COMPHET'){
        // ! fix this, need to have G and M GT!
        // ? Comp. Het. needs special analysis. This code selects TRUE Comp.Hets
        // this has already been filtered in the filter object => tmpVariantData = Object.values(tmpVariantData).filter(t => t['Inherit Model'] === 'ch');

        //? remove the genes with only 1 variant
        let groupedByGene=groupBy(tmpVariantData, 'Gene');
        groupedByGene = Object.keys(groupedByGene).filter(t => groupedByGene[t].length > 1);
        tmpVariantData = Object.values(tmpVariantData).filter(t => Object.values(groupedByGene).includes(t['Gene']));

        //? keep the true comp. het. variants
        if (tmpVariantData[0]){
          if(tmpVariantData[0][props.PMFColNames[1]] && tmpVariantData[0][props.PMFColNames[2]]){
            groupedByGene=groupBy(tmpVariantData, 'Gene');
            groupedByGene = Object.keys(groupedByGene).filter(gene => {
              // console.log(`group ${gene} has ${groupedByGene[gene].length} members : `);
              let momGT=[];
              let dadGT=[];
              groupedByGene[gene].forEach ((t,i) => {
                momGT.push(t[props.PMFColNames[1]]!=='ref');
                dadGT.push(t[props.PMFColNames[2]]!=='ref');
              })
              return (momGT.includes(true) && dadGT.includes(true));
            }); 
            tmpVariantData = Object.values(tmpVariantData).filter(t => Object.values(groupedByGene).includes(t['Gene']));
          }
        }

      }

      xlsxSheetData.push([tabs[tabId].label , XLSX.utils.json_to_sheet(tmpVariantData)]);

    });
    updateExportProgress(50);

    //! add this? xlsxSheetData.push(['Filtered (raw)' , XLSX.utils.json_to_sheet(props.caseData)]);
    //! add this? xlsxSheetData.push(['Filtered (Global)' , XLSX.utils.json_to_sheet(globalFilteredVariantData)]);
    //! add this? xlsxSheetData.push(['Selected' , XLSX.utils.json_to_sheet(variantData)]);

		const wb = XLSX.utils.book_new();
    xlsxSheetData.forEach( element => {
      console.log('appending: ', element);
      XLSX.utils.book_append_sheet(wb, element[1], element[0]);  
    });

    updateExportProgress(70);
    
    //! if(!!props.currentCaseInformation){
    //!   XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet([props.currentCaseInformation]), "Case Info");
    //!   if(!!props.currentCaseInformation.CASE_ID){
    //!     outputFileName = props.currentCaseInformation.CASE_ID;
    //!   }
    //! }

    updateExportProgress(80);
    /* generate XLSX file and send to client */
		XLSX.writeFile(wb, outputFileName + ".xlsx");
    setIsExporting(false); // updateExportProgress(99, true);
  }

  //? --------------------------------------------- process values ----------------------------------

  const showWithPrecision = (inGnomadFreq, decNum=2) => {
    let gnomadFreq = inGnomadFreq;
    if (inGnomadFreq !== undefined && inGnomadFreq !== null){
      gnomadFreq = Number(gnomadFreq);
      return(gnomadFreq.toPrecision(decNum));
      // if(gnomadFreq!==0) return gnomadFreq.toFixed(decNum);
    }
    return gnomadFreq
  }

  const processHGVS = (hgvsTermsString) => {
    if(hgvsTermsString===null || hgvsTermsString===undefined) return('-'); //! use this? if(hgvsTermsString===null) return('-'); //  
    if(hgvsTermsString.trim()==='') return('-');
    return(hgvsTermsString.split(';').map((hgvsTerm, hgvsIndex) => {
      return(
        <div className="rounded-pill border-light-blue m-0 mb-2 px-5" key={hgvsIndex}>
          {hgvsTerm.split(':').map((subTerm, inerIndex) => {  //.split(/(?=:)/g);
            return(<div key={inerIndex} className="">{subTerm}{(inerIndex!==hgvsTerm.split(':').length-1) ? ':' : ''}</div>);
          })}
      </div>
      );
    }));
  }

  const processOMIMTerms = (omimTermsString) => {
    let omimTerms = omimTermsString.split(';');
    omimTerms = omimTerms.map((term, index) => {
      return(
        <div  key={index} className="rounded-pill px-2 mx-2 my-1 border-light-blue d-flex flex-row flex-nowrap align-items-center justify-content-center" 
          dangerouslySetInnerHTML={{ __html: term.replaceAll(/,? ?autosomal dominant/ig, '<span class="ms-1 badge bg-orange">AD</span>')
            .replaceAll(/,? ?autosomal recessive/ig, '<span class="ms-1 badge bg-green">AR</span>')
            .replaceAll(/,? ?X-linked dominant/ig, '<span class="ms-1 badge bg-orange">XLD</span>')
            .replaceAll(/,? ?X-linked recessive/ig, '<span class="ms-1 badge bg-green">XLR</span>') }} />
      );
    });
    return omimTerms;
  }

  const processHpoTerms = (hpoTermsString) => {
    let hpoTerms = hpoTermsString.toString().split(',');
    hpoTerms = hpoTerms.map( (term, index) => {
      return(
        <div key={index} className="rounded-pill px-2 mx-2 my-1 border-light-blue">{term}</div>
      );
    });
    return hpoTerms;
  }

  const processOrphanetTerms = (orphanetTermsString) => {
    let orphanetTerms = orphanetTermsString.split(';');
    orphanetTerms = orphanetTerms.map( (term, index) => {
      return(
        <div key={index} className="rounded-pill px-2 mx-2 my-1 border-light-blue">{term}</div>
      );
    });
    return orphanetTerms;
  }


  //? --------------------------------------------- IGV ----------------------------------

  useEffect(() => {
    if (!!igvContainer) {
      igv.createBrowser(igvContainer, igvOptions).then((browser) => {
        setIgvBrowser(browser);
        if (!!igvOptions.locus);
          browser.search(igvOptions.locus)
          .then(() => {
            browser.zoomOut();
          });
      })
    }
  }, [igvContainer]);

  useEffect(() => {
    if (igvBrowser) {
      igvBrowser.search(igvOptions.locus)
      .then(() => {
        igvBrowser.zoomOut();
      });
    }
  }, [igvOptions.locus]);

  const setIgvContainerElement = (element) => {
    setIgvContainer(element);
  }


  //? --------------------------------------------- renders ----------------------------------

  const renderVariantInfo = (variantId) => {
    if(variantId===null){ // ! which one???    if(variantId===null || !props.caseData[variantId]){
      return(
        <div className="bottom-var-info-panel d-flex flex-column">
          <div className="d-flex overflow-auto flex-column flex-nowrap">
            <div className="card m-2 shadow-sm flex-grow-1">
              <h5 className="card-header bg-light-blue">Variant</h5>
              <div className="card-body d-flex flex-row flex-nowrap align-items-center justify-content-center">
                <h3>No variant selected</h3>
              </div>
            </div>
          </div>
        </div>
      );
    }
    else{
      const variant = props.caseData[variantId];
      const otherVariationsInThisGene = Object.values(props.caseData).filter(t => t.Gene === variant.Gene);

      return(
        <div className="bottom-var-info-panel d-flex flex-column">
          <div className="d-flex overflow-auto flex-column flex-nowrap">
            
            <div className="card m-1 shadow-sm flex-grow-1">
              <h5 className="variant-card-header bg-light-blue d-flex flex-row">Variant: <div className="mx-2 user-select-all">{variant.Chr}-{variant.Start}-{variant.Ref}-{variant.Alt}</div> <span className="ms-5">Gene: <span className="mx-1 user-select-all">{variant.Gene}</span> </span></h5>
              <div className="variant-card-body d-flex flex-row flex-wrap align-items-center justify-content-center">
                <div className="d-flex flex-row flex-nowrap align-items-center justify-content-center flex-grow-0" style={{minWidth:'30rem'}}>
                  <div className="variant-item text-start">
                    <a href={`https://varsome.com/variant/${props.hgRefVersion}/${variant.Chr}-${variant.Start}-${variant.Ref}-${variant.Alt}?annotation-mode=germline`}  target="_blank" rel="noreferrer"><i className="fas fa-check"></i> Varsome</a>
                    <div className="mt-2">
                      {(variant.dbSNP===null) ?
                        <span><i className="fas fa-external-link-alt"></i> dbSNP</span> :
                        <a href={'https://www.ncbi.nlm.nih.gov/snp/?term='+variant.dbSNP} target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt"></i> dbSNP</a>
                      }
                    </div>
                  </div>
                  <div className="variant-item text-start">
                    <a href={'http://localhost:60151/goto?locus=chr'+variant.Chr+':'+variant.Start+'-'+variant.Start} target="_blank" rel="noreferrer"><i className="fas fa-align-center"></i> IGV</a>
                    <div className="mt-2"><a href={'http://denovo-db.gs.washington.edu/denovo-db/QueryVariantServlet?searchBy=Gene&target='+variant.Gene} target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt"></i> denovo-db</a></div>
                  </div>
                  <div className="variant-item text-start" style={{minWidth:'fit-content'}}>
                    <a href={'https://www.genecards.org/cgi-bin/carddisp.pl?gene='+variant.Gene} target="_blank" rel="noreferrer"><i className="fas fa-dna"></i> {variant.Gene}</a>
                    <div className="mt-2"><a href={'http://genome.ucsc.edu/cgi-bin/hgTracks?db='+props.hgRefVersion+'&position=chr'+variant.Chr+':'+variant.Start+'-'+variant.Start} target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt"></i> UCSC</a></div>
                  </div>
                </div>
                {/* <div className="variant-item text-center"><span className="text-muted">Gene</span><br/><a href={'https://www.genecards.org/cgi-bin/carddisp.pl?gene='+variant.Gene} target="_blank" rel="noreferrer"><i className="fas fa-dna"></i> {variant.Gene}</a></div> */}
                {/* <div className="variant-item text-center flex-grow-1 show-overflow">{variant.Chr}-{variant.Start}-{variant.Ref}-{variant.Alt}</div> */}
                <div className="variant-item text-center flex-grow-0"><span className="text-muted">Quality</span><div className="m-1"><span className="px-2 py-1 rounded-pill fw-bold" style={{backgroundColor: GetHeatedQualityScore(variant.Qual)}}>{variant.Qual}</span></div></div>
                <div className="variant-item text-center variant-item-HGVS flex-grow-1" style={{minWidth:'min-content'}}>
                  {/* //! fix this: do we need trim? <span className="text-muted">{(variant["MANE HGVS"].trim()!=="") ? 'MANE HGVS' : 'HGVS'}</span> */}
                  <span className="text-muted">{(variant["MANE HGVS"]) ? 'MANE HGVS' : 'HGVS'}</span>
                  <div className="mt-1 limited-text-height d-flex flex-column align-items-center"  style={{width:'100%',height:'4.4rem'}}>
                    {(variant["MANE HGVS"]) ? processHGVS(variant["MANE HGVS"]) : ( (variant["HGVS"])  ? processHGVS(variant["HGVS"]) : '-')}
                    {/* //! here as well {(variant["MANE HGVS"].trim()!=="") ? processHGVS(variant["MANE HGVS"]) : ( (variant.HGVS) ? processHGVS(variant.HGVS) : '-')} */}
                  </div>
                </div>
                <div className="variant-item text-center flex-grow-0 1flex-shrink-1" style={{minWidth:'15rem'}}><span className="text-muted">Loc In Gene</span><div className="">{variant["Loc In Gene"]}</div></div>
                <div className="variant-item text-center flex-grow-0"><span className="text-muted">Splice Dist</span><div className="">{variant["Splice Dist"]}</div></div>
              </div>
              {/* <footer className="card-footer text-muted">2 days ago <cite title="Source Title">Source Title</cite></footer> */}
            </div>


            {/* {renderPathogenicity()} */}
            <div className="card m-1 shadow-sm flex-grow-1" style={{minWidth: '10rem'}}>
              <h5 className="variant-card-header bg-light-blue">OMIM</h5>
              <div className="variant-card-body d-flex flex-row flex-nowrap align-items-center justify-content-center">
                <div className="variant-item text-center m-1 d-flex flex-row">
                  {(variant["OMIM"]===null) ? "" :
                    <a href={'https://www.omim.org/entry/'+variant["OMIM"]} target="_blank" rel="noreferrer" className="me-2"><i className="fas fa-external-link-alt"></i> OMIM<br/> Phenotypes</a>
                  }
                  <div className="flex-grow-1 d-flex flex-row flex-wrap">
                    {(variant["OMIM Phenotypes"]===undefined) ? "Error!" : ( (variant["OMIM Phenotypes"]===null) ? "-" : processOMIMTerms(variant["OMIM Phenotypes"]) )}
                  </div>
                </div>
              </div>
            </div>

            {/* {renderPathogenicity()} */}
            <div className="card m-1 shadow-sm flex-grow-1" style={{minWidth: '10rem'}}>
              <h5 className="variant-card-header bg-light-blue">Pathogenicity</h5>
              <div className="variant-card-body d-flex flex-row flex-wrap align-items-center justify-content-center">
                <div className="d-flex flex-row flex-nowrap align-items-center justify-content-center flex-grow-1">
                  <div className="variant-item text-center m-0 p-0 px-1 flex-grow-0" style={{minWidth:'15rem'}}><span className="text-muted ">ClinVar Significance</span><br/>
                    {(variant["ClinVar Significance"]===undefined) ? "Error!" : ( (variant["ClinVar Significance"]===null) ? "-" : 
                    <span className={getClinvarSignificanceStyle(variant["ClinVar Significance"])}>{variant["ClinVar Significance"]}</span>)}
                  </div>
                  <div className="variant-item text-center m-0 p-0 px-1 flex-grow-1 flex-wrap"><span className="text-muted">ClinVar Disease</span><br/>{(variant["ClinVar Disease"]===undefined) ? "Error!" : ( (variant["ClinVar Disease"]===null) ? "-" : variant["ClinVar Disease"] )}</div>
                </div>
                <div className="text-center m-1 d-flex flex-row flex-nowrap align-items-center justify-content-center flex-grow-1 ">
                  <div className="variant-item text-center m-1 flex-grow-1"><span className="text-muted">CADD Phred</span><br/>{(variant["CADD Phred"]===undefined) ? "Error!" : ( (variant["CADD Phred"]===null) ? "-" : showWithPrecision(variant["CADD Phred"], 3) )}</div>
                  <div className="variant-item text-center m-1 flex-grow-1"><span className="text-muted">CADD Raw</span><br/>{(variant["CADD Raw"]===undefined) ? "Error!" : ( (variant["CADD Raw"]===null) ? "-" : showWithPrecision(variant["CADD Raw"], 3) )}</div>
                </div>
              </div>
              {/* {renderClinVarPlots()} */}
              {/* <div className="card m-1 shadow-sm flex-grow-1" style={{minWidth: '10rem'}}> */}
                {/* <h5 className="variant-card-header bg-light-blue">ClinVar Plot</h5> */}
                {/* <div className="variant-card-body d-flex flex-row flex-nowrap align-items-center justify-content-center"> */}
                  <div  className="variant-card-body text-center m-0 p-0 d-flex flex-column align-items-center justify-content-center">
                    {/* <br/> */}
                    {/* {(variant.ClinVarPlot) ? */}
                    {(clinVarPlotSrc)
                    ?
                    <div id="img-holder">
                      {/* <span id="clinvarMask" tabIndex="1"></span> */}
                      {/* <img id="clinvarPic" tabIndex="2" className="clinvarplot" src={variant.ClinVarPlot} alt="ClinVar Plot"/> */}
                      {/* <img tabIndex="2" className="clinvarplot" src={variant.ClinVarPlot} alt="ClinVar Plot"/> */}
                      {/* <img className="clinvarplot" src={clinVarPlotSrc} alt="ClinVar Plot"/> */}
                      <a href={clinVarPlotSrc} target="_blank" rel="noreferrer"> <img className="clinvarplot mb-2" src={clinVarPlotSrc} alt="ClinVar Plot"/></a>
                    </div>
                    :
                      (loadingClinVarPlot)
                      ?
                      <span><span className="mx-2 spinner-border spinner-border" role="status" aria-hidden="true"> </span><i className="far fa-image px-3 fs-1"></i></span>
                      :
                        (errorMessage.length > 0)
                        ?
                        <h5>{errorMessage}</h5>
                        :
                        <h5 className="d-flex flex-row flex-nowrap align-items-center justify-content-center cursor-pointer" onClick={ () => loadClinVarPlot(variant)}>Click to load the plot <i className="far fa-image px-3 fa-2x"></i></h5>
                    }
                    </div>
                {/* </div> */}
              {/* </div> */}
            </div>

            

            <div className="card-group flex-grow-1">
              {/* {renderPopulationCounts()} */}
              <div className="card m-1 shadow-sm flex-fill" style={{minWidth: '10rem'}}>
                <h5 className="variant-card-header bg-light-blue">gnomAD Counts</h5>
                <div className="variant-card-body d-flex flex-column flex-nowrap align-items-center justify-content-center">
                  <div className="d-flex flex-row flex-nowrap align-items-center">
                    <div className="text-center">
                      <a href={'http://gnomad.broadinstitute.org/variant/'+variant.Chr+'-'+variant.Start+'-'+variant.Ref+'-'+variant.Alt+'?dataset=gnomad_r4'} target="_blank" rel="noreferrer" ><i className="fas fa-external-link-alt"></i> gnomAD <strong>WG</strong></a>
                    </div>
                    {/* <div className="variant-item text-end"><span className="fw-bold">WG</span></div> */}
                    <div className="variant-item text-center">{(variant["Alt Count gnomAD WG "]===undefined) ? "Error!" : ( (variant["Alt Count gnomAD WG "]===null) ? "-" : variant["Alt Count gnomAD WG "] )}</div>
                    <div className="variant-item text-center">{(variant["gnomAD WG PopMax"]===undefined) ? "Error!" : ( (variant["gnomAD WG PopMax"]===null) ? "-" : showWithPrecision(variant["gnomAD WG PopMax"], 2) )}</div>
                    <div className="variant-item text-center">{(variant["Homozygous Count gnomAD WG "]===undefined) ? "Error!" : ( (variant["Homozygous Count gnomAD WG "]===null) ? "-" : variant["Homozygous Count gnomAD WG "] )}</div>
                  </div>
                  <div className="d-flex flex-row flex-nowrap align-items-center">
                    <div className="text-center">
                      <a href={'http://gnomad.broadinstitute.org/variant/'+variant.Chr+'-'+variant.Start+'-'+variant.Ref+'-'+variant.Alt} target="_blank" rel="noreferrer" ><i className="fas fa-external-link-alt"></i> gnomAD <strong>Ex</strong></a>
                    </div>
                    {/* <div className="variant-item text-end"><span className="fw-bold">Ex</span></div> */}
                    <div className="variant-item text-center">{(variant["Alt Count gnomAD EX "]===undefined) ? "Error!" : ( (variant["Alt Count gnomAD EX "]===null) ? "-" : variant["Alt Count gnomAD EX "] )}<br/><span className="text-muted">Alt</span></div>
                    <div className="variant-item text-center">{(variant["AF PopMax gnomAD EX"]===undefined) ? "Error!" : ( (variant["AF PopMax gnomAD EX"]===null) ? "-" : showWithPrecision(variant["AF PopMax gnomAD EX"], 2) )}<br/><span className="text-muted">Fraction</span></div>
                    <div className="variant-item text-center">{(variant["Homozygous Count gnomAD EX "]===undefined) ? "Error!" : ( (variant["Homozygous Count gnomAD EX "]===null) ? "-" : variant["Homozygous Count gnomAD EX "] )}<br/><span className="text-muted">Homozygous</span></div>
                  </div>
                </div>
              </div>
              {/* {renderIGMAlleleCounts()} */}
              <div className="card m-1 shadow-sm" style={{minWidth: '10rem'}}>
                <h5 className="variant-card-header bg-light-blue">IGM Allele Counts</h5>
                <div className="variant-card-body d-flex flex-row flex-wrap align-items-center justify-content-center">
                  <div className="variant-item text-center">{(variant["IGM Alt Sample Count"]===undefined) ? "Error!" : ( (variant["IGM Alt Sample Count"]===null) ? "-" : variant["IGM Alt Sample Count"] )}<br/><span className="text-muted">Alt</span></div>
                  <div className="variant-item text-center">{(variant["IGM Total Sample Count"]===undefined) ? "Error!" : ( (variant["IGM Total Sample Count"]===null) ? "-" : variant["IGM Total Sample Count"] )}<br/><span className="text-muted">Total</span></div>
                  <div className="variant-item text-center">{(variant["IGM Alt Sample Fraction"]===undefined) ? "Error!" : ( (variant["IGM Alt Sample Fraction"]===null) ? "-" : showWithPrecision(variant["IGM Alt Sample Fraction"], 2) )}<br/><span className="text-muted">Fraction</span></div>
                </div>
              </div>
            </div>
    
            {/* {renderdbSNPFrame()} */}
            <div className="card m-1 shadow-sm flex-grow-1" >
              <h5 className="variant-card-header bg-light-blue">dbSNP</h5>
              <div className="variant-card-body d-flex flex-column align-items-center justify-content-center">
                {(dbSNPSrc)
                ?
                <iframe src={dbSNPSrc} style={{width:'100%', height:'30vh'}} height="100" title="dbSNP Frame"></iframe>
                :
                <div>
                  {(variant.dbSNP===null) ? "-" :
                    <span>
                      <a href={`https://www.ncbi.nlm.nih.gov/snp/?term=${variant.dbSNP}`} target="_blank" rel="noreferrer" ><i className="fas fa-external-link-alt"></i> dbSNP</a>
                      <span className="fs-5 mx-5" onClick={ () => setdbSNPSrc(`https://www.ncbi.nlm.nih.gov/snp/?term=${variant.dbSNP}`)} style={{cursor:'pointer'}}>(click to load dbSNP data in here!)</span>
                    </span>
                  }
                </div>
                }
                {/* <iframe src={'https://www.ncbi.nlm.nih.gov/snp/?term='+variant.dbSNP} width="700" height="300"></iframe> */}
              </div>
            </div>

            {/* {renderIGV()} */}
            <div className="card m-1 shadow-sm flex-grow-1" style={{minWidth: '10rem'}}>
              {/* <div className="variant-card-body d-flex flex-column align-items-center justify-content-center"> */}
              <div className="variant-card-body d-flex flex-column m-0 mb-1 p-0">
                  <div ref={setIgvContainerElement} style={{width:'100%'}}/>
              </div>
            </div>

            {/* {renderdOtherVriationInthisGene()} */}
            <div className="card m-1 shadow-sm flex-grow-1" style={{minWidth: '10rem'}}>
              <h5 className="variant-card-header bg-light-blue">All SNVs in {variant.Gene} {' ('}{Object.keys(otherVariationsInThisGene).length}{')'}</h5>
              <div className="variant-card-body p-0 m-1 d-flex flex-column show-overflow bg-white" style={{ minHeight:'12rem', maxHeight:'20rem'}}>
                <VariantTable
                  initialSortBy={props.initialSortBy}
//! fix this part 
                  updateVariantTableData={() => console.log('ToBeFixed!')}
                  DoNotSortColumns={true}
                  hiddenColumnsIds={props.hiddenColumnsIds}
                  columns={memoizedColumns} data={otherVariationsInThisGene}
                />
              </div>
            </div>


            <div className="card m-1 shadow-sm flex-grow-1" style={{minWidth: '10rem'}}>
              <h5 className="variant-card-header bg-light-blue">Orphanet/HPO</h5>
              <div className="variant-card-body d-flex flex-row align-items-center justify-content-center">
    
                <div className="card-group flex-grow-1 d-flex flex-column">
                  <div className="mx-2 mb-4">
                    <h5 className="card-title">HPO</h5>
                    <div className="limited-text-height d-flex flex-row flex-wrap ">
                      {(variant["HPO Label"]===undefined) ? "Error!" : ( (variant["HPO Label"]===null) ? <span className="mx-4">-</span> : processHpoTerms(variant["HPO Label"]) )}
                    </div>
                  </div>
                  <div className="mx-2 mb-4">
                    <h5 className="card-title">Orphanet</h5>
                    <div className="limited-text-height d-flex flex-row flex-wrap ">
                      {(variant["Orphanet"]===undefined) ? "Error!" : ( (variant["Orphanet"]===null) ? <span className="mx-4">-</span> : processOrphanetTerms(variant["Orphanet"]) )}
                    </div>
                  </div>
                  <div className="mx-2">
                    <h5 className="card-title">InterVar</h5>
                    <div className=" d-flex flex-row">
                      {(variant["InterVar"]===undefined) ? "Error!" : ( (variant["InterVar"]===null) ? <span className="mx-4">-</span> : variant["InterVar"] )}
                    </div>
                  </div>
                </div>
    
              </div>
            </div>
    
            <div className="card m-1 shadow-sm flex-grow-1" style={{minWidth: '10rem'}}>
              <h5 className="variant-card-header bg-light-blue">Complete Record</h5>
              <div className="card-body">
                {/* {Object.keys(variant).map( (element) => { return( String(variant[element])) } ) } */}
                { Object.keys(variant).map( (element, i) => <span className="" key={i}> {element}= { String(variant[element]) } <br/></span>) }
              </div>
            </div>
    
          </div>
        </div>
      );
    }
  }


  function onVariantFilterChange(event, useGlobalFilters){
    // console.log(event.target.name , ' is ', event.target.value);
    let currentFilterName = event.target.name;

    if(event.target.type === 'checkbox'){
      if(useGlobalFilters){
        setGlobalFilters(prevGlobalFilters => ({
          ...prevGlobalFilters,
          [currentFilterName]: {
            ...prevGlobalFilters[currentFilterName],
            isApplied: Boolean(event.target.checked)
          }
        }));
      } else {
        // * update isApplied of this filter in the current tab 
        setTabs( prevTabs => ({
          ...prevTabs,
          [selectedVariantTab]: {
            ...prevTabs[selectedVariantTab],
            filters:{
              ...prevTabs[selectedVariantTab].filters,
              [currentFilterName]: {
                ...prevTabs[selectedVariantTab].filters[currentFilterName],
                isApplied: Boolean(event.target.checked)
              }
            }
          }
        }));
      }
    }
  }


  function renderFilterInfo(filterList, useGlobalFilters){

    if(Object.keys(filterList).length === 0){
      return(<label className="list-group-item ps-4">Found None.</label>)
    }
    else{
      return(
        <React.Fragment>
          {Object.keys(filterList).filter( t => filterList[t].editable===true).map((filterTerm, index)=> {
            return(
              <label className="list-group-item pe-2" key={index}>
                <div className="form-check form-switch m-0">
                  <input className="form-check-input me-1" type="checkbox" name={filterTerm} 
                    onChange={(e) => onVariantFilterChange(e, useGlobalFilters)} 
                    checked={filterList[filterTerm]["isApplied"]}
                    />
                      <div className="d-flex flex-row">
                      <div className="filter-description flex-grow-1" >{filterList[filterTerm].getFilterDescr() }</div>
                      {(useGlobalFilters)
                        ? 
                        <button className="btn move-filter-button flex-center" 
                          onClick={ () => {addFilterToSet(filterList[filterTerm].columnId)}}
                          ><span className="plus-icon-next-to-arrow" style={{fontSize:'.8rem'}}><i className="fas fa-plus fa-xs"></i></span><i className="fas fa-level-up-alt"></i>
                        </button>
                      : null }
                    </div>
                </div>
              </label>
            );
          })}
        </React.Fragment>
      );
    }
  }

  function addNewFilter(){
    toast((t) => (<span>{"Yay!, you found a hidden gem!"}<br/> {"(under construction)"}</span>),{icon: '👏', duration:5000});
    // showAlert("To be added!", 0, true, 'Success!')
    // console.log(JSON.stringify(globalFilters))
    // localStorage.setItem('globalFilters', JSON.stringify(globalFilters));
    // Object.assign(globalFilters, JSON.parse(localStorage.getItem('game_status')));
  }

  function createNewFilterSet(inputValue){
    // * check to make sure there is no other filter-set with the same name (including the invisible ones)
    let filteKey = inputValue.toUpperCase().replace(/\W/g, '');
    console.log(filterDefinition.XLS_FILTER_SET.filter(t => t.label.toUpperCase().replace(/\W/g, '') === filteKey).length > 0);
    if(filterDefinition.XLS_FILTER_SET.filter(t => t.label.toUpperCase().replace(/\W/g, '') === filteKey).length > 0){
      toast.error('The filter-set name needs to be unique. A hidden filter-set might exist!', {duration:6000})
      return;
    }

    setIsLoadingVisibleFilterSets(true);
    // setTimeout(() => {
      const newFilterSet = {
        value: visibleFilterSets.length,
        label: inputValue,
        user_defined: true,
        visible: true,
        FILTER_DEFINITIONS: {},
      }
      setVisibleFilterSets([...visibleFilterSets, newFilterSet]);

      // * show the new FilterSet
      refForCreatableSelect.current.state.value = newFilterSet; // console.log(refForCreatableSelect.current.state.value); // console.log(refForCreatableSelect.current.props);

      updateFilterSet(visibleFilterSets.length);
      setSelectedFilterSetIndex(visibleFilterSets.length);

      setIsLoadingVisibleFilterSets(false);
    // }, 500);
  }

  function addFilterToSet(filterName){
    // if(visibleFilterSets[selectedFilterSetIndex].user_defined===true){
      setIsLoadingVisibleFilterSets(true);
      // setTimeout(() => { // timeout isn't necessary, can get deleted!

        setTabs( prevTabs => ({
          ...prevTabs,
          [selectedVariantTab]: {
            ...prevTabs[selectedVariantTab],
            filters:{
              ...prevTabs[selectedVariantTab].filters,
              [filterName]: {...globalFilters[filterName]}
            }
          }
        }));

        let tmpGlobalFilters = {...globalFilters}
        delete tmpGlobalFilters[filterName]        //! is there a way to delete a property without copying it first? NO!
        setGlobalFilters(tmpGlobalFilters);

        setIsFilterSetModified(true);
        setIsLoadingVisibleFilterSets(false);
      // }, 300);
    // } else {
    //   toast.error("Can only add to filter-set created by you!");
    // }
  }

  const saveFilterSet = () => {
    if(!!refForCreatableSelect.current){
      if (!refForCreatableSelect.current.state.value){
        toast.error("Select a filter-set first!");
      } else if( selectedFilterSetIndex!==null && selectedFilterSetIndex!==undefined && visibleFilterSets[selectedFilterSetIndex].user_defined===true){
        //* update the filterSet first
        setIsLoadingVisibleFilterSets(true);
        let currentFilterSet = {};
        Object.keys(tabs[selectedVariantTab].filters).forEach( filterId => {
          currentFilterSet[filterId] = {...tabs[selectedVariantTab].filters[filterId]}; //! do we need a shallow copy here?
        });

        // setTimeout(() => { // timeout isn't necessary, can get deleted!
          // console.log(currentFilterSet);
          setVisibleFilterSets(
            prevVisibleFilterSets => prevVisibleFilterSets.map( 
              el => (el.value === selectedFilterSetIndex ? { ...el, FILTER_DEFINITIONS: currentFilterSet } : el ) 
            )
          );
          setIsFilterSetModified(false);
          setIsLoadingVisibleFilterSets(false);
          toast.success("Saved!");
        // }, 300);
      }
      else {
        toast.error("Cannot make changes to VaNex filters!");
      }
    }
  }

  const onFilterSetSelectChange = (selectedFilterSet, actionMeta) =>{
    if (!!selectedFilterSet){
      updateFilterSet(selectedFilterSet.value);
      setSelectedFilterSetIndex(selectedFilterSet.value);
    }
  }

  const onFilterMenuOpen = (e, b) => {
    if(isFilterSetModified && selectedFilterSetIndex!==null && selectedFilterSetIndex!==undefined && visibleFilterSets[selectedFilterSetIndex].user_defined===true){
    // if(isFilterSetModified){
      setIsFilterSetModified(false);
      toast((t) => (
        <div className="d-flex flex-row ">
          <div>Save your modifications before switching to another filter-set, otherwise <b>they will be lost!</b></div>
          <button className="btn btn-secondary" onClick={() => toast.dismiss(t.id)}>
            Dismiss
          </button>
        </div>
      ), {duration:6000});
      // toast("Modifications will be lost if you switch to another filter-set without saving this one first!", {duration:8000});
      // toast("Save the filter-set before switching to another one if you want to keep your modifications!", {duration:8000});
      // toast((t) => (
      //   <div className="d-flex flex-row">
      //     <b>Do you want to save this <br/>filter-set?</b>
      //     <button className="btn btn-sm btn-primary my-0 ms-3 me-2" onClick={() => toast.dismiss(t.id)}>
      //       Yes
      //     </button>
      //     <button className="btn btn-sm btn-secondary my-0 ms-2 me-0" onClick={() => toast.dismiss(t.id)}>
      //       No
      //     </button>
      //   </div>
      // ), {duration:Infinity});
    }
  }

  function renderFilterList(){

    return(
      <div className="d-flex flex-row justify-content-center">
        <div className="absolute-panel scroll-div">
          <div className="flex-center flex-row mt-1" >
            <CreatableSelect
              isClearable
              className="flex-grow-1 mx-2"
              ref={refForCreatableSelect}
              isDisabled={isLoadingVisibleFilterSets}
              isLoading={isLoadingVisibleFilterSets}
              onChange={onFilterSetSelectChange}
              // onChange={(selectedFilterSet, actionMeta) => ( (!!selectedFilterSet) ? setSelectedFilterSetIndex(selectedFilterSet.value) : null )}
              onMenuOpen={onFilterMenuOpen}
              onCreateOption={createNewFilterSet}
              options={visibleFilterSets}
              // defaultValue={visibleFilterSets[selectedFilterSetIndex || 0]}
              placeholder='Preset Filter Sets'
            />
            {/* <i className="far fa-save filter-set-button mx-2 flex-center" onClick={saveFilterSet}></i> */}
            <div className="filter-set-button mx-2 flex-center" onClick={saveFilterSet}><i className="far fa-save"></i></div>
          </div>

          <ul className="list-group small mx-1 my-1">
            {renderFilterInfo( tabs[selectedVariantTab].filters, false )}
            {/* {renderFilterInfo( Object.values(tabs[selectedVariantTab].filters).filter( t => t.visible===true), false )} */}
            {/* {renderFilterInfo( Object.keys(tabs[selectedVariantTab].filters).filter( t => tabs[selectedVariantTab].filters[t].visible===true), false )} */}
            {/* {renderFilterInfo(vanexFilterData, false )} */}
          </ul>

          {(Object.keys(globalFilters).length>0)
            ?
            <ul className="list-group small m-1">
              <label className="list-group-item fw-bold d-flex flex-row">
                <span className=" flex-grow-1">Global Filters:</span>
                  <React.Fragment>
                    <span className="fw-normal flex-grow-0 text-dblue cursor-pointer "
                    onClick={() => setGlobalFilters({})}
                      >Clear <i className="fas fa-trash-alt text-primary"></i></span>
                  </React.Fragment>
              </label>
              {renderFilterInfo(globalFilters, true)}
            </ul>
          : null }

          {/* //* show unmodifiable filters! */}
          <ul className="list-group small mx-1 my-1">
            {/* <label className="list-group-item fw-bold d-flex flex-row">Tab Filters:</label> */}
              { Object.values(tabs[selectedVariantTab].filters).filter( t => t.editable===false).map( (item, i) => 
              <label className="list-group-item" key={i}><div className="form-check form-switch"><input className="form-check-input me-1" type="checkbox" name={item} checked="checked" disabled />{item.getFilterDescr()}</div></label>
              )}
              {/* <label className="list-group-item"><div className="form-check form-switch"><input className="form-check-input me-1" type="checkbox" name="gnomAD" checked="checked" disabled />{"gnomAD frq. < 0.001"}</div></label> */}
              { filterDescriptions.map( (item, i) => 
                <label className="list-group-item" key={i}><div className="form-check form-switch"><input className="form-check-input me-1" type="checkbox" name={item} checked="checked" disabled />{item}</div></label>
              )}
          </ul>
          {/* <ul className="list-group small m-1">
              { filterDescriptions.map( (item, i) => 
              <label className="list-group-item" key={i}><div className="form-check form-switch"><input className="form-check-input me-1" type="checkbox" name={item} checked="checked" disabled />{item}</div></label>
            )}
          </ul> */}

          {/* //* to leave some space after the list! */}
          {/* <ul className="list-group small m-5"></ul> */}

          <div className="flex-center add-new-filter-wrapper mt-5 mb-3">
            <div className="add-new-filter flex-center" onClick={addNewFilter}><i className="fas fa-plus fa-2x"></i></div>
          </div>
        </div>
      </div>
    );
  }

	function handleToggleColumnVisibility(e, columnId){
    let hiddenCols = [...props.hiddenColumnsIds];
    // TODO: check if this is in the array? this is fine now, but it might be good to check if the checkbox status matches th array!
    if(e.target.checked){
      const index = hiddenCols.indexOf(columnId);
      if (index > -1) {
        // console.log('removed ' + columnId);
        hiddenCols.splice(index, 1);
      }
    }
    else{
      // console.log('pushed ' + columnId);
      hiddenCols.push(columnId);
    }
    props.setHiddenColumnsIds(hiddenCols);
  }
  

  const renderColumnList = () => {
    let userModifiableColumns =[];
    Object.values(props.dataColumns).forEach(column => {
      if(column.accessor!=='SAMPLES' && column.accessor!=='ROW_INDEX' ){  //! can be deleted: && column.accessor!=='VAR_INDEX'
        userModifiableColumns.push(column); // userModifiableColumns.push(column.accessor);
      }
    });
    // console.log(userModifiableColumns);
    //! fix the readonly part ((column.Header==="Chr") disabled={true})
    return(
      <div className="absolute-panel scroll-div fw-normal p-2">
        {/* {Object.keys(activeCaseVariantData[0]).map(column => ( */}
        {userModifiableColumns
        .sort( (elA,elB) => (props.hiddenColumnsIds.indexOf(elA.accessor) - props.hiddenColumnsIds.indexOf(elB.accessor)) )
        .map( (column, index) => (
          (column.Header==="ROW_INDEX") ? null :
          (<div key={index} className="d-flex flex-row align-items-center no-white-space-wrap ">
          <label>
            {(column.Header==="Chr") ?
              <input type="checkbox" className="me-1" checked="checked" disabled={true}/> 
            :
              <input type="checkbox" className="me-1" 
                checked={!props.hiddenColumnsIds.includes(column.accessor)}
                onChange={(e) => handleToggleColumnVisibility(e, column.accessor)}/>
            }
            {column.Header}
          </label>
          {(column.Header.endsWith("_GT") ? 
              // <button type="button" className="btn btn-sm btn-outline-secondary m-0 ms-1 p-0 px-1" data-bs-toggle="modal" data-bs-target="#changeSampleType">{column.sampleType}</button>
              <button type="button" className="btn btn-sm btn-outline-secondary m-0 ms-1 p-0 px-1" onClick={() => props.sampleTypeModal.show()}>{column.sampleType}</button>
              : null)}
          </div>
          )
        ))}
      </div>
    );
  }

  const renderVariantTab = () => {
    return(
      <div className="variant-viewer-split-pane">
        <SplitPane split="horizontal" minSize={50} maxSize={-50} defaultSize={300}>
          <div className="show-overflow absolute-panel d-flex flex-column mb-1 bg-white">
            <VariantTable 
              setSortBy={props.setSortBy}
              initialSortBy={props.initialSortBy}
              updateVariantTableData={props.updateVariantTableData}
              setColumnsSpec={props.setColumnsSpec}
              hiddenColumnsIds={props.hiddenColumnsIds}
              setDataForFilterSelectionModal={setDataForFilterSelectionModal}
              PMFSampleColNames={props.PMFColNames}
              DoNotSortColumns={true}
              currentVariantId={currentVariantId} 
              displayVariantInfo={ (variantId) => setCurrentVariantId(variantId)}
              variantFilterObj={ {...tabs[selectedVariantTab].filters, ...globalFilters} }
              columns={memoizedColumns}
              data={memoizedData}
              />
            {(props.devOptions) ? 
              <div className="btn btn-sm btn-outline-light mx-3 px-3 pt-2 text-dark border border-dark" onClick={() => filterByGene()} >Filter By Gene</div>
            : ''}
          </div>
          {renderVariantInfo(currentVariantId)}
        </SplitPane>
      </div>
    );
  }

  const onSelectedTabChange = (index, lastIndex, event) => {
    // console.log(event.target.innerText);
    if(!!refForCreatableSelect.current){
      refForCreatableSelect.current.state.value = null;
    }
    setCurrentVariantId(null);
    setSelectedVariantTab(Object.keys(tabs)[index]);
    setSelectedFilterSetIndex(null);
    // console.log(Object.keys(tabs)[index] , 'selected!!!!!!');
  }

  const isNumericColumn = (columnId) => {
    if(!!props.caseData[0]){
      // console.log(props.caseData[0][columnId]);
      if(!!props.caseData[0][columnId]){
        return(typeof(props.caseData[0][columnId])=='number');
      }
      else if(props.caseData[0][columnId]==null){
        let tmpList = props.caseData.filter(t => t[columnId]!=null);
        // console.log(tmpList);
        // console.log(tmpList[0][columnId]);
        if(!!tmpList[0][columnId]){
          return(typeof(tmpList[0][columnId])=='number');
        }
      }
    }
    return false;
  }

  const setDataForFilterSelectionModal = (e, selectedColumn) => {
    // console.log("----------");
    // console.log(selectedColumn);
    // console.log(isNumericColumn(selectedColumn.id));
    setFilterSelectionTitle(`Filtering ${selectedColumn.Header}`);
    let initValues = { columnName: selectedColumn.id, value: '', minValue: 0, maxValue:0, selTerms:[], filterBy: "UserProvidedValue", selectAllStatus: UNCHECKED }

    // let initValues = { value: 'z', minValue: 0, maxValue:0 }
    // setFilterSelectionFormik(<Formik className="" enableReinitialize={true} initialValues={initValues}
    // onSubmit={(fields) => {
    //     console.log(fields);
    //     filterSelectionModal.hide();
    //   }}>
    //     {({ values, setFieldValue }) => (
    //     <Form id={}>
    //       {/* { console.log(values)} */}
    //       {/* <CustomerPopup
    //           showModal={showModal}
    //           close={() => toggleCustomerModal()}
    //           onSelect={data => onSelectCustomerHandler(data, setFieldValue)}
    //         /> */}
    //       <div className="modal-body">
    //         <Field className="form-control" type="text" name="minValue" placeholder="" aria-label=""/>
    //         <Field className="form-control" type="text" name="maxValue" placeholder="" aria-label=""/>
    //       </div>
    //     <div className="modal-footer ">
    //       <button className="btn btn-primary" type="submit" ><i className="fas fa-filter me-2"></i> Apply</button>
    //       <button type="button" className="btn btn-secondary" onClick={() => filterSelectionModal.hide()}>Cancel</button>
    //     </div>
    //     </Form>)}
    //   </Formik>);
    //   filterSelectionModal.show();
    // return;

    if(isNumericColumn(selectedColumn.id)){
      //? ------------------- filtering column with NUMERIC values  ---------------------------

      if (!!globalFilters[selectedColumn.id]){
        if (!!globalFilters[selectedColumn.id]["minValue"] && !!globalFilters[selectedColumn.id]["maxValue"]){
          initValues["minValue"] = globalFilters[selectedColumn.id]["minValue"];
          initValues["maxValue"] = globalFilters[selectedColumn.id]["maxValue"];
        }
      }

      setFilterSelectionFormik(
        <Formik className="" enableReinitialize={true} initialValues={initValues} onSubmit={(fields) => {
          // console.log(fields);
          setGlobalFilters(
            prevGlobalFilters => ({
              ...prevGlobalFilters,
              [selectedColumn.id]: {
                columnId: selectedColumn.id,
                label: `${selectedColumn.Header}`,
                value: `"${fields.minValue} to ${fields.maxValue}"`,
                minValue: fields.minValue,
                maxValue: fields.maxValue,
                operation: '=',
                visible: true,
                editable: true,  // tabFilter: false,
                // getFilterDescr: function(){ return(this.label + this.value)},
                getFilterDescr: function(){ return(this.label + ' ' + this.operation + ' ' + this.value)},
                filterFunction: function(){ return(
                  (t) => ( (!!t[selectedColumn.id]) ? ( (t[selectedColumn.id] >= fields.minValue ) && (t[selectedColumn.id] <= fields.maxValue )) : false )
                );},
                isApplied: true
              }
            })
          );

          filterSelectionModal.hide();
        }}>
          <Form>
          <div className="modal-body">
            <table className="">
              <tbody>
                <tr>
                  <td>
                    <label className=""><br/>{selectedColumn.Header}</label> 
                  </td>
                  <td>
                    <label className=""><br/><i className="fas fa-equals"></i></label> 
                  </td>
                  <td>
                    <label className="form-check-label">
                      Min
                      <Field className="form-control" type="text" name="minValue" placeholder="" aria-label=""/>
                    </label>
                  </td>
                  <td>
                    <label className="form-check-label">
                      Max
                      <Field className="form-control" type="text" name="maxValue" placeholder="" aria-label=""/>
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="modal-footer ">
            <button className="btn btn-primary" type="submit" ><i className="fas fa-filter me-2"></i>Apply Filter</button>
            <button type="button" className="btn btn-secondary" onClick={() => filterSelectionModal.hide()}>Cancel</button>
          </div>
          </Form>
        </Formik>
      );
    }
    else {
      //? ------------------- filtering column with TEXT values ---------------------------
      
      if (!!globalFilters[selectedColumn.id]){
        // debugger;
        if (!!globalFilters[selectedColumn.id]["value"]){
          initValues["value"] = globalFilters[selectedColumn.id]["value"];
        }
        if (!!globalFilters[selectedColumn.id]["filterBy"]){
          initValues["filterBy"] = globalFilters[selectedColumn.id]["filterBy"];
        }
        if (!!globalFilters[selectedColumn.id]["selTerms"]){
          initValues["selTerms"] = globalFilters[selectedColumn.id]["selTerms"];
        }
      }

      //? ------------------- collect unique values in an array ---------------------------
      let selColUniqueValues = []
      let tmpObj = {}
      // Object.values(props.caseData).forEach((variantRecord) => {
      Object.values(variantData).forEach((variantRecord) => {
        // tmpObj[variantRecord[selectedColumn.id]] = ((!!variantRecord[selectedColumn.id]) ? variantRecord[selectedColumn.id] : "__Blank__");
        tmpObj[variantRecord[selectedColumn.id]] = variantRecord[selectedColumn.id];
      });
      Object.values(tmpObj).forEach( (variantRecord) =>{
        selColUniqueValues.push(variantRecord);
      });
      selColUniqueValues.sort();
      // console.log(selColUniqueValues);

      setFilterSelectionFormik(
        <Formik className="" enableReinitialize={true} initialValues={initValues} onSubmit={(fields) => {
          //! Equals, Does not equal, Contains, Does not contain, Begins with, Ends with, 
          // console.log(fields);
          let operation = "contains";
          // let useSelTerms = fields.filterBy === 'SelectedTerms';
          let myFilterFunction;
          if(fields.filterBy === 'SelectedTerms'){
            operation = "=";
            let selectedTerms = fields.selTerms
            // let selectedTerms = fields.selTerms.map( (term) => {
            //   if (term==='__Blank__'){
            //     return(null);
            //   }
            //   else{
            //     return(term);
            //   }
            // });
            // let includeNullValuesInFilter = fields.selTerms.includes("__Blank__");
            let includeNullValuesInFilter = fields.selTerms.includes(null);
            myFilterFunction = function(){ return(
              (t) => ( (!!t[selectedColumn.id]) ? ( selectedTerms.indexOf(t[selectedColumn.id]) !== -1) : includeNullValuesInFilter )
            );}
          }
          else{
            myFilterFunction = function(){ return(
              (t) => ( (!!t[selectedColumn.id]) ? t[selectedColumn.id].toLowerCase().includes(fields.value.toLowerCase()) : false )
            );}
          }

          setGlobalFilters(
            prevGlobalFilters => ({
              ...prevGlobalFilters,
              [selectedColumn.id]: {
                columnId: selectedColumn.id,
                label: `${selectedColumn.Header}`,
                value: fields.value,
                operation: `${operation}`,
                visible: true,
                editable: true,  // tabFilter: false,
                selTerms: fields.selTerms,
                filterBy: fields.filterBy,
                getFilterDescr: function(){
                  let fieldValue = `"${this.value}"`;
                  if(this.filterBy === 'SelectedTerms'){
                    fieldValue = `[${this.selTerms.join('], [')}]`;
                    // if(this.selTerms.length > 3){fieldValue = "[...]";}
                    // return(`"${fieldValue}"`)
                  }
                  return(this.label + ' ' + this.operation + ' ' + fieldValue);
                },
                filterFunction: myFilterFunction,
                isApplied: true
              }
            })
          );

          filterSelectionModal.hide();
        }}>
          {({ errors, values, touched, setValues }) => (
            <Form>
            <div className="modal-body">
              <table className="">
                <tbody>
                  <tr>
                    <td>
                      <label className="">
                        <Field name="filterBy" className="form-check-input me-2" type="radio" value="UserProvidedValue" placeholder="" aria-label=""/>
                        {selectedColumn.Header}<span className="fw-bold mx-1">Contains</span></label> 
                    </td>
                    <td>
                      <label className="form-check-label">
                        <Field className="form-control" type="text" name="value" placeholder="" aria-label=""
                        onFocus={e => { values.filterBy ='UserProvidedValue'; }}
                        />
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr/>
              <label className="">
                <Field name="filterBy" className="form-check-input me-2" type="radio" value="SelectedTerms" placeholder="" aria-label=""/>
                  {selectedColumn.Header}<span className="fw-bold mx-1">matches</span>
              </label>
              <div className="ist-group list-group-flush border border-secondary border-1 m-1 show-overflow l" style={{maxHeight:'40vh'}}>
                <label className="list-group-item">
                  <IndeterminateCheckbox
                    className="form-check-input me-1"
                    name="select-all"
                    value={values.selectAllStatus}
                    onFocus={e => { values.filterBy ='SelectedTerms'; }}
                    onChange={e => {
                      let chboxStatus = INDETERMINATE;
                      let selectedTerms = [];
                      if (e.target.checked) {
                        selColUniqueValues.forEach( (term) => selectedTerms.push(term));
                        chboxStatus = CHECKED;
                      } else {
                        chboxStatus = UNCHECKED;
                      }
                      setValues({ ...values, 
                        selectAllStatus: chboxStatus,
                        selTerms: selectedTerms 
                      });
                      
                    }}
                  />
                  Select All
                </label>
                <FieldArray
                  name="selTerms"
                  render={arrayHelpers => (
                    <React.Fragment>
                      {selColUniqueValues.map(tag => (
                        <label className="list-group-item" key={tag}>
                          <input
                            className="form-check-input me-1"
                            name={(!!tag) ? tag : "__Blank__"}
                            type="checkbox"
                            value={tag}
                            checked={values.selTerms.includes(tag)}
                            onFocus={e => { values.filterBy ='SelectedTerms'; }}
                            onChange={e => {
                              let chboxStatus = INDETERMINATE;
                              let selectedTerms = [...values.selTerms];
                              if(values.selTerms.length === 0){
                                chboxStatus = UNCHECKED;
                              }
                              if (e.target.checked) {
                                if(values.selTerms.length === 0){
                                  chboxStatus = INDETERMINATE;
                                }
                                if(selColUniqueValues.length === (values.selTerms.length+1) ){
                                  chboxStatus = CHECKED;
                                }
                                // arrayHelpers.push(tag);
                                selectedTerms.push(tag);
                              } else {
                                if(values.selTerms.length-1 === 0){
                                  chboxStatus = UNCHECKED;
                                }
                                const removeIndex = values.selTerms.indexOf(tag);
                                // arrayHelpers.remove(removeIndex);
                                // let removeIndex = selectedTerms.map(item => item.id).indexOf(tag);
                                ~removeIndex && selectedTerms.splice(removeIndex, 1);  //! <= this is a nice way of doing this: (removeIndex >= 0) && array.splice(removeIndex, 1);
                              }
                              setValues({ ...values, 
                                selectAllStatus: chboxStatus,
                                selTerms: selectedTerms 
                              });
                            }}
                          />
                          <span>{(!!tag) ? tag : "__Blank__"}</span>
                          {/* <span>{tag}</span> */}
                        </label>
                      ))}
                    </React.Fragment>
                  )}
                />
              </div>
            </div>
            <div className="modal-footer ">
              <button className="btn btn-primary" type="submit" ><i className="fas fa-filter me-2"></i>Apply Filter</button>
              <button type="button" className="btn btn-secondary" onClick={() => filterSelectionModal.hide()}>Cancel</button>
            </div>
            </Form>)}
          </Formik>
      );
    }
    filterSelectionModal.show();
    return;
  }
  // console.log("render...");
  // console.log(tabs);
  return (
    <React.Fragment>
      <SplitPane className={" bottom-tab-panel "}
        split="vertical" minSize={100} maxSize={-100} defaultSize={265} >
        <div className="sidebar-splitpane-panel">
          <Tabs>
            <TabList>
              <Tab><span>Filters</span></Tab>
              <Tab><span>Columns</span></Tab>
            </TabList>
            <TabPanel>{renderFilterList()}</TabPanel>
            <TabPanel>{renderColumnList()}</TabPanel>
          </Tabs>
        </div>
        <div className="sidebar-splitpane-panel absolute-panel">
          <Tabs onSelect={onSelectedTabChange} >
            <TabList style={{paddingRight:'7rem'}}>
              {Object.values(tabs).map( (tabName, index) => <Tab key={index}><span>{tabName.label}</span></Tab>)}
              <span className="tab-menu-button-group" onClick={exportSnvAsXlsx}>
                <span>
                  <i className="fas fa-download fs-5"></i><span className="fs-5 fw-bold mx-1 p-0">Export</span>
                  {(isExporting) ? 
                  <div className="progress export-progressbar">
                    <div className="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" style={{width:`${exportProgressbarValue}%`}} aria-valuenow={exportProgressbarValue} aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  : null}
                </span>
              </span>
            </TabList>
            {Object.values(tabs).map( (tabName, index) => <TabPanel key={index}>{renderVariantTab()}</TabPanel>)}
            {/* {Object.values(tabs).map( (tabName, index) => <TabPanel key={index} style={{display:"none"}}></TabPanel>)}
            {renderVariantTab()} */}
          </Tabs>
        </div>
      </SplitPane>
      {/* //? --------------------------------------------- Modal ---------------------------------- */}
      <div className="modal fade" ref={refForFilterSelectionModal} tabIndex="-1" aria-labelledby="refForFilterSelectionModal" aria-hidden="true">
        {/* <div className="modal-dialog modal-sm"> */}
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{filterSelectionTitle}</h5>
              <button type="button" className="btn-close" onClick={() => filterSelectionModal.hide()} aria-label="Close"></button>
            </div>
            {filterSelectionFormik}
          </div>
        </div>
      </div>
      {/* END Modal */}
    </React.Fragment>
  );
}

