import React from 'react';
// import ReactDOM from 'react-dom'; // react 17
import { createRoot } from 'react-dom/client'; // react 18
// import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { Switch, Route, BrowserRouter } from 'react-router-dom';
import {BrowserRouter } from 'react-router-dom';
// import reportWebVitals from './reportWebVitals';
// import DyLoP from './DyLoP/DyLoP';
// import './App.css';

// const root = ReactDOM.createRoot(document.getElementById('root'));
const root = createRoot(document.getElementById('root'));
root.render(<BrowserRouter><App /></BrowserRouter>);

// ReactDOM.render(
//   // <React.StrictMode><App /></React.StrictMode>,
//   <BrowserRouter><App /></BrowserRouter>,
//   // <BrowserRouter>
//   //   <Switch>
//   //     <Route path={"/rd"}>
//   //     <h1>rd</h1>
//   //     </Route>
//   //     <Route path={"/bh"}>
//   //     <h1>bh</h1>
//   //     </Route>
//   //     <Route path={"/"}>
//   //     <h1>/</h1>
//   //     </Route>
//   //   </Switch>
//   // </BrowserRouter>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
