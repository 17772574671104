import React, { useState, useEffect, useCallback } from 'react';
import {GetSignedURL} from '../HelperFunctions'

const CnvStaticPlotViewer = (props) => {

  const [loadingClinVarPlot, setLoadingClinVarPlot] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  const [clinVarPlotSrc, setClinVarPlotSrc] = useState('');
  // const loadClinVarPlot = () => {
  const loadClinVarPlot =  useCallback(() => {
    if(!props.cnv_plot_uri) return;
    const s3Bucket = props.cnv_plot_uri.replace("s3://", "").split("/", 1)[0];
    const s3BaseKey = props.cnv_plot_uri.replace(`s3://${s3Bucket}/`, "");
    const url= (!!props.chromosome ? `${s3BaseKey}/varscan_normal/Chromosome${props.chromosome}_Concat.png` : `${s3BaseKey}/varscan_normal/varScan.genome.png` );
    // let url=`${props.cnv_plot_uri.replace("s3://nch-igm-research-rare-disease/","")}/varscan_normal/varScan.genome.png`;
    // if(!!props.chromosome) url=`${props.cnv_plot_uri.replace("s3://nch-igm-research-rare-disease/","")}/varscan_normal/Chromosome${props.chromosome}_Concat.png`;
    // if(!!props.chromosome) url=`${s3BaseKey}/varscan_normal/Chromosome${props.chromosome}_Concat.png`;
    setLoadingClinVarPlot(true);
    GetSignedURL(s3Bucket,url) 
    .then(result_json => {
      setClinVarPlotSrc(result_json);
      setErrorMessage('');
    }).catch(e => {
      setErrorMessage(`Failed to load the image (${e})`);
    });
  }, [props.cnv_plot_uri, props.chromosome]);

  useEffect(() => {
    //! ---- console.log("cnvvvvv");
    if(props.cnv_plot_uri !== undefined) loadClinVarPlot();
  }, [props.cnv_plot_uri, loadClinVarPlot]);

  return (
    <div className="">
      <div  className="">
        { (errorMessage.length > 0)
        ?
          <h5>{errorMessage}</h5>
        :
          (clinVarPlotSrc)
          ?
            <img className="cnvPlot" src={clinVarPlotSrc} alt="ClinVar Plot" style={{maxWidth:(!!props.chromosome ? '80em' : '100%')}} />
          :
            (loadingClinVarPlot)
            ?
            <span><span className="mx-2 spinner-border spinner-border" role="status" aria-hidden="true"> </span><i className="far fa-image px-3 fs-1"></i></span>
            :
            null
        }
        </div>
        <h6 className="m-4">S3 URI: {props.cnv_plot_uri+'/varscan_normal/varScan.genome.png'}</h6>
    </div>
  );
}


export default CnvStaticPlotViewer;
