import React, { useState, useEffect } from 'react'; // , useCallback    Component, useMemo, useRef // const { useState, useEffect, useRef } = React; 

const VariantInfoBox = (props) =>  {

  // const [collapsedInfoBox, setCollapsedInfoBox] = useState( (typeof props.collapsed == 'boolean') ? props.collapsed : true );
  const [collapsedInfoBox, setCollapsedInfoBox] = useState( (!! props.collapsed ) ? true : false);

  return ( 
    <div className="card m-1 shadow-sm flex-fill" style={(!!props.min_width) ? {minWidth: props.min_width} : {}}>
      {/* <h5 className="variant-card-header bg-light-blue cursor-pointer " */}
      <div className="variant-card-header bg-light-blue cursor-pointer d-flex flex-row" style={{fontSize:'1.25rem', fontWeight:'500', lineHeight:'1.2'}}
        onClick={() => setCollapsedInfoBox(prevState => !prevState)}
        >
        {(!!collapsedInfoBox) ?
          <i className="fas fa-caret-right pe-1"></i>
          : 
          <i className="fas fa-caret-down pe-1"></i> 
        }
        {props.title}
      </div>
      {/* <div style={{width:'1rem'}}> {(!!collapsedInfoBox)? <i className="fas fa-caret-down pe-1"></i> : <i className="fas fa-caret-right pe-1"></i>}</div> */}

      <div className={`variant-info-box d-flex flex-column ${(collapsedInfoBox) ? 'collapsed' : '' }`} >
      {/* <div className="variant-card-body d-flex flex-column flex-nowrap align-items-center justify-content-center"> */}
        {props.children}
      </div>
    </div>
  );
}

export default VariantInfoBox