import React, { useState, useEffect } from 'react';
// import { useState } from 'react';   // , useEffect
import { withRouter } from 'react-router';
// !!!! wrong way of using it? import { withRouter, useParams } from 'react-router';
import { useHistory, useParams } from 'react-router-dom'; // , useRouteMatch, useLocation,Switch, Route
// import DyLoP from '../DyLoP/DyLoP';
// import Autosuggest from 'react-autosuggest';
import genes_to_ids from "../resources/GRCh38.p14_Genenames_to_IDs.json";
import {GetSignedURL} from '../HelperFunctions';
import {parse_JSONL, downloadIndividualNote } from './ProjectSpecificApp'
import InfoGeneTable from './InfoGeneTable'

const Info = (props) => {

  let history = useHistory();
  let params = useParams();
  // // let routeMatch = useRouteMatch(); // { path, url }
  // const [isShownImportPanel, setIsShownImportPanel] = useState(false);
  // const [variantsAsText, setVariantsAsText] = useState('');
  // const [importFormat, setImportFormat] = useState('cdot');
  // const [highlightInputFormat, setHighlightInputFormat] = useState('aa');
  // const [inputPositions, setInputPositions] = useState("");

  const [geneNameIDs, setGeneNameIDs] = useState(Object.keys(genes_to_ids['ENTREZ_ID']).map(i => ({ name:i, entrez_id: genes_to_ids['ENTREZ_ID'][i]}) ));
  
  const [selectedGene, setSelectedGene] = useState({name:null, entrez_id:null});
  // const [autosuggestValue, setAutosuggestValue] = useState('');
  // const [suggestions, setSuggestions] = useState([]);
  
  // const [shownAggNotesPanelId, setShownAggNotesPanelId] = useState(1);
  // const [orderOfAggNotes, setOrderOfAggNotes] = useState("Date");
  // const [minAggNotesChar, setMinAggNotesChar] = useState(50);
  const [aggNotesGeneLevel, setAggNotesGeneLevel] = useState({});
  // const [aggNotesPositionLevel, setAggNotesPositionLevel] = useState({});
  const [isLoadingAggNotes, setIsLoadingAggNotes] = useState(true);
  const AggNotesUsers= ["Rolf_Stottmann","Daniel_Koboldt","Mohammad_Marhabaie","Swetha_Ramadesikan","Iftekhar_Showpnil","Umamaheswaran_Gurusamy","Ivana_Stojkic",
      "Shayne_Plourde","Ashton_Holub","Caitlyn_Colwell","Ava_Willoughby","Sari_Goldson",
      "Elizabeth_Garfinkle","Olivia_Roof2","Olivia_Grischow","Maeson_Latsko"];
  // const [selectedAggNotesUsers, setSelectedAggNotesUsers] = useState(AggNotesUsers);


  useEffect(() => {
    let vanexTitle = 'VaNex'
    if(!!selectedGene && !!selectedGene.name) {
      vanexTitle = `${selectedGene.name} - VaNex`;
    }

    document.title = vanexTitle;
  }, [selectedGene]);


  useEffect(() => {
    if(!!params && !!params.geneName){
      let geneInfo = geneNameIDs.filter(gn => gn.name===params.geneName.toUpperCase());
      if(!!geneInfo && !!geneInfo[0]){
        setSelectedGene(geneInfo[0]);

        const entrezid = geneInfo[0]?.entrez_id;
        // const varChromosome = currentVariant?.CHROMOSOME;
        // const varPosition = currentVariant?.POSITION;
        // const varChrPosId = `${varChromosome}-${varPosition}`;

        const basePathForGeneNotes = `case_notes_by_gene_v2/ENTREZ_ID=${entrezid}/&folder=true`;
        GetSignedURL('nch-igm-vanex', basePathForGeneNotes, true) 
        .then(response => downloadAggregatedNotes(response, entrezid, setAggNotesGeneLevel))
        .catch( e => {setIsLoadingAggNotes(false); console.error(`Error in retrieving notes: ${e.toString()}`); })

      } else {
        setSelectedGene({name:params.geneName, entrez_id:null});
      }
    }
  }, [])


  function downloadAggregatedNotes(allSignedUrls, entrezid, setAggregatedNotes){
    const promises = [];
    let allExistingNotesAggregated = [];
    setIsLoadingAggNotes(true);
    console.log(allSignedUrls)
    allSignedUrls.forEach( signedUrl => {
      promises.push(downloadIndividualNote(signedUrl));
    });

    Promise.all(promises)
    .then((results) => {
      results.forEach( (setOfNote, index) => {
        let notes = parse_JSONL(setOfNote);
        console.log("notes",notes)
        notes.forEach( myNote => {
          const thisNoteTag = myNote['VANEX_TAGS']===true ? 1 : (myNote['VANEX_TAGS']===false ? 0 : myNote['VANEX_TAGS']);
          // console.log("myNote", myNote, " t = ", thisNoteTag)
          if (myNote.VANEX_NOTES && (myNote.VANEX_NOTES.length > 0) ){  //! set the length here
            allExistingNotesAggregated.push(
              {
                ...myNote,
                VANEX_TAGS: thisNoteTag
              }
            );
          }
        });
      });
      
      allExistingNotesAggregated.sort( (elA,elB) => (elA.UPDATED_AT > elB.UPDATED_AT) ? -1 : ((elB.UPDATED_AT > elA.UPDATED_AT) ? 1 : 0)); // * reverse sorting (descending)
      // console.log("allExistingNotesAggregated", allExistingNotesAggregated);
      // console.log(activeCaseInfo)
      // allExistingNotesAggregated.sort( (elA,elB) =>  elA.UPDATED_AT.localeCompare(elB.UPDATED_AT)); // * ascending
      // setOpenCases(prevOpenCases => prevOpenCases.map(indvCase => (indvCase[0] === caseAnalysisId ? [indvCase[0], [...indvCase[1]], allExistingNotesAggregated] : indvCase)));
      // console.log("allExistingNotesAggregated", allExistingNotesAggregated.map( vr => ({...vr, VANEX_TAGS: 3 }) ))
      // console.log('allExistingNotesAggregated', allExistingNotesAggregated);
      
      setAggregatedNotes(allExistingNotesAggregated)
      //! setAggregatedNotes(prevAgg => ({...prevAgg, [entrezid]:allExistingNotesAggregated}) )
      // console.log(" notes loaded successfully!");
      setIsLoadingAggNotes(false);
    })
    .catch((e) => {
      console.error("Error: " + e);
      setIsLoadingAggNotes(false);
    });
  }
  
  function showActiveCaseCardInfo(caseID, showEditView=false){
    console.log(caseID)
  }

  function updateCaseTableDataFunction(prevRowValues, columnId, newValue) {
    console.log(prevRowValues)
    console.log(columnId)
    console.log(newValue)
  }

  // console.log(aggNotesGeneLevel)
  return (
    <div>
      <InfoGeneTable className="case-table " 
        inputData={aggNotesGeneLevel}
        isLoadingAggNotes={isLoadingAggNotes}
        currentCaseId=""
        caseClickAction={(e, showEditView) => showActiveCaseCardInfo(e.CASE_ID, showEditView)}
        updateCaseTableData = {updateCaseTableDataFunction}
      />  
    </div>
  )
}

export default withRouter(Info);
