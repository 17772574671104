import React, { useState, useEffect, useCallback } from 'react'; // Component, useMemo, useRef, // const { useState, useEffect, useRef } = React; 

import SplitPane from 'react-split-pane'; //, { Pane }
import { Auth } from 'aws-amplify'; //Amplify, { Auth, Hub }
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CreatableSelect from 'react-select/creatable'; // Creatable, { makeCreatableSelect } 
import Select from 'react-select';
import toast from 'react-hot-toast';
// import XLSX from 'xlsx';
import * as XLSX from 'xlsx';
import IgvVaNex from '../components/IgvVaNex';

// import DropdownButton from 'react-bootstrap/DropdownButton';
// import ButtonGroup from 'react-bootstrap/ButtonGroup';
// import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
// import Modal from 'react-bootstrap/Modal';

import { Modal } from 'bootstrap'; // Collapse, Popover, Toast, Tooltip, Alert, , Dropdown
import { Formik, Form, Field, FieldArray } from 'formik'; //, ErrorMessage
import { Prompt } from 'react-router'; // withRouter

import OmimInfo from "./OmimInfo";
import VariantTable from "./VariantTable";
import VariantInfoBox from "./VariantInfoBox";
import CnvStaticPlotViewer from "./CnvStaticPlotViewer";
import CnvPlotter from "./CnvPlotter/CnvPlotter";
import {GetSignedURL, GetHeatedQualityScore, GetSampleRank, EditableTextArea, VanexNoteCell, 
  VanexTagCell, VanexReviewCell, VanexGenotypeCell, ConstructFilterFunctions, CalcDamagingScores, 
  VanexSegmentSizeCell, VanexArrayCell, VanexMrPhenePhenoTermsCell, VanexCNVGenotypeCell,
  sortIntColumn, sortFloatColumn, sortColumnByArrayLength, separateBy1000} from '../HelperFunctions' // VanexMrPhenePhenoTermsCell, 
import IndeterminateCheckbox, {CHECKED, UNCHECKED, INDETERMINATE} from "./IndeterminateCheckbox";

import filterDefinition from '../resources/filters.json';
import pako from 'pako';
import ReviewOptions from '../resources/reviewOptions.json';
import Firefly from '@nch-igm/firefly';



/** props.currentAnalysisInfo
{
  "CASE_ID": "RD0386",
  "ANALYSIS_ID": "2023-07-11T19-02-40_CNV",
  "ANALYSIS_NAME": "2023-07-11",
  "ANALYSIS_NAME_SUFFIX": "2023-07-11T19-02-40",
  "ANALYSIS_TYPE": "CNV",
  "ANALYSIS_NOTE": "",
  "SOURCE_FILE_STATUS": 1,
  "JSON_URI": "s3://nch-igm-vanex/rare_disease/cnv_cases/CASE_ID=RD0386/varscan",
  "PARQUET_URI": "s3://nch-igm-research-rare-disease/2023/RD0386/Churchill/2023-04-06/cnvloh",
  "V_REF": "GRCh38",
  "N_PARQUET_VARIANTS": 0,
  "N_VARIANTS": 0,
  "N_GENES": 0,
  "N_SAMPLES": 3,
  "SAMPLES": [
    {
        "SAMPLE_ID": "RD0386_P_S-2023-009960",
        "SAMPLE_TYPE": "Proband",
        "FILE_URI": "s3://nch-igm-research-rare-disease/2023/RD0386/Churchill/2023-04-06/cnvloh/RD0386_P_S-2023-009960"
    }, {
        "SAMPLE_ID": "RD0386_M_S-2022-025806",
        "SAMPLE_TYPE": "Mother",
        "FILE_URI": "s3://nch-igm-research-rare-disease/2023/RD0386/Churchill/2023-04-06/cnvloh/RD0386_M_S-2022-025806"
    }, {
        "SAMPLE_ID": "RD0386_F_S-2022-025805",
        "SAMPLE_TYPE": "Father",
        "FILE_URI": "s3://nch-igm-research-rare-disease/2023/RD0386/Churchill/2023-04-06/cnvloh/RD0386_F_S-2022-025805"
    }
  ]
}
**/

/** //! duplicated code! is in RdApp too
* @param {Object} object
* @param {string} key
* @return {any} value
*/
const getParameterCaseInsensitive = (object, key) => {
  return object[Object.keys(object)
    .find(k => k.toLowerCase() === key.toLowerCase())
  ];
}

// var request;
// const ENDPOINT_BASE = process.env.REACT_APP_API_URI;



const CnvViewer = (props) => {

  //* this controls whether we want the user to change sample types (PMF) during the analysis by showing the button before the column names in the column list
  const showPMFPrefix = false; //!!!! (!!props.currentAnalysisInfo && props.currentAnalysisInfo.ANALYSIS_TYPE && (props.currentAnalysisInfo.ANALYSIS_TYPE==='GermlineSNV' || props.currentAnalysisInfo.ANALYSIS_TYPE==='SomaticSNV')) ? false : true ;
  const [use1000Separator, setUse1000Separator] = useState(true);
  const initialSortBy = [{id:"CHR_NUM", desc:false},{id:"ABS_SEG_MEAN", desc:true}];
  // const [initialSortBy, setInitialSortBy] = useState([{id:"CHROMOSOME", desc:false},{id:"LOC_START", desc:false}]);
  // const [initialSortBy, setInitialSortBy] = useState([{id:"CHROMOSOME", desc:false},{id:"SEG_SIZE", desc:false}]);

  const [initialColumnData, setInitialColumnData] = useState( () => {
    if(!!props.dataColumns)
      return(props.dataColumns);
    if(!!props.caseData && props.caseData.length > 0 && !!props.caseData[0]){
      let columnKeys = new Set(Object.keys(props.caseData[0]));
      props.caseData.forEach(rowData =>{
        columnKeys = new Set([...columnKeys,...Object.keys(rowData)]);
      });
      return( Array.from(columnKeys).map(key => {
        // if(!!props.keepColumnAsIs){
        //   return {
        //     Header: getColumnNameFromAccessor(key),
        //     id: key,
        //     accessor: (dt) => dt[key],
        //     width: 100,
        //     setColWidth: function(inWidth){ this.width = inWidth; }
        //   }
        // }
        if(key==="VANEX_TAGS"){
          return {
            Header: "Tag",
            id: key,
            accessor: (dt) => dt[key],
            width: 10,
            disableResizing: true,
            Cell: VanexTagCell,
            renderDefinedCell: true,
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="VANEX_NOTES"){
          return {
            Header: "Note",
            id: key,
            accessor: (dt) => dt[key],
            width: 200,
            Cell: VanexNoteCell,
            renderDefinedCell: true,
            sortType: (rowA, rowB, columnId, desc) => {
              // if(rowA.values[columnId].CURRENT_USER_NOTE!=="" && !!rowB.values[columnId].CURRENT_USER_NOTE!=="")
                return(rowA.values[columnId].CURRENT_USER_NOTE.localeCompare(rowB.values[columnId].CURRENT_USER_NOTE, undefined, { numeric: true }))
              // if(rowA.values[columnId].CURRENT_USER_NOTE==="" && rowB.values[columnId].CURRENT_USER_NOTE==="") return 100;
              // if(rowA.values[columnId].CURRENT_USER_NOTE==="") return 100;
              // return -100;
            },
            columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="VANEX_REVIEWS"){
          return {
            Header: "Review",
            id: key,
            accessor: (dt) => dt[key],
            width: 150,
            Cell: VanexReviewCell,
            renderDefinedCell: true,
            disableResizing: true,
            columnStyle: {textAlign: 'center'},
            style: {overflow :'visible'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="MR_PHENE_PHENOTYPE_TERMS"){
          return {
            Header: "MrPhene Pheno. Terms",
            id: key,
            accessor: (dt) => dt[key],
            width: 100,
            sortType: sortColumnByArrayLength,
            // Cell: VanexMrPhenePhenoTermsCell,
            // renderDefinedCell: true,
            columnStyle: {textAlign: 'center'},
            // style: {overflow :'visible'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="CHROMOSOME") {
          return {
            Header: "Chr",
            id: key,
            accessor: (dt) => dt[key],
            width: 40,
            sortType: (rowA, rowB, columnId, desc) => {
              let valA = (rowA.values[columnId]==="X" ? 23 : (rowA.values[columnId]==="Y" ? 24 : parseInt(rowA.values[columnId])));
              let valB = (rowB.values[columnId]==="X" ? 23 : (rowB.values[columnId]==="Y" ? 24 : parseInt(rowB.values[columnId])));
              return (valA < valB ? -1 : 1);
              // return (valA < valB ? -1 : (valA > valB ? 1 : 0) );
            },
            style: {fontWeight: 'bolder',textAlign: 'center'},
            // columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="LOC_START") {
          return {
            Header: "Start",
            id: key,
            accessor: (dt) => dt[key],
            width: 90,
            sortType: sortIntColumn,
            use1000Separator:use1000Separator,
            Cell: (props) => (props.column.use1000Separator ? separateBy1000(props.value) : props.value),
            renderDefinedCell: true,
            // style: {fontWeight: 'bolder',textAlign: 'center'},
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="LOC_END") {
          return {
            Header: "End",
            id: key,
            accessor: (dt) => dt[key],
            width: 90,
            sortType: sortIntColumn,
            use1000Separator:use1000Separator,
            Cell: (props) => (props.column.use1000Separator ? separateBy1000(props.value) : props.value),
            renderDefinedCell: true,
            // style: {fontWeight: 'bolder',textAlign: 'center'},
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="SEG_SIZE") {
          return {
            Header: "Size",
            id: key,
            accessor: (dt) => dt[key],
            width: 90,
            Cell: VanexSegmentSizeCell,
            sortType: sortIntColumn,
            // style: {fontWeight: 'bolder',textAlign: 'center'},
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="CNV_TYPE") {
          return {
            Header: "Type",
            id: key,
            accessor: (dt) => dt[key],
            width: 50,
            useGainLossColor: true,
            // style: {fontWeight: 'bolder',textAlign: 'center'},
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="SEG_MEAN") {
          return {
            Header: "Seg Mean",
            id: key,
            accessor: (dt) => dt[key],
            width: 80,
            sortType: sortFloatColumn,
            Cell: (props) => (  Math.round((Number(props.value) + Number.EPSILON) * 100) / 100 ),
            renderDefinedCell: true,
            // style: {fontWeight: 'bolder',textAlign: 'center'},
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="NUM_MARK") {
          return {
            Header: "Mark",
            id: key,
            accessor: (dt) => dt[key],
            width: 50,
            sortType: sortIntColumn,
            // style: {fontWeight: 'bolder',textAlign: 'center'},
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="ENTREZ_IDS") {
          return {
            Header: "Entrez IDs",
            id: key,
            accessor: (dt) => dt[key],
            width: 100,
            Cell: VanexMrPhenePhenoTermsCell,
            renderDefinedCell: true,
            sortType: sortColumnByArrayLength,
            // style: {fontWeight: 'bolder',textAlign: 'center'},
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; },
          }
        }
        if(key==="GENE_SYMBOLS") {
          return {
            Header: "Genes",
            id: key,
            accessor: (dt) => dt[key],
            width: 150,
            Cell: VanexMrPhenePhenoTermsCell,
            renderDefinedCell: true,
            sortType: sortColumnByArrayLength,
            // style: {fontWeight: 'bolder',textAlign: 'center'},
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="OMIM_PHENOTYPES") {
          return {
            Header: "OMIM Phenotypes",
            id: key,
            accessor: (dt) => dt[key],
            width: 150,
            Cell: VanexArrayCell, //VanexMrPhenePhenoTermsCell,
            renderDefinedCell: true,
            sortType: sortColumnByArrayLength,
            // sortType: (rowA, rowB, columnId, desc) => ( !!rowA.values[columnId] && rowA.values[columnId].length > 0 ? (rowA.values[columnId].length < rowB.values[columnId].length ? -1 : 1) : 0),
            // style: {fontWeight: 'bolder',textAlign: 'center'},
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="OMIM_INFO") {
          return {
            Header: "OMIM Genes",
            id: key,
            accessor: (dt) => dt[key],
            width: 250,
            // Cell: VanexArrayCell, //VanexMrPhenePhenoTermsCell,
            // renderDefinedCell: true,
            // sortType: sortColumnByArrayLength,
            // sortType: (rowA, rowB, columnId, desc) => ( !!rowA.values[columnId] && rowA.values[columnId].length > 0 ? (rowA.values[columnId].length < rowB.values[columnId].length ? -1 : 1) : 0),
            // style: {fontWeight: 'bolder',textAlign: 'center'},
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        // if(key==="MIM_NUMBERS") {
        //   return {
        //     Header: "OMIM Numbers",
        //     id: key,
        //     accessor: (dt) => dt[key],
        //     width: 150,
        //     Cell: VanexArrayCell, //VanexMrPhenePhenoTermsCell,
        //     renderDefinedCell: true,
        //     sortType: sortColumnByArrayLength,
        //     // sortType: (rowA, rowB, columnId, desc) => ( !!rowA.values[columnId] && rowA.values[columnId].length > 0 ? (rowA.values[columnId].length < rowB.values[columnId].length ? -1 : 1) : 0),
        //     // style: {fontWeight: 'bolder',textAlign: 'center'},
        //     // style: { textAlign: 'center'}, //fontWeight: 'bolder',
        //     columnStyle: {textAlign: 'center'},
        //     setColWidth: function(inWidth){ this.width = inWidth; }
        //   }
        // }
        if(key==="CENTROMERE_OVERLAP") {
          return {
            Header: "Cen. Overlaps",
            id: key,
            accessor: (dt) => dt[key],
            width: 50,
            sortType: sortFloatColumn,
            // style: {fontWeight: 'bolder',textAlign: 'center'},
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            // columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="RECURRENT_OVERLAP_SIZE") {
          return {
            Header: "Recur. Overlap Size",
            id: key,
            accessor: (dt) => dt[key],
            width: 60,
            Cell: VanexSegmentSizeCell,
            sortType: sortIntColumn,
            // style: {fontWeight: 'bolder',textAlign: 'center'},
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="MAX_RECURRENT_OVERLAP") {
          return {
            Header: "Max Recur. Overlaps",
            id: key,
            accessor: (dt) => dt[key],
            width: 50,
            sortType: sortFloatColumn,
            // style: {fontWeight: 'bolder',textAlign: 'center'},
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            // columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key.endsWith("_GT")){
          return {
            // Header: (showPMFPrefix ? `${getSampleLabel(key.slice(0,-3))} [${key}]` : key) ,
            // Header: `${getSampleLabel(key.slice(0,-3))}: ${key}`,
            Header: `${getSampleLabel(getSampleIdFromColumnAccessor(key))} [${key}]`,
            id: key,
            accessor: (dt) => dt[key],
            withCNVOverlapSchematic:true,
            // useGenotypeColor:true,
            use_GTinsteadOf_Alt_Percent:true,
            Cell: VanexCNVGenotypeCell,
            // Cell: VanexGenotypeCell,
            width: 65, //46,
            style: { textAlign:'center', paddingTop:'.7em'}, //fontWeight: 'bolder', 
            columnStyle: {textAlign: 'center'},
            sampleLabel: getSampleLabel(key.slice(0,-3)),
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }

        // if(key==="QUAL"){
        //   return {
        //     Header: "Qual",
        //     id: key,
        //     accessor: (dt) => dt[key],
        //     width: 70,
        //     useHeated: true,
        //     // addFilter: true,
        //     Cell: (props) => (  Math.round((Number(props.value) + Number.EPSILON) * 100) / 100 ), // Math.trunc(Number(props.value));
        //     heatedThresholdValue: 120, // ! maybe change later?! or this is good?
        //     style: { textAlign: 'center'},
        //     columnStyle: {textAlign: 'center'},
        //     setColWidth: function(inWidth){ this.width = inWidth; }
        //   }
        // }
        // if(key==="GENE"){
        //   return {
        //     Header: "Gene",
        //     id: key,
        //     accessor: (dt) => dt[key],
        //     width: 130,
        //     // addFilter: true,
        //     defaultCanFilter: true,
        //     // canFilter: true,
        //     // Filter: SelectColumnFilter,
        //     // filter: "equals",
        //     Filter: simpleFilter,
        //     filter: (rows, id, filterValue) => {
        //       return rows.filter(row => {
        //         const rowValue = row.values[id]
        //         return rowValue !== undefined
        //           ? String(rowValue)
        //               .toLowerCase()
        //               .startsWith(String('a').toLowerCase())
        //           : true
        //       })
        //     },
        //     setColWidth: function(inWidth){ this.width = inWidth; }
        //   }
        // }
        // if( key.endsWith('_Alt_Percent')){
        //   // let tmpHeader = ( showPMFPrefix ? key : `${getSampleLabel(key.slice(0,-12))} [${key}]`);
        //   // let tmpHeader = `${getSampleLabel(key.slice(0,-12))}: ${key}`;
        //   let tmpHeader = `${getSampleLabel(getSampleIdFromColumnAccessor(key))}: ${key}`;
        //   let threshold = 100;
        //   // tmpHeader = `x_${tmpHeader}`
        //   return {
        //     Header: tmpHeader,
        //     id: key,
        //     accessor: (dt) => dt[key],
        //     width: 50,
        //     Cell: VanexGenotypeCell,
        //     setColWidth: function(inWidth){ this.width = inWidth; },
        //     // useHeated: true,
        //     sampleLabel: getSampleLabel(key.slice(0,-12)),
        //     useReverseHeated: true,
        //     heatedThresholdValue: threshold
        //   }
        // }
        // if(!!getSampleIdFromColumnAccessor(key)){
        //   return {
        //     // Header: key, //key.substring( key.lastIndexOf("_AD_") + 1) + '_' + key.substring(0, key.lastIndexOf("_AD_") ),
        //     // Header: `${getSampleLabel(key.slice(0,-3))}: ${key}`,
        //     Header: `${getSampleLabel(getSampleIdFromColumnAccessor(key))}: ${key}`,
        //     id: key,
        //     accessor: (dt) => dt[key],
        //     width: 40,
        //     setColWidth: function(inWidth){ this.width = inWidth; },
        //     // style: { textAlign: 'center'}, //fontWeight: 'bolder',
        //     columnStyle: {textAlign: 'center'}
        //   }
        // }
        
        let colHeader = key;
        let colWidth = 100;

        // // ! fix this, this is using RD column-definitions, maybe make it project specific?!
        // let colDefinition = getParameterCaseInsensitive(variantFormatDefinition['column-definitions'],key);
        // if (!!colDefinition){
        //   colHeader = colDefinition['header'] || colHeader;
        //   colWidth = colDefinition['column_width'] || colWidth;
        // }

        // TODO: fix the default withd
        if (colWidth < 30) colWidth = 50;

        return {
          Header: colHeader,
          id: key,
          accessor: (dt) => dt[key],
          width: colWidth,
          // columnStyle: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'} , //instead of this, I added it to the CSS file
          setColWidth: function(inWidth){ this.width = inWidth; }
        }
      }));
    }
    return [];
  });


  const columnData = React.useMemo(() => {
    return(  initialColumnData.map( colInfo => {
      if(colInfo.id==="LOC_START" || colInfo.id==="LOC_END") return({...colInfo, use1000Separator:use1000Separator})   
      return(colInfo)
    }));
  },[initialColumnData, use1000Separator]);

  const [hiddenColumnsIds, setHiddenColumnsIds] = useState(props.hiddenColumnsIds || []);
  const [columnOrder, setColumnOrder] = useState(Object.values(columnData).map(i => i.id).filter(t => !hiddenColumnsIds.includes(t)));
  const [sampleColumns, setSampleColumns] = useState({});
  const [PMFSampleColNames, setPMFSampleColNames] = useState(props.PMFSampleColNames || []);
    // it is better to keep it empty at first // const [PMFSampleColNames, setPMFSampleColNames] = useState(props.PMFSampleColNames || columnData.filter(t => t.id.endsWith("_GT")).map(i => i.id).sort( (elA,elB) => (GetSampleRank(elB, true) - GetSampleRank(elA, true)) ) );
  const [sampleTypeModal, setSampleTypeModal] = useState(null);
  const refForChangeSampleTypeModal = React.useRef();
  const [refresh, setRefresh] = useState(false);
  // const [analysisStartedAt, setAnalysisStartedAt] = useState((!!props.analysisStartedAt ? new Date(props.analysisStartedAt).toLocaleString([], {hour: '2-digit', minute:'2-digit', hour12: false}) : 
  //   new Date().toLocaleString([], {hour: '2-digit', minute:'2-digit', hour12: false})));
  // const [analysisStartedAt, setAnalysisStartedAt] = useState(props.analysisStartedAt || new Date().toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit', hour12: true}));
  

  function getSampleLabel (sampleID) {
    let label = ''
    if (!!sampleID && !!props.currentAnalysisInfo && !!props.currentAnalysisInfo.SAMPLES){
      // console.log(Object.values(props.currentAnalysisInfo.SAMPLES).find(t => t.SAMPLE_ID === sampleID));
      let sampleInfo = Object.values(props.currentAnalysisInfo.SAMPLES).find(t => t.SAMPLE_ID === sampleID);
      if(!!sampleInfo && !!sampleInfo['SAMPLE_TYPE']) label=sampleInfo['SAMPLE_TYPE'];
    }
    return(label);
  }


  function getTypeOfColumnsInPMFList(columnInfo, short=true, addUnderline=false, inData = PMFSampleColNames) {
    let label='';
    if(!showPMFPrefix){
      if (!!props.currentAnalysisInfo && !!props.currentAnalysisInfo.SAMPLES)
        label= columnInfo.sampleLabel;
    } else {
      switch(inData.indexOf(columnInfo.id)){
        case 0:
          label= ((short) ? 'P' : 'Proband');
          break;
        case 1:
          label= ((short) ? 'M' : 'Mother');
          break;
        case 2:
          label= ((short) ? 'F' : 'Father');
          break;
        default:
          label= '';
      }
    }
    return( (addUnderline && label!=='') ? `${label}_` : label);
  }

  function getSampleColumnId(sampleType, sampleColNames) {
    let smpType = Object.keys(sampleColNames).find(el => sampleColNames[el].toLowerCase()===sampleType.toLowerCase());
    if(!!smpType)
      return(smpType);
    else
      return 'None';
      // return null;
  }

  function getSampleIdFromColumnAccessor(clmnAccessor) {
    let testingSamples='';
    if (!!props.currentAnalysisInfo && !!props.currentAnalysisInfo.SAMPLES){
      props.currentAnalysisInfo.SAMPLES.forEach(element => {
        if(clmnAccessor.includes(element.SAMPLE_ID))
          testingSamples = element.SAMPLE_ID;
        // testingSamples.push(clmnAccessor.includes(element.SAMPLE_ID)); //SAMPLE_TYPE
      });
    }
    // return(testingSamples.some(i => !!i));
    return(testingSamples);
  }

  function getSampleColumnsProbandAtFirst() {
    let columnNamesWOproband = Object.keys(sampleColumns).filter(el => el!==PMFSampleColNames[0]).map(i => i);
    if(!! PMFSampleColNames[0] && PMFSampleColNames[0]!=='None')
      return([PMFSampleColNames[0], ...columnNamesWOproband]);
    else
      return(columnNamesWOproband);
  }

  // const [selectedVariantTab, setSelectedVariantTab] = useState("TAB_ALL_CNV_CALLS_PROBAND");
  const [selectedVariantTab, setSelectedVariantTab] = useState(props.selectedVariantTab || 'TAB_SUMMARY' );


  const [tabs, setTabs] = useState( () => {
    let mainTabLabel="All CNVs";
    let otherSampleIds;
    if(!!props.currentAnalysisInfo && !!props.currentAnalysisInfo.SAMPLES){
      let prbSample = props.currentAnalysisInfo.SAMPLES.find(smp => smp.SAMPLE_TYPE.toLowerCase()==="proband");
      if(!!prbSample && !!prbSample['SAMPLE_ID']) mainTabLabel= `${prbSample.SAMPLE_TYPE} (${prbSample.SAMPLE_ID})`;
      otherSampleIds = props.currentAnalysisInfo.SAMPLES.filter(smp => smp.SAMPLE_TYPE.toLowerCase()!=="proband");//.map(smp => smp.SAMPLE_ID)
    }

    // // * set Analysis_Type specific settings
    // let tabsToShow = -1; // * show all tabs
    // if(!!props.onlyShowOneTab){
    //   tabsToShow = 1;
    // } else if(!!props.currentAnalysisInfo && (props.currentAnalysisInfo.ANALYSIS_TYPE==='GermlineSNV' || props.currentAnalysisInfo.ANALYSIS_TYPE==='SomaticSNV' || props.currentAnalysisInfo.ANALYSIS_TYPE==='GenomeWideDeNovo')){
    //   tabsToShow = 2;
    // }

    // * set tabs
    // if(tabsToShow === 1){
    //   return(
    //     {
    //       'TAB_ALL': {
    //         label: props.oneTabTitle || 'Results',
    //         filters: {}
    //       }
    //     }
    //   );
    // }
    // if(tabsToShow === 2){
    let tabsInfo= {
      'TAB_SUMMARY':
      {
        label: 'Summary',
        filters: {}
      },
      'TAB_ALL_CNV_CALLS_PROBAND': {
        label: "Proband", //"Proband's CNVs",
        // label: mainTabLabel,
        filters: {}
      },
      'TAB_ALL_CNV_CALLS_PROBAND_DENOVO': {
        label: "De Novos",
        filters: {}
      },
      // 'TAB_ALL_CNV_CALLS_PROBAND_W_GENES': {
      //   label: "with Genes",
      //   filters: {}
      // },
      // 'TAB_ALL_CNV_CALLS_PROBAND_W_OMIM_GENES': {
      //   label: "with OMIM",
      //   filters: {}
      // },
      // 'TAB_ALL_CNV_CALLS_MOTHER': {
      //   label: "Mother's CNVs",
      //   filters: {}
      // },
      // 'TAB_ALL_CNV_CALLS_FATHER': {
      //   label: "Father's CNVs",
      //   filters: {}
      // }
    }
    // TODO: uncomment to add tabs for each individual
    // if(!!otherSampleIds && otherSampleIds.length > 0){
    //   otherSampleIds.forEach(smpId => {
    //     tabsInfo = {...tabsInfo, 
    //                   [`TAB_${smpId.SAMPLE_ID}`]: {
    //                     label: `${smpId.SAMPLE_TYPE}`, //`${smpId.SAMPLE_TYPE}'s CNVs`, //"Mother's CNVs",
    //                     // label: `${smpId.SAMPLE_TYPE} (${smpId.SAMPLE_ID})`,
    //                     filters: {}
    //                   }
    //                 }
    //   });
    // }
    return(tabsInfo); 
  });

  const [selectedPathwayGeneEntrezIds, setSelectedPathwayGeneEntrezIds] = useState([]);
  const [selectedPathwayIds, setSelectedPathwayIds] = useState([]);
  const [pathwaySearchValue, setPathwaySearchValue] = useState('');
  const [pathwayCategory, setPathwayCategory] = useState("KEGG");

  //!!!! useEffect(() => {
  //   let geneEntrezIds = [];
  //   selectedPathwayIds.forEach( l => geneEntrezIds = [...new Set([...geneEntrezIds, ...pathways[l]])]);
  //   // console.log(geneEntrezIds); // console.log(selectedPathwayIds);
  //   setSelectedPathwayGeneEntrezIds(geneEntrezIds);
  //   if(selectedPathwayIds.length > 0){
  //     setGlobalTabFilters(
  //       prevGlobalTabFilters => ({
  //         ...prevGlobalTabFilters,
  //         ["PATHWAYS_GLOBAL_FILTER"]: {
  //           columnId: 'PATHWAYS_GLOBAL_FILTER',
  //           label: 'PATHWAYS_GLOBAL_FILTER',
  //           value: 'PATHWAYS_GLOBAL_FILTER',
  //           // minValue: null,
  //           // maxValue: null,
  //           operation: '=',
  //           visible: true,
  //           editable: true,
  //           getFilterDescr: function(){ return("Only Selected Pathways")},
  //           // getFilterDescr: function(){ return(this.label + this.value)},
  //           filterFunction: function(){ return(t => (geneEntrezIds.includes(t['ENTREZ_ID']))) },
  //           isApplied: true
  //         }
  //       })
  //     );
  //   } else {
  //     let tmpGlobalTabFilters = {...globalTabFilters}
  //     delete tmpGlobalTabFilters['PATHWAYS_GLOBAL_FILTER']        //! is there a way to delete a property without copying it first? NO!
  //     setGlobalTabFilters(tmpGlobalTabFilters);
  //   }
  // }, [selectedPathwayIds])

  const [numberOfReviewSaved, setNumberOfReviewSaved] = useState(null);
  const [numberOfNotesSaved, setNumberOfNotesSaved] = useState(null);
  const canSaveNotes = props.canSaveNotes || (!(!!props.onlyShowOneTab || !props.currentCaseInformation));
  const [isSavingNotes, setIsSavingNotes] = useState(false);
  const [isAutoSavingNotes, setIsAutoSavingNotes] = useState(process.env.NODE_ENV!=='development' || false);
  const [autoSaveNotesInterval, setAutoSaveNotesInterval] = useState(3);
  const [autoSaveNotesCounter, setAutoSaveNotesCounter] = useState(0);
  const [savingNotesProgressbarValue, setSavingNotesProgressbarValue] = useState(0);
  const [isExporting, setIsExporting] = useState(false);
  const [exportProgressbarValue, setExportProgressbarValue] = useState(0);
  const [trigerExportPreparation, setTrigerExportPreparation] = useState(false);
  const [preparedDataFromVariantTable, setPreparedDataFromVariantTable] = useState(null);
  const [VaNexAutoNotes, setVaNexAutoNotes] = useState([]);
  const [showVaNexAutoNotes, setShowVaNexAutoNotes] = useState(false);
  const [showOtherUsersNotes, setShowOtherUsersNotes] = useState(true);
  
  const [latestTablePageIndex, setLatestTablePageIndex] = useState(0);
  // const [notesForCurrentVariant, setNotesForCurrentVariant] = useState([]);
  const [selectedSegment, setSlectedSegment] = useState(null);
  const [currentVariantId, setCurrentVariantId] = useState(null);

  const [plotInfileData, setPlotInfileData] = useState({});//{"chr1":[],"chr2":[],"chr3":[],"chr4":[],"chr5":[],"chr6":[],"chr7":[],"chr8":[],"chr9":[],"chr10":[],"chr11":[],"chr12":[],"chr13":[],"chr14":[],"chr15":[],"chr16":[],"chr17":[],"chr18":[],"chr19":[],"chr20":[],"chr21":[],"chr22":[],"chrX":[],"chrY":[]});
  const [plotSegmentsData, setPlotSegmentsData] = useState({});//{"chr1":[],"chr2":[],"chr3":[],"chr4":[],"chr5":[],"chr6":[],"chr7":[],"chr8":[],"chr9":[],"chr10":[],"chr11":[],"chr12":[],"chr13":[],"chr14":[],"chr15":[],"chr16":[],"chr17":[],"chr18":[],"chr19":[],"chr20":[],"chr21":[],"chr22":[],"chrX":[],"chrY":[]});


  // const [loadingClinVarPlot, setLoadingClinVarPlot] = useState(false);
  const [autoLoadFirefly, setAutoLoadFirefly] = useState(false);
  // const [clinVarPlotSrc, setClinVarPlotSrc] = useState('');
  // const [errorMessage, setErrorMessage] = useState('');
  const [fireflyGeneID, setFireflyGeneID] = useState(null);

  // const loadClinVarPlot = (currentVariant) => {
  //   setLoadingClinVarPlot(true);

  //   let geneName = currentVariant.GENE;
  //   GetSignedURL('nch-igm-vanex-client',`ClinVarPlots/GRCH38-Varhouse/${geneName.toUpperCase()}%2F${geneName.toUpperCase()}.ALL.png`) 
  //   .then(result_json => {
  //     setClinVarPlotSrc(result_json);
  //     setErrorMessage('');
  //     setLoadingClinVarPlot(false);
  //   }).catch(e => {
  //     setClinVarPlotSrc('');
  //     setLoadingClinVarPlot(false);
  //     setErrorMessage(`Failed to load the image. (${e})`);
  //   });
  // }


  useEffect(() => {
    setAutoLoadFirefly(false);
    setFireflyGeneID(null);
    // setLoadingClinVarPlot(false);
    // setClinVarPlotSrc('');
    // setErrorMessage('');
    // setdbSNPSrc('');

    let autoNotes = [];

    if(currentVariantId!==null && currentVariantId!==undefined && !!props.caseData && !!props.caseData[currentVariantId]){
      // console.log(currentVariant.VANEX_NOTES);
      // //!!!! autoNotes=`${gnomADCounts},\n${ntDamaging}`
      setSlectedSegment(props.caseData[currentVariantId]);
      const chrom=props.caseData[currentVariantId].CHROMOSOME;
      // console.log(chrom);

      let probandSampleId = "";
      if(!!props.currentAnalysisInfo && !!props.currentAnalysisInfo.SAMPLES){
        probandSampleId = props.currentAnalysisInfo.SAMPLES.find(smp => smp.SAMPLE_TYPE.toLowerCase()==="proband");
        if(!!probandSampleId && !!probandSampleId['SAMPLE_ID']) probandSampleId = probandSampleId.SAMPLE_ID;
      }
      // console.log("plotSegmentsData", plotSegmentsData);
      // console.log("chrom in plotSegmentsData", `chr${chrom}` in plotSegmentsData);
      if(!!chrom && !(`chr${chrom}` in plotSegmentsData)){
        // console.log("plotSegmentsData[`chr${chrom}`]",plotSegmentsData[`chr${chrom}`]);

        // let bucket = 'nch-igm-research-cfm';
        // let uriToLoad = `IGMCFM_ANALYSES/2022/IGMCFM0023/Churchill/2022-04-26/cnvloh/22-0770_IGM_CFM_0023_001_S-2022-008333/varscan_normal/varScan.CNV.cbs.chr${chrom}.infile`

        
        let s3Bucket = props.currentAnalysisInfo.PARQUET_URI.replace("s3://", "").split("/", 1)[0];
        let s3BaseKey = props.currentAnalysisInfo.PARQUET_URI.replace(`s3://${s3Bucket}/`, "")
        let uriToLoad = `${s3BaseKey}/${probandSampleId}/varscan_normal/varScan.CNV.cbs.chr${chrom}.infile`;
        // let uriToLoad = `2023/RD0386/Churchill/2023-04-06/cnvloh/RD0386_P_S-2023-009960/varscan_normal/varScan.CNV.cbs.chr${chrom}.infile`;

        console.log("bucket", s3Bucket);
        console.log("uriToLoad", uriToLoad);
        GetSignedURL(s3Bucket,uriToLoad)
        .then(response => downloadIndividualFile(response))
        .then(response => setPlotInfileData(prevValue => ({...prevValue, [`chr${chrom}`]: parse_infile_data(response)})))
        // .then(response => setPlotInfileData(parse_infile_data(response)))
        // .then(response => console.log("resresponse: ", parse_infile_data(response)))
        .catch( e => { toast(`Failed to fetch the data. File not found.\n(${e.toString()})`, {duration:6000}); return("") });
        // .catch( e => toast.error(`Notes not found (${e.toString()})`, {duration:6000}))

        
        //uriToLoad =CFMbucket `IGMCFM_ANALYSES/2022/IGMCFM0023/Churchill/2022-04-26/cnvloh/22-0770_IGM_CFM_0023_001_S-2022-008333/varscan_normal/varScan.CNV.cbs.chr8.segments.p_value`;
        //uriToLoad =CFMbucket `IGMCFM_ANALYSES/2022/IGMCFM0023/Churchill/2022-04-26/cnvloh/22-0770_IGM_CFM_0023_001_S-2022-008333/varscan_normal/varScan.CNV.cbs.chr${chrom}.segments.p_value`;
        // uriToLoad = `2023/RD0386/Churchill/2023-04-06/cnvloh/RD0386_P_S-2023-009960/varscan_normal/varScan.CNV.cbs.chr${chrom}.segments.p_value`;
        uriToLoad = `${s3BaseKey}/${probandSampleId}/varscan_normal/varScan.CNV.cbs.chr${chrom}.segments.p_value`;
        // console.log("uriToLoad", uriToLoad);
        GetSignedURL(s3Bucket,uriToLoad)
        .then(response => downloadIndividualFile(response))
        .then(response => setPlotSegmentsData(prevValue => ({...prevValue, [`chr${chrom}`]: parse_segments_p_value_data(response)}) ))
        // .then(response => console.log("resresponse: ", parse_infile_data(response)))
        .catch( e => { toast(`Failed to fetch the data. File not found.\n(${e.toString()})`, {duration:6000}); return("") });
        // .catch( e => toast.error(`Notes not found (${e.toString()})`, {duration:6000}))

      }
      // else if(!!chrom && chrom in plotSegmentsData && plotSegmentsData[chrom]===undefined){ } //* this means the last try returned no record, so no need to retry!

    }

    // // autoNotes=`${gnomADCounts},\n${ntDamaging}`
    // autoNotes=[gnomADCounts, ntDamaging]
    // // autoNotes['GNOMAD']=gnomADCounts;
    // // autoNotes['DAMAGING']=ntDamaging;
    // // console.log(currentVariantId);

    if(isAutoSavingNotes && (autoSaveNotesCounter >= autoSaveNotesInterval) ) saveNotes();
    // if(columnId==='VANEX_NOTES' && isAutoSavingNotes){
    // if(isAutoSavingNotes){
    //   if(autoSaveNotesCounter >= autoSaveNotesInterval){
    //     saveNotes();
    //   } else {
    //     setAutoSaveNotesCounter(autoSaveNotesCounter + 1);
    //   // console.log(columnId);
    //   }
    // }

    setVaNexAutoNotes(autoNotes);
  }, [currentVariantId]);


  useEffect(() => {
    let timer;
    if(numberOfNotesSaved!==null && numberOfNotesSaved!==undefined){
      timer = setTimeout(() => {setNumberOfReviewSaved(null); setNumberOfNotesSaved(null)}, 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [numberOfNotesSaved]);
 

  useEffect(() => {
    if(!!preparedDataFromVariantTable){
      navigator.clipboard.writeText(preparedDataFromVariantTable);
      console.log("Copied to Clipboard!");
      // console.log(preparedDataFromVariantTable);
      setPreparedDataFromVariantTable(null);
    }
  }, [preparedDataFromVariantTable]);


  //? --------------------------------------------- Filters ----------------------------------

  // const [filterSelectionIndeterminateCheckboxStatus, setFilterSelectionIndeterminateCheckboxStatus] = useState(UNCHECKED);
  const [filterSelectionFormik, setFilterSelectionFormik] = useState(null);
  const [filterSelectionTitle, setFilterSelectionTitle] = useState('Filtering');
  const [filterSelectionModal, setFilterSelectionModal] = useState(null);
  const refForFilterSelectionModal = React.useRef();
  const refForCreatableSelect = React.useRef();

  const [visibleFilterSets, setVisibleFilterSets] = useState(() => {
    let tmpVisibleSetsObj = [];
    Object.values(filterDefinition.CNV_FILTER_SET).filter(t => t.visible === true).forEach( (myFilter, filterInd) => {
    // Object.keys(Object.values(filterDefinition.CNV_FILTER_SET).filter(t => t.visible==true)).FILTER_DEFINITIONS).forEach( tabFilterId => {
    // Object.keys(filterDefinition.CNV_FILTER_SET[0].FILTER_DEFINITIONS).forEach( filterId => {
      let tmpFilterSet = {};
      let tmpDefinitionObj = {};

      Object.keys(myFilter.FILTER_DEFINITIONS).filter( t => ( myFilter.FILTER_DEFINITIONS[t].visible === true)
      ).forEach( filterId => {
        // * createFilterObjectFromJSON
        // console.log(filterId);
        let filter = myFilter.FILTER_DEFINITIONS[filterId];
        let tmpObj = {
          columnId: filter.column_id,
          label: filter.label,
          value: ( (!!filter.value) ? filter.value : null ),
          visible: (filter.visible===true || filter.visible===false) ? filter.visible : true, 
          editable: (filter.editable===true || filter.editable===false) ? filter.editable : true,
          getFilterDescr: ( (!!filter.value) ? function(){ return(this.label + this.value);} : function(){ return(this.label);} ),
          filterFunction: ConstructFilterFunctions(filter),
          isApplied: (filter.isApplied===true || filter.isApplied===false) ? filter.isApplied : false,
          isSpecial: filter.isSpecial===true
        }
        tmpDefinitionObj[filterId]=tmpObj;
      });
      tmpFilterSet['value']=filterInd;
      tmpFilterSet['label']=myFilter.label;
      tmpFilterSet['user_defined']=false;
      tmpFilterSet['visible']=myFilter.visible;
      tmpFilterSet['FILTER_DEFINITIONS']=tmpDefinitionObj;
      tmpVisibleSetsObj.push(tmpFilterSet);
    });

    //TODO: fetch user filters and add to this with tmpFilterSet['user_defined']=true; (async? in another method?)

    // console.log('tmpVisibleSetsObj');
    // console.log(tmpVisibleSetsObj);
    return(tmpVisibleSetsObj);
  });

  const [isLoadingVisibleFilterSets, setIsLoadingVisibleFilterSets] = useState(false);
  // const [selectedFilterSetIndex, setSelectedFilterSetIndex] = useState(props.defaultFilterSet || null);
  const defaultFilterSet = 1;
  const [selectedFilterSetIndex, setSelectedFilterSetIndex] = useState(null);
  const [isFilterSetModified, setIsFilterSetModified] = useState(false);
  const [filterDescriptions, setFilterDescriptions] = useState([]);
  const [globalTabFilters, setGlobalTabFilters] = useState({});




  //? --------------------------------------------- Apply Filters ----------------------------------

  const groupBy = (data, key) => {
    return data.reduce(function(rval, item) {
      (rval[item[key]] = rval[item[key]] || []).push(item);
      return rval;
    }, {});
  };

  // function notIn(inFunction){ return function(){return !inFunction.apply(this, arguments);};}

  // ! ------ Filter ------
  const applyFilter = (tmpVariantData, tabId) => {
    // if (!!tabs && !!tabs[tabId] && !tabs[tabId].filters) 
    // return([tmpVariantData, []]);
    // console.log("applyFilter");
    let filterSpec = [];
    const IGNORE_FILTERS_4_PLP_CALLS=tabs[tabId].filters.IGNORE_FILTERS_4_PLP_CALLS && tabs[tabId].filters.IGNORE_FILTERS_4_PLP_CALLS.isApplied;

    //! THIS MODIFIES THE ORIGINAL DATA AND CANNOT BE UNDONE!!
    if(!!tabs[tabId].filters && !!tabs[tabId].filters.NO_LOC_LINC && !!tabs[tabId].filters.NO_LOC_LINC.isApplied){
      tmpVariantData.forEach( (cnvCall, callIndex) => {
        const allIndexes = tmpVariantData[callIndex].GENE_SYMBOLS.reduce(function(finalList, item, index) {
          if (item.startsWith("LOC") || item.startsWith("LINC")) 
            finalList.push(index);
          return finalList;
        }, []);
        if(!!allIndexes && allIndexes.length >0){
          tmpVariantData[callIndex].GENE_SYMBOLS = tmpVariantData[callIndex].GENE_SYMBOLS.filter( (item, index) => !allIndexes.includes(index));
          tmpVariantData[callIndex].ENTREZ_IDS = tmpVariantData[callIndex].ENTREZ_IDS.filter( (item, index) => !allIndexes.includes(index));
        }
      })
    }

    // ! add this:  check if clinvar then add all variants in those genes to make sure there is no comp.het. !
    Object.values(globalTabFilters).filter(k => !!k['isApplied']).forEach((filterTerm)=> {
      // if (filterTerm.isApplied!== undefined && filterTerm.isApplied!== null && filterTerm.isApplied===true){
        tmpVariantData = Object.values(tmpVariantData).filter( filterTerm.filterFunction(IGNORE_FILTERS_4_PLP_CALLS)); 
      // }
    });

    // ! To Be Deleted (this is added to the global filters)
    // if(selectedPathwayGeneEntrezIds.length>0){
    //   tmpVariantData = Object.values(tmpVariantData).filter(t => ( selectedPathwayGeneEntrezIds.includes(t['ENTREZ_ID'])) );
    // }

    if(tabId==='TAB_SUMMARY' && !!tabs[tabId] && !!tabs[tabId].filters['MAIN_B'] && !!tabs[tabId].filters['MAIN_B'].isApplied){
      // console.log(tabs[tabId].filters.MAIN_A.filterFunction);
      // tmpVariantData = Object.values(tmpVariantData).filter( tabs[tabId].filters['MAIN_B'].filterFunction()); 
      Object.keys(tabs[tabId].filters).filter(k => ( !tabs[tabId].filters[k]['isSpecial'] && !!tabs[tabId].filters[k]['isApplied'])).forEach( filterKey => {
        let filterTerm = tabs[tabId].filters[filterKey];
        if( filterKey !=='MAIN_A'){
          tmpVariantData = Object.values(tmpVariantData).filter( filterTerm.filterFunction(IGNORE_FILTERS_4_PLP_CALLS)); 
        }
      });
    } else {
      Object.values(tabs[tabId].filters).filter(k => (!k['isSpecial'] && !!k['isApplied']) ).forEach((filterTerm)=> {
        tmpVariantData = Object.values(tmpVariantData).filter( filterTerm.filterFunction(IGNORE_FILTERS_4_PLP_CALLS, PMFSampleColNames));
      });
      // * making sure that TAB_Filter is applied after other filters
      if( !!tabs[tabId] && !!tabs[tabId].filters['MAIN'] ){
        tmpVariantData = Object.values(tmpVariantData).filter( tabs[tabId].filters['MAIN'].filterFunction());
      }
    }

    if (tabId === 'TAB_ALL_CNV_CALLS_PROBAND_DENOVO'){
      if (PMFSampleColNames.includes('None')){
        //* filter everything to show "No data" since this needs full trio 
        tmpVariantData = Object.values(tmpVariantData).filter(t => false);
      } else {
        tmpVariantData = Object.values(tmpVariantData).filter(t => (t[PMFSampleColNames[0]] === 100 && t[PMFSampleColNames[1]] < 10 && t[PMFSampleColNames[2]] < 10));
        // tmpVariantData = Object.values(tmpVariantData).filter(t => (t[PMFSampleColNames[0]] !== 0 && t[PMFSampleColNames[1]] === 0 && t[PMFSampleColNames[2]] === 0));
        // tmpVariantData = Object.values(tmpVariantData).filter(t => (t[PMFSampleColNames[0]] !== 'ref' && t[PMFSampleColNames[1]] === 'ref' && t[PMFSampleColNames[2]] === 'ref'));
      }
    }
    
    // if (tabId === 'TAB_HOMREC'){
    //   tmpVariantData = Object.values(tmpVariantData).filter(t => (t[PMFSampleColNames[0]] === 'hom' && t[PMFSampleColNames[1]] !== 'ref' && t[PMFSampleColNames[2]] !== 'ref'));
    // }
    
    // if (tabId === 'TAB_COMPHET'){
    //   if (PMFSampleColNames.includes('None')){
    //     //* filter everything to show "No data" since this needs full trio 
    //     tmpVariantData = Object.values(tmpVariantData).filter(t => false);
    //   } else{
    //     tmpVariantData = Object.values(tmpVariantData).filter(t => (t[PMFSampleColNames[0]] === 'het' && 
    //     ( (t[PMFSampleColNames[1]] === 'ref' && t[PMFSampleColNames[2]] !== 'ref') ||
    //     (t[PMFSampleColNames[1]] !== 'ref' && t[PMFSampleColNames[2]] === 'ref'))
    //     ));
    //     // ! fix this, need to have G and M GT!
    //     // ? Comp. Het. needs special analysis. This code selects TRUE Comp.Hets
    //     // tmpVariantData = Object.values(tmpVariantData).filter(t => t['Inherit Model'] === 'ch');

    //     //? remove the genes with only 1 variant
    //     let groupedByGene=groupBy(tmpVariantData, 'GENE');
    //     groupedByGene = Object.keys(groupedByGene).filter(t => groupedByGene[t].length > 1);
    //     tmpVariantData = Object.values(tmpVariantData).filter(t => Object.values(groupedByGene).includes(t['GENE']));

    //     //? keep the true comp. het. variants
    //     if (PMFSampleColNames.length > 0 && !!tmpVariantData[0] && tmpVariantData[0][PMFSampleColNames[1]] && tmpVariantData[0][PMFSampleColNames[2]]){
    //       groupedByGene=groupBy(tmpVariantData, 'GENE');
    //       groupedByGene = Object.keys(groupedByGene).filter(gene => {
    //         // console.log(`group ${gene} has ${groupedByGene[gene].length} members : `);
    //         let momGT=[];
    //         let dadGT=[];
    //         groupedByGene[gene].forEach ((t,i) => {
    //           momGT.push(t[PMFSampleColNames[1]]!=='ref');
    //           dadGT.push(t[PMFSampleColNames[2]]!=='ref');
    //         })
    //         return (momGT.includes(true) && dadGT.includes(true));
    //       }); 
    //       tmpVariantData = Object.values(tmpVariantData).filter(t => Object.values(groupedByGene).includes(t['GENE']));
    //     }
    //   }

    //   // filterSpec.push("Inheritance = Comp.Het.");
    //   filterSpec.push("Only show TRUE Comp.Het.");
    // }

    // // * sort variants based on damaging score in both LOF and DAMAGING tabs
    // if (tabId === 'TAB_LOF' || tabId === 'TAB_DAMAGING'){
    //   tmpVariantData = Object.values(tmpVariantData).filter(t => (calCumulativeDamagingScore(t)>0.3) ).sort( (elA,elB) => (calCumulativeDamagingScore(elB) - calCumulativeDamagingScore(elA)));
    // }
    
    // if (tabId === 'TAB_LOF'){
    //   tmpVariantData.sort( (elA,elB) => (calCumulativeDamagingScore(elB) - calCumulativeDamagingScore(elA)));
    // }

    // if(props.project==='rd') filterSpec.push("gnomAD frq. < 0.001");

    return([tmpVariantData, filterSpec]);
  }


  const updateFilterSet = (filterSetIndex, selectedTabId) => {
    let tmpTabFilterObj = {};

    // * add Tab-Filters defined in the JSON for this tab
    let tabFilterDefinition = filterDefinition.RD_TAB_FILTER_SET.FILTER_DEFINITIONS[selectedTabId];
    if(tabFilterDefinition !== undefined && tabFilterDefinition !==null){
      Object.keys(tabFilterDefinition).forEach( tabFilterId => {
        // * createFilterObjectFromJSON
        let filter = tabFilterDefinition[tabFilterId];
        let tmpObj = {
          columnId: filter.column_id,
          label: filter.label,
          value: null,
          visible: (filter.visible===true || filter.visible===false) ? filter.visible : false, 
          editable: (filter.editable===true || filter.editable===false) ? filter.editable : false,
          getFilterDescr: function(){ return(this.label)},
          filterFunction: ConstructFilterFunctions(filter),
          isApplied: (filter.isApplied===true || filter.isApplied===false) ? filter.isApplied : true,
          isSpecial: filter.isSpecial===true
        }
        tmpTabFilterObj[tabFilterId]=tmpObj;
      });
    }

    // * add filters from visibleFilterSets
    if (filterSetIndex !==undefined && filterSetIndex !== null && !!visibleFilterSets &&
      !!visibleFilterSets[filterSetIndex] && !!visibleFilterSets[filterSetIndex].FILTER_DEFINITIONS){
      Object.keys(visibleFilterSets[filterSetIndex].FILTER_DEFINITIONS).forEach( filterId => {
        tmpTabFilterObj[filterId]={...visibleFilterSets[filterSetIndex].FILTER_DEFINITIONS[filterId]};
      });
    }

    setTabs( prevTabs => ({
      ...prevTabs,
      [selectedTabId]: {
        ...prevTabs[selectedTabId],
        filters: tmpTabFilterObj
      }
    }));

  }

 
  //? --------------------------------------------- main useEffects ---------------------------------- 
  const [mainUseEffectsIsDone, setMainUseEffectsIsDone] = useState(false);

  // * by default it uses the first filter set defined in filters.json file. 
  useEffect(() => {
    // set Modal for selecting column filters
    setFilterSelectionModal( new Modal(refForFilterSelectionModal.current));

    // set Modal for changing sample type
    setSampleTypeModal( new Modal(refForChangeSampleTypeModal.current));

    // ! fix   'MR_PHENE_PHENOTYPE_TERMS' in all three 
    // const defaultColumnOrder = ['VANEX_TAGS','CHROMOSOME','LOC_START','LOC_END','SEG_SIZE','CNV_TYPE','SEG_MEAN','P_GT','VANEX_NOTES','NUM_MARK','ENTREZ_IDS','GENE_SYMBOLS','OMIM_PHENOTYPES','MR_PHENE_PHENOTYPE_TERMS','MAX_RECURRENT_OVERLAP','RECURRENT_OVERLAP_SIZE','CENTROMERE_OVERLAP']; // , 'VANEX_REVIEWS', 'CLINVAR_CLNSIG' ,'MR_PHENE_PHENOTYPE_TERMS'
    let defaultVisibleColumns = ['VANEX_TAGS','CHROMOSOME','LOC_START','LOC_END','P_GT','VANEX_NOTES','SEG_SIZE','CNV_TYPE','SEG_MEAN','NUM_MARK','GENE_SYMBOLS','OMIM_PHENOTYPES']; // , 'VANEX_REVIEWS', 'CLINVAR_CLNSIG' ,'MR_PHENE_PHENOTYPE_TERMS'
    let include_GT = true;
    let include_Alt_Percent = false;
    let columnToUseForPMF = '_GT';

    if(!!props.onlyShowOneTab) defaultVisibleColumns.push('CASE_ID')


    let hiddenCols = [];
    if(!props.hiddenColumnsIds){
      // * set hidden columns
      // hiddenCols = Object.keys(inputData[0]).filter(t => !defaultVisibleColumns.includes(t) ).map( k => k);
      //! fix this, move columnData to here?! and then use the top line instead of the below

      Object.values(columnData).forEach(column => {
        if(!defaultVisibleColumns.includes(column.id) &&
            !(include_GT && column.id.endsWith('_GT')) &&
            !(include_Alt_Percent && column.id.endsWith('_Alt_Percent'))
          ){
          hiddenCols.push(column.id);
        }
      });
      setHiddenColumnsIds(hiddenCols);
    } else { hiddenCols = hiddenColumnsIds}


    Object.keys(tabs).forEach( tabId => {
      let filterSetIndex = undefined;
      if(tabId!=='TAB_SUMMARY') filterSetIndex = defaultFilterSet ;
      updateFilterSet(filterSetIndex, tabId);
    } );

    // setSelectedFilterSetIndex(defaultFilterSet);

    if(selectedVariantTab === 'TAB_SUMMARY') 
      setSelectedFilterSetIndex(null);

    let sampleColNames = {};
    if (!!props.currentAnalysisInfo && !!props.currentAnalysisInfo.SAMPLES){
      props.currentAnalysisInfo.SAMPLES.sort( (elA,elB) => (GetSampleRank(elB.SAMPLE_TYPE) - GetSampleRank(elA.SAMPLE_TYPE)) ).forEach(element => {
        sampleColNames[`${element.SAMPLE_ID}${columnToUseForPMF}`]=element.SAMPLE_TYPE;
        // sampleColNames[element.SAMPLE_TYPE]=`${element.SAMPLE_ID}${columnToUseForPMF}`;
      });
      if (!props.PMFSampleColNames)
        setPMFSampleColNames( [getSampleColumnId('Proband', sampleColNames), getSampleColumnId('Mother', sampleColNames), getSampleColumnId('Father', sampleColNames) ]);
      
    } else {
      columnData.filter(t => t.id.endsWith(columnToUseForPMF))
        .map(i => i.id)
        .sort( (elA,elB) => (GetSampleRank(elB, true) - GetSampleRank(elA, true)) )
        .forEach(element => {
          sampleColNames[element]="None";
        });
    }
    // * example of sampleColNames: 
    // {
    //   "RD0393_p_GT": "Proband",
    //   "RD0393_m_GT": "Mother",
    //   "RD0393_f_GT": "Father"
    // }
    setSampleColumns(sampleColNames);

    // * set column order
    // if (!!props.PMFSampleColNames) sampleColNames= PMFSampleColNames;
    // if(sampleColNames.length > 0){
    //   let colOrder = Object.values(columnData).map(i => i.id).filter(t => (!hiddenCols.includes(t) && !PMFSampleColNames.includes(t) ) );
    //   console.log(colOrder);
    //   console.log(PMFSampleColNames);
    //   colOrder =[...colOrder, ...PMFSampleColNames]
    //   console.log(colOrder);
    //   setColumnOrder( colOrder );
    // }
    // console.log("columnData", columnData);
    setColumnOrder( Object.values(columnData).map(i => i.id).filter(t => !hiddenCols.includes(t)) );
            //.sort( (elA,elB) => (defaultColumnOrder.indexOf(elA) - defaultColumnOrder.indexOf(elB)) )
    // setColumnOrder( Object.values(columnData).map(i => i.id).filter(t => !hiddenCols.includes(t)).sort( (elA,elB) => (defaultVisibleColumns.indexOf(elA) - defaultVisibleColumns.indexOf(elB))  ) );







    // * load data for CoNDyPlots
    // // GetSignedURL('nch-igm-vanex',`case_notes/CASE_ID=${caseID}/ANALYSIS_ID=${analysisObj.ANALYSIS_ID}/USERNAME=${userName}/${userName}.json`)
    // GetSignedURL('nch-igm-research-cfm',`IGMCFM_ANALYSES/2022/IGMCFM0023/Churchill/2022-04-26/cnvloh/22-0770_IGM_CFM_0023_001_S-2022-008333/varscan_normal/varScan.CNV.cbs.chr1.infile`)
    // .then(response => downloadIndividualFile(response))
    // .then(response => setPlotInfileData({"chr1": parse_infile_data(response)}))
    // // .then(response => setPlotInfileData(parse_infile_data(response)))
    // // .then(response => console.log("resresponse: ", parse_infile_data(response)))
    // .catch( e => { toast(`Failed to fetch the data. File not found.\n(${e.toString()})`, {duration:6000}); return("") });
    // // .catch( e => toast.error(`Notes not found (${e.toString()})`, {duration:6000}))

    // // GetSignedURL('nch-igm-research-cfm',`IGMCFM_ANALYSES/2022/IGMCFM0023/Churchill/2022-04-26/cnvloh/22-0770_IGM_CFM_0023_001_S-2022-008333/varscan_normal/varScan.CNV.cbs.chr8.segments.p_value`)
    // GetSignedURL('nch-igm-research-cfm',`IGMCFM_ANALYSES/2022/IGMCFM0023/Churchill/2022-04-26/cnvloh/22-0770_IGM_CFM_0023_001_S-2022-008333/varscan_normal/varScan.CNV.cbs.chr1.segments.p_value`)
    // .then(response => downloadIndividualFile(response))
    // .then(response => setPlotSegmentsData({"chr1": parse_segments_p_value_data(response)}) )
    // // .then(response => setPlotSegmentsData(prevValue => ({...prevValue, "chr1": parse_segments_p_value_data(response)}) ))
    // // .then(response => console.log("resresponse: ", parse_infile_data(response)))
    // .catch( e => { toast(`Failed to fetch the data. File not found.\n(${e.toString()})`, {duration:6000}); return("") });
    // // .catch( e => toast.error(`Notes not found (${e.toString()})`, {duration:6000}))

    // let geneName = currentVariant.GENE;
    // GetSignedURL('nch-igm-vanex-client',`ClinVarPlots/GRCH38-Varhouse/${geneName.toUpperCase()}%2F${geneName.toUpperCase()}.ALL.png`) 
    // .then(result_json => {
    //   setClinVarPlotSrc(result_json);
    //   setErrorMessage('');
    //   setLoadingClinVarPlot(false);
    // }).catch(e => {
    //   setClinVarPlotSrc('');
    //   setLoadingClinVarPlot(false);
    //   setErrorMessage(`Failed to load the image. (${e})`);
    // });


    // ! does this work? fix it if not!
    if(!props.userName && canSaveNotes){ 
      toast.error(`Cannot save notes since you are either not logged in or your session has expired! (${props.userName})`, {duration:8000});
    }
    setMainUseEffectsIsDone(true);

  }, []);


  function downloadIndividualFile(signedURL) {  // , userName
    return new Promise(function (resolve, reject) {
      fetch(signedURL, {
        method: 'GET',
        mode: 'cors'
      })
      .then(response => {     
        if (!response.ok) { // if (status >= 200 && status < 300) {
          reject("HTTP error " + response.status);
        }
        const responseText = response.text();
        resolve(responseText);
      })
      .catch(e => {
        reject("Failed to fetch the data.", e); //! fix the error message!
      });
    });
  }


  function parse_segments_p_value_data (input_text) { 
    //    "ID"        "chrom" "loc.start" "loc.end" "num.mark" "seg.mean" "bstat"         "pval"                "lcl"   "ucl"
    //"9" "Chromosome.chr8" "chr8" 7021617 7133453   100        -0.9798   6.27295561798728 1.86224885309002e-08 7132693 7133453
    let parsedData = [];
    //* remove the first /n if there is one and then process each line
    input_text = input_text.replace(/\n+$/,"").split('\n');
    // //* remove the first line
    // let removedElement = input_text.shift();
    input_text.forEach((line) => {
      // * remove all quotation marks
      let segValues = line.replaceAll("\"","").split(' ');
      segValues = segValues.map( (el,ind) => (ind <= 2 ? el : parseFloat(el)) );
      // segValues = segValues.map((el,ind) => (ind <= 2 ? el :  (ind <= 5 ? parseInt(el) : parseFloat(el))  ));
      if(segValues.length===11){
        try{
          parsedData.push({
            // 'ID':segValues[1],
            'CHROM':segValues[2],
            'LOC_START':segValues[3],
            'LOC_END':segValues[4],
            'NUM_MARK':segValues[5],
            'SEG_MEAN':segValues[6],
            // 'BSTAT':segValues[7],
            // 'PVAL':segValues[8],
            // 'LCL':segValues[9],
            // 'UCL':segValues[10],
          });
          // parsedData.push(...segValues);
        }
        catch(e){
          console.error('Error: excluded data =',segValues, e);
        }
      }
    });
    // console.log("parsedData:", parsedData);
    return(parsedData);
  }

  function parse_infile_data (input_text) { 
    //    "ID"        "chrom" "loc.start" "loc.end" "num.mark" "seg.mean" "bstat"         "pval"                "lcl"   "ucl"
    //"9" "Chromosome.chr8" "chr8" 7021617 7133453   100        -0.9798   6.27295561798728 1.86224885309002e-08 7132693 7133453
    let parsedData = [];
    //* remove the first /n if there is one and then process each line
    input_text = input_text.replace(/\n+$/,"").split('\n');
    // //* remove the first line
    // let removedElement = input_text.shift();
    input_text.forEach((line) => {
      let segValues = line.split('\t').map( (el,ind) => (ind === 0 ? el : parseFloat(el)) );
      if(segValues.length===6){
        try{
          parsedData.push({
            'CHROM':segValues[0],
            'LOC':segValues[1],
            'SIZE':segValues[2],
            'NORMAL_DEPTH':segValues[3],
            'TUMOR_DEPTH':segValues[4],
            'LOG_RATIO':segValues[5],
          });
          // parsedData.push(...segValues);
        }
        catch(e){
          console.error('Error: excluded data =',segValues, e);
        }
      }
    });
    // console.log("parsedData:", parsedData);
    return(parsedData);
  }







  const variantData = React.useMemo(() => {
    let [filteredVariantData, filterSpec] = applyFilter(props.caseData, selectedVariantTab);  // ! [...props.caseData] or  props.caseData ?
    setFilterDescriptions(filterSpec);
    return(filteredVariantData);
    // return(props.caseData);
  }, [tabs, globalTabFilters, selectedVariantTab, props.caseData, refresh, selectedPathwayGeneEntrezIds]); //! deconstruct props and use individual ones?? 


  useEffect(() => {
    setLatestTablePageIndex(0);
  }, [tabs, globalTabFilters, selectedVariantTab]);


  // const memoizedSegmentsData = React.useMemo(() => {
  //   //! remove these in Production!
  //   // console.log("props.caseData", props.caseData);
  //   // return(props.caseData.filter(seg => seg.CHROMOSOME==="21" ));

  //   // if(!!props.caseData && !!props.caseData[currentVariantId] && !!props.caseData[currentVariantId].CHROMOSOME){
  //   //   console.log(props.caseData[currentVariantId].CHROMOSOME);
  //   //   console.log(props.caseData.filter(seg => seg.CHROMOSOME===props.caseData[currentVariantId].CHROMOSOME ));
  //   //   return(props.caseData.filter(seg => seg.CHROMOSOME===props.caseData[currentVariantId].CHROMOSOME ));
  //   // }
  //   if(!!selectedSegment && !!selectedSegment.CHROMOSOME){
  //     return(props.caseData.filter(seg => seg.CHROMOSOME===selectedSegment.CHROMOSOME));
  //   }
  //   else
  //     return [];
  // }, [selectedSegment]);
  // // }, [tabs, globalTabFilters, selectedVariantTab]);

  // const [dbSNPSrc, setdbSNPSrc] = useState(''); //! not used, so delete this?



  //? --------------------------------------------- saveNotes ----------------------------------

  const saveNotes = () => {
    // toast.error(`Cannot save notes yet! 🙁 (${props.userName} - ${props.currentCaseInformation.CASE_ID})`);
    // return 1;

    // ! find a better way to update progress bar?
    const updateSavingNotesProgress = (value, final=false) => {
      setSavingNotesProgressbarValue(value);
      // const interval = setInterval(() => {  // ! fix this!
      //   setSavingNotesProgressbarValue(value);
      //   if(final || value > 90) {
      //     setIsSavingNotes(false);
      //     clearInterval(interval);
      //   }
      // }, 400);
    }

    // ! does this work? fix it if not!
    if(!(!!props.userName && !!props.currentCaseInformation.CASE_ID)){
      toast.error(`Cannot save notes since you are either not logged in or your session has expired 🙁 (${props.userName} - ${props.currentCaseInformation.CASE_ID})`);
      return 1;
    }

    setIsSavingNotes(true);

    updateSavingNotesProgress(10);

    const recordsWithReview = Object.values(props.caseData).filter(t => ( t.VANEX_REVIEWS['REVIEW_VALUE']>0) )
    .map( variant => (({ VANEX_REVIEWS, CHROMOSOME, LOC_START, LOC_END, SEG_MEAN }) => {
      let tmpDate = (!!VANEX_REVIEWS['UPDATED_AT'] ? VANEX_REVIEWS['UPDATED_AT'] : new Date() );
      return({ REVIEW_VALUE:VANEX_REVIEWS['REVIEW_VALUE'] || -1, CHROMOSOME, LOC_START, LOC_END, SEG_MEAN, UPDATED_AT:tmpDate })
    })(variant));
    
    if(!!recordsWithReview && recordsWithReview.length > 0) {
      // console.log(recordsWithReview);
      let caseId = props.currentCaseInformation.CASE_ID; // *no need for .toUpperCase();
      let userName= "REVIEWS";
      let fileName= `${caseId}_${props.currentAnalysisInfo.ANALYSIS_ID}_${userName}.json.gz`;
      
      // const resultAsBinString = pako.gzip(Object.values(recordsWithReview).map(x=>JSON.stringify(x)).join(''));
      const resultAsBinString = pako.gzip(JSON.stringify(recordsWithReview));
      
      // const stringUtf16ThatWeNeedInUtf8 = JSON.stringify(recordsWithReview);
      // const stringUtf8 = unescape(encodeURIComponent(stringUtf16ThatWeNeedInUtf8));
      // const resultAsBinString = pako.gzip(stringUtf8);
      // const gzippedBlob = new Blob([resultAsBinString]);

      GetSignedURL('nch-igm-vanex',`cnv_case_notes/CASE_ID=${caseId}/ANALYSIS_ID=${props.currentAnalysisInfo.ANALYSIS_ID}/USERNAME=${userName}/${fileName}&upload=true`)
      .then(result_json => { 
        updateSavingNotesProgress(25);
        return(
          fetch(result_json, {
            method: 'PUT',
            // headers: {
            //   'Content-Type': 'application/json'
            //   'Content-Encoding': 'gzip',
            // },
            body: resultAsBinString //Object.values(recordsWithReview).map(x=>JSON.stringify(x)).join('\n')
          })
        );
      }).then( response => {
        if(response.status === 200){
          setNumberOfReviewSaved(Object.keys(recordsWithReview).length);
          // toast.success(`${Object.keys(recordsWithReview).length} Reviews Saved!`, {duration:2000});
          // setShouldBlockNavigation(false);
          // setIsSavingNotes(false);
          // setAutoSaveNotesCounter(0);
        }
        else { throw Error(`${response.status} - ${response.statusText}`); }
      }).catch(e => {
        toast.error(`Failed to save review data. (${e})`);
        // setShouldBlockNavigation(false);
        // setIsSavingNotes(false);
      });
    }

    const userNotes = Object.values(props.caseData).filter(t => (!!t.VANEX_TAGS || !!t.VANEX_NOTES['CURRENT_USER_NOTE']) )
    .map( variant => (({ VANEX_TAGS, VANEX_NOTES, CHROMOSOME, LOC_START, LOC_END, SEG_MEAN }) => {
      let tmpDate = (!!VANEX_NOTES['UPDATED_AT'] ? VANEX_NOTES['UPDATED_AT'] : new Date() );
      return({ VANEX_TAGS, VANEX_NOTES:VANEX_NOTES['CURRENT_USER_NOTE'] || '',CHROMOSOME, LOC_START, LOC_END, SEG_MEAN, UPDATED_AT:tmpDate })
    })(variant));
    // .map( variant => ( ({ VANEX_TAGS, VANEX_NOTES,CHROMOSOME, LOC_START, LOC_END, SEG_MEAN }) => ({ VANEX_TAGS, VANEX_NOTES:VANEX_NOTES['CURRENT_USER_NOTE'],CHROMOSOME, LOC_START, LOC_END, SEG_MEAN, UPDATED_AT:VANEX_NOTES['UPDATED_AT'] }))(variant));

    // const userNotes = Object.values(props.caseData).filter(t => !!t.FILTER ).map( variant => (({ FILTER,CHROMOSOME, LOC_START, LOC_END, SEG_MEAN }) => ({ FILTER,CHROMOSOME, LOC_START, LOC_END, SEG_MEAN }))(variant));
    // const userNotes = Object.values(props.caseData).filter(t => !!t.FILTER ).map( variant => (({ FILTER,CHROMOSOME, LOC_START, LOC_END, SEG_MEAN }) => ({ FILTER,CHROMOSOME, LOC_START, LOC_END, SEG_MEAN, VARIANTID: `${CHROMOSOME}_${POSITION}_${REF}_${ALT}` }))(variant));
    // const userNotes = Object.values(props.caseData).filter(t => !!t.FILTER ).map( variant => (({CHROMOSOME, LOC_START, LOC_END, SEG_MEAN, FILTER, index }) => ({CHROMOSOME, LOC_START, LOC_END, SEG_MEAN, FILTER, index }))(variant));
    
    // * no need to check for this
    // if(!(!!userNotes && userNotes.length > 0)) {
    //   toast.error('No Notes to Save!');
    //   setShouldBlockNavigation(false);
    //   setIsSavingNotes(false);
    //   return;
    // }

    let caseId = props.currentCaseInformation.CASE_ID; // *no need for .toUpperCase();
    let userName= props.userName.replaceAll(' ','_');                     // ! do we need to make it .toUpperCase()?
    let fileName= `${userName}.json`;
    //!! add this back let fileName= `${caseId}_${props.currentAnalysisInfo.ANALYSIS_ID}_${userName}.json`;
    // const url_to_fetch = ENDPOINT_BASE+`get_signed_url?upload=true&bucket=nch-igm-vanex&key=cnv_case_notes/CASE_ID=${caseId}/${userName}.json`;
    // const url_to_fetch = ENDPOINT_BASE+`get_signed_url?upload=true&bucket=nch-igm-vanex&key=cnv_case_notes/CASE_ID=${caseId}/USERNAME=${userName}/${userName}.json`;
    GetSignedURL('nch-igm-vanex',`cnv_case_notes/CASE_ID=${caseId}/ANALYSIS_ID=${props.currentAnalysisInfo.ANALYSIS_ID}/USERNAME=${userName}/${fileName}&upload=true`)
    .then(result_json => {
      updateSavingNotesProgress(50);
      return(
        fetch(result_json, {
          method: 'PUT',
          headers: {
            // 'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: Object.values(userNotes).map(x=>JSON.stringify(x)).join('\n')
          // body: JSON.stringify(userNotes)
          // body: JSON.stringify({a: 1, b: 'Textual content'})
        })
      );
    }).then( response => {
      if(response.status === 200){
        setNumberOfNotesSaved(Object.keys(userNotes).length);
        // setTimeout(() => {setSaveNotesMessage(`${Object.keys(userNotes).length} Notes Saved!`)}, 1000); //! fix this hack!
        // toast.success(`${Object.keys(userNotes).length} Notes Saved!`, {duration:2000});
        setShouldBlockNavigation(false);
        setIsSavingNotes(false);
        setAutoSaveNotesCounter(0);
      }
      else { throw Error(`${response.status} - ${response.statusText}`); }
    }).catch(e => {
      toast.error(`Failed to save notes. (${e})`);
      setShouldBlockNavigation(false);
      setIsSavingNotes(false);
    });

  }

  //? --------------------------------------------- Exports ----------------------------------

  const shouldExportThisTab = (scope, tabId) => {
    switch(scope){
      case 'summary':
        return(tabId==='TAB_SUMMARY');
      case 'noOriginalData':
        return(tabId!=='TAB_ALL');
      default:
        return true;
    }
  }

  const notesOptions = (scope) => {
    if(scope==='autoSavingNotes')
      setIsAutoSavingNotes(prevState => !prevState);
    if(scope==='hideOtherUsersNotes')
      setShowOtherUsersNotes(prevState => !prevState);
  }

  const exportSnvAsXlsx = (scope) => {

    if (scope==="clipboard"){
      setTrigerExportPreparation(true);
      // navigator.clipboard.writeText(preparedDataFromVariantTable);
      // navigator.clipboard.writeText(createTable());
      return;
    }

    // ! find a better way to update progress bar?
    const updateExportProgress = (value, final=false) => {
      setExportProgressbarValue(value);
      // setTimeout(() => {setExportProgressbarValue(value)}, 100); //! fix this hack!
      // const interval = setInterval(() => { // ! fix this!
      //   setExportProgressbarValue(value);
      //   if(final || value > 90) {
      //     setIsExporting(false);
      //     clearInterval(interval);
      //   }
      // }, 400);
    }

    setIsExporting(true);
    
    let outputFileName = "VaNex-output";
    let xlsxSheetData= [];

    updateExportProgress(1);
    // let deepCopiedCaseData = JSON.parse(JSON.stringify(props.caseData));
    const deepCopiedCaseData = structuredClone(props.caseData);
    updateExportProgress(10);

    let totalTabs = Object.keys(tabs).length;
    Object.keys(tabs).forEach( (tabId, index) => {
      if(shouldExportThisTab(scope, tabId)){
        // * apply filteres for each tab!
        let [tmpVariantData, filterSpec]= applyFilter(deepCopiedCaseData, tabId); //! [...props.caseData], tabId);  // ! [...props.caseData] or  props.caseData ?
        Object.keys(tmpVariantData).forEach( rId => {tmpVariantData[rId].VANEX_NOTES = tmpVariantData[rId].VANEX_NOTES['CURRENT_USER_NOTE']});
        
        // * add a sheet for each tab!
        xlsxSheetData.push([tabs[tabId].label , XLSX.utils.json_to_sheet(tmpVariantData, {header:['VANEX_TAGS', 'VANEX_NOTES', 'CHROMOSOME' ]})]);
        // xlsxSheetData.push([tabs[tabId].label , XLSX.utils.json_to_sheet(tmpVariantData, {header:['CHROMOSOME' POSITION	STOP_POSITION	REF	ALT	SAMPLES	INHERIT_MODEL	QUAL	FILTER	GENE	ENTREZ_ID	LOC_IN_GENE	DIST_TO_SPLICE_SITE	DIST_TO_CODING_REGION	EFFECT	IMPACT	MANE_HGVS	HGVS	HGVS_ALL_PRIORITIZED	INFORMATION, 	ROW_INDEX	VANEX_TAGS	VANEX_NOTES']})]);
      }
      updateExportProgress(10+((index+1)*(60/totalTabs)));
    });

    updateExportProgress(70);

    //! add this? xlsxSheetData.push(['Filtered (raw)' , XLSX.utils.json_to_sheet(props.caseData)]);
    //! add this? xlsxSheetData.push(['Filtered (Global)' , XLSX.utils.json_to_sheet(globalFilteredVariantData)]);
    //! add this? xlsxSheetData.push(['Selected' , XLSX.utils.json_to_sheet(variantData)]);

    const wb = XLSX.utils.book_new();
    xlsxSheetData.forEach( element => {
      // console.log('appending: ', element);
      XLSX.utils.book_append_sheet(wb, element[1], element[0]);  
    });

    updateExportProgress(80);

    if(!!props.currentCaseInformation){
      XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet([props.currentCaseInformation]), "Case Info");
      if(!!props.currentCaseInformation.CASE_ID){
        outputFileName = props.currentCaseInformation.CASE_ID;
      }
    }
    if(!!props.currentAnalysisInfo){
      XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet([props.currentAnalysisInfo]), "Analysis Info");
    }

    updateExportProgress(90);
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, outputFileName + ".xlsx");
    toast.success('Export Completed!');
    setIsExporting(false); // updateExportProgress(99, true);
  }

  //? --------------------------------------------- process values ----------------------------------

  // const showWithPrecision = (inGnomadFreq, decNum=2) => {
  //   let gnomadFreq = inGnomadFreq;
  //   if (inGnomadFreq !== undefined && inGnomadFreq !== null){
  //     gnomadFreq = Number(gnomadFreq);
  //     return(gnomadFreq.toPrecision(decNum));
  //     // if(gnomadFreq!==0) return gnomadFreq.toFixed(decNum);
  //   }
  //   return gnomadFreq
  // }

  const processOMIMArrayTerms = (omimTermsString) => {
    // let omimTerms = omimTermsString.split(';');
    return(
      omimTermsString.map((term, index) => {
        return(
          <div  key={index} className="rounded-pill px-2 mx-2 my-1 border-light-blue d-flex flex-row flex-nowrap text-nowrap align-items-center justify-content-center" 
            dangerouslySetInnerHTML={{ __html: term.replaceAll(/,? ?autosomal dominant/ig, '<span class="ms-1 badge bg-orange">AD</span>')
              .replaceAll(/,? ?autosomal recessive/ig, '<span class="ms-1 badge bg-green">AR</span>')
              .replaceAll(/,? ?X-linked dominant/ig, '<span class="ms-1 badge bg-orange">XLD</span>')
              .replaceAll(/,? ?X-linked recessive/ig, '<span class="ms-1 badge bg-green">XLR</span>') }} />
        );
      })
    );
  }

  // const processMrPheneBoxTitle = (mrPheneTerms) => {
  //   let outputStr = '(-)';
  //   let number = mrPheneTerms.match(/^\[(\d*)\]/);
  //   if(!!number && !!number[1]) outputStr=`(${number[1]})`;

  //   // outputStr += `: ${mrPheneTerms}`; 

  //   return <span>{outputStr}</span>;
  // }

 

  //!!!! const [selectedPathwayInVariantDesc, setSelectedPathwayInVariantDesc] = useState('');

  // const allPathwayTermsForTheCurrentVariant = React.useMemo(() => {
  //   let pathwaysTerms = [];
  //   if (!!currentVariantId && props.caseData[currentVariantId] && props.caseData[currentVariantId].ENTREZ_ID!==undefined && props.caseData[currentVariantId].ENTREZ_ID!==null){
  //     // console.log(props.caseData[currentVariantId])
  //     if(pathways!==null && Object.keys(pathways).length >0){
  //       pathwaysTerms = Object.keys(pathways).filter(t => pathways[t].includes(props.caseData[currentVariantId].ENTREZ_ID));
  //     }
  //   }
  //   if (pathwaysTerms.length > 0)
  //     setSelectedPathwayInVariantDesc(pathwaysTerms[0]);
  //   else
  //     setSelectedPathwayInVariantDesc('');
  //   return pathwaysTerms;
  // }, [currentVariantId]); //! deconstruct props and use individual ones?? 

  //!!! const processedPathwayTerms = React.useMemo(() => {
  //   let pathwaysTerms = allPathwayTermsForTheCurrentVariant.map( (term, index) => {
  //     let numberOfCallsInThisPathway = Object.values(props.caseData).filter(t => pathways[term].includes(t.ENTREZ_ID)).length;
  //     return(
  //       // <div key={index} className={`rounded-pill px-2 mx-2 my-1 cursor-pointer ${ (term===selectedPathwayInVariantDesc ) ? 'border-red ' :'border-light-blue ' }`}
  //       <div key={index} className={`rounded-pill px-2 mx-2 my-1 cursor-pointer ${ (numberOfCallsInThisPathway > 1) ? 'bg-light-pink' :'' } ${ (term===selectedPathwayInVariantDesc ) ? 'border-red ' :'border-light-blue ' }`}
  //         onClick={() => setSelectedPathwayInVariantDesc(term)}
  //       >{term} ({numberOfCallsInThisPathway})</div>
  //     );
  //   });
  //   return pathwaysTerms;
  // }, [currentVariantId, selectedPathwayInVariantDesc]); //! deconstruct props and use individual ones?? 


  //!!! const otherVariationsInThisPathway = React.useMemo(() => {
  //   if(selectedPathwayInVariantDesc!==undefined && selectedPathwayInVariantDesc!==null && Object.keys(pathways).length > 0 && !!pathways[selectedPathwayInVariantDesc] ){
  //     return(
  //       Object.values(props.caseData).filter(t => pathways[selectedPathwayInVariantDesc].includes(t.ENTREZ_ID))
  //     );
  //   }
  //   return([]);
  // }, [selectedPathwayInVariantDesc]);

  // const processFunctionalElements = (fnctionalElementsString) => {
  //   try{
  //     if(fnctionalElementsString.length < 5) return("-");
  //     let funcElements = JSON.parse(fnctionalElementsString);
  //     let fullAnnotation = Object.keys(funcElements).map( (element, i) => 
  //       <div className="mx-2 my-1" key={i}> 
  //         {Object.keys(funcElements[element]).map( (innerElement, innerI) => <span className="no-white-space-wrap" key={innerI}><b>{element}</b>: {String(funcElements[element][innerElement]) } <br/></span>) }
  //       </div>)

  //     let wholeBlock = 
  //       <>
  //         {Object.keys(funcElements.CHROM).map( (innerElementId, innerInd) => 
  //           <div className="variant-item text-center m-1 d-flex flex-row flex-wrap" key={innerInd}>
  //             <span className="functional-element-annotation-text"><b>ID</b>: {innerElementId}</span>
  //             <span className="functional-element-annotation-text"><b>CHROM</b>: {funcElements.CHROM[innerElementId]}</span>
  //             <span className="functional-element-annotation-text"><b>Start</b>: {funcElements.Start[innerElementId]}</span>
  //             <span className="functional-element-annotation-text"><b>End</b>: {funcElements.End[innerElementId]}</span>
  //             <span className="functional-element-annotation-text"><b>db_xref</b>: {funcElements.db_xref[innerElementId]}</span>
  //             <span className="functional-element-annotation-text"><b>regulatory_class</b>: {funcElements.regulatory_class[innerElementId]}</span>
  //             <span className="functional-element-annotation-text"><b>function</b>: {funcElements.function[innerElementId]}</span>
  //             <span className="functional-element-annotation-text"><b>note</b>: {funcElements.note[innerElementId]}</span>
  //             <span className="functional-element-annotation-text"><b>experiment</b>: {funcElements.experiment[innerElementId]}</span>
  //             {/* <span className="functional-element-annotation-text"><b>experiment</b>: {funcElements.experiment[innerElementId].replaceAll(/\[PMID:([\d])*]/g,'xxx')}</span> */}
  //             {/* <span className="functional-element-annotation-text"><b>experiment</b>: {funcElements.experiment[innerElementId].replaceAll(/\[PMID:/g,'[PMID:<a>https://pubmed.ncbi.nlm.nih.gov/30612741/')}</span> */}
  //             <span className="functional-element-annotation-text"><b>genes</b>: {funcElements.genes[innerElementId].replaceAll(';',', ')}</span>
  //           </div>)}
  //         {(props.devOptions ?
  //         <><hr/>
  //         <div className="variant-item text-center m-1 d-flex flex-row flex-wrap">{fullAnnotation}</div>
  //         </> : "")}
  //       </>
  //     return(wholeBlock)
  //   }
  //   catch (e) {
  //     return "error in parsing functional elements info"
  //   }

  //   // Object.keys(funcElements).map( (element, i) =>   String(funcElements[element]) )
  //   // funcElements.map(s -> )
  //   // debugger;
  //   // return fnctionalElementsString
  //   // if(fnctionalElementsString===null || fnctionalElementsString===undefined) return('-'); //! use this? if(fnctionalElementsString===null) return('-'); //  
  //   // if(fnctionalElementsString.trim()==='') return('-');
  //   // return(fnctionalElementsString.split(';').map((hgvsTerm, hgvsIndex) => {
  //   //   return(
  //   //     <div className="rounded-pill border-light-blue m-0 mb-2 px-5" key={hgvsIndex}>
  //   //       {hgvsTerm.split(':').map((subTerm, inerIndex) => {  //.split(/(?=:)/g);
  //   //         return(<div key={inerIndex} className="">{subTerm}{(inerIndex!==hgvsTerm.split(':').length-1) ? ':' : ''}</div>);
  //   //       })}
  //   //   </div>
  //   //   );
  //   // }));
  // }

  // const getPrtPos = (hgvs) => {
  //   // console.log('hgvs', hgvs);
  //   if (!hgvs) return 0;
  //   let prtPos = 0;
  //   let prtPosSearch = hgvs.match(/p\.[A-Za-z]+(\d+)/);
  //   if(!!prtPosSearch && prtPosSearch.length > 0 && parseInt(prtPosSearch[1]) > 0)
  //     prtPos = parseInt(prtPosSearch[1]);
  //   // if(prtPos == 0 ){
  //   //   let prtPosSearch = hgvs.match(/c\.[A-Za-z]+(\d+)/);
  //   // }
  //   // console.log('prtPos',prtPos);
  //   return(prtPos);
  // }

  // //? --------------------------------------------- IGV ----------------------------------
  // !! IGV stuff, took out 

  //? --------------------------------------------- renders ----------------------------------

  const renderVariantInfo = () => {
    if(currentVariantId===null || !props.caseData[currentVariantId]){
      return(
        <div className="bottom-var-info-panel d-flex flex-column">
          <div className="d-flex overflow-auto flex-column flex-nowrap">
            <div className="card m-2 shadow-sm flex-grow-1">
              {/* <h5 className="card-header bg-light-blue">Variant</h5> */}
              <div className="card-body d-flex flex-row flex-nowrap align-items-center justify-content-center">
                <h3>No variant selected</h3>
              </div>
            </div>
          </div>
        </div>
      );
    }
    else{
      const variant = props.caseData[currentVariantId];

      //!! console.log("variant: ", variant);
      // console.log({name: variant.GENE, entrez_id:variant.ENTREZ_ID});
      // console.log([{POSITION:parseInt(variant.POSITION), PRT_POS:getPrtPos(variant.MANE_HGVS)}]);

      return(
        <div className="bottom-var-info-panel d-flex flex-column">
          <div className="d-flex overflow-auto flex-column flex-nowrap">
            
            <div className="card m-1 shadow-sm flex-grow-1">
              <footer className="card-footer text-muted  d-flex flex-row">
              {/* <h6 className="variant-card-header bg-light-blue d-flex flex-row"> */}
                <div className="text-start flex-grow-1">
                  <a href={'https://genome.ucsc.edu/cgi-bin/hgTracks?db=hg38&position=chr'+variant.CHROMOSOME+':'+variant.LOC_START+'-'+variant.LOC_END} target="_blank" rel="noreferrer">
                    <i className="fas fa-external-link-alt"></i> UCSC</a>
                </div>

                <div className="text-start flex-grow-1">
                  <a href={'http://dgv.tcag.ca/gb2/gbrowse/dgv2_hg38/?name=chr'+variant.CHROMOSOME+':'+variant.LOC_START+'-'+variant.LOC_END} target="_blank" rel="noreferrer">
                    <i className="fas fa-external-link-alt"></i> dgv</a>
                </div>
                
                <div className="text-start flex-grow-1">
                  <a href={'https://search.clinicalgenome.org/kb/regions?page=1&type=GRCh38&region=chr'+variant.CHROMOSOME+':'+variant.LOC_START+'-'+variant.LOC_END+'?page=1&size=25&search='} target="_blank" rel="noreferrer">
                    <i className="fas fa-external-link-alt"></i> ClinGen</a>                    
                </div>

                <div className="text-start flex-grow-1">
                  <a href={'https://www.deciphergenomics.org/search/patients/results?q='+variant.CHROMOSOME+'%3A'+variant.LOC_START+'-'+variant.LOC_END+''} target="_blank" rel="noreferrer">
                    <i className="fas fa-external-link-alt"></i> DECIPHER</a>                    
                </div>

                <span className="ms-5 flex-grow-1">CNV: <span className="mx-2 user-select-all variant-full-name-display">{variant.CHROMOSOME}-{variant.LOC_START}-{variant.LOC_END}</span></span>
                <span className="ms-5 flex-grow-1">Size: <span className="mx-1 user-select-all">{variant.SEG_SIZE}</span></span>
                <span className="ms-5 flex-grow-1">Seg. Mean: <span className="mx-1 user-select-all">{variant.SEG_MEAN}</span></span>
                  
                  {/* 
https://search.clinicalgenome.org/kb/gene-dosage/region_search/GRCh38/chr2:70087477-70089203?page=1&size=25&search=
https://www.ncbi.nlm.nih.gov/clinvar?term=(((2%5Bchr%5D%20AND%2070087477%3A70089203%5Bchrpos38%5D))%20AND%20((%22copy%20number%20gain%22%5BType%20of%20variation%5D%20OR%20%22copy%20number%20loss%22%5BType%20of%20variation%5D%20OR%20%22translocation%22%5BType%20of%20variation%5D)))
http://dgv.tcag.ca/gb2/gbrowse/dgv2_hg38/?name=chr2:70087477-70089203
https://genome.ucsc.edu/cgi-bin/hgTracks?db=hg38&position=chr2:70087477-70089203

                  */}
                {/* </h6> */}
                </footer>
              {/* <footer className="card-footer text-muted">2 days ago <cite title="Source Title">Source Title</cite></footer> */}
            </div>


            {/* {renderGenes()} */}
            <VariantInfoBox title="Genes" min_width="10rem" >

              <div className="variant-card-body p-0 m-1 d-flex flex-row show-overflow bg-white" style={{ minHeight:'1rem', maxHeight:'10rem'}}>
                {(!!variant.GENE_SYMBOLS && variant.GENE_SYMBOLS.length > 0)
                ?
                  <>
                    <span className="rounded-pill px-2 mb-1 border-light-blue bg-blue text-light d-flex flex-row flex-nowrap align-items-center justify-content-center" >{variant.GENE_SYMBOLS.length}</span>
                    {variant.GENE_SYMBOLS.map( (geneSymbol, index) => <span className="rounded-pill px-2 mx-1 mb-1 border-light-blue d-flex flex-row flex-nowrap text-nowrap align-items-center justify-content-center" key={index}>
                    <a href={'https://www.genecards.org/cgi-bin/carddisp.pl?gene='+geneSymbol} target="_blank" rel="noreferrer" className="me-2">{geneSymbol}</a>
                    </span>)}
                  </>
                :
                  '-'
                }
              </div>

              {/* <div className="variant-card-body p-0 m-1 d-flex flex-column show-overflow bg-white" style={{ minHeight:'12rem', maxHeight:'20rem'}}>
                <VariantTable
                  initialSortBy={props.initialSortBy}
//! fix this part 
                  updateVariantTableData={() => console.log('ToBeFixed!')}
                  getTabName={() => selectedVariantTab}
                  hiddenColumnsIds={hiddenColumnsIds}
                  // PMFSampleColNames={Object.keys(sampleColumns).map(i => i)}
                  PMFSampleColNames={getSampleColumnsProbandAtFirst()}
                  columns={columnData} data={[]}
                />
              </div> */}
            </VariantInfoBox>


            {/* {renderClinVar()} */}
            <VariantInfoBox title="Firefly" min_width="10rem" >
              <div className="variant-card-body p-0 m-1 d-flex flex-row show-overflow bg-white" style={{ minHeight:'1rem', maxHeight:'10rem'}}>
                {(!!variant.GENE_SYMBOLS && variant.GENE_SYMBOLS.length > 0)
                ?
                  <>
                    <div className="rounded-pill px-2 mb-1 border-light-blue bg-blue text-light d-flex flex-row flex-nowrap align-items-center justify-content-center" >{variant.GENE_SYMBOLS.length}</div>
                    {variant.GENE_SYMBOLS.map( (geneSymbol, index) => <div className="firefly-gene-name-in-cnv rounded-pill px-2 mx-1 mb-1 border-light-blue d-flex flex-row flex-nowrap text-nowrap align-items-center justify-content-center"
                    key={index} onClick={e => {if (!!variant.ENTREZ_IDS && !!variant.ENTREZ_IDS[index]){ setFireflyGeneID(variant.ENTREZ_IDS[index]); setAutoLoadFirefly(true); } }}>{geneSymbol}</div>)}
                  </>
                :
                  '-'
                }
              </div>
              <div  className="variant-card-body d-flex flex-column align-items-center justify-content-center">
                {(autoLoadFirefly)
                  ?
                    <Firefly 
                      className="mb-3"
                      entrezId={fireflyGeneID}
                      useStagingEnv = {process.env.REACT_APP_ENV!=='prod'}
                    />
                  : null }
              </div>
            </VariantInfoBox>


            {/* {renderOMIMPhenotypes()} */}
            <VariantInfoBox title="OMIM Phenotypes" min_width="10rem" >
              {( (!!variant.OMIM_PHENOTYPES && variant.OMIM_PHENOTYPES.length > 0)
                ?
                <>
                  <div className="d-flex flex-row flex-nowrap ">
                    <div className="variant-item text-center m-1 d-flex flex-row">
                      <div className="flex-grow-1 d-flex flex-row flex-wrap">
                        {(variant.OMIM_PHENOTYPES===undefined) ? "Error!" : ( (variant.OMIM_PHENOTYPES===null) ? "-" : processOMIMArrayTerms(variant.OMIM_PHENOTYPES) )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-row w-100"><OmimInfo omimPhenotypes={variant.OMIM_PHENOTYPES.join(";")}  /></div>
                </>
                :
                <div className="d-flex flex-row flex-nowrap ">
                  <div className="variant-item text-center m-1 d-flex flex-row">
                    {'-'}
                  </div>
                </div>
              )}
            </VariantInfoBox>


            {/*  // * a sample of  OMIM_INFO obj
            [
              {
                "TYPES": "gene",
                "MIM_IDS": "602215",
                "GENE": "DEFB4A"
              },
              {
                "TYPES": "gene",
                "MIM_IDS": "613047",
                "GENE": "FAM90A10"
              }, {
                "TYPES": "gene",
                "MIM_IDS": "600985",
                "GENE": "TNXB",
                "PHENOTYPES": "Ehlers-Danlos syndrome, classic-like, 1, 606408 (3), Autosomal recessive; Vesicoureteral reflux 8, 615963 (3), Autosomal dominant"
              },  
              {
                "TYPES": "gene",
                "MIM_IDS": "613815",
                "GENE": "CYP21A2",
                "PHENOTYPES": "Hyperandrogenism, nonclassic type, due to 21-hydroxylase deficiency, 201910 (3), Autosomal recessive; Adrenal hyperplasia, congenital, due to 21-hydroxylase deficiency, 201910 (3), Autosomal recessive"
              }
            ]
            */}

  
            {/* {renderOMIMGenes()} */}
            <VariantInfoBox title="OMIM Genes" min_width="10rem" >
              <div className="variant-card-body p-0 m-1 d-flex flex-row show-overflow bg-white" style={{ minHeight:'1rem', maxHeight:'10rem'}}>
                {(!!variant.OMIM_INFO && variant.OMIM_INFO.length > 0)
                ?
                  <>
                    <span className="rounded-pill px-2 mb-1 border-light-blue bg-blue text-light d-flex flex-row flex-nowrap align-items-center justify-content-center" >{variant.OMIM_INFO.length}</span>
                    {variant.OMIM_INFO.map( (omimInfo, index) => <span key={index} className="rounded-pill px-2 mx-1 mb-1 border-light-blue d-flex flex-row flex-nowrap text-nowrap align-items-center justify-content-center">
                      {omimInfo.MIM_IDS.map( (mimID, mimIndex) => <a key={mimIndex} href={'https://www.omim.org/entry/'+mimID} target="_blank" rel="noreferrer">{omimInfo.GENE}{!!omimInfo.TYPES && omimInfo.TYPES.join(";")!=="gene" ? ` (${omimInfo.TYPES})` : null}</a>)}
                      {/* <a href={'https://www.omim.org/entry/'+omimInfo.MIM_IDS} target="_blank" rel="noreferrer">{omimInfo.GENE}{omimInfo.TYPES!=="gene" ? ` (${omimInfo.TYPES})` : null}</a> */}
                      {/* <div className="d-flex flex-row w-100"><OmimInfo omimPhenotypes={null} omimNumber={mimNumber} mimNumbers={[mimNumber]}/></div> */}
                    </span>)}
                  </>
                :
                  <div className="d-flex flex-row flex-nowrap ">
                    <div className="variant-item text-center m-1 d-flex flex-row">
                      {'-'}
                    </div>
                  </div>
                }
              </div>
            </VariantInfoBox>





            {/* ///! can be deleted
             {( (!!variant.OMIM_PHENOTYPES && variant.OMIM_PHENOTYPES.length > 0)
            ?
              <VariantInfoBox title="OMIM Phenotypes" min_width="10rem" >
                <div className="d-flex flex-row flex-nowrap ">
                  <div className="variant-item text-center m-1 d-flex flex-row">
                    <div className="flex-grow-1 d-flex flex-row flex-wrap">
                      {(variant.OMIM_PHENOTYPES===undefined) ? "Error!" : ( (variant.OMIM_PHENOTYPES===null) ? "-" : processOMIMTerms(variant.OMIM_PHENOTYPES.join(";")) )}
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-row w-100"><OmimInfo omimPhenotypes={variant.OMIM_PHENOTYPES.join(";")} omimNumber={variant.MIM_NUMBERS} /></div>
              </VariantInfoBox>
            :
              <VariantInfoBox title="OMIM Phenotypes (none)" min_width="10rem" >
                <div className="d-flex flex-row flex-nowrap ">
                  <div className="variant-item text-center m-1 d-flex flex-row">
                    {'-'}
                  </div>
                </div>
              </VariantInfoBox>
            )}

            {( (!!variant.MIM_NUMBERS && variant.MIM_NUMBERS.length > 0)
            ?
              <VariantInfoBox title="OMIM Numbers" min_width="10rem">
                <div className="variant-card-body p-0 m-1 d-flex flex-row show-overflow bg-white" style={{ minHeight:'1rem', maxHeight:'10rem'}}>
                  <span className="rounded-pill px-2 border-light-blue bg-blue text-light d-flex flex-row flex-nowrap align-items-center justify-content-center" >{variant.MIM_NUMBERS.length}</span>
                  {variant.MIM_NUMBERS.map( (mimNumber, index) => <span className="rounded-pill px-2 mx-1 border-light-blue d-flex flex-row flex-nowrap align-items-center justify-content-center" key={index}>
                    <a href={'https://www.omim.org/entry/'+mimNumber} target="_blank" rel="noreferrer" className="me-2">{mimNumber}</a>
                  </span>)}
                </div>
              </VariantInfoBox>
            :
              <VariantInfoBox title="OMIM Numbers (none)" min_width="10rem" >
                <div className="d-flex flex-row flex-nowrap ">
                  <div className="variant-item text-center m-1 d-flex flex-row">
                    {'-'}
                  </div>
                </div>
              </VariantInfoBox>
            )} ///! can be deleted END */}





            {/* {( (variant.MR_PHENE_PHENOTYPE_TERMS!==undefined && variant.MR_PHENE_PHENOTYPE_TERMS!==null)
            ?
              <VariantInfoBox title={<span>Mr. Phene <span className="fs-6 ms-2">{processMrPheneBoxTitle(variant.MR_PHENE_PHENOTYPE_TERMS)}</span></span>}
                min_width="10em" collapsed>
                <div className="variant-card-body d-flex flex-row align-items-center justify-content-center">
                  <div className="card-group flex-grow-1 d-flex flex-column">
                      <div className="limited-text-height d-flex flex-row flex-wrap ">
                        {processHpoTerms(variant.MR_PHENE_PHENOTYPE_TERMS.replace(/^\[\d*\]/g, ''))}
                      </div>
                  </div>
                </div>
              </VariantInfoBox>
            : <span/> )} */}

            {/* {renderClinVar()} */}
            {/* <VariantInfoBox title="ClinVar" min_width="10rem" >
            </VariantInfoBox> */}
            {/* <div className="card-group flex-grow-1">
              <VariantInfoBox title="Functional Elements" min_width="10rem">
              </VariantInfoBox>
              <VariantInfoBox title="Constraint" min_width="10rem">
              </VariantInfoBox>
              <VariantInfoBox title="Unaffected Sibs Counts" min_width="10rem">
              </VariantInfoBox>
              <VariantInfoBox title="Common in population" min_width="10rem">
              </VariantInfoBox>
            </div> */}

            {/* <VariantInfoBox title={<span>All SNVs in {variant.GENE}<span className="fs-6 ms-3">({Object.keys(otherVariationsInThisGene).length})</span></span>} */}
            {/* <VariantInfoBox title="Overlapping CNV's in Mother" min_width="10rem" collapsed> 
              <div className="variant-card-body p-0 m-1 d-flex flex-column show-overflow bg-white" style={{ minHeight:'12rem', maxHeight:'20rem'}}>
                <VariantTable
                  initialSortBy={props.initialSortBy}
//! fix this part 
                  updateVariantTableData={() => console.log('ToBeFixed!')}
                  getTabName={() => selectedVariantTab}
                  hiddenColumnsIds={hiddenColumnsIds}
                  // PMFSampleColNames={Object.keys(sampleColumns).map(i => i)}
                  PMFSampleColNames={getSampleColumnsProbandAtFirst()}
                  columns={columnData} data={[]}
                />
              </div>
            </VariantInfoBox>

            <VariantInfoBox title="Overlapping CNV's in Father" min_width="10rem" collapsed> 
              <div className="variant-card-body p-0 m-1 d-flex flex-column show-overflow bg-white" style={{ minHeight:'12rem', maxHeight:'20rem'}}>
                <VariantTable
                  initialSortBy={props.initialSortBy}
//! fix this part 
                  updateVariantTableData={() => console.log('ToBeFixed!')}
                  getTabName={() => selectedVariantTab}
                  hiddenColumnsIds={hiddenColumnsIds}
                  // PMFSampleColNames={Object.keys(sampleColumns).map(i => i)}
                  PMFSampleColNames={getSampleColumnsProbandAtFirst()}
                  columns={columnData} data={[]}
                />
              </div>
            </VariantInfoBox> */}

            {/* {renderIGV()} */}
            <div className="card m-1 p-0 shadow-sm flex-grow-1" style={{minWidth: '10rem'}}>
              {/* <h5 className="variant-card-header bg-light-blue">IGV</h5> */}
              {/* <div className="variant-card-body d-flex flex-row flex-nowrap align-items-center justify-content-center"> */}
              <div className="variant-card-body d-flex flex-column m-1 mt-0 p-0">
                <IgvVaNex chromosome={variant.CHROMOSOME} position={variant.LOC_START} currentAnalysisInfo={props.currentAnalysisInfo}/>
              </div>
            </div>

            {/* {renderGeneDosageGenes()} */}
            <VariantInfoBox title={<span>ClinGen Gene Dosage <span className="fs-6 ms-3">({!!variant.GENE_DOSAGE && variant.GENE_DOSAGE.length >0 ? String(variant.GENE_DOSAGE.length): '-' })</span></span>} min_width="10rem" collapsed>
              <div className="variant-card-body p-0 m-1 d-flex flex-row show-overflow bg-white" style={{ minHeight:'1rem', maxHeight:'12rem'}}>
                {(!!variant.GENE_DOSAGE && variant.GENE_DOSAGE.length > 0)
                ?
                  <>
                    <div className="card-body">
                      
                      {Object.keys(variant.GENE_DOSAGE).map( (element, i) => <span className="" key={i}> 
                      {Object.keys(variant.GENE_DOSAGE[element]).map((element2, j) => (
                          <span className="" key={j}>
                              {(variant.GENE_DOSAGE[element][element2] !== "") 
                                 && (element2 !== "CHROMOSOME")
                                 && (element2 !== "LOC_START")
                                 && (element2 !== "LOC_END")
                                 && (element2 !== "GENE ID")
                                 && (element2 !== "CYTOBAND")
                               ? (
                                <div>
                                {element2} = <strong>{(variant.GENE_DOSAGE[element][element2].startsWith("MONDO:"))? <a href={'https://monarchinitiative.org/'+ variant.GENE_DOSAGE[element][element2]} target="_blank" rel="noreferrer">{variant.GENE_DOSAGE[element][element2]}</a>:variant.GENE_DOSAGE[element][element2]}</strong>
                            </div>
                              ) : null}
                          </span>
                      ))}
                      <br/></span>) }
                    </div>
                  </>
                :
                  <div className="d-flex flex-row flex-nowrap ">
                    <div className="variant-item text-center m-1 d-flex flex-row">
                      {'-'}
                    </div>
                  </div>
                }
              </div>
            </VariantInfoBox>

            {/* {renderRegionDosageGenes()} */}
            <VariantInfoBox title={<span>ClinGen Region Dosage <span className="fs-6 ms-3">({!!variant.REGION_DOSAGE && variant.REGION_DOSAGE.length >0 ? String(variant.REGION_DOSAGE.length): '-' })</span></span>} min_width="10rem" collapsed>
              <div className="variant-card-body p-0 m-1 d-flex flex-row show-overflow bg-white" style={{ minHeight:'1rem', maxHeight:'11rem'}}>
                {(!!variant.REGION_DOSAGE && variant.REGION_DOSAGE.length > 0)
                ?
                  <>
                    <div className="card-body">
                      {/* {console.log(variant.REGION_DOSAGE)} */}
                      {/* {Object.keys(variant.GENE_DOSAGE).map( (element) => { return( String(variant.GENE_DOSAGE[element])) } ) } */}
                      { Object.keys(variant.REGION_DOSAGE).map( (element, i) => <span className="" key={i}> 
                      {/* <strong>{ String(variant.GENE_DOSAGE[element]["#GENE SYMBOL"]) } </strong> */}
                      {Object.keys(variant.REGION_DOSAGE[element]).map((element2, j) => (
                          <span className="" key={j}>
                              {(variant.REGION_DOSAGE[element][element2] !== "") 
                                 && (element2 !== "CHROMOSOME")
                                 && (element2 !== "LOC_START")
                                 && (element2 !== "LOC_END")
                                 && (element2 !== "#ISCA ID")
                                 && (element2 !== "CYTOBAND")
                               ? (
                                  <div>
                                      {element2} = <strong>{(variant.REGION_DOSAGE[element][element2].startsWith("MONDO:"))? <a href={'https://monarchinitiative.org/'+ variant.REGION_DOSAGE[element][element2]} target="_blank" rel="noreferrer">{variant.REGION_DOSAGE[element][element2]}</a>:variant.REGION_DOSAGE[element][element2]}</strong>
                                  </div>
                              ) : null}
                          </span>
                      ))}
                      <br/></span>) }
                    </div>
                  </>
                :
                  <div className="d-flex flex-row flex-nowrap ">
                    <div className="variant-item text-center m-1 d-flex flex-row">
                      {'-'}
                    </div>
                  </div>
                }
              </div>
            </VariantInfoBox>

            {/* <VariantInfoBox title={<span>Pathways <span className="fs-6 ms-3">({(!!processedPathwayTerms && !!processedPathwayTerms.length) ? processedPathwayTerms.length : '-'})</span></span>}
              min_width="10em" collapsed> 
            </VariantInfoBox> */}
    
            <VariantInfoBox title="Complete Record" min_width="10em" collapsed>
            {/* <div className="card m-1 shadow-sm flex-grow-1" style={{minWidth: '10rem'}}> */}
              {/* <h5 className="variant-card-header bg-light-blue">Complete Record</h5> */}
              <div className="card-body">
                {/* {Object.keys(variant).map( (element) => { return( String(variant[element])) } ) } */}
                { Object.keys(variant).map( (element, i) => <span className="" key={i}> {element}= { String(variant[element]) } <br/></span>) }
              </div>
            </VariantInfoBox>{/* </div> */}
    
          </div>
        </div>
      );
    }
  }


  const onVariantFilterChange = (event, useGlobalTabFilters) => {
    // console.log(event.target.name , ' is ', event.target.value);
    let currentFilterName = event.target.name;

    if(event.target.type === 'checkbox'){
      if(useGlobalTabFilters){
        setGlobalTabFilters(prevGlobalTabFilters => ({
          ...prevGlobalTabFilters,
          [currentFilterName]: {
            ...prevGlobalTabFilters[currentFilterName],
            isApplied: Boolean(event.target.checked)
          }
        }));
      } else {
        // * update isApplied of this filter in the current tab 
        setTabs( prevTabs => ({
          ...prevTabs,
          [selectedVariantTab]: {
            ...prevTabs[selectedVariantTab],
            filters:{
              ...prevTabs[selectedVariantTab].filters,
              [currentFilterName]: {
                ...prevTabs[selectedVariantTab].filters[currentFilterName],
                isApplied: Boolean(event.target.checked)
              }
            }
          }
        }));
      }
    }
  }


  const renderFilterInfo = (filterList, useGlobalTabFilters) => {

    if(Object.keys(filterList).length === 0){
      return(<label className="list-group-item ps-4">Found None.</label>)
    }
    else{
      return(
        <React.Fragment>
          {Object.keys(filterList).filter( t => filterList[t].editable===true).map((filterTerm, index)=> {
            return(
              <label className="list-group-item pe-2" key={index}>
                <div className="form-check form-switch m-0">
                  <input className="form-check-input me-1" type="checkbox" name={filterTerm} 
                    onChange={(e) => onVariantFilterChange(e, useGlobalTabFilters)}
                    checked={filterList[filterTerm]["isApplied"]}
                    disabled={filterTerm==="NO_LOC_LINC" && filterList[filterTerm]["isApplied"]}
                    // disabled={filterList[filterTerm].isSpecial && filterList[filterTerm]["isApplied"]}
                    />
                      <div className="d-flex flex-row">
                      <div className="filter-description flex-grow-1" >{filterList[filterTerm].getFilterDescr() }</div>
                      {(useGlobalTabFilters)
                        ? 
                        <button className="btn move-filter-button flex-center" 
                          onClick={ () => {addFilterToSet(filterList[filterTerm].columnId)}}
                          ><span className="plus-icon-next-to-arrow"><i className="fas fa-plus fa-xs"></i></span><i className="fas fa-level-up-alt"></i>
                        </button>
                      : null }
                    </div>
                </div>
              </label>
            );
          })}
        </React.Fragment>
      );
    }
  }

  // const addNewFilter = () => {
  //   toast((t) => (<span>{"Yay!, you found a hidden gem!"}<br/> {"(under construction)"}</span>),{icon: '👏', duration:5000});
  //   // showAlert("To be added!", 0, true, 'Success!')
  //   // console.log(JSON.stringify(globalTabFilters))
  //   // localStorage.setItem('globalTabFilters', JSON.stringify(globalTabFilters));
  //   // Object.assign(globalTabFilters, JSON.parse(localStorage.getItem('game_status')));

  //   // console.log(JSON.stringify(variantFilterObj))
  //   // localStorage.setItem('variantFilterObj', JSON.stringify(variantFilterObj));
  //   // Object.assign(variantFilterObj, JSON.parse(localStorage.getItem('game_status')));
    
  // }

  const createNewFilterSet = (inputValue) => {
    // * check to make sure there is no other filter-set with the same name (including the invisible ones)
    let filteKey = inputValue.toUpperCase().replace(/\W/g, '');
    console.log(filterDefinition.XLS_FILTER_SET.filter(t => t.label.toUpperCase().replace(/\W/g, '') === filteKey).length > 0);
    if(filterDefinition.XLS_FILTER_SET.filter(t => t.label.toUpperCase().replace(/\W/g, '') === filteKey).length > 0){
      toast.error('The filter-set name needs to be unique. A hidden filter-set might exist!', {duration:6000})
      return;
    }

    setIsLoadingVisibleFilterSets(true);
    const newFilterSet = {
      value: visibleFilterSets.length,
      label: inputValue,
      user_defined: true,
      visible: true,
      FILTER_DEFINITIONS: {},
    }
    setVisibleFilterSets([...visibleFilterSets, newFilterSet]);

    // * show the new FilterSet
    refForCreatableSelect.current.state.value = newFilterSet; // console.log(refForCreatableSelect.current.state.value); // console.log(refForCreatableSelect.current.props);

    updateFilterSet(visibleFilterSets.length, selectedVariantTab);
    setSelectedFilterSetIndex(visibleFilterSets.length);

    setIsLoadingVisibleFilterSets(false);
  }

  const addFilterToSet = (filterName) => {
    // if(visibleFilterSets[selectedFilterSetIndex].user_defined===true){
      setIsLoadingVisibleFilterSets(true);
      setTabs( prevTabs => ({
        ...prevTabs,
        [selectedVariantTab]: {
          ...prevTabs[selectedVariantTab],
          filters:{
            ...prevTabs[selectedVariantTab].filters,
            [filterName]: {...globalTabFilters[filterName]}
          }
        }
      }));

      let tmpGlobalTabFilters = {...globalTabFilters}
      delete tmpGlobalTabFilters[filterName]        //! is there a way to delete a property without copying it first? NO!
      setGlobalTabFilters(tmpGlobalTabFilters);

      setIsFilterSetModified(true);
      setIsLoadingVisibleFilterSets(false);
    // } else {
    //   toast.error("Can only add to filter-set created by you!");
    // }
  }

  const saveFilterSet = () => {
    if(!!refForCreatableSelect.current){
      if (!refForCreatableSelect.current.state.value){
        toast.error("Select a filter-set first!");
      } else if( selectedFilterSetIndex!==null && selectedFilterSetIndex!==undefined && visibleFilterSets[selectedFilterSetIndex].user_defined===true){
        //* update the filterSet first
        setIsLoadingVisibleFilterSets(true);
        let currentFilterSet = {};
        Object.keys(tabs[selectedVariantTab].filters).forEach( filterId => {
          currentFilterSet[filterId] = {...tabs[selectedVariantTab].filters[filterId]}; //! do we need a shallow copy here?
        });

        // console.log(currentFilterSet);
        setVisibleFilterSets(
          prevVisibleFilterSets => prevVisibleFilterSets.map( 
            el => (el.value === selectedFilterSetIndex ? { ...el, FILTER_DEFINITIONS: currentFilterSet } : el ) 
          )
        );
        setIsFilterSetModified(false);
        setIsLoadingVisibleFilterSets(false);
        toast.success("Saved!");
      }
      else {
        toast.error("Cannot make changes to VaNex filters!");
      }
    }
  }

  const onFilterSetSelectChange = (selectedFilterSet, actionMeta) =>{
    if (!!selectedFilterSet){
      updateFilterSet(selectedFilterSet.value, selectedVariantTab);
      setSelectedFilterSetIndex(selectedFilterSet.value);
    }
  }

  const onFilterMenuOpen = (e, b) => {
    if(isFilterSetModified && selectedFilterSetIndex!==null && selectedFilterSetIndex!==undefined && visibleFilterSets[selectedFilterSetIndex].user_defined===true){
    // if(isFilterSetModified){
      setIsFilterSetModified(false);
      toast((t) => (
        <div className="d-flex flex-row ">
          <div>Save your modifications before switching to another filter-set, otherwise <b>they will be lost!</b></div>
          <button className="btn btn-secondary" onClick={() => toast.dismiss(t.id)}>
            Dismiss
          </button>
        </div>
      ), {duration:6000});
      // toast("Modifications will be lost if you switch to another filter-set without saving this one first!", {duration:8000});
      // toast("Save the filter-set before switching to another one if you want to keep your modifications!", {duration:8000});
      // toast((t) => (
      //   <div className="d-flex flex-row">
      //     <b>Do you want to save this <br/>filter-set?</b>
      //     <button className="btn btn-sm btn-primary my-0 ms-3 me-2" onClick={() => toast.dismiss(t.id)}>
      //       Yes
      //     </button>
      //     <button className="btn btn-sm btn-secondary my-0 ms-2 me-0" onClick={() => toast.dismiss(t.id)}>
      //       No
      //     </button>
      //   </div>
      // ), {duration:Infinity});
    }
  }


  const renderFilterList = () => {
    // const options = [ // ! it might be good to show them under groups
    //   {
    //     label: "RD-SNV",
    //     options: [
    //       { label: "Broad", value: "value_1" },
    //       { label: "Specific", value: "value_2" },
    //       { label: "Germline", value: "value_1" },
    //       { label: "Somatic", value: "value_2" }
    //     ]
    //   },
    //   {
    //     label: "Epilepsy",
    //     options: [
    //       { label: "Germline", value: "value_5" },
    //       { label: "Somatic", value: "value_6" }
    //     ]
    //   },
    //   { label: "A root option", value: "value_3" },
    //   { label: "Another root option", value: "value_4" }
    // ];
    return(
      <div className="d-flex flex-row justify-content-center">
        <div className="absolute-panel scroll-div">
          <div className="flex-center flex-row mt-1" >
            <CreatableSelect
              isClearable
              className="flex-grow-1 mx-2"
              ref={refForCreatableSelect}
              isDisabled={isLoadingVisibleFilterSets}
              isLoading={isLoadingVisibleFilterSets}
              onChange={onFilterSetSelectChange}
              // onChange={(selectedFilterSet, actionMeta) => ( (!!selectedFilterSet) ? setSelectedFilterSetIndex(selectedFilterSet.value) : null )}
              onMenuOpen={onFilterMenuOpen}
              onCreateOption={createNewFilterSet}
              options={visibleFilterSets}
              // defaultValue={visibleFilterSets[selectedFilterSetIndex || 0]}
              placeholder='Preset Filter Sets'
            />
            {/* <i className="far fa-save filter-set-button mx-2 flex-center" onClick={saveFilterSet}></i> */}
            <div className="filter-set-button mx-2 flex-center" onClick={saveFilterSet}><i className="far fa-save"></i></div>
          </div>

          <ul className="list-group small mx-1 my-1">
            {renderFilterInfo( tabs[selectedVariantTab].filters, false )}
            {/* {renderFilterInfo( Object.values(tabs[selectedVariantTab].filters).filter( t => t.visible===true), false )} */}
            {/* {renderFilterInfo( Object.keys(tabs[selectedVariantTab].filters).filter( t => tabs[selectedVariantTab].filters[t].visible===true), false )} */}
            {/* {renderFilterInfo(vanexFilterData, false )} */}
          </ul>

          {(Object.keys(globalTabFilters).length>0)
            ?
            <ul className="list-group small m-1">
              <label className="list-group-item fw-bold d-flex flex-row">
                <span className=" flex-grow-1">Global Filters:</span>
                  <React.Fragment>
                    <span className="fw-normal flex-grow-0 text-dblue cursor-pointer "
                    onClick={() => {setGlobalTabFilters({}); setSelectedPathwayIds([])}}
                      >Clear <i className="fas fa-trash-alt text-primary"></i></span>
                  </React.Fragment>
              </label>
              {renderFilterInfo(globalTabFilters, true)}
            </ul>
          : null }

          {/* //* show unmodifiable filters! */}
          <ul className="list-group small mx-1 my-1">
            {/* <label className="list-group-item fw-bold d-flex flex-row">Tab Filters:</label> */}
              { Object.values(tabs[selectedVariantTab].filters).filter( t => t.editable===false).map( (item, i) => 
              <label className="list-group-item" key={i}><div className="form-check form-switch"><input className="form-check-input me-1" type="checkbox" name={item} checked="checked" disabled  readOnly />{item.getFilterDescr()}</div></label>
              )}
              {/* <label className="list-group-item"><div className="form-check form-switch"><input className="form-check-input me-1" type="checkbox" name="gnomAD" checked="checked" disabled  readOnly />{"gnomAD frq. < 0.001"}</div></label> */}
              { filterDescriptions.map( (item, i) => 
                <label className="list-group-item" key={i}><div className="form-check form-switch"><input className="form-check-input me-1" type="checkbox" name={item} checked="checked" disabled  readOnly />{item}</div></label>
              )}
          </ul>
          {/* <ul className="list-group small m-1">
              { filterDescriptions.map( (item, i) => 
              <label className="list-group-item" key={i}><div className="form-check form-switch"><input className="form-check-input me-1" type="checkbox" name={item} checked="checked" disabled  readOnly />{item}</div></label>
            )}
          </ul> */}

          {/* <div className="flex-center add-new-filter-wrapper mt-5 mb-3">
            <div className="add-new-filter flex-center" onClick={addNewFilter}><i className="fas fa-plus fa-lg"></i></div>
          </div> */}
        </div>
      </div>
    );
  }


  const renderNotesPanel = () => {
    let message = "No notes found.";
    if(currentVariantId===null){
      message = "No variant selected";
    }

    // let noteFilterFunction = t => (t['IS_SET_BY_OTHERS'] && !!t['VANEX_NOTES']);
    // if(selectedVariantTab==='TAB_SUMMARY' && !!tabs[selectedVariantTab] && !!tabs[selectedVariantTab].filters['MAIN_B'] && !!tabs[selectedVariantTab].filters['MAIN_B'].isApplied){
    //   noteFilterFunction = t => t['IS_SET_BY_OTHERS'];
    // }

    const variant = props.caseData[currentVariantId];
    // console.log(variant.VANEX_NOTES); //! fix this, it may need to get memoized? to have optimized performace
    return(
      <div className="card m-1 shadow-sm flex-grow-1 bg-white" style={{minWidth: '10rem'}}>
        <div className="variant-card-header bg-mid-blue text-light py-0 d-flex flex-row align-items-center">
          <span className="fs-5 fw-bold flex-grow-1">Notes</span>
          {/* <div className="">
            <label>
              <input className="form-check-input" type="checkbox" 
                onChange={(e) => setShowVaNexAutoNotes(e.target.checked)} 
                checked={showVaNexAutoNotes}
              /> Auto-Notes
            </label>
          </div> */}
        </div>
        {/* <h5 className="variant-card-header bg-light-blue">Notes</h5> */}
        {/* <h5 className="variant-card-header" style={{backgroundColor:'#b9def0'}}>Notes</h5> */}
        {/* <h5 className="variant-card-header" style={{backgroundColor:'#dcf3ff'}}>Notes</h5> */}
        <div className="d-flex flex-row flex-nowrap align-items-center justify-content-center">
          <div className="m-2 d-flex flex-column w-100">
          {(!!props.isLoadingNotes)
          ?
            <div className="d-flex flex-row mt-2">
              <span className="mx-3 spinner-border spinner-border-lg" role="status" aria-hidden="true"></span><span className="fs-4">Loading...</span>
            </div>
          :
          <>
            {/* { (!!variant && !!variant.VANEX_NOTES && (!!variant.VANEX_TAGS ||  !!variant.VANEX_NOTES['CURRENT_USER_NOTE'] || ( !!variant.VANEX_NOTES['ALL_USERS_NOTES'] && !!variant.VANEX_NOTES['ALL_USERS_NOTES'].length>0))) */}
            { (!!variant && !!variant.VANEX_NOTES )
            ?
              <>
                <div className="note-bubble d-flex flex-column flex-wrap">
                  <div className="fw-bold me-1 mt-1">
                    {(!!variant.VANEX_TAGS ? 
                      <i className="fas fa-check-circle fa-lg text-primary me-2"></i>
                    : null)}
                    {props.userName}
                  </div>
                  <EditableTextArea 
                    value={variant.VANEX_NOTES['CURRENT_USER_NOTE']} 
                    updateTextAreaValue={(value)=> {
                      if(!!props.updateVariantTableData){ 
                        setShouldBlockNavigation(true);
                        props.updateVariantTableData(variant.ROW_INDEX, 'VANEX_NOTES', {
                          ...variant.VANEX_NOTES,
                          CURRENT_USER_NOTE: value, 
                          UPDATED_AT: new Date() 
                          // ALL_USERS_NOTES: variant.VANEX_NOTES['ALL_USERS_NOTES']
                        });
                      } else {toast.error('Cannot update this value!')}
                      if(isAutoSavingNotes && (autoSaveNotesCounter < autoSaveNotesInterval) ){
                        setAutoSaveNotesCounter(autoSaveNotesCounter + 1);
                      }
                    }}
                  />
                  {/* {(!!variant.VANEX_NOTES['UPDATED_AT']) ? <hr className="m-1 p-0"/> : null} */}
                  <div className="small text-end">{variant.VANEX_NOTES['UPDATED_AT'] && new Date(variant.VANEX_NOTES['UPDATED_AT']).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit', hour12: true})}</div>
                </div>
                {showVaNexAutoNotes ? <div className="note-bubble d-flex flex-column flex-wrap">
                  <div className="fw-bold me-1 mt-1">
                    VaNex Auto-Notes
                  </div>
                  <div className="note-div px-1 display-linebreak">{VaNexAutoNotes.join('\n')}</div>
                </div> : null }
              {(!!variant.VANEX_NOTES['ALL_USERS_NOTES'] && !!variant.VANEX_NOTES['ALL_USERS_NOTES'].length>0 && showOtherUsersNotes)
              ?
                variant.VANEX_NOTES['ALL_USERS_NOTES'].filter(t => t['IS_SET_BY_OTHERS']).map( (note, index) => {
                  // variant.VANEX_NOTES['ALL_USERS_NOTES'].map( (note, index) => {
                  if (!note.VANEX_NOTES && !!note.VANEX_TAGS)
                    return(
                      <div className="d-flex flex-column flex-wrap align-items-center justify-content-center w-100 mb-1" key={index}>
                        <div className="fw-bold "><i className="fas fa-check-circle fa-lg text-primary my-1 mb-2 mx-2"></i>{note.USERNAME.split('::')[0]}{note.USERNAME.split('::').length > 1 ? <div className="text-end text-small">{note.USERNAME.split('::')[1]} </div>: '' }</div>
                      </div>
                    );
                  else if (!!note.VANEX_NOTES)
                    return (
                      // <div className="plus-border-radius border-light-blue mb-2 px-2 py-1 d-flex flex-row flex-wrap" key={index}>
                      <div className="note-bubble d-flex flex-column flex-wrap" key={index}>
                        <div className="fw-bold me-1 mt-1">
                          {(!!note.VANEX_TAGS ? 
                            <i className="fas fa-check-circle fa-lg text-primary me-2"></i>
                          : null)}
                          {note.USERNAME.split('::')[0]}{note.USERNAME.split('::').length > 1 ? <div className="text-end text-small">{note.USERNAME.split('::')[1]} </div>: '' }
                        </div>
                        <div className="note-div px-1">{note.VANEX_NOTES}</div>
                        {/* <hr className="m-1 p-0"/> */}
                        <div className="small text-end">{note.UPDATED_AT && new Date(note.UPDATED_AT).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit', hour12: true})}</div>
                      </div>
                    );
                  else return( <div key={index}>?</div> );
                })
              : null}
              </>
            :
              <span className="fs-6 text-center">{message}</span>
            }
            </>
          }
          </div>
        </div>
      </div>
    )
  }

  const handleToggleColumnVisibility = (e, columnId) => {
    let hiddenCols = [...hiddenColumnsIds];
    // TODO: check if this is in the array? this is fine now, but it might be good to check if the checkbox status matches th array!
    if(e.target.checked) {
      // console.log(columnId + ' removed from hiddenColumnsIds');
      hiddenCols = hiddenCols.filter(t => t!==columnId);
      if(columnOrder.indexOf(columnId) < 0) setColumnOrder([...columnOrder, columnId]);
    }
    else {
      // console.log(columnId + ' pushed to hiddenColumnsIds');
      hiddenCols.push(columnId);
    }
    setHiddenColumnsIds(hiddenCols);
  }

  const getColumnNameFromAccessor = (columnAccessor) => {
    //!!! let colDefinition = getParameterCaseInsensitive(variantFormatDefinition['column-definitions'],columnAccessor);
    // if(!!colDefinition){
    //   let colHeader= colDefinition['header'];
    //   if(colHeader){
    //     return colHeader;
    //   } else{
    //     return columnAccessor;
    //   }
    // } else {
    //   // console.log(colDefinition, columnAccessor);
      return columnAccessor;
    // }
  }

  const changeVisibleColumnsToDefaultForExport = () => {
    let defaultExportVisibleColumns = ['VANEX_TAGS','CHROMOSOME','LOC_START','LOC_END','P_GT','VANEX_NOTES','SEG_SIZE','CNV_TYPE','SEG_MEAN','NUM_MARK','GENE_SYMBOLS','OMIM_PHENOTYPES'];  
    let hiddenCols = [];
    Object.values(columnData).forEach(column => {
      if(!defaultExportVisibleColumns.includes(column.id) &&
          !( column.id.endsWith('_GT')) // && !(include_Alt_Percent && column.id.endsWith('_Alt_Percent'))
        ){
        hiddenCols.push(column.id);
      }
    });
    setHiddenColumnsIds(hiddenCols);
  }

  const [columnNameSearchValue, setColumnNameSearchValue] = useState('');

  const renderColumnList = () => {
    const sampleColNames = Object.keys(sampleColumns).map(i => i);
    let columnList = Object.values(columnData).filter( t => t.id!=='SAMPLES' && t.id!=='ROW_INDEX' )
      .sort( (elA,elB) => {
        if(!sampleColNames.includes(elA.id) && sampleColNames.includes(elB.id)) return 1;
        if(sampleColNames.includes(elA.id) && !sampleColNames.includes(elB.id)) return -1;
        if(sampleColNames.includes(elA.id) && sampleColNames.includes(elB.id))
          return(sampleColNames.indexOf(elA.id) - sampleColNames.indexOf(elB.id));

        if(!columnOrder.includes(elA.id) && !columnOrder.includes(elB.id)) return 1;
        if(!columnOrder.includes(elA.id) && columnOrder.includes(elB.id)) return 1;
        if(columnOrder.includes(elA.id) && !columnOrder.includes(elB.id)) return -1;
        return(columnOrder.indexOf(elA.id) - columnOrder.indexOf(elB.id));
    });
    if(columnNameSearchValue!==undefined && columnNameSearchValue!==null && columnNameSearchValue.trim() !== ''){ //(columnNameSearchValue.length>0){
      columnList = columnList.filter(t => String(t['Header']).toLowerCase().includes(columnNameSearchValue.toLowerCase()));
    }
    return (
      <div className="absolute-panel scroll-div fw-normal p-2">
        <button type="button" className="btn btn-sm btn-outline-primary mx-4 my-1 px-3 py-0" onClick={changeVisibleColumnsToDefaultForExport}>Default export columns</button>
        <input className="w-100 mb-1" type="text" placeholder="Search Column Names" value={columnNameSearchValue} onChange={(e)=>setColumnNameSearchValue(e.target.value)}/>
        { columnList.map( (column, index) => (
            <div className="no-white-space-wrap" key={index}>
              { ( !!showPMFPrefix && !!sampleColumns[column.id] ?  
                ((!!getTypeOfColumnsInPMFList(column)) ? 
                  // <button type="button" className="btn btn-sm btn-outline-secondary m-0 ms-1 p-0 px-1" data-bs-toggle="modal" data-bs-target="#refForChangeSampleTypeModal">{column.sampleType}</button>
                  <button type="button" style={{minWidth:'2rem'}}
                    className="btn btn-sm btn-outline-secondary m-0 me-1 mb-1 p-0 px-1"
                    onClick={() => sampleTypeModal.show()}
                  >
                    {getTypeOfColumnsInPMFList(column)}
                  </button>
                : <span style={{marginRight:'2.25rem'}}></span>)
              : <></>)}
              <label>
                <input type="checkbox" className="me-1"
                  checked={!hiddenColumnsIds.includes(column.id)}
                  onChange={(e) => handleToggleColumnVisibility(e, column.id)}
                  disabled={(!hiddenColumnsIds.includes(column.id) && hiddenColumnsIds.length >= (Object.keys(columnData).length)-3)}
                />
                {column.Header}
              </label>
            </div>
        ))}
        {(columnNameSearchValue.length>0) ? 
          <div className="text-dblue cursor-pointer text-center" onClick={()=>setColumnNameSearchValue('')}>+ {Object.values(columnData).length - Object.values(columnList).length } unmatched (show all)</div>
        : null }
      </div>
    );
  }

  const handleTogglePathwaySelection = (e, pathwayId) => {
    let selPathways = [...selectedPathwayIds];
    // TODO: check if this is in the array? this is fine now, but it might be good to check if the checkbox status matches th array!
    if(e.target.checked) {
      // console.log(pathwayId + ' pushed to selectedPathwayIds');
      selPathways.push(pathwayId);
    }
    else {
      // console.log(pathwayId + ' removed from selectedPathwayIds');
      selPathways = selPathways.filter(t => t!==pathwayId);
    }
    setSelectedPathwayIds(selPathways);
  }

  //!!!!  const renderPathwayList = () => {
  //   let pathwayList = Object.keys(pathways)
  //   if(pathwayCategory!=='ALL')
  //     pathwayList=pathwayList.filter(t => t.includes(`(${pathwayCategory})`))

  //   pathwayList.sort( (elA,elB) => {
  //     if(!selectedPathwayIds.includes(elA) && !selectedPathwayIds.includes(elB)) return 1;
  //     if(!selectedPathwayIds.includes(elA) && selectedPathwayIds.includes(elB)) return 1;
  //     if(selectedPathwayIds.includes(elA) && !selectedPathwayIds.includes(elB)) return -1;
  //     return(selectedPathwayIds.indexOf(elA) - selectedPathwayIds.indexOf(elB));
  //   });

  //   if(pathwaySearchValue.length>0){
  //     pathwayList = pathwayList.filter(t => t.toLowerCase().includes(pathwaySearchValue.toLowerCase()));
  //   }
  //   return (
  //     <div className="absolute-panel scroll-div fw-normal p-2">
  //       <div>
  //         Database: <select value={pathwayCategory} onChange={e => {setPathwayCategory(e.target.value)}}>
  //           {["ALL","BIOCARTA", "KEGG", "PID", "REACTOME", "WP"].map(k => (
  //             <option key={k} value={k}>{(k==='WP') ? 'WikiPathways' : k}</option>
  //           ))}
  //         </select>{` (n=${pathwayList.length})`}</div>
  //       <input className="w-100 mb-1" type="text" placeholder="Search Pathways" value={pathwaySearchValue} onChange={(e)=>setPathwaySearchValue(e.target.value)}/>
  //       {(pathwaySearchValue.length>0) ? 
  //         <div className="text-dblue cursor-pointer text-center" onClick={() => setSelectedPathwayIds([...selectedPathwayIds, ...pathwayList])}>Select All Matched</div>
  //       :
  //       (selectedPathwayIds.length>0) ? 
  //         <div className="text-dblue cursor-pointer text-center" onClick={() => setSelectedPathwayIds([])}>Unselect All</div>
  //       : null }
  //       { pathwayList.map( (column, index) => (
  //           <div className="no-white-space-wrap" key={index}>
  //             <label>
  //               <input type="checkbox" className="me-1"
  //                 checked={selectedPathwayIds.includes(column)}
  //                 onChange={(e) => handleTogglePathwaySelection(e, column)}
  //               />
  //               {column}
  //             </label>
  //           </div>
  //       ))}
  //       {(pathwaySearchValue.length>0) ? 
  //         <div className="text-dblue cursor-pointer text-center" onClick={()=>setPathwaySearchValue('')}>+ {Object.keys(pathways).length - Object.keys(pathwayList).length } unmatched (show all)</div>
  //       : null }
  //     </div>
  //   );
  // }

  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  useEffect(() => {
    if (shouldBlockNavigation) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  });
  useEffect(() => {
    props.setShouldBlockNavigation(shouldBlockNavigation);
  }, [shouldBlockNavigation])


  // function sendDenovoAssessment(values, variantIndex, columnId, valueToSet){ 
    
  //   let newVals = {
  //     'PROJECT':props.project,
  //     'USERNAME':props.userName,
  //     'CASE_ID':props.currentCaseInformation.CASE_ID,
  //     'DENOVO_ASSESSMENT': values['VANEX_REVIEWS']['DENOVO_ASSESSMENT'], //DENOVOMANUAL_ASSESSMENT
  //     "CHROMOSOME": values['CHROMOSOME'],
  //     'POSITION': values['POSITION'],
  //     'REF': values['REF'],
  //     'ALT': values['ALT'],
  //     'PMF': PMFSampleColNames,
  //     'DATA':values
  //     // 'CYTOBAND_ID': values['CYTOBAND_ID'],
  //     // 'LOC_IN_GENE': values['LOC_IN_GENE'],
  //     // 'NUM_TO_SPLICE_SITE': values['NUM_TO_SPLICE_SITE'],
  //     // 'DIST_TO_SPLICE_SITE': values['DIST_TO_SPLICE_SITE'],
  //     // 'DIST_TO_CODING_REGION': values['DIST_TO_CODING_REGION'],
  //     // 'EFFECT': values['EFFECT'],
  //   };
  //   console.log(values);
  //   console.log(newVals);

  //   Auth.currentSession().then(data => {
  //     let id_token = data.getIdToken().getJwtToken();
  //     const url_to_send_to = `${ENDPOINT_BASE}collect_denovo_assessment?key=denovo_assessments/${props.currentCaseInformation.CASE_ID}.json.gz`;

  //     toast.promise(
  //       postDenovoAssessmentInformation(url_to_send_to, newVals, id_token, variantIndex, columnId, valueToSet),
  //       {
  //         loading: 'Saving...',
  //         success: (data) => <>Successfully saved<br/>{data}</>,
  //         error: (err) => {
  //           console.error(err);
  //           return(`Error: ${err}`);
  //         }
  //       },{
  //         success: {
  //           duration: 3000,
  //         },
  //       }
  //     );

  //   })
  //   .catch( e => toast.error(`Error in saving: ${e}`, {duration:8000}));
  //   // toast.success("Feedback sent!", {duration:2000});
  //   // console.log({'PROJECT':props.project, 'USERNAME':props.userName, 'CASE_ID':props.currentCaseInformation.CASE_ID,...values});
  // }

  // function postDenovoAssessmentInformation(url_to_send_to, fields, id_token, variantIndex, columnId, valueToSet) {
  //   return new Promise(function (resolve, reject) {
  //     // if (request) {  // ! ---------------------------- uncomment this back?! ?!!!!!! -------------------------
  //     //   request.abort();
  //     // }
  //     console.log(fields);
  //     let xhReq = new XMLHttpRequest();
  //     // request = xhReq;

  //     // get a callback when the server responds
  //     xhReq.addEventListener("load", (oEvent) => {
  //       if (xhReq.status===200) {
  //         // toast.success("Feedback sent!", {duration:2000});

  //         //! fix this. this is copied from below
  //         if(!!props.updateVariantTableData){ 
  //           setShouldBlockNavigation(true);
  //           props.updateVariantTableData(variantIndex, columnId, valueToSet);
  //         } else {toast.error('Cannot update this value!')}
  //         if(isAutoSavingNotes && (autoSaveNotesCounter < autoSaveNotesInterval) ){
  //           setAutoSaveNotesCounter(autoSaveNotesCounter + 1);
  //         }
          
  //         resolve(xhReq.responseText);
  //       }
  //       else {
  //         reject(xhReq.responseText);
  //       }
  //     });

  //     // TODO: handle error in these:
  //     xhReq.addEventListener("error", () => {  reject("An error occurred while transferring the file"); });
  //     xhReq.addEventListener("abort", () => { reject("Transfer has been canceled by the user"); });
  //     // xhReq.addEventListener("error", () => {  setErrorLog(['An error occurred while transferring the data','']);  });
  //     // xhReq.addEventListener("abort", () => { setErrorLog(['Transfer has been canceled by the user', '']);   });
  //     // xhReq.addEventListener("loadend",() => { setErrorLog(['Transfer finished (succeeded or not?!)'], '');   });
  //     // xhReq.addEventListener("loadstart",() => { console.error('Transfer started!');   });
      
  //     xhReq.open("POST", url_to_send_to, true);
  //     // xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
  //     xhReq.setRequestHeader('Content-Type', 'application/json');
  //     xhReq.setRequestHeader('Accept', 'application/json');
  //     xhReq.setRequestHeader('Authorization','Bearer ' + id_token);

  //     // console.log('----');
  //     // console.log(fields);
  //     xhReq.send(JSON.stringify(fields));
  //   });
  // }


  // ? maybe useMemo to make it faster? 
  // const staticCnvPlots = React.useMemo(() => {
  //   return(Object.values(props.currentAnalysisInfo.SAMPLES).map((element, index) => {
  //     return(
  //           <React.Fragment>
  //             {(!!props.caseData && !!props.caseData[currentVariantId] && !!props.caseData[currentVariantId].CHROMOSOME ? <CnvStaticPlotViewer cnv_plot_uri={element.FILE_URI} chromosome={props.caseData[currentVariantId].CHROMOSOME}/> : null )}
  //             <CnvStaticPlotViewer cnv_plot_uri={element.FILE_URI}/>
  //           </React.Fragment>
  //     );
  //   }));

  // } ,[currentVariantId]);


  const renderVariantTab = (tabId) => {
    // console.log(props.currentAnalysisInfo);
    if(!mainUseEffectsIsDone){
      return(<div className="variant-viewer-split-pane d-flex my-5 justify-content-center">
          <span className="mx-3 spinner-border" role="status" aria-hidden="true"></span>
          <span className="fs-3">Loading...</span>
        </div>)
    }

    // if(tabId ==="TAB_ALL_CNV_CALLS_MOTHER" || tabId ==="TAB_ALL_CNV_CALLS_FATHER"){
    if(tabId !=="TAB_SUMMARY" && tabId !=="TAB_ALL_CNV_CALLS_PROBAND" && tabId !=="TAB_ALL_CNV_CALLS_PROBAND_DENOVO" ){
      return(<div className="variant-viewer-split-pane mt-3 ms-5"><h4>coming soon!</h4></div>)
    }

    return(
      <div className="variant-viewer-split-pane">
        <Prompt
          when={shouldBlockNavigation}
          message='You have unsaved changes, are you sure you want to leave?'
        />
        <SplitPane split="horizontal" minSize={50} maxSize={-50} defaultSize={230}>
          {/* <div className="variant-card-body p-0 m-1 d-flex flex-column show-overflow bg-white" style={{ minHeight:'12rem', maxHeight:'20rem'}}> */}
          <div className="show-overflow absolute-panel d-flex flex-column mb-1 bg-white">

            {/* <VariantTable
    //! fix this part 
              updateVariantTableData={() => console.log('ToBeFixed!')}
              getTabName={() => console.log('selectedVariantTab=TAB_ALL_CNV_CALLS!')}
              hiddenColumnsIds={hiddenColumnsIds}
              // PMFSampleColNames={Object.keys(sampleColumns).map(i => i)}
              PMFSampleColNames={getSampleColumnsProbandAtFirst()}
              setDataForFilterSelectionModal={setDataForFilterSelectionModal}
              columns={columnData}
              data={variantData}
            /> */}

            <VariantTable
              // setSortBy={props.setSortBy}
              // initialSortBy={props.initialSortBy}
              initialSortBy={initialSortBy}
              trigerExportPreparation={trigerExportPreparation}
              preparedDataFromVariantTable={(values) => {setPreparedDataFromVariantTable(values); setTrigerExportPreparation(false)}}
              updateVariantTableData={(variantIndex, columnId, value) => { 
                if(!!props.updateVariantTableData){ 
                  setShouldBlockNavigation(true);
                  props.updateVariantTableData(variantIndex, columnId, value);
                } else {toast.error('Cannot update this value!')}
                if(isAutoSavingNotes && (autoSaveNotesCounter < autoSaveNotesInterval) ){
                  setAutoSaveNotesCounter(autoSaveNotesCounter + 1);
                }
              }}
              getTabName={() => selectedVariantTab}
              // getCaseID={() => props.currentCaseInformation.CASE_ID}
              // sendFeedback={sendDenovoAssessment}
              setColumnsSpec={(colId, colWidth) => Object.values(columnData).find(t => t.id===colId).setColWidth(colWidth) }
              hiddenColumnsIds={hiddenColumnsIds}
              reportCurrentPageIndex={(pind) => setLatestTablePageIndex(pind)}
              initialPageIndex={latestTablePageIndex}
              setDataForFilterSelectionModal={setDataForFilterSelectionModal}
              // PMFSampleColNames={Object.keys(sampleColumns).map(i => i)}
              PMFSampleColNames={getSampleColumnsProbandAtFirst()}
              currentVariantId={currentVariantId} 
              displayVariantInfo={ (variantId) => setCurrentVariantId(variantId)}
              variantFilterObj={ {...tabs[selectedVariantTab].filters, ...globalTabFilters} }
              columns={columnData} data={variantData}
            />
          </div>
          <div className="inner-tabs-wrapper">
            <Tabs>
              <TabList>
                <Tab><span>CNV Info</span></Tab>
                <Tab><span>Dynamic Plots</span></Tab>
                { Object.values(props.currentAnalysisInfo.SAMPLES).sort( (elA,elB) => (GetSampleRank(elB.SAMPLE_TYPE) - GetSampleRank(elA.SAMPLE_TYPE)) ).map((element, index) => 
                  <Tab key={index}><span>St. Plot ({element.SAMPLE_TYPE})</span></Tab> )}
                {/* <Tab><span>Tracks</span></Tab> */}
              </TabList>
              <TabPanel>{renderVariantInfo()}</TabPanel>
              {/* <TabPanel><h1>Dynamic Plots</h1></TabPanel> */}
              <TabPanel>
                {(!!selectedSegment && !!selectedSegment.CHROMOSOME) ?
                  <CnvPlotter
                    // segments_data={memoizedSegmentsData} 
                    plotInfileData={plotInfileData[`chr${selectedSegment.CHROMOSOME}`]}
                    plotSegmentsData={plotSegmentsData[`chr${selectedSegment.CHROMOSOME}`]}
                    selectedSegment={selectedSegment}
                    // chromosome={selectedSegment.CHROMOSOME}
                  />
                :
                  <div className="d-flex overflow-auto flex-column flex-nowrap align-items-center justify-content-center mt-4">
                    <h3>No variant selected</h3>
                  </div>
              }
              </TabPanel>
              {Object.values(props.currentAnalysisInfo.SAMPLES).map((element, index) => 
                <TabPanel key={index}>
                  <div className="bottom-var-info-panel d-flex flex-column">
                    <div className="d-flex overflow-auto flex-column flex-nowrap">
                      {/* {(!!props.caseData && !!props.caseData[currentVariantId] && !!props.caseData[currentVariantId].CHROMOSOME ? <CnvStaticPlotViewer cnv_plot_uri={element.FILE_URI} chromosome={props.caseData[currentVariantId].CHROMOSOME}/> : null )} */}
                      {/*///!!! maybe good to make sure this is the correct path?! if we have it stored somewhere we need to pull it from that location  */}
                      {(!!selectedSegment && !!selectedSegment.CHROMOSOME ? <CnvStaticPlotViewer cnv_plot_uri={`${props.currentAnalysisInfo.PARQUET_URI}/${element.SAMPLE_ID}`} chromosome={selectedSegment.CHROMOSOME}/> : null )}
                      <CnvStaticPlotViewer cnv_plot_uri={`${props.currentAnalysisInfo.PARQUET_URI}/${element.SAMPLE_ID}`}/>
                      {/* currentAnalysisInfo={props.currentAnalysisInfo}  cnv_plot_uri={element.FILE_URI} */}
                      {/* {staticCnvPlots[index]} */}
                    </div>
                  </div>
                </TabPanel> )}
              {/* <TabPanel><h1>Tracks</h1></TabPanel> */}
            </Tabs>
          </div>
        </SplitPane>
      </div>
    );
  }



  const renderFiltersAndColumnsTabs = () => {
    return(
      <Tabs>
        <TabList>
          <Tab><span>Filters</span></Tab>
          <Tab><span>Columns</span></Tab>
          {/* <Tab><span>Pathways</span></Tab> */}
        </TabList>
        <TabPanel>{renderFilterList()}</TabPanel>
        <TabPanel>{renderColumnList()}</TabPanel>
        {/* <TabPanel>{renderPathwayList()}</TabPanel> */}
      </Tabs>
    );
  }

  const onSelectedTabChange = (index, lastIndex, event) => {
    // console.log(event.target.innerText);
    if(!!refForCreatableSelect.current){
      refForCreatableSelect.current.state.value = null;
    }
    setLatestTablePageIndex(0);
    setCurrentVariantId(null);
    // setNotesForCurrentVariant([]);
    setSelectedVariantTab(Object.keys(tabs)[index]);
    setSelectedFilterSetIndex(null);
  }

  const isNumericColumn = (columnId) => {
    let tmpList = props.caseData.filter(t => t[columnId]!==null);
    if(!!tmpList[0]){
      if(tmpList[0][columnId]!==undefined && tmpList[0][columnId]!==null){
        return(typeof(tmpList[0][columnId])=='number');
      }
    }
    return false;
  }

  const review_code_to_text = (revCode, returnNullForNotFound=false, returnLowerCase=false) => {
    let tmpOption = Object.values(ReviewOptions).find(l => l.value===revCode);
    // let tmpOption = Object.values(ReviewOptions).find(l => String(l.value).toLowerCase() === String(revCode).toLowerCase());
    if(!!tmpOption && !!tmpOption.label){
      if(returnLowerCase)
        return(String(tmpOption.label).toLowerCase());
      else
        return(tmpOption.label);
    } else {
      return( (returnNullForNotFound ? null : '' ) );
    }
  }

  const setDataForFilterSelectionModal = (e, selectedColumn) => {
    // console.log("---------", selectedColumn);
    // console.log(isNumericColumn(selectedColumn.id));
    if(selectedColumn.id==='VANEX_TAGS'){
      toast.error('Cannot filter '+selectedColumn.Header);
      return;
    }
    setFilterSelectionTitle(`Filtering ${selectedColumn.Header}`);
    let initValues = { columnName:selectedColumn.id, value:'', valueIsList:false, separateValueListBy:' ' , minValue:'', maxValue:'', includeNullValues:true, selTerms:[], filterBy:"UserProvidedValue", selectAllStatus:UNCHECKED }

    // let initValues = { value: 'z', minValue: 0, maxValue:0 }
    // setFilterSelectionFormik(<Formik className="" enableReinitialize={true} initialValues={initValues}
    // onSubmit={(fields) => {
    //     console.log(fields);
    //     filterSelectionModal.hide();
    //   }}>
    //     {({ values, setFieldValue }) => (
    //     <Form id={}>
    //       {/* { console.log(values)} */}
    //       {/* <CustomerPopup
    //           showModal={showModal}
    //           close={() => toggleCustomerModal()}
    //           onSelect={data => onSelectCustomerHandler(data, setFieldValue)}
    //         /> */}
    //       <div className="modal-body">
    //         <Field className="form-control" type="text" name="minValue" placeholder="" aria-label=""/>
    //         <Field className="form-control" type="text" name="maxValue" placeholder="" aria-label=""/>
    //       </div>
    //     <div className="modal-footer ">
    //       <button className="btn btn-primary" type="submit" ><i className="fas fa-filter me-2"></i> Apply</button>
    //       <button type="button" className="btn btn-secondary" onClick={() => filterSelectionModal.hide()}>Cancel</button>
    //     </div>
    //     </Form>)}
    //   </Formik>);
    //   filterSelectionModal.show();
    // return;

    if(isNumericColumn(selectedColumn.id)){
      //? ------------------- filtering column with NUMERIC values  ---------------------------

      if (!!globalTabFilters[selectedColumn.id]){
        if (!!globalTabFilters[selectedColumn.id]["minValue"] && !!globalTabFilters[selectedColumn.id]["maxValue"]){
          initValues["minValue"] = globalTabFilters[selectedColumn.id]["minValue"];
          initValues["maxValue"] = globalTabFilters[selectedColumn.id]["maxValue"];
        }
      }

      const getFilterDescriptionValue = (minValue, maxValue, includeNullValues) =>{
        let hasMinValue = (!!minValue || minValue===0);
        let hasMaxValue = (!!maxValue || maxValue===0);
        let nullValueDesc = ( includeNullValues ? " (Include Blanks)" : "" )
        if( hasMinValue && !hasMaxValue)
          return(` >= "${minValue}"${nullValueDesc}`);
        if( !hasMinValue && hasMaxValue)
          return(` <= "${maxValue}"${nullValueDesc}`);
        if( !hasMinValue && !hasMaxValue)
          return(`${nullValueDesc}`);
        return(` = "${minValue} to ${maxValue}"`);
      }

      setFilterSelectionFormik(
        <Formik className="" enableReinitialize={true} initialValues={initValues} onSubmit={(fields) => {
          // console.log(fields);
          setGlobalTabFilters(
            prevGlobalTabFilters => ({
              ...prevGlobalTabFilters,
              [selectedColumn.id]: {
                columnId: selectedColumn.id,
                label: `${selectedColumn.Header}`,
                value: getFilterDescriptionValue(fields.minValue, fields.maxValue, fields.includeNullValues),
                minValue: fields.minValue,
                maxValue: fields.maxValue,
                operation: '=',
                visible: true,
                editable: true,  // tabFilter: false,
                getFilterDescr: function(){ return(this.label + this.value)},
                // getFilterDescr: function(){ return(this.label + ' ' + this.operation + ' ' + this.value); },
                filterFunction: function(){
                  let hasMinValue = (!!fields.minValue || fields.minValue===0);
                  let hasMaxValue = (!!fields.maxValue || fields.maxValue===0);
                  if( hasMinValue && !hasMaxValue)
                    return( (t) => ( (t[selectedColumn.id]!==null && t[selectedColumn.id]!==undefined) ? (t[selectedColumn.id] >= fields.minValue ) : ( fields.includeNullValues ? true : false) ) );
                  if( !hasMinValue && hasMaxValue)
                    return( (t) => ( (t[selectedColumn.id]!==null && t[selectedColumn.id]!==undefined) ? (t[selectedColumn.id] <= fields.maxValue ) : ( fields.includeNullValues ? true : false) ) );
                  if( !hasMinValue && !hasMaxValue)
                    return( (t) => ( (t[selectedColumn.id]!==null && t[selectedColumn.id]!==undefined) ? false : ( fields.includeNullValues ? true : false) ) );
                  return( (t) => ( (t[selectedColumn.id]!==null && t[selectedColumn.id]!==undefined) ? ( (t[selectedColumn.id] >= fields.minValue ) && (t[selectedColumn.id] <= fields.maxValue )) : ( fields.includeNullValues ? true : false) ) );
                },
                isApplied: true
              }
            })
          );
          filterSelectionModal.hide();
        }}>
          <Form>
          <div className="modal-body">
            <table className="">
              <tbody>
                <tr>
                  <td>
                    <label className=""><br/>{selectedColumn.Header}</label> 
                  </td>
                  <td>
                    <label className=""><br/><i className="fas fa-equals"></i></label> 
                  </td>
                  <td>
                    <label className="form-check-label">
                      Min
                      <Field className="form-control" type="text" name="minValue" placeholder="" aria-label=""/>
                    </label>
                  </td>
                  <td>
                    <label className="form-check-label">
                      Max
                      <Field className="form-control" type="text" name="maxValue" placeholder="" aria-label=""/>
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
            <label className="mt-2 w-100 d-flex flex-column ms-1 align-items-center">
              <div>
                <Field className="mx-1" type="checkbox" name="includeNullValues" placeholder="" aria-label=""/>
                Include Blanks
              </div>
            </label>
          </div>
          <div className="modal-footer ">
            <button className="btn btn-primary" type="submit" ><i className="fas fa-filter me-2"></i>Apply Filter</button>
            <button type="button" className="btn btn-secondary" onClick={() => filterSelectionModal.hide()}>Cancel</button>
          </div>
          </Form>
        </Formik>
      );
    }
    else {
      //? ------------------- filtering column with TEXT values ---------------------------
      
      if (!!globalTabFilters[selectedColumn.id]){
        // debugger;
        if (!!globalTabFilters[selectedColumn.id]["value"]){
          initValues["value"] = globalTabFilters[selectedColumn.id]["value"];
        }
        if (!!globalTabFilters[selectedColumn.id]["filterBy"]){
          initValues["filterBy"] = globalTabFilters[selectedColumn.id]["filterBy"];
        }
        if (!!globalTabFilters[selectedColumn.id]["selTerms"]){
          initValues["selTerms"] = globalTabFilters[selectedColumn.id]["selTerms"];
        }
      }

      //? ------------------- collect unique values in an array ---------------------------
      let tmpObj = {}
      let tmpval;
      // Object.values(props.caseData).forEach((variantRecord) => {
      Object.values(variantData).forEach((variantRecord) => {
        // tmpObj[variantRecord[selectedColumn.id]] = ((!!variantRecord[selectedColumn.id]) ? variantRecord[selectedColumn.id] : "__Blank__");
        if(selectedColumn.id==='VANEX_NOTES'){
          tmpObj[variantRecord[selectedColumn.id]['CURRENT_USER_NOTE']] = variantRecord[selectedColumn.id]['CURRENT_USER_NOTE'];
        } else if(selectedColumn.id==='VANEX_REVIEWS'){
          tmpObj[variantRecord[selectedColumn.id]['REVIEW_VALUE']] = null;
          tmpval = Object.values(ReviewOptions).find(l => l.value === variantRecord[selectedColumn.id]['REVIEW_VALUE']);
          // console.log(tmpval);
          if(!!tmpval && !!tmpval.label){
            tmpObj[variantRecord[selectedColumn.id]['REVIEW_VALUE']] = tmpval.label;
          }
        } else {
          tmpObj[variantRecord[selectedColumn.id]] = variantRecord[selectedColumn.id];
        }
      });
      let selColUniqueValues = Object.values(tmpObj).map( k => k).sort().sort( (elA,elB) => ( Number(!elA) - Number(!elB)) );
      // console.log(selColUniqueValues);

      setFilterSelectionFormik(
        <Formik className="" enableReinitialize={true} initialValues={initValues} onSubmit={(fields) => {
          //! Equals, Does not equal, Contains, Does not contain, Begins with, Ends with, 
          // console.log(fields);
          let operation = "contains";
          // let useSelTerms = fields.filterBy === 'SelectedTerms';
          let myFilterFunction;
          if(fields.filterBy === 'SelectedTerms' || fields.valueIsList===true){
            operation = "=";
            let selectedTerms;
            let multiValuesProvided = (fields.valueIsList===true && fields.filterBy!=='SelectedTerms');
            if(multiValuesProvided){
              // selectedTerms = fields.value.split(fields.separateValueListBy);
              selectedTerms = String(fields.value).toLowerCase().split(fields.separateValueListBy);
            } else {
              selectedTerms = fields.selTerms;
            }
            // let selectedTerms = fields.selTerms.map( (term) => {
            //   if (term==='__Blank__'){
            //     return(null);
            //   }
            //   else{
            //     return(term);
            //   }
            // });
            // let includeNullValuesInFilter = fields.selTerms.includes("__Blank__");
            let includeNullValuesInFilter = (fields.selTerms.includes(null) || fields.selTerms.includes(''));
            myFilterFunction = function(){
              //* if multi values are provided as a list use toLowerCase first before comparing! otherwise they need to match exactly (it is case sensitive)
              if(selectedColumn.id==='VANEX_NOTES'){
                if(multiValuesProvided)
                  return( (t) => ( (!!t[selectedColumn.id]) ? ( selectedTerms.indexOf(String(t[selectedColumn.id]['CURRENT_USER_NOTE']).toLowerCase()) !== -1) : includeNullValuesInFilter ) );
                else
                  return( (t) => ( (!!t[selectedColumn.id]) ? ( selectedTerms.indexOf(t[selectedColumn.id]['CURRENT_USER_NOTE']) !== -1) : includeNullValuesInFilter ) );
              } else if(selectedColumn.id==='VANEX_REVIEWS'){
                // if(fields.valueIsList===true)
                //   selectedTerms = String(fields.value).toLowerCase().split(fields.separateValueListBy);
                return( (t) => ( (!!t[selectedColumn.id]) ? ( selectedTerms.indexOf(review_code_to_text(t[selectedColumn.id]['REVIEW_VALUE'], true, multiValuesProvided )) !== -1) : includeNullValuesInFilter ) );
              } else { 
                if(multiValuesProvided)
                  return( (t) => ( (!!t[selectedColumn.id]) ? ( selectedTerms.indexOf(String(t[selectedColumn.id]).toLowerCase()) !== -1) : includeNullValuesInFilter ) );
                else
                  return( (t) => ( (!!t[selectedColumn.id]) ? ( selectedTerms.indexOf(t[selectedColumn.id]) !== -1) : includeNullValuesInFilter ) );
              }
            }
          } else {
            myFilterFunction = function(){
              if(selectedColumn.id==='VANEX_NOTES'){
                return( (t) => ( (!!t[selectedColumn.id]) ? String(t[selectedColumn.id]['CURRENT_USER_NOTE']).toLowerCase().includes(fields.value.toLowerCase()) : false ));
              } else if(selectedColumn.id==='VANEX_REVIEWS'){
                return( (t) => ( (!!t[selectedColumn.id]) ? String(review_code_to_text(t[selectedColumn.id]['REVIEW_VALUE'], false)).toLowerCase().includes(fields.value.toLowerCase()) : false ));
                // return( (t) => ( (!!t[selectedColumn.id]) ? false : false ));
              } else {
                return( (t) => ( (!!t[selectedColumn.id]) ? String(t[selectedColumn.id]).toLowerCase().includes(fields.value.toLowerCase()) : false ));
                // return( (t) => {
                //   if(!!t[selectedColumn.id])
                //     console.log(String(t[selectedColumn.id]));
                //   return(true)
                //   // return( (!!t[selectedColumn.id]) ? t[selectedColumn.id].toLowerCase().includes(fields.value.toLowerCase()) : false )
                // });
              }
            }
          }

          setGlobalTabFilters(
            prevGlobalTabFilters => ({
              ...prevGlobalTabFilters,
              [selectedColumn.id]: {
                columnId: selectedColumn.id,
                label: `${selectedColumn.Header}`,
                value: fields.value,
                operation: `${operation}`,
                visible: true,
                editable: true,  // tabFilter: false,
                selTerms: fields.selTerms,
                filterBy: fields.filterBy,
                getFilterDescr: function(){
                  let fieldValue = `"${this.value}"`;
                  if(this.filterBy === 'SelectedTerms'){
                    fieldValue = `[${this.selTerms.join('], [')}]`;
                    // if(this.selTerms.length > 3){fieldValue = "[...]";}
                    // return(`"${fieldValue}"`)
                  }
                  return(this.label + ' ' + this.operation + ' ' + fieldValue);
                },
                filterFunction: myFilterFunction,
                isApplied: true
              }
            })
          ); 

          filterSelectionModal.hide();
        }}>
          {({ errors, values, touched, setValues }) => (
            <Form>
            <div className="modal-body">
              <div className="d-flex flex-row w-100">
                <div className="d-flex flex-column flex-grow-1">
                  <label className="">
                    <Field name="filterBy" className="form-check-input me-2" type="radio" value="UserProvidedValue" placeholder="" aria-label=""/>
                    {selectedColumn.Header}<span className="fw-bold mx-1">Contains</span>
                  </label>
                  <label className="form-check-label flex-grow-1">
                    <Field className="form-control w-100" type="text" name="value" placeholder="" aria-label=""
                    onFocus={e => { values.filterBy ='UserProvidedValue'; }}
                    />
                  </label>
                </div>
                <label className="d-flex flex-row ms-1 align-items-center flex-grow-0">
                  <Field className="mx-1" name="valueIsList" type="checkbox" />
                  <div className={(values.valueIsList ? '' : 'text-muted')}>
                    multiple values <br/>separated by 
                    <Field className="mx-1" disabled={!values.valueIsList} name="separateValueListBy" type="text" style={{maxWidth:'1.5rem'}}/>
                  </div>
                </label>
              </div>
              <hr/>
              <label className="">
                <Field name="filterBy" className="form-check-input me-2" type="radio" value="SelectedTerms" placeholder="" aria-label=""/>
                  {selectedColumn.Header}<span className="fw-bold mx-1">matches</span>
              </label>
              <div className="ist-group list-group-flush border border-secondary border-1 m-1 show-overflow l" style={{maxHeight:'40vh'}}>
                <label className="list-group-item">
                  <IndeterminateCheckbox
                    className="form-check-input me-1"
                    name="select-all"
                    value={values.selectAllStatus}
                    onFocus={e => { values.filterBy ='SelectedTerms'; }}
                    onChange={e => {
                      let chboxStatus = INDETERMINATE;
                      let selectedTerms = [];
                      if (e.target.checked) {
                        selColUniqueValues.forEach( (term) => selectedTerms.push(term));
                        chboxStatus = CHECKED;
                      } else {
                        chboxStatus = UNCHECKED;
                      }
                      setValues({ ...values, 
                        selectAllStatus: chboxStatus,
                        selTerms: selectedTerms 
                      });
                    }}
                  />
                  Select All
                </label>
                <FieldArray
                  name="selTerms"
                  render={arrayHelpers => (
                    <React.Fragment>
                      {selColUniqueValues.map( (tag, ind) => (
                        <label className="list-group-item" key={ind}>
                          <input
                            className="form-check-input me-1"
                            type="checkbox"
                            name={(tag!==null && tag!==undefined && tag!=='') ? tag.toString() : "__Blank__"}
                            checked={values.selTerms.includes(tag)}
                            onFocus={e => { values.filterBy ='SelectedTerms'; }}
                            onChange={e => {
                              let chboxStatus = INDETERMINATE;
                              let selectedTerms = [...values.selTerms];
                              if(values.selTerms.length === 0){
                                chboxStatus = UNCHECKED;
                              }
                              if (e.target.checked) {
                                if(values.selTerms.length === 0){
                                  chboxStatus = INDETERMINATE;
                                }
                                if(selColUniqueValues.length === (values.selTerms.length+1) ){
                                  chboxStatus = CHECKED;
                                }
                                // arrayHelpers.push(tag);
                                selectedTerms.push(tag);
                              } else {
                                if(values.selTerms.length-1 === 0){
                                  chboxStatus = UNCHECKED;
                                }
                                const removeIndex = values.selTerms.indexOf(tag);
                                // arrayHelpers.remove(removeIndex);
                                // let removeIndex = selectedTerms.map(item => item.id).indexOf(tag);
                                ~removeIndex && selectedTerms.splice(removeIndex, 1);  //! <= this is a nice way of doing this: (removeIndex >= 0) && array.splice(removeIndex, 1);
                              }
                              setValues({ ...values, 
                                selectAllStatus: chboxStatus,
                                selTerms: selectedTerms 
                              });
                            }}
                          />
                          {/* <span>{ (typeof(tag)==='boolean' ? ( (tag) ? 'Checked' : 'Unchecked') : (!!tag ? tag : "__Blank__") )}</span> */}
                          {/* <span>{(!!tag) ? (typeof(tag)==='boolean' ? 'Checked' : tag ) : "__Blank__"}</span> */}
                          <span>{(tag!==null && tag!==undefined && tag!=='') ? tag : "__Blank__"}</span>
                        </label>
                      ))}
                    </React.Fragment>
                  )}
                />
              </div>
            </div>
            <div className="modal-footer ">
              <button className="btn btn-primary" type="submit" ><i className="fas fa-filter me-2"></i>Apply Filter</button>
              <button type="button" className="btn btn-secondary" onClick={() => filterSelectionModal.hide()}>Cancel</button>
            </div>
            </Form>)}
          </Formik>
      );
    }
    filterSelectionModal.show();
    return;
  }

  function handleSampleTypeChange(fields) {
    if(
      ((fields.Proband !== 'None')  && (fields.Mother !== 'None')  && (fields.Father !== 'None') &&
      (fields.Proband !== fields.Mother) && (fields.Mother !== fields.Father)  && (fields.Proband !== fields.Father) 
      // && (fields.Proband !== '')  && (fields.Mother !== '')  && (fields.Father !== '')
      )
    ||
      ((fields.Proband !== 'None')  && (fields.Mother !== 'None')  && (fields.Father === 'None') &&
      (fields.Proband !== fields.Mother) 
      )
    ||
      ((fields.Proband !== 'None')  && (fields.Mother === 'None')  && (fields.Father !== 'None') &&
      (fields.Proband !== fields.Father) 
      )
    ||
      ((fields.Proband !== 'None')  && (fields.Mother === 'None')  && (fields.Father === 'None')
      )
    ){
      setPMFSampleColNames([fields.Proband, fields.Mother, fields.Father]);
      const sampleColumnNames = Object.keys(sampleColumns).map(i => i);
      if(!!sampleColumnNames && sampleColumnNames.length>0){
        setInitialColumnData( prevColumnData => prevColumnData.map( colInfo => {
          // let colHeader=colInfo.Header;
          let colAccessor = colInfo.id;
          if(sampleColumnNames.includes(colInfo.id))
            return({...colInfo, Header: getTypeOfColumnsInPMFList(colInfo,false, true, [fields.Proband, fields.Mother, fields.Father]) + colInfo.id })
            // colHeader=getTypeOfColumnsInPMFList(colInfo,false, true, [fields.Proband, fields.Mother, fields.Father]) + colInfo.id;
          if( colAccessor.endsWith("_GT") || colAccessor.endsWith('_Alt_Percent')  || colAccessor.endsWith('_GQ') || colAccessor.endsWith('_AD') || colAccessor.endsWith('_RD') ||
              colAccessor.endsWith('_TD') || colAccessor.endsWith('_PL') || colAccessor.endsWith('_DP') || colAccessor.endsWith('_SB') || colAccessor.endsWith('_GT_RAW') )
            // colHeader=colAccessor;//`${getSampleLabel(colAccessor.slice(0,-3))}: ${colAccessor}`;
            return({...colInfo, Header: colAccessor});
          return(colInfo)
        }));
      }
      toast.success('Success!'); // toast.success('SUCCESS!! :-)\n\n' + JSON.stringify(fields, null, 4));
      setRefresh(prevState => !prevState);
      sampleTypeModal.hide();
    }
    else {
      toast.error("Not a valid combination!")
    }
  }


  //? --------------------------------------------- Main Render ----------------------------------
  // console.log("render...");
  // console.log(tabs);
  // console.log(selectedFilterSetIndex);



//   return (
//     <div className="">
//       <div className="variant-card-body p-0 m-1 d-flex flex-column show-overflow bg-white" style={{ minHeight:'12rem', maxHeight:'20rem'}}>
//         <VariantTable
//           // initialSortBy={props.initialSortBy}
// //! fix this part 
//           updateVariantTableData={() => console.log('ToBeFixed!')}
//           getTabName={() => console.log('selectedVariantTab=TAB_ALL!')}
//           // hiddenColumnsIds={hiddenColumnsIds}
//           // PMFSampleColNames={Object.keys(sampleColumns).map(i => i)}
//           // PMFSampleColNames={getSampleColumnsProbandAtFirst()}
//           columns={columnData}
//           data={props.caseData}
//         />
//       </div>
//     </div>
//   );


 


  return (
    <React.Fragment>
      <SplitPane className={" bottom-tab-panel " + ( (props.onlyShowOneTab) ? " plus-border-radius " : "" )}
        split="vertical" minSize={100} maxSize={-100} defaultSize={280} >
        {(!!props.currentCaseNotes) ?
          <div className="variant-viewer-split-pane">
            <SplitPane split="horizontal" minSize={50} maxSize={-50} defaultSize={475} >
              <div className="show-overflow absolute-panel d-flex flex-column mb-1 sidebar-splitpane-panel">
                {renderFiltersAndColumnsTabs()}
              </div>
              <div className="show-overflow absolute-panel d-flex flex-column mb-1 sidebar-splitpane-panel">
                {renderNotesPanel()}
              </div>
            </SplitPane>
          </div>
        :
          <div className="sidebar-splitpane-panel">
            {renderFiltersAndColumnsTabs()}
          </div>
        }
        <div className="sidebar-splitpane-panel absolute-panel">
          <Tabs defaultIndex={Object.keys(tabs).indexOf(selectedVariantTab)} onSelect={onSelectedTabChange} >
            <TabList style={{paddingRight:'7rem'}}>
              {Object.values(tabs).map( (tabName, index) => <Tab key={index}><span>{tabName.label}</span></Tab>)}
              <div className="tab-menu-button-group d-flex flex-row ps-1 pt-1 align-items-center " >
                {/* <Dropdown as={ButtonGroup} size="sm" className="m-0 p-0">
                  <Button variant=""  className="m-0 p-0 text-light">
                  
                  <div className="m-0 ms-1 p-0 px-1 tab-menu-button cursor-pointer" onClick={saveNotes}>
                      <i className="fas fa-file-alt fs-6"></i><span className="fs-6 fw-bold ms-1 p-0">Save Notes</span>
                      {(isSavingNotes) ? 
                      <div className="progress export-progressbar">
                        <div className="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" style={{width:`${savingNotesProgressbarValue}%`}} aria-valuenow={savingNotesProgressbarValue} aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      : null}
                    </div>

                  </Button>

                  <Dropdown.Toggle split variant="" className="text-light" id="dropdown-split-basic" />

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
                {/* <span className="ms-1 " style={{cursor:'default'}}>Started at: <span className="fw-bold">{analysisStartedAt}</span></span> */}
                {(canSaveNotes)
                ?
                  <>
                    <div className="m-0 ms-1 p-0 px-1">
                      <label className="fs-6" >
                        <input className="mx-1" type="checkbox" name="use1000separatorcheckbox"
                          onChange={(e) => {setUse1000Separator(e.target.checked)}}
                          checked={use1000Separator} 
                        />
                        Use 1000 Separator
                      </label>
                    </div>
                    <div className="m-0 ms-1 p-0 px-1 tab-menu-button cursor-pointer" onClick={saveNotes}>
                      {(isSavingNotes) ? 
                        <>
                          <span className="mx-2 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          {/* <i className="fas fa-circle fs-6"></i> */}
                          <span className="fs-6 fw-bold ms-1 p-0">Saving...</span>
                          <div className="progress export-progressbar">
                            <div className="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" style={{width:`${savingNotesProgressbarValue}%`}} aria-valuenow={savingNotesProgressbarValue} aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </>
                      :
                        (numberOfNotesSaved!==null && numberOfNotesSaved!==undefined) ? 
                          <>
                            <i className="fas fa-check-double green-lighter fs-6"></i>
                            <span className="fs-6 fw-bold ms-1 p-0">{numberOfNotesSaved} Notes Saved!</span>
                            {/* // TODO: if added review to CNV, bring this message back! <span className="fs-6 fw-bold ms-1 p-0">{numberOfNotesSaved} Notes &amp; {numberOfReviewSaved} Review Saved!</span> */}
                          </>
                        :
                          <>
                            <i className="fas fa-file-alt fs-6"></i><span className="fs-6 fw-bold ms-1 p-0">Save Notes</span>
                          </>
                      }
                    </div>
                    <Dropdown align="end" onSelect={notesOptions}>
                      <Dropdown.Toggle variant="" id="notesOptions-dropdown" className="tab-menu-button dropdown-toggle m-0 p-0 py-2 px-1 d-flex flex-row align-items-center">
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="export-menu m-0" >
                        {/* <Dropdown.Item as={() => (<div className=""><input type="checkbox" className="mx-2" checked={isAutoSavingNotes}  readOnly />Auto Save Notes</div>)
                        } eventKey="summary"></Dropdown.Item> */}
                        <Dropdown.Item as="button" eventKey="hideOtherUsersNotes"><div className=""><input type="checkbox" className="mx-2" checked={!showOtherUsersNotes}  readOnly />Hide Other Users' Notes</div></Dropdown.Item>
                        <Dropdown.Item as="button" eventKey="autoSavingNotes" ><div className=""><input type="checkbox" className="mx-2" checked={isAutoSavingNotes}  readOnly />Auto Save Notes</div></Dropdown.Item>
                        <Dropdown.Item as={() => (<div className={`ms-5 d-flex ${(!isAutoSavingNotes) ? 'text-muted' :'' }`}>
                          - every<input type="number" 
                            min="1" step="1" 
                            className="mx-2" style={{maxWidth:'2.5rem', maxHeight:'1.6rem'}}
                            disabled={!isAutoSavingNotes}
                            defaultValue={autoSaveNotesInterval}
                            onChange={e => setAutoSaveNotesInterval(e.target.value)}
                          />
                          </div>)
                        } eventKey="summary"></Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                : null }
                <Dropdown align="end " onSelect={exportSnvAsXlsx}>
                  <Dropdown.Toggle variant="" id="export-dropdown" className="tab-menu-button m-0 ms-1 p-0 px-2 d-flex flex-row align-items-center">
                    <div>
                      <i className="fas fa-download fs-5"></i><span className="fs-6 fw-bold mx-1 p-0">Export</span>
                      {(isExporting) ? 
                      <div className="progress export-progressbar">
                        <div className="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" style={{width:`${exportProgressbarValue}%`}} aria-valuenow={exportProgressbarValue} aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      : null}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="export-menu m-0" >
                    <Dropdown.Item as="button" eventKey="clipboard">to Clipboard</Dropdown.Item>
                    <Dropdown.Item as="button" eventKey="summary">Summary Tab Only</Dropdown.Item>
                    {/* <Dropdown.Item as="button" eventKey="noOriginalData">Exclude 'All SNVs' Tab</Dropdown.Item>
                    <Dropdown.Item as="button" eventKey="all">Complete Dataset</Dropdown.Item> */}
                    {/* <Dropdown.Item as="button" eventKey="all" onClick={() => exportSnvAsXlsx('all')} >Complete Dataset</Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>

                {/* <span onClick={exportSnvAsXlsx}>
                  <i className="fas fa-download fs-5"></i><span className="fs-5 fw-bold mx-1 p-0">Export</span>
                  {(isExporting) ? 
                  <div className="progress export-progressbar">
                    <div className="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" style={{width:`${exportProgressbarValue}%`}} aria-valuenow={exportProgressbarValue} aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  : null}
                </span> */}
              </div>
            </TabList>
            {Object.keys(tabs).map( (tabId, indx) => <TabPanel key={indx}>{renderVariantTab(tabId)}</TabPanel>)}
          </Tabs>
        </div>
      </SplitPane>
      {/* //? --------------------------------------------- Modal ---------------------------------- */}
      <div className="modal fade" ref={refForFilterSelectionModal} tabIndex="-1" aria-labelledby="refForFilterSelectionModal" aria-hidden="true">
        {/* <div className="modal-dialog modal-sm"> */}
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{filterSelectionTitle}</h5>
              <button type="button" className="btn-close" onClick={() => filterSelectionModal.hide()} aria-label="Close"></button>
            </div>
            {filterSelectionFormik}
          </div>
        </div>
      </div>
      {/* END Modal */}

      {/* //? --------------------------------------------- SampleType Modal ---------------------------------- */}
      <div className="modal fade" ref={refForChangeSampleTypeModal} tabIndex="-1" aria-labelledby="changeSampleTypeLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="changeSampleTypeLabel">Change Sample Types</h5>
              <button type="button" className="btn-close" onClick={() => sampleTypeModal.hide()}  aria-label="Close"></button>
            </div>
            <Formik className=""
              enableReinitialize={true}
              initialValues={{ Proband: PMFSampleColNames[0] || 'None', Mother: PMFSampleColNames[1] || 'None', Father: PMFSampleColNames[2] || 'None' }}
              onSubmit={handleSampleTypeChange}
            >
            {({ errors, values, touched, setValues, setFieldValue }) => {
              let selectOptions = Object.keys(sampleColumns).map(i => ({value: i, label:i}) );
              selectOptions=[...selectOptions,{value: 'None', label:'None'}];
              return(
                <Form>
                  <div className="modal-body d-flex justify-content-center ">

                    <table className="change-sample-type-table w-100">
                      <thead>
                        <tr>
                          <th className="">Sample Type</th>
                          <th className="w-100">Sample ID</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <label className="mx-3">Proband</label> 
                          </td>
                          <td className="d-flex">
                            <Select className="w-100" 
                              name="Proband"
                              options={selectOptions}
                              value={{value: values.Proband, label:values.Proband}}
                              onChange={selectedOption => setFieldValue('Proband', selectedOption.value)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className="mx-3">Mother</label> 
                          </td>
                          <td className="d-flex">
                            <Select className="w-100"
                              name="Mother"
                              options={selectOptions}
                              value={{value: values.Mother, label:values.Mother}}
                              onChange={selectedOption => setFieldValue('Mother', selectedOption.value)}
                            />
                          </td>
                        </tr>
                          <tr>
                            <td>
                              <label className="mx-3">Father</label> 
                            </td>
                            <td className="d-flex">
                              <Select className="w-100"
                                name="Father"
                                options={selectOptions}
                                value={{value: values.Father, label:values.Father}}
                                onChange={selectedOption => setFieldValue('Father', selectedOption.value)}
                              />
                            </td>
                          </tr>
                      </tbody>
                    </table>

                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => sampleTypeModal.hide()}>Close</button>
                    <button className="btn btn-primary" type="submit" ><i className="fas fa-save me-2"></i> Save changes</button>
                  </div>
                </Form>
              )
            }}
            </Formik>
          </div>
        </div>
      </div>
      {/* END Modal */}
    </React.Fragment>
  );
}


export default CnvViewer;