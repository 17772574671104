import React, { useState, useEffect, useRef } from 'react'; // , useCallback    Component, useMemo, useRef // const { useState, useEffect, useRef } = React; 
import { Auth } from 'aws-amplify'; //Amplify, { Auth, Hub }
import toast from 'react-hot-toast';
// import Select from 'react-select';
// import Select, { StylesConfig } from 'react-select';
import Select from 'react-select';
import ReviewOptions from './resources/reviewOptions.json';

const ENDPOINT_BASE = process.env.REACT_APP_API_URI;

const CaseStatusOptions = ['Pre-QC','QC failure','Open','1st pass done','Needs digging','Needs follow up','Ready for SC','Waiting on GM','Closed (Solved)','Closed (Partially Solved)','Closed (Unsolved)','Reopened','Waiting','Closed','Reclosed','Defer']

const AnalysisTypeOptions = [
  {
    value: 'SNV',
    label: 'SNV Analysis (RD style)',
  },{
    value: 'SNV_Pheno',
    label: 'SNV Analysis (Pheno-Driven)',
  },{
    value: 'GermlineSNV',
    label: 'Germline SNV Analysis',
  },{
    value: 'SomaticSNV',
    label: 'Somatic SNV Analysis',
  },{
    value: 'MT_Variants',
    label: 'MT Variant Analysis',
  },{
    value: 'CNV',
    label: 'CNV Analysis',
  },{
    value: 'SV',
    label: 'SV Analysis',
  },{
    value: 'GenomeWideDeNovo',
    label: 'Genome-Wide De Novo Analysis',
  }
];
// ,{
//   value: 'RNASeq',
//   label: 'RNASeq Analysis',
// }


function prettySize(initialValue, sep=' ', unit='b'){
  if(initialValue === 0) return '0';
  if(initialValue === 1) return '1';
  if(!initialValue) return '';
  if(typeof(initialValue) !== "number") return '';

  let finalVal = Math.log10(initialValue);
  if(!!finalVal){
    if(finalVal<3)
      finalVal = initialValue;
    else if(finalVal>=3 && finalVal < 6)
      finalVal = `${Math.round(initialValue/100)/10}${sep}K${unit}`;
    else if(finalVal>=6 && finalVal < 9)
      finalVal = `${Math.round(initialValue/10000)/100}${sep}M${unit}`;
    else if(finalVal>=9)
      finalVal = `${Math.round(initialValue/10000000)/100}${sep}G${unit}`;
  } else finalVal = "-";
  // finalVal = `${finalVal} - ${initialValue}`;
  return finalVal;
}

function separateBy1000 (inValue) {
  if (!!inValue)
    return(inValue.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","));
  // return(inValue.toLocaleString());
  // return(inValue.toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2}));
  // return(inValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
  // return(inValue.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","));
}


const GetSignedURL = (bucket, key, gzip=false) => {
  return new Promise(function (resolve, reject) {

    let username='no_username_found';
    Auth.currentAuthenticatedUser({
      bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
    .then(user => {
      username = user.username.toLowerCase().replace("azuread_", "").replace("@nationwidechildrens.org", "")
      // * do not make the like names as some people have numbers after their name in their email address!! .split(".").map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(" ");
      // console.log('username: ', username);
      // console.log('user: ', user);
    })
    .catch(err => console.error(err));

    Auth.currentSession().then( data => {
      // if(username==='no_username_found' && !!data && !!data.idToken && !!data.idToken.payload && !!data.idToken.payload.email){
      //   username = data.idToken.payload.email.toLowerCase().replace("@nationwidechildrens.org", "")
      // }
      // else if(username==='no_username_found' && !!data && !!data.accessToken && !!data.accessToken.payload && !!data.accessToken.payload.username){
      //   username = data.accessToken.payload.username.toLowerCase().replace("azuread_", "").replace("@nationwidechildrens.org", "")
      //   console.log(username);
      // }
      let id_token = data.getIdToken().getJwtToken();
      let url_to_fetch = `${ENDPOINT_BASE}get_signed_url?username=${username}&bucket=${bucket}&key=${key}`
      if(gzip) url_to_fetch += `&gzip=${gzip}`
      console.log('fetching: ', url_to_fetch);
      fetch(url_to_fetch, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Authorization': id_token
        }
      })
      .then(response => {     
        // console.log('response2')
        // console.log(response)
        if (!response.ok) { // if (status >= 200 && status < 300) {
          reject("File not found");
        }
        resolve(response.json());
      })
      .catch(e => {
        reject("Failed to fetch the data - "+ e.toString()); //! fix the error message!
      });
    })
    .catch(e => {
      reject("No Current Session - " + e.toString()); //! fix the error message!
    });
  });
}

const GetHeatedQualityScore = (value, thresholdValue, revert=false) => {
  let colorVal;
  // let h = 100*cell.value/150; // rgb(253,199,205)=red=hsl(354,21,99) , rgb(199,239,207)=green=hsl(132,16,93)
  // colorVal= "hsl(" + h + ", 80%, 80%)";
  if(value <= thresholdValue){ // *  null values make this IF true
    // colorVal= "hsl(" + 100*value/150 + ", 80%, 80%)";
    // if(value===null) return (`hsl(${10000/150}, 95%, 65%)`); // * between 1 - 100 is red-to-green, and it gets to blue and pink -->250, or 300
    // if(value===null) return (`hsl(${90}, 95%, 65%)`); // * if it is null we set it to 90%
    // ! use min and max along with threshold to get a value between 1-100 or 1-90. 

    if(value===null){
      value=13;
      if (revert) value=117;  // * if it is null we set it to 10% or 90% in revert 
    } else {
      if (revert) value=100-value;
    }
    colorVal= "hsl(" + 100*value/130 + ", 95%, 65%)"; // * max is set at 100/130=76 , it used to be 100/15=66

  }
  else {
    colorVal= 'transparent';
  }
  return(colorVal);
  // return(<span style={{ backgroundColor: `hsl(${120 * ((120 - value) / 120) * -1 + 120}, 100%, 67%)`}} >{value}</span>);
};

function GetSampleRank(sampleType, short=false, analysisType='SNV'){
  if(sampleType.toLowerCase().includes('normal'))
    return 10;
  if(analysisType==='SNV' || analysisType==='SNV_Pheno'){
    if(short){
      if(sampleType.toLowerCase().includes('_p_'))
        return 5;
      if(sampleType.toLowerCase().includes('_m_'))
        return 4;
      if(sampleType.toLowerCase().includes('_f_'))
        return 3;
      if(sampleType.toLowerCase().includes('_s_'))
        return 2;
      if(sampleType.toLowerCase().includes('_b_'))
        return 1;
    } else {
      if(sampleType.toLowerCase().startsWith('proband'))
        return 5;
      if(sampleType.toLowerCase().startsWith('mother'))
        return 4;
      if(sampleType.toLowerCase().startsWith('father'))
        return 3;
      if(sampleType.toLowerCase().startsWith('sister'))
        return 2;
      if(sampleType.toLowerCase().startsWith('brother'))
        return 1;
    }
  } 
  else if(analysisType.includes('SomaticSNV') ){ //|| analysisType.includes('GermlineSNV')
    return -sampleType.toLowerCase().charCodeAt(0);
  }
  return -1;
}

function truncateToDots(myString, n){ // ? can be done using "stringxxx".replace(/(.{10})..+/, "$1…")
  if(myString===undefined || myString===null) return '-';
  myString = String(myString);
  return (myString.length > n) ? myString.substr(0, n-1) + '…' : myString; // '…' ===> '&hellip;'
}

// Create an editable cell renderer
const VanexTagCell = ({
  value: initialValue,
  row: { values }, // get the values of the current row to extract the variant index!
  column: { id },
  updateVariantTableData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  // 0 not selected, default
  // 1 selected for everyone to see
  // 2 personal selection (only the current user can see)
  const [selectionStatus, setSelectionStatus] = useState(initialValue || 0);

  const onSelectClick = e => {
    if(selectionStatus === 0)
      updateVariantTableData(values.ROW_INDEX, id, 1);
    else if(selectionStatus === 1)
      updateVariantTableData(values.ROW_INDEX, id, 2);
    else 
      updateVariantTableData(values.ROW_INDEX, id, 0);
    // setSelectionStatus(!selectionStatus);
  }

  useEffect(() => {
    setSelectionStatus(initialValue || 0);
  }, [initialValue]);


  return(
    <div className={`d-flex align-items-center justify-content-center w-100 h-100 ${ (!!values['MR_PHENE_PHENOTYPE_TERMS'] ? ' with-edge-indicator ' : '') }`} 
      onClick={onSelectClick}>
      <div >
        {(selectionStatus === 1 ? <i className="fas fa-check-circle fa-lg text-primary"></i> : 
          (selectionStatus === 2 ? <i className="fas fa-user-check fa-lg text-primary"></i> : <i className="far fa-circle fa-lg text-secondary"></i>) )}
      </div>
    </div>
  );
}

// Create an editable cell renderer
const VanexGenotypeCell = ({
  value: initialValue,
  row,//{ values }, // get the values of the current row to extract the variant index!
  column, //{ id },
}) => {
  // We need to keep and update the state of the cell normally
  const [cellStats, setCellStats] = useState([]);
  const [genotypeQuality, setGenotypeQuality] = useState([]);
  // const [supportingReadCounts, setSupportingReadCounts] = useState(null);

  // const onSelectClick = e => {
    // updateVariantTableData(values.ROW_INDEX, id,!isSelected);
    // setIsSelected(!isSelected);
  // }

  useEffect(() => {
    // setIsSelected(initialValue || false);
    // console.log(column);
    // let cellClassName = " genotype-cell ";
    // cellClassName += initialValue==='ref' ? 'bg-green' : (initialValue==='het' ? 'bg-pink' : (initialValue==='hom' ? 'bg-red' : ''));
    // setCellClassName(cellClassName);

    
    let stats = [];
    try {
      let sampleID = column.id.slice(0,-3); // * column.id.endsWith("_GT") || key.endsWith('_AD') || key.endsWith('_RD') || key.endsWith('_TD') || key.endsWith('_PL') || key.endsWith('_DP') || key.endsWith('_GQ')

      if( column.id.endsWith('_Alt_Percent')) sampleID = column.id.slice(0,-12);
      // if( column.id.endsWith('_GT_RAW')) sampleID = column.id.slice(0,-7);
      if(!!sampleID) {
        let altPercent = row.values[`${sampleID}_Alt_Percent`];
        if(!altPercent) altPercent=0;
        stats.push({NAME: 'Alt Percentage', VALUE: `${altPercent}%`});
        stats.push({NAME: 'Ref Depth', VALUE: row.values[`${sampleID}_RD`]});
        stats.push({NAME: 'Alt Depth', VALUE: row.values[`${sampleID}_AD`]});
        stats.push({NAME: 'Total Depth', VALUE: row.values[`${sampleID}_TD`]});
        // stats.push({NAME: '', VALUE: '-----------------'});
        stats.push({NAME: 'Genotype Quality', VALUE: row.values[`${sampleID}_GQ`]});
        stats.push({NAME: 'Genotype Raw', VALUE: row.values[`${sampleID}_GT_RAW`]});
        stats.push({NAME: 'DP', VALUE: row.values[`${sampleID}_DP`]});
        stats.push({NAME: 'PL', VALUE: row.values[`${sampleID}_PL`]});
        // stats.push({NAME: 'Alt', VALUE: `${altPercent}%`});
        // stats.push({NAME: 'RD', VALUE: row.values[`${sampleID}_RD`]});
        // stats.push({NAME: 'AD', VALUE: row.values[`${sampleID}_AD`]});
        // stats.push({NAME: 'TD', VALUE: row.values[`${sampleID}_TD`]});
        // // stats.push({NAME: '', VALUE: '-----------------'});
        // stats.push({NAME: 'GQ', VALUE: row.values[`${sampleID}_GQ`]});
        // stats.push({NAME: 'GT', VALUE: row.values[`${sampleID}_GT_RAW`]});
        // stats.push({NAME: 'DP', VALUE: row.values[`${sampleID}_DP`]});
        // stats.push({NAME: 'PL', VALUE: row.values[`${sampleID}_PL`]});
        
        let genoQual = row.values[`${sampleID}_GQ`];
        if (genoQual===undefined || genoQual===null || genoQual==='0') genoQual=100;
        genoQual=genoQual/100;
        
        if (genoQual < .4)
          setGenotypeQuality(<span className='genotype-quality' style={{opacity:1-genoQual}}>{' '}</span> ); // ! '⸮'
        else
        setGenotypeQuality(<span></span> );
        // setGenotypeQuality(<span className='genotype-quality' style={{opacity:1-genoQual}}>{(genoQual < .4) ? ' ' : ''}</span> ); // ! '⸮'
        // setGenotypeQuality(<span className="fw-bold" style={{opacity:1-genoQual, paddingRight:'2px', fontSize:'.7em'}}>{(genoQual < .4) ? '?' : ''}</span> ); // ! '⸮'
        //   setSupportingReadCounts(row.values[`${sampleID}_AD`]);} else { setSupportingReadCounts(null); }
      }
      setCellStats(stats);
    } catch(e){ }
    // console.log(stats);
    // console.log(cell`${cell.column.id.slice(0,-3)}_Alt_Percent`);

  }, [initialValue, column.id, row.values]);
  
  // {/* <div className={`genotype-cell ${(supportingReadCounts!==null && supportingReadCounts !== undefined && supportingReadCounts < 4) ? ' cross ' : '' }`}> */}
  return(
    <>
      <div className="genotype-cell">
        <div className="genotype-value">{(initialValue===null ? '-' : <span>{initialValue}</span>)}</div>{genotypeQuality}
      </div>
      {/* <div className="test-tag" data-content={'xk'}></div> */}
      <div className="genotype-info" style={{left:`calc(${column.totalLeft+column.totalWidth}px - 2em)`}}>
        {(!!cellStats.length && cellStats.length>0)?
        <div className="d-flex flex-row">
          <div className="d-flex flex-column">
            {cellStats.slice(0,4).map( (property, index) => <div key={index}>{property.NAME + ': '}</div> )}
          </div>
          <div className="d-flex flex-column">
            {cellStats.slice(0,4).map( (property, index) => <div key={index} className="px-1 fw-bold" style={ (index===0) ? {backgroundColor: GetHeatedQualityScore(property.VALUE.slice(0,-1), 100, true), borderRadius:'.3em'} : {} }>{truncateToDots(property.VALUE,12)}</div>)}
          </div>
          <div className="d-flex flex-column ms-2">
            {cellStats.slice(4,8).map( (property, index) => <div key={index} >{property.NAME + ': ' }</div> )}
          </div>
          <div className="d-flex flex-column">
            {cellStats.slice(4,8).map( (property, index) => <div key={index} className="px-1 fw-bold" style={ (index===0) ? {backgroundColor: GetHeatedQualityScore(property.VALUE, 100), borderRadius:'.3em'} : {} }>{truncateToDots(property.VALUE,12)}</div>)}
          </div>
        </div>: null }
      </div>
    </>
  );
// linear-gradient(to bottom, #a2ea4c 200px, #07aa91 200px, #07aa91)
// <div style={{background:'linear-gradient( red, #ffffff)'}}> 
// <div className={cellClassName}>
  // return(
  //   <div className="d-flex align-items-center justify-content-center w-100 h-100" onClick={onSelectClick}>
  //     <div >
  //       {(isSelected) ? <i className="fas fa-check-circle fa-lg text-primary"></i> : <i className="far fa-circle fa-lg text-secondary"></i>}
  //     </div>
  //   </div>
  // );
}


const VanexCNVGenotypeCell = ({
  value: initialValue,
  row, 
  column, 
}) => {
  const [cellStats, setCellStats] = useState([]);

  useEffect(() => {
    let stats = [];
    try {
      let sampleID = column.id.slice(0,-3); // * column.id.endsWith("_GT") || key.endsWith('_AD') || key.endsWith('_RD') || key.endsWith('_TD') || key.endsWith('_PL') || key.endsWith('_DP') || key.endsWith('_GQ')
      if( column.id.endsWith('_Alt_Percent')) sampleID = column.id.slice(0,-12);
      if(!!sampleID) {
        let overlapSegData = row.values[`${sampleID}_OL`];
        if(!!overlapSegData && !!overlapSegData.SEG_SIZE){
          // let totalLength = Math.max(row.values.LOC_END, overlapSegData.LOC_END) - Math.min(row.values.LOC_START, overlapSegData.LOC_START);
          // let topBandStart = Math.round(100*(row.values.LOC_START-Math.min(row.values.LOC_START, overlapSegData.LOC_START))/totalLength);
          // let bottomBandStart = Math.round( 100*(overlapSegData.LOC_START-Math.min(row.values.LOC_START, overlapSegData.LOC_START))/totalLength) ;
          // let topBandSize = Math.round(100 * row.values.SEG_SIZE / totalLength);
          // let bottomBandSize = Math.round(100 * overlapSegData.SEG_SIZE / totalLength);
          
          // stats.push({NAME: 'totalLength', VALUE: totalLength});
          // stats.push({NAME: 'topBandSize', VALUE: topBandSize});
          // stats.push({NAME: 'topBandStart', VALUE: topBandStart});
          // stats.push({NAME: 'topVal', VALUE: row.values.LOC_START-Math.min(row.values.LOC_START, overlapSegData.LOC_START)});
          // stats.push({NAME: 'bottomBandSize', VALUE: bottomBandSize});
          // stats.push({NAME: 'bottomBandStart', VALUE: bottomBandStart});
          // stats.push({NAME: 'bottomVal', VALUE: overlapSegData.LOC_START-Math.min(row.values.LOC_START, overlapSegData.LOC_START)});

          stats.push({NAME: 'Start', VALUE: separateBy1000(overlapSegData.LOC_START)});
          stats.push({NAME: 'End', VALUE: separateBy1000(overlapSegData.LOC_END)});
          stats.push({NAME: 'Size', VALUE: prettySize(overlapSegData.SEG_SIZE)});
          stats.push({NAME: 'Similarity', VALUE: row.values[`${sampleID}_SIM`]});
          // stats.push({NAME: 'Type', VALUE: overlapSegData.CNV_TYPE});
          // stats.push({NAME: 'Overlap', VALUE: `${initialValue}%`});
          stats.push({NAME: 'Chr', VALUE: overlapSegData.CHROMOSOME});
          stats.push({NAME: 'N. Marks', VALUE: overlapSegData.NUM_MARK});
          stats.push({NAME: 'Seg Mean', VALUE: Math.round( (Number(overlapSegData.SEG_MEAN)+ Number.EPSILON)*100)/100});
        }
        setCellStats(stats);
      }
    } catch(e){ }

  }, [initialValue, column.id, row.values]);
  
  return(
    <>
      <div className="genotype-cell">
        <div className="genotype-value">{(initialValue===null ? '-' : <span>{initialValue}</span>)}</div>
      </div>
      {(!!cellStats && cellStats.length > 0 ?
        <div className="genotype-info" style={{left:`calc(${column.totalLeft+column.totalWidth}px - 2em)`}}>
          {(!!cellStats.length && cellStats.length>0)?
          <div className="d-flex flex-row">
            <div className="d-flex flex-column">
              {cellStats.slice(0,3).map( (property, index) => <div key={index}>{property.NAME + ': '}</div> )}
            </div>
            <div className="d-flex flex-column">
              {cellStats.slice(0,3).map( (property, index) => <div key={index} className="px-1 fw-bold" 
              // style={ (index===3 ? {backgroundColor: (property.VALUE==="GAIN" ? "#B0C4DE" : "#FC8696"), borderRadius:'.3em'} : {}) }
              >{truncateToDots(property.VALUE,12)}</div>)}
            </div>
            <div className="d-flex flex-column ms-2">
              {cellStats.slice(3,7).map( (property, index) => <div key={index} >{property.NAME + ': ' }</div> )}
            </div>
            <div className="d-flex flex-column">
              {cellStats.slice(3,7).map( (property, index) => <div key={index} className="px-1 fw-bold" style={ (index===3 ? {backgroundColor: (property.VALUE>0 ? "#B0C4DE" : "#FC8696"), borderRadius:'.3em'} : {}) }>{truncateToDots(property.VALUE,12)}</div>)}
            </div>
          </div>: null }
        </div>
       : null)}
    </>
  ); 
}

const VanexSVGenotypeCell = ({
  value: initialValue,
  row,
  column, 
}) => {
  const [cellStats, setCellStats] = useState([]);

  useEffect(() => {
    let stats = [];
    try {
      let sampleID = column.id.slice(0,-3); // * column.id.endsWith("_GT") || key.endsWith('_AD') || key.endsWith('_RD') || key.endsWith('_TD') || key.endsWith('_PL') || key.endsWith('_DP') || key.endsWith('_GQ')
      if( column.id.endsWith('_Alt_Percent')) sampleID = column.id.slice(0,-12);
      if(!!sampleID) {
        let overlapSegData = row.values[`${sampleID}_OL`];
        // let overlapSegGT = row.values[`${sampleID}_GT`];
        if(!!overlapSegData && !!overlapSegData.LOC_START){
          stats.push({NAME: 'Start', VALUE: separateBy1000(overlapSegData.LOC_START)});
          stats.push({NAME: 'End', VALUE: separateBy1000(overlapSegData.LOC_END)});
          stats.push({NAME: 'Length', VALUE: separateBy1000(overlapSegData.SEG_SIZE)});
          stats.push({NAME: 'GT (M)', VALUE: overlapSegData.GT_MANTA});
          stats.push({NAME: 'Similarity', VALUE: row.values[`${sampleID}_SIM`]});
          stats.push({NAME: 'Caller', VALUE: (overlapSegData.CALLERS)});
          stats.push({NAME: 'Quality (M)', VALUE: overlapSegData.QUAL_MANTA});
          stats.push({NAME: 'Reads (M)', VALUE: overlapSegData.MANTA_READS});
          stats.push({NAME: 'Quality (G)', VALUE: overlapSegData.QUAL_GRIDSS});
          // stats.push({NAME: 'Read Score GRIDSS', VALUE: overlapSegData.GRIDSS_READ_SCORE});
          // stats.push({NAME: 'TYPE', VALUE: overlapSegData.TYPE});
        }
        setCellStats(stats);
      }
    } catch(e){ }
    // console.log(stats);
    // console.log(cell`${cell.column.id.slice(0,-3)}_Alt_Percent`);

  }, [initialValue, column.id, row.values]);
  
  // {/* <div className={`genotype-cell ${(supportingReadCounts!==null && supportingReadCounts !== undefined && supportingReadCounts < 4) ? ' cross ' : '' }`}> */}
  return(
    <>
      <div className="genotype-cell">
        <div className="genotype-value">{(initialValue===null ? '-' : <span>{initialValue} </span>)}</div>
      </div>
      {(!!cellStats && cellStats.length > 0 ?
        <div className="genotype-info" style={{left:`calc(${column.totalLeft+column.totalWidth}px - 2em)`}}>
          {(!!cellStats.length && cellStats.length>0)?
          <div className="d-flex flex-row">
            <div className="d-flex flex-column">
              {cellStats.slice(0,5).map( (property, index) => <div key={index}>{property.NAME + ': '}</div> )}
            </div>
            <div className="d-flex flex-column">
              {cellStats.slice(0,5).map( (property, index) => <div key={index} className="px-1 fw-bold">{truncateToDots(property.VALUE,12)}</div>)}
            </div>
            <div className="d-flex flex-column ms-2">
              {cellStats.slice(5,9).map( (property, index) => <div key={index} >{property.NAME + ': ' }</div> )}
            </div>
            <div className="d-flex flex-column">
              {cellStats.slice(5,9).map( (property, index) => <div key={index} className="px-1 fw-bold">{truncateToDots(property.VALUE,12)}</div>)}
            </div>
          </div>: null }
        </div>
       : null)}
    </>
  ); 
}
const VanexSVQUALCell = ({
  value: initialValue,
  row,
  column, 
}) => {
  const [cellStats, setCellStats] = useState([]);
  // console.log(initialValue,row,column)
  useEffect(() => {
    let stats = [];
    try {
      // let sampleID = column.id.slice(0,-3); // * column.id.endsWith("_GT") || key.endsWith('_AD') || key.endsWith('_RD') || key.endsWith('_TD') || key.endsWith('_PL') || key.endsWith('_DP') || key.endsWith('_GQ')
      // if( column.id.endsWith('_Alt_Percent')) sampleID = column.id.slice(0,-12);
      // if(!!sampleID) {
        // let overlapSegData = row.values;
        // let overlapSegGT = row.values[`${sampleID}_GT`];
        // if(!!overlapSegData && !!overlapSegData.QUAL_MANTA){
          stats.push({NAME: 'MANTA', VALUE: row.original.QUAL_MANTA});
          stats.push({NAME: 'GRIDSS', VALUE: row.original.QUAL_GRIDSS});
          // stats.push({NAME: 'Read Score GRIDSS', VALUE: overlapSegData.GRIDSS_READ_SCORE});
          // stats.push({NAME: 'TYPE', VALUE: overlapSegData.TYPE});
        // }
        setCellStats(stats);
      // }
    } catch(e){ }
    // console.log(stats);
    // console.log(cell`${cell.column.id.slice(0,-3)}_Alt_Percent`);

  }, [initialValue, column.id, row.values]);
  
  // {/* <div className={`genotype-cell ${(supportingReadCounts!==null && supportingReadCounts !== undefined && supportingReadCounts < 4) ? ' cross ' : '' }`}> */}
  return(
    <>
      <div className="genotype-cell">
        <div className="genotype-value">{(initialValue===null ? '-' : <span>{initialValue} </span>)}</div>
      </div>
      {(!!cellStats && cellStats.length > 0 ?
        <div className="genotype-info" style={{left:`calc(${column.totalLeft+column.totalWidth}px - 2em)`}}>
          {(!!cellStats.length && cellStats.length>0)?
          <div className="d-flex flex-row">
            <div className="d-flex flex-column">
              {cellStats.slice(0,1).map( (property, index) => <div key={index}>{property.NAME + ': '}</div> )}
            </div>
            <div className="d-flex flex-column">
              {cellStats.slice(0,1).map( (property, index) => <div key={index} className="px-1 fw-bold">{truncateToDots(property.VALUE,12)}</div>)}
            </div>
            <div className="d-flex flex-column ms-2">
              {cellStats.slice(1,2).map( (property, index) => <div key={index} >{property.NAME + ': ' }</div> )}
            </div>
            <div className="d-flex flex-column">
              {cellStats.slice(1,2).map( (property, index) => <div key={index} className="px-1 fw-bold">{truncateToDots(property.VALUE,12)}</div>)}
            </div>
          </div>: null }
        </div>
       : null)}
    </>
  ); 
}

// Create an editable cell renderer
const VanexNoteCell = ({
  value: initialValue,
  row: { values }, // get the values of the current row to extract the variant index!
  column: { id },
  updateVariantTableData, // This is a custom function that we supplied to our table instance
  // getTabName,
  // sendFeedback,
  // getCaseID
}) => {
  // * We need to keep and update the state of the cell normally
  const [currentUserNoteValue, setCurrentUserNoteValue] = useState(initialValue['CURRENT_USER_NOTE'] || '');
  const [existingNotes, setExistingNotes] = useState(initialValue['ALL_USERS_NOTES'] || []);
  const [inEditMode, setInEditMode] = useState(false);

  const onCurrentUserNoteChange = e => {
    setCurrentUserNoteValue(e.target.value);
  }

  // * We'll only update the external data when the input is blurred
  const onCurrentUserNoteBlur = () => {
    // updateVariantTableData(values.ROW_INDEX, id, value);
    setInEditMode(false);

    // * only save if teh value is changed
    if(currentUserNoteValue!==initialValue['CURRENT_USER_NOTE']){      
      updateVariantTableData(values.ROW_INDEX, id, {
        ...initialValue,
        CURRENT_USER_NOTE: currentUserNoteValue, 
        UPDATED_AT: new Date() 
        // ALL_USERS_NOTES: initialValue['ALL_USERS_NOTES']
      });
    }
  }

  // * If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setCurrentUserNoteValue(initialValue['CURRENT_USER_NOTE'] || '');
    setExistingNotes(initialValue['ALL_USERS_NOTES'] || []);
  }, [initialValue]);


  return(
    <div className="w-100 d-flex flex-row">
      <div className="show-overflow d-flex flex-row flex-grow-1">
        {(inEditMode)
        ?
          <input autoFocus className="m-0 p-0 w-100" value={(!!currentUserNoteValue) ? currentUserNoteValue : '' } 
          onChange={onCurrentUserNoteChange} onBlur={onCurrentUserNoteBlur}/>
        :
          <span className="w-100" onClick={() => setInEditMode(true)}>{currentUserNoteValue} &nbsp;</span>
        }
      </div>
      {/* {(existingNotes.length>0) ? <i className="ms-1 d-flex align-items-center fas fa-file-alt text-primary"></i> : ''} */}
      {(existingNotes.filter(t => t['IS_SET_BY_OTHERS']).length>0) ? <i className="ms-1 d-flex align-items-center fas fa-file-alt text-primary"></i> : ''}
    </div>
  );
}

// Create an editable cell renderer
const VanexReviewCell = ({
  value: initialValue,
  row: { values }, // get the values of the current row to extract the variant index!
  column: { id },
  updateVariantTableData, // This is a custom function that we supplied to our table instance
  getTabName,
  sendFeedback,
  // getCaseID
}) => {

  // const ReviewOptions = [
  //   {value: 1, label:'PASS'},
  //   {value: 5, label:'AMBIGUOUS'},
  //   {value: 6, label:'FAIL - low quality'},
  //   {value: 7, label:'FAIL - noisy region'},
  //   {value: 8, label:'FAIL - not de novo (in a parent)'},
  //   {value: 9, label:'FAIL - not observed'},
  //   {value: 10, label:'FAIL - paralogous alignment'},
  //   {value: 11, label:'FAIL - read end bias'},
  //   {value: 12, label:'FAIL - repeat'},
  //   {value: 13, label:'FAIL - strand bias'}
  // ];
  const [currentReviews, setCurrentReviews] = useState({});
  // const [currentReviews, setCurrentReviews] = useState(initialValue['REVIEW_VALUE'] || {});
  const [inEditMode, setInEditMode] = useState(false);
  const [inSelectMode, setInSelectMode] = useState(false);
  const [denovoManualAssessment, setDenovoManualAssessment] = useState(null);
  // const [userProvidedFeedback, setUserProvidedFeedback] = useState(false);

  const onReviewSelectChange = selectedOption => {
    console.log(selectedOption);
    setInEditMode(false);
    setCurrentReviews((selectedOption===undefined || selectedOption===null) ? {} : selectedOption);
    let selectedOptionValue = (selectedOption===undefined || selectedOption===null) ? null : selectedOption.value;
    updateVariantTableData(values.ROW_INDEX, id, {
      ...initialValue,
      REVIEW_VALUE: selectedOptionValue,
      UPDATED_AT: new Date()
    });
  }

  const onReviewSelectFocus = () => {
    setInSelectMode(true);
    console.log(currentReviews)
    console.log(denovoManualAssessment)
  }

  const onReviewSelectBlur = () => {
    if(inEditMode){
      setInEditMode(false);
      setInSelectMode(false);
    }
  }

  const onPointerLeave = () => {
    if(!inSelectMode)
      setInEditMode(false);
  }

  // const onDenovoManualAssessmentMouseLeave = () => {
  //   if(!!getTabName && getTabName()==='TAB_DENOVO' && userProvidedFeedback){
  //     if(!!sendFeedback ){ //&& !!getCaseID
  //       sendFeedback({'DENOVO_ASSESSMENTS':denovoManualAssessment, ...values, UPDATED_AT: new Date()}); //'CASE_ID':getCaseID(),
  //       setUserProvidedFeedback(false);
  //       // console.log("feedback sent!");
  //     }
  //     // else {
  //     //   console.log("cannot send feedback since sendFeedback and/or getCaseID are not provided!");
  //     //   toast.error("cannot send feedback since sendFeedback and/or getCaseID are not provided!", {duration:4000});
  //     // }
  //   }
  // }

  // const onDenovoManualAssessmentClick = (code) => {
  //   if(!!getTabName && getTabName()==='TAB_DENOVO'){
  //     if(!!sendFeedback ){ //&& !!getCaseID
  //       setDenovoManualAssessment(code);
  //       setUserProvidedFeedback(true);
  //     } else {
  //       console.log("cannot send feedback since sendFeedback and/or getCaseID are not provided!");
  //       toast.error("cannot send feedback since sendFeedback is not provided!", {duration:4000});
  //     }
  //   }
  // }

  
  const onDenovoManualAssessmentClick = () => {
    if(!!getTabName && getTabName()==='TAB_DENOVO'){
      if(!!sendFeedback ){ //&& !!getCaseID
        let selectedAssessment = (currentReviews!==undefined && currentReviews!==null && currentReviews.value > 0 ) ? currentReviews.value : -1;
        // sendFeedback(values.ROW_INDEX, id, {
        //   ...values,
        //   DENOVO_ASSESSMENT: selectedAssessment,
        //   UPDATED_AT: new Date()
        // }); 
        sendFeedback({ ...values, VANEX_REVIEWS: {
          ...initialValue,
          DENOVO_ASSESSMENT: selectedAssessment,
          UPDATED_AT: new Date()
        }},
        values.ROW_INDEX, id, {
          ...initialValue,
          DENOVO_ASSESSMENT: selectedAssessment,
          UPDATED_AT: new Date()
        }); 
        //'CASE_ID':getCaseID(),
        // updateVariantTableData(values.ROW_INDEX, id, {
        //   ...initialValue,
        //   DENOVO_ASSESSMENT: selectedAssessment,
        //   UPDATED_AT: new Date()
        // });
      } else {
        console.log("cannot send feedback since sendFeedback and/or getCaseID are not provided!");
        toast.error("cannot send feedback since sendFeedback is not provided!", {duration:4000});
      }
    }
  }

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    let option = {};
    if (initialValue['REVIEW_VALUE'] > 0)
      option = ReviewOptions.find(k => k.value === initialValue['REVIEW_VALUE']);
    setCurrentReviews(option);

    // setDenovoManualAssessment( (initialValue['DENOVO_ASSESSMENT'] !== undefined && initialValue['DENOVO_ASSESSMENT'] !== null) ? initialValue['DENOVO_ASSESSMENT'] : -1);
    setDenovoManualAssessment(initialValue['DENOVO_ASSESSMENT']);
    if(inEditMode){
      setInEditMode(false);
    }
    // setCurrentReviews(initialValue['REVIEW_VALUE'] || []);
  }, [initialValue]);

  // <div onMouseOver={changeBackground}>
  // function changeBackground(e) {e.target.style.background = 'red';}

  // const dot = (color = 'transparent') => ({
  //   alignItems: 'center',
  //   display: 'flex',
  
  //   ':before': {
  //     backgroundColor: color,
  //     borderRadius: 10,
  //     content: '" "',
  //     display: 'block',
  //     marginRight: 8,
  //     height: 10,
  //     width: 10,
  //   },
  // });

  // const colourStyles: StylesConfig<ColourOption> = {
  const colourStyles = {
    // control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    menu: (styles) => ({ ...styles, 
      width:'fit-content',
    }),
    menuList: (styles) => ({ ...styles, 
      // width:'fit-content',
      maxHeight:'8em',
      overflow:'scroll'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        // backgroundColor: 'white',
        color: (data.value === 1) ? 'darkgreen' : ((data.value === 5) ? 'darkorange': 'darkred'),
        // cursor: isDisabled ? 'not-allowed' : 'default',
        margin: '0px',
        padding: '1px 5px',
        
        // ':active': {
        //   ...styles[':active'],
        //   // backgroundColor: (data.value === '01') ? 'green' : ((data.value === '02') ? 'orange': 'red'),
        // },
        // ':hover': {
        //   ...styles[':hover'],
        //   backgroundColor: '#afd9ee',
        // },
      };
    },
    // input: (styles) => ({ ...styles }),
    // placeholder: (styles) => ({ ...styles }),
    // singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  };

  // const getColor = (data) => {
  //   return( (data.value === '01') ? 'darkgreen' : ((data.value === '02') ? 'orange': 'darkred') );
  // }
  
  const getColorClass = (data) => {
    return( (data.value === 1 ) ? 'bg-green' : ((data.value === 5) ? 'bg-orange': 'bg-pink') );
  }

  // console.log(denovoManualAssessment)
  return(
    <div className="d-flex flex-row" onPointerLeave={onPointerLeave}>
      {(inEditMode)
        ?
        <Select
          // isMulti //! removed, since each record needs to be classified as only one class
          autoFocus
          closeMenuOnSelect={true}
          defaultMenuIsOpen={true}
          isClearable={true}
          className="flex-grow-1 mx-1"
          placeholder='Select...'
          onFocus={onReviewSelectFocus}
          onBlur={onReviewSelectBlur}
          onChange={onReviewSelectChange}
          options={ReviewOptions}
          styles={colourStyles}
          defaultValue={currentReviews}
        />
        :
        <div className="flex-grow-1 vanex-review-cell" onDoubleClick={() => setInEditMode(true)}>
          {/* ////! removed, since each record needs to be classified as only one class */}
          {/* {currentReviews.map(k => <span key={k.label} className={`me-1 ${getColorClass(k)}`}>&nbsp;{k.label}&nbsp;</span>)} &nbsp;  */}
          {(!!currentReviews && !!currentReviews.label) ? <span className={`me-1 px-1 plus-border-radius ${getColorClass(currentReviews)}`}>&nbsp;{currentReviews.label}&nbsp;</span> : ''} &nbsp; 
          {/* style={{backgroundColor:getColor(k)}} */}
        </div>
      }
      {(!!getTabName && getTabName()==='TAB_DENOVO' && !inEditMode)
      ? 
        <div className="d-flex flex-column" style={{fontSize:'1.3em'}} onClick={onDenovoManualAssessmentClick}>
          {
            // * if the assessment is removed for this record and the recorded assessment is -1 (which means exclude this record from the de novo db) show a green check
          (denovoManualAssessment=== -1 && Object.values(currentReviews).length===0)
          ?
            <i className="mx-1 d-flex align-items-center far fa-check-circle green-mid" ></i>
            // <i className="mx-1 d-flex align-items-center far fa-times-circle red-mid"></i>
          :
            // * if the recorded assessment does not match what we have show a red exclamation
            (denovoManualAssessment!==null && denovoManualAssessment!==undefined &&
              currentReviews!==null && currentReviews!==undefined &&
              denovoManualAssessment !== currentReviews.value) ? 
              <i className="mx-1 d-flex align-items-center fas fa-exclamation-circle red-mid"></i>
            :
              // * if they match show a green check
              (denovoManualAssessment > 0) ? 
                <i className="mx-1 d-flex align-items-center far fa-check-circle green-mid" ></i>
              : 
                <i className="mx-1 d-flex align-items-center far fa-question-circle grey-light"></i>
          }
        </div>
      : null}
      {/* {(!!getTabName && getTabName()==='TAB_DENOVO')
      ? 
        <div className="d-flex flex-column" style={{fontSize:'1.3em'}} onMouseLeave={onDenovoManualAssessmentMouseLeave}>
          {(denovoManualAssessment === 0) ? 
            <i className="mx-1 d-flex align-items-center far fa-times-circle red-mid" onClick={() => {onDenovoManualAssessmentClick(1)} }></i>
            : ( (denovoManualAssessment === 1) ? 
              <i className="mx-1 d-flex align-items-center far fa-check-circle green-mid" onClick={() => {onDenovoManualAssessmentClick(-1)} }></i>
            : 
            <>
              <i className="mx-1 d-flex align-items-center far fa-question-circle grey-light" onClick={() => {onDenovoManualAssessmentClick(0)} }></i>
            </>
            )
          }
        </div>
      : null} */}
    </div>
  );
}

const VanexMrPhenePhenoTermsCell = ({
  value: initialValue,
  // row: { values }, // get the values of the current row to extract the variant index!
  // column: { id },
  // updateVariantTableData, // This is a custom function that we supplied to our table instance
}) => {
  const [phenoTerms, setPhenoTerms] = useState(initialValue);

  useEffect(() => {
    setPhenoTerms(initialValue);
  }, [initialValue]);

  return(
    <div className="d-flex align-items-center justify-content-start">
      {(!!phenoTerms && phenoTerms.length > 0)
      ?
        <>
          <span className="rounded-pill px-2 border-light-blue bg-blue text-light d-flex flex-row flex-nowrap align-items-center justify-content-center" >{phenoTerms.length}</span>
          {phenoTerms.map( (phTerm, index) => <span className="rounded-pill px-2 mx-1 border-light-blue d-flex flex-row flex-nowrap align-items-center justify-content-center" key={index}>{phTerm}</span>)}
        </>
      :
        '-'
      }
    </div>
  );
}

const VanexArrayCell = ({
  value: initialValue
}) => {
  const [values, setValues] = useState(initialValue);

  useEffect(() => {
    setValues(initialValue.filter(e => e!==""));
  }, [initialValue]);

  return(
    <div className="d-flex align-items-center justify-content-start">
      {(!!values && values.length > 0)
      ?
        <>
          <span className="rounded-pill px-2 border-light-blue bg-blue text-light d-flex flex-row flex-nowrap align-items-center justify-content-center" >{values.length}</span>
          {values.map( (phTerm, index) => <span className="rounded-pill px-2 mx-1 border-light-blue d-flex flex-row flex-nowrap align-items-center justify-content-center" key={index}>{phTerm}</span>)}
        </>
      :
        '-'
      }
    </div>
  );
}

const VanexSegmentSizeCell = ({
  value: initialValue
}) => {

  const processedValue = React.useMemo(() => prettySize(initialValue), [initialValue]);

  return(
    <div className="d-flex align-items-center justify-content-start">
      {(!!processedValue)
      ?
        <span className="" title={initialValue}>{processedValue}</span>
      :
        '-'
      }
    </div>
  );
}


const EditableCell = ({
  value: initialValue,
  row: { values }, // * get the values of the current row to extract the variant index!
  column: { id },
  updateVariantTableData, // * This is a custom function that we supplied to our table instance
}) => {
  // * We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);
  const [inEditMode, setInEditMode] = useState(false);

  const onChange = e => {
    setValue(e.target.value);
  }

  // * We'll only update the external data when the input is blurred
  const onBlur = () => {
    // * only save if teh value is changed
    if(value!==initialValue)
      updateVariantTableData(values.ROW_INDEX, id, value);
    setInEditMode(false);
  }

  // * If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if(inEditMode){
    return <input autoFocus className="m-0 p-0" value={(!!value) ? value : '' } onChange={onChange} onBlur={onBlur} style={{width:'100%'}}/>
  }
  else{
    return <div style={{width:'100%'}} onClick={() => setInEditMode(true)}>{value}&nbsp;</div>
  }
}


const EditableTextArea = ({
  value: initialValue,
  updateTextAreaValue
}) => {
// const EditableTextArea = (props) => {
  const [value, setValue] = useState(initialValue);
  const [inEditMode, setInEditMode] = useState(false);
  const textareaRef = useRef(null);

  const adjustElementHeight = element => {
    element.style.height = 'inherit';
    element.style.height = `${Math.min(element.scrollHeight, 400)}px`;
    // element.style.height = `${element.scrollHeight}px`; 
  }

  const onChange = e => {
    setValue(e.target.value);
  }

  const adjustHeight = e => {
    adjustElementHeight(e.target);
  }

  // * We'll only update the external data when the input is blurred
  const onBlur = () => {
    // * only save if teh value is changed
    if(value!==initialValue)
      updateTextAreaValue(value);
    setInEditMode(false);
  }

  useEffect(() => {
    if(!!textareaRef && !!textareaRef.current){
      adjustElementHeight(textareaRef.current)
    }
  }, [value])

  useEffect(() => {
    if(inEditMode && !!textareaRef && !!textareaRef.current){
      adjustElementHeight(textareaRef.current)
    }
  }, [inEditMode])
  
  //* If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
    // setInEditMode(!!initialValue);
  }, [initialValue]);

  if(inEditMode){
    return <textarea
        // {...(!value ? {autoFocus:true} : undefined)}
        autoFocus
        ref={textareaRef} 
        className="m-0 p-0 px-1" 
        value={value}
        onMouseUp={adjustHeight} onKeyDown={adjustHeight} onChange={onChange} onBlur={onBlur} 
        style={{width:'100%', minHeight:'5em'}}
        />
  } else {
    // return(<div className="m-0 px-1 note-div" onClick={()=>setInEditMode(true)} >&nbsp;</div>)
    return(<div className="m-0 px-1 note-div" style={{whiteSpace:'pre-wrap'}} onClick={()=>setInEditMode(true)} >{!!value ? value : ' '}</div>)
  }
}


// const sortIntColumn = (rowA, rowB, columnId, desc) =>  (parseInt(rowA.values[columnId]) < parseInt(rowB.values[columnId]) ? -1 : 1);
const sortIntColumn = (rowA, rowB, columnId, desc) =>  ( (!!rowA.values[columnId] && !rowB.values[columnId]) || ( parseInt(rowA.values[columnId]) > parseInt(rowB.values[columnId])) ? 1 : -1);

const sortFloatColumn = (rowA, rowB, columnId, desc) => ( (!!rowA.values[columnId] && !rowB.values[columnId]) || ( parseFloat(rowA.values[columnId]) > parseFloat(rowB.values[columnId])) ? 1 : -1);

// const sortColumnByArrayLength = (rowA, rowB, columnId, desc) => (rowA.values[columnId].length < rowB.values[columnId].length ? -1 : 1);
const sortColumnByArrayLength = (rowA, rowB, columnId, desc) => {
  if(!!rowA.values[columnId] && !!rowA.values[columnId].length  && !!rowB.values[columnId] && !!rowB.values[columnId].length )
    return(rowA.values[columnId].length < rowB.values[columnId].length ? -1 : 1)
  else if( (!!rowA.values[columnId] && !!rowA.values[columnId].length)  && (!rowB.values[columnId] || !rowB.values[columnId].length) )
    return(1);
  else if( (!!rowB.values[columnId] && !!rowB.values[columnId].length)  && (!rowA.values[columnId] || !rowA.values[columnId].length) )
    return(-1);
  // else if( (!!rowA.values[columnId] && !!rowB.values[columnId] ) )
  //   return(-1);
  // else if( (!!rowA.values[columnId] &&  !rowB.values[columnId]  ) )
  //   return(1);
  // else if( (!rowA.values[columnId] &&  !!rowB.values[columnId]  ) )
  //   return(-1);
}


// const AnalysisTypeSelect = (props) => {
//
// {/* <AnalysisTypeSelect className="my-1" onChange={ selectedOption => setParquetAnalysisType(selectedOption.value)}/> */}
// {/* <AnalysisTypeSelect 
//       className="w-100"
//       defaultValueIndex={0
//       onChange={ selectedOption => {
//         setValues({ ...values, ANALYSIS_ID: `${values.ANALYSIS_NAME_SUFFIX}_${selectedOption.value}`, ANALYSIS_NAME:`${values.ANALYSIS_NAME_SUFFIX.split('T')[0]}`, ANALYSIS_TYPE: selectedOption.value})
//       }}
//     /> */}
//
//   return(
//     <Select {...props}
//     // className="w-100"
//     // defaultValue={analysisTypeOptions[0]}
//     // onChange={ selectedOption => {
//     //   setValues({ ...values, ANALYSIS_ID: `${values.ANALYSIS_NAME_SUFFIX}_${selectedOption.value}`, ANALYSIS_NAME:`${values.ANALYSIS_NAME_SUFFIX.split('T')[0]}`, ANALYSIS_TYPE: selectedOption.value})
//     // }}
//     options={analysisTypeOptions}
//     placeholder='Select Analysis'
//   />
//   )
// }

const CalcDamagingScores = (currentVariant) => {

  let nDamagingMeta =0;
  let nUnknownMeta =0;
  let nToleratedMeta =0;
  let nTotalMeta =0;

  let nDamaging =0;
  let nUnknown =0;
  let nTolerated =0;
  let nTotal =0;
  let nNoCats =0;
  
  function processMetaPrediction(toolName){
    if (currentVariant[toolName]!==undefined && currentVariant[toolName]!==null){
      nTotalMeta += 1;
      if (currentVariant[toolName].toLowerCase() === 't') nToleratedMeta += 1;
      if (currentVariant[toolName].toLowerCase() === 'u') nUnknownMeta += 1;
      if (currentVariant[toolName].toLowerCase() === 'd') nDamagingMeta += 1;
    }
  }

  ['DBNSFP_METALR_PRED','DBNSFP_METASVM_PRED','DBNSFP_METARNN_PRED'].forEach(k => processMetaPrediction(k))
  if (currentVariant.DBNSFP_REVEL_SCORE!==undefined && currentVariant.DBNSFP_REVEL_SCORE!==null){
    nTotalMeta += 1;
    if(currentVariant.DBNSFP_REVEL_SCORE > 0.5)
      nDamagingMeta += 1
    else
      nToleratedMeta += 1
  }

  function processPrediction(toolName){
    if (currentVariant[toolName]!==undefined && currentVariant[toolName]!==null){
      nTotal += 1;
      if (currentVariant[toolName].toLowerCase() === 't' || currentVariant[toolName].toLowerCase() === 'b' ||
        currentVariant[toolName].toLowerCase() === 'n' || currentVariant[toolName].toLowerCase() === 'l') nTolerated += 1;
      else if (currentVariant[toolName].toLowerCase() === 'u') nUnknown += 1;
      else if (currentVariant[toolName].toLowerCase() === 'd' || currentVariant[toolName].toLowerCase() === 'p' || 
      currentVariant[toolName].toLowerCase() === 'm' || currentVariant[toolName].toLowerCase() === 'h') nDamaging += 1;
      else nNoCats +=1;
    }
  }

  [
    // 'DBNSFP_ALOFT_PRED',
    'DBNSFP_BAYESDEL_ADDAF_PRED',
    'DBNSFP_BAYESDEL_NOAF_PRED',
    'DBNSFP_CLINPRED_PRED',
    'DBNSFP_DEOGEN2_PRED',
    // 'DBNSFP_EIGEN_PRED',
    // 'DBNSFP_EIGENPC_PRED',
    'DBNSFP_FATHMM_PRED',
    'DBNSFP_FATHMM-MKL_CODING_PRED',
    'DBNSFP_FATHMM-XF_CODING_PRED',
    'DBNSFP_LIST-S2_PRED',
    'DBNSFP_LRT_PRED',
    'DBNSFP_M-CAP_PRED',
    // 'DBNSFP_MVP_PRED',
    // 'DBNSFP_MUTPRED_PRED',
    'DBNSFP_MAX_MUTATIONASSESSOR_PRED', //* 'DBNSFP_MAX_MUTATIONTASTER_PRED', this one is done differently 
    'DBNSFP_PROVEAN_PRED',
    'DBNSFP_MAX_POLYPHEN2_HDIV_PRED',
    'DBNSFP_MAX_POLYPHEN2_HVAR_PRED',
    'DBNSFP_PRIMATEAI_PRED',
    'DBNSFP_SIFT_PRED',
    'DBNSFP_SIFT4G_PRED'
    // 'DBNSFP_MIN_SIFT_PRED',
    // 'DBNSFP_MIN_SIFT4G_PRED',
    // 'DBNSFP_MIN_FATHMM_PRED',
    // 'DBNSFP_MIN_PROVEAN_PRED',
    // 'DBNSFP_MAX_DEOGEN2_PRED',
    // 'DBNSFP_MAX_LIST-S2_PRED',
    // 'DBNSFP_MAX_VEST4_SCORE',
    // 'DBNSFP_MAX_METARNN_SCORE',
    // 'DBNSFP_MAX_MVP_SCORE',
    // 'DBNSFP_MAX_MPC_SCORE',
  ].forEach(k => processPrediction(k))
  //* processPrediction('DBNSFP_MAX_MUTATIONASSESSOR_PRED'); // N, L, | M, H == Neutral, Low, | Medium, High
  
  
  //* processPrediction('DBNSFP_MAX_MUTATIONTASTER_PRED');   // N, P, | D, A == Polymorphism automatic, Polymorphism, | Disease causing, Disease causing automatic
  if (currentVariant.DBNSFP_MAX_MUTATIONTASTER_PRED!==undefined && currentVariant.DBNSFP_MAX_MUTATIONTASTER_PRED!==null){
    nTotal += 1;
    if (currentVariant.DBNSFP_MAX_MUTATIONTASTER_PRED.toLowerCase() === 'p' ||
      currentVariant.DBNSFP_MAX_MUTATIONTASTER_PRED.toLowerCase() === 'n') nTolerated += 1;
    else if (currentVariant.DBNSFP_MAX_MUTATIONTASTER_PRED.toLowerCase() === 'd' ||
    currentVariant.DBNSFP_MAX_MUTATIONTASTER_PRED.toLowerCase() === 'a') nDamaging += 1;
    else nNoCats +=1;
  }
  if (currentVariant.DBNSFP_CADD_PHRED!==undefined && currentVariant.DBNSFP_CADD_PHRED!==null){
    nTotal += 1;
    if(currentVariant.DBNSFP_CADD_PHRED > 19)
      nDamaging += 1
    else
      nTolerated += 1
  }
  if (currentVariant.DBNSFP_DANN_SCORE!==undefined && currentVariant.DBNSFP_DANN_SCORE!==null){
    nTotal += 1;
    if(currentVariant.DBNSFP_DANN_SCORE > 0.5)
      nDamaging += 1
    else
      nTolerated += 1
  }
  // if (variant.DBNSFP_REVEL_SCORE!==undefined && variant.DBNSFP_REVEL_SCORE!==null){
  //   nTotal += 1;
  //   if (variant.DBNSFP_METALR_PRED.toLowerCase() === 't') nTolerated += 1;
  //   if (variant.DBNSFP_METALR_PRED.toLowerCase() === 'u') nUnknown += 1;
  //   if (variant.DBNSFP_METALR_PRED.toLowerCase() === 'd') nDamaging += 1;
  // }

  return([nDamagingMeta,nUnknownMeta,nToleratedMeta,nTotalMeta,nDamaging,nUnknown,nTolerated,nTotal,nNoCats])
}

const ConstructFilterFunctions = (filter) => {
  let functionTerms = '';
  Object.values(filter.filter_by).forEach( (term, termInd) => {
    let operation = '===';

    let lowerCaseIt = '';
    if(filter.lower_case_it === true){
      lowerCaseIt = '.toLowerCase()';
    }

    switch(term.operation){
      case 'includes':
        operation = '.includes';
        Object.values(term.selTerms).forEach( (value, valueInd) => {
          if(filter.lower_case_it === true && !!value){
            value = value.toLowerCase();
          }
          if(typeof(value) === 'string'){
            value = "'" + value +"'"
          }
          if (Object.values(term.selTerms).length > 1 && valueInd === 0){
            functionTerms += " ( "
          }
          functionTerms +=  " t['"+filter.column_id+"']"+lowerCaseIt+operation+"("+value+") ";
          if (Object.values(term.selTerms).length > 1 && valueInd < (Object.values(term.selTerms).length-1) ){
            functionTerms += " || "
          }
          if (Object.values(term.selTerms).length > 1 && valueInd === (Object.values(term.selTerms).length-1)){
            functionTerms += " ) "
          }
        });
        break;
      case 'not_includes':
        operation = '.includes';
        Object.values(term.selTerms).forEach( (value, valueInd) => {
          if(filter.lower_case_it === true && !!value){
            value = value.toLowerCase();
          }
          if(typeof(value) === 'string'){
            value = "'" + value +"'"
          }
          if (Object.values(term.selTerms).length > 1 && valueInd === 0){
            functionTerms += " ( "
          }
          functionTerms +=  " ( (t['"+filter.column_id+"']!==undefined && t['"+filter.column_id+"']!==null) ? !t['"+filter.column_id+"']"+lowerCaseIt+operation+"("+value+") : true) ";
          // functionTerms +=  " t['"+filter.column_id+"']!==null && !t['"+filter.column_id+"']"+lowerCaseIt+operation+"("+value+") ";
          // functionTerms +=  " !t['"+filter.column_id+"']"+lowerCaseIt+operation+"("+value+") ";
          if (Object.values(term.selTerms).length > 1 && valueInd < (Object.values(term.selTerms).length-1) ){
            functionTerms += " || "
          }
          if (Object.values(term.selTerms).length > 1 && valueInd === (Object.values(term.selTerms).length-1)){
            functionTerms += " ) "
          }
        });
        break;
      case 'startsWith':
        operation = '.startsWith';
        Object.values(term.selTerms).forEach( (value, valueInd) => {
          if(filter.lower_case_it === true && !!value){
            value = value.toLowerCase();
          }
          if(typeof(value) === 'string'){
            value = "'" + value +"'"
          }
          if (Object.values(term.selTerms).length > 1 && valueInd === 0){
            functionTerms += " ( "
          }
          functionTerms +=  " t['"+filter.column_id+"']"+lowerCaseIt+operation+"("+value+") ";
          if (Object.values(term.selTerms).length > 1 && valueInd < (Object.values(term.selTerms).length-1) ){
            functionTerms += " || "
          }
          if (Object.values(term.selTerms).length > 1 && valueInd === (Object.values(term.selTerms).length-1)){
            functionTerms += " ) "
          }
        });
        break;
      case 'endsWith':
        operation = '.endsWith';
        Object.values(term.selTerms).forEach( (value, valueInd) => {
          if(filter.lower_case_it === true && !!value){
            value = value.toLowerCase();
          }
          if(typeof(value) === 'string'){
            value = "'" + value +"'"
          }
          if (Object.values(term.selTerms).length > 1 && valueInd === 0){
            functionTerms += " ( "
          }
          functionTerms +=  " t['"+filter.column_id+"']"+lowerCaseIt+operation+"("+value+") ";
          if (Object.values(term.selTerms).length > 1 && valueInd < (Object.values(term.selTerms).length-1) ){
            functionTerms += " || "
          }
          if (Object.values(term.selTerms).length > 1 && valueInd === (Object.values(term.selTerms).length-1)){
            functionTerms += " ) "
          }
        });
        break;
      case '=':
        operation = '===';
        Object.values(term.selTerms).forEach( (value, valueInd) => {
          
          if(filter.lower_case_it === true && !!value){
            value = value.toLowerCase();
          }
          if(typeof(value) === 'string'){
            value = "'" + value +"'"
          }
          if (Object.values(term.selTerms).length > 1 && valueInd === 0){
            functionTerms += " ( "
          }
          functionTerms +=  " t['"+filter.column_id+"']"+lowerCaseIt+" "+operation+" "+value+" ";
          if (Object.values(term.selTerms).length > 1 && valueInd < (Object.values(term.selTerms).length-1) ){
            functionTerms += " || "
          }
          if (Object.values(term.selTerms).length > 1 && valueInd === (Object.values(term.selTerms).length-1)){
            functionTerms += " ) "
          }
        });
        break;
      case '!=':
        operation = '!==';
        Object.values(term.selTerms).forEach( (value, valueInd) => {
          if(filter.lower_case_it === true && !!value){
            value = value.toLowerCase();
          }
          if(typeof(value) === 'string'){
            value = "'" + value +"'"
          }
          if (Object.values(term.selTerms).length > 1 && valueInd === 0){
            functionTerms += " ( "
          }
          // console.log(filter.column_id + '  ' + valueInd + '  '+ value);
          if(filter.column_id==="EFFECT" && (value==="'synonymous SNV'" || value==="'synonymous snv'"))
            functionTerms +=  "(!( t['EFFECT']"+lowerCaseIt+" === "+value+" && t['LOC_IN_GENE']==='coding_sequence')) ";
          else
            functionTerms +=  " t['"+filter.column_id+"']"+lowerCaseIt+" "+operation+" "+value+" ";
          
          if (Object.values(term.selTerms).length > 1 && valueInd < (Object.values(term.selTerms).length-1) ){
            functionTerms += " && "
          }
          if (Object.values(term.selTerms).length > 1 && valueInd === (Object.values(term.selTerms).length-1)){
            functionTerms += " ) "
          }
        });
        // if(filter.column_id==="EFFECT") console.log("functionTerms", functionTerms);
        break;
      case '>':
        operation = '>';
        // // functionTerms +=  "  (t['"+filter.column_id+"']===undefined || t['"+filter.column_id+"'] "+operation+" this.value) ";
        functionTerms +=  " t['"+filter.column_id+"'] "+operation+" this.value ";
        break;
      case '<':
        operation = '<';
        // // functionTerms +=  " (t['"+filter.column_id+"']===undefined || t['"+filter.column_id+"'] "+operation+" this.value) ";
        functionTerms +=  " t['"+filter.column_id+"'] "+operation+" this.value ";
        break;
        // case 'special_!==':
        // operation = '!==';
        // functionTerms +=  " t["+filter.column_id+"] " + " !== 'ref' ";
        // functionTerms = " return( (t) => ( t["+filter.column_id+"] !== 'xxref' ) )";
        // console.log(functionTerms);
        // return("return( (t) => ( t["+filter.column_id+"] !== 'xxref' ) )");
        // return(" t["+filter.column_id+"] !== 'ref' ");
        // return( Function(inPMFColNames, "return( (t) => (t[inPMFColNames[0]]!=='ref') )");
        // (t)=>(t['P_GT']!=='ref') // ! <----- fix this  --------!
        // break;
      case '|>|':
        operation = '>';
        // // functionTerms +=  "  (t['"+filter.column_id+"']===undefined || t['"+filter.column_id+"'] "+operation+" this.value) ";
        functionTerms +=  " Math.abs(t['"+filter.column_id+"']) "+operation+" this.value ";
        break;
      case '|<|':
        operation = '<';
        // // functionTerms +=  " (t['"+filter.column_id+"']===undefined || t['"+filter.column_id+"'] "+operation+" this.value) ";
        functionTerms +=  " Math.abs(t['"+filter.column_id+"']) "+operation+" this.value ";
        break;
      case 'len=':
        operation = '===';
        Object.values(term.selTerms).forEach( (value, valueInd) => {
          // if(filter.lower_case_it === true && !!value){
          //   value = value.toLowerCase();
          // }
          // if(typeof(value) === 'string'){
          //   value = "'" + value +"'"
          // }
          if (Object.values(term.selTerms).length > 1 && valueInd === 0){
            functionTerms += " ( "
          }
          // functionTerms +=  " t['"+filter.column_id+"']"+lowerCaseIt+operation+"("+value+") ";
          functionTerms +=  " t['"+filter.column_id+"'].length "+operation+value;
          if (Object.values(term.selTerms).length > 1 && valueInd < (Object.values(term.selTerms).length-1) ){
            functionTerms += " || "
          }
          if (Object.values(term.selTerms).length > 1 && valueInd === (Object.values(term.selTerms).length-1)){
            functionTerms += " ) "
          }
        });
        break;
      case 'len!=':
        operation = '!=';
        Object.values(term.selTerms).forEach( (value, valueInd) => {
          // if(filter.lower_case_it === true && !!value){
          //   value = value.toLowerCase();
          // }
          // if(typeof(value) === 'string'){
          //   value = "'" + value +"'"
          // }
          if (Object.values(term.selTerms).length > 1 && valueInd === 0){
            functionTerms += " ( "
          }
          // functionTerms +=  " t['"+filter.column_id+"']"+lowerCaseIt+operation+"("+value+") ";
          functionTerms +=  " t['"+filter.column_id+"'].length "+operation+value;
          if (Object.values(term.selTerms).length > 1 && valueInd < (Object.values(term.selTerms).length-1) ){
            functionTerms += " || "
          }
          if (Object.values(term.selTerms).length > 1 && valueInd === (Object.values(term.selTerms).length-1)){
            functionTerms += " ) "
          }
        });
        break;
      case 'complex_notes':
        // operation = '<';
        functionTerms +=  " ( (t['VANEX_TAGS']===1) || (t['VANEX_NOTES']['ALL_USERS_NOTES'].filter(t => t['VANEX_TAGS']===1).length > 0) ) ";
        break;
      // case 'complex_notes_c':
      //   // operation = '<';
      //   functionTerms +=  " ( t['VANEX_TAGS']===1 || t['VANEX_TAGS']===2 ) ";
      //   break;
      default:
        break;
    }

    if (functionTerms!=='' && Object.values(filter.filter_by).length > 0 && termInd < (Object.values(filter.filter_by).length-1) ){
      functionTerms += " && "
    }

  });

  const CLINVAR_PLP_RESCUE_CODE = "  ( (!!IGNORE_FILTERS_4_PLP_CALLS && t['CLINVAR_CLNSIG']!==undefined && t['CLINVAR_CLNSIG']!==null) ?  !t['CLINVAR_CLNSIG'].toLowerCase().includes('conflicting') && ( t['CLINVAR_CLNSIG'].toLowerCase().includes('pathogenic') || t['CLINVAR_CLNSIG'].toLowerCase().includes('likely_pathogenic') ) : false ) "

  if(filter.column_id==="proband"){  // term.operation==="special_!=="
    return(Function("IGNORE_FILTERS_4_PLP_CALLS, inPMFColNames","{ return( (t) => (t[inPMFColNames[0]]!=='ref') ) }"));
    // return(Function("IGNORE_FILTERS_4_PLP_CALLS, inPMFColNames"," { return( (t) => (  (t[inPMFColNames[0]]!=='ref')  || (" + CLINVAR_PLP_RESCUE_CODE + ") ) )}"));
    // return(Function("{ return( (t) => ( t['Qual'] > 120 ) ) }"));
  }
  
  if(filter.column_id==="QUAL&ALTDEPTH"){
    return(Function("IGNORE_FILTERS_4_PLP_CALLS, inPMFColNames","{ return( (t) => ( (t['QUAL']>75) && (t[`${inPMFColNames[0].substr(0,inPMFColNames[0].length-2)}AD`]>3) ) ) }"));
  }

  if(functionTerms==='') {functionTerms = 'true';}
  if(!!filter.exclude_null_values && filter.exclude_null_values[0]){
    functionTerms = " (t['"+filter.column_id+"']) ? (" + functionTerms + " ) : "+filter.exclude_null_values[1].toString()+ " ";
  }
  // let definedFunction = "return( (t) => (" + functionTerms + ") )";
  // let definedFunction = "return( (t) => ( (t['"+filter.column_id+"']===undefined) || (" + functionTerms + ") ) )";
  let definedFunction = "return( (t) => ( (t['"+filter.column_id+"']===undefined) || (" + functionTerms + ") || (" + CLINVAR_PLP_RESCUE_CODE + ") ) )";

  // console.log(definedFunction); // ! uncomment this to see all the filters!
  
  // return(Function(definedFunction));
  return(Function("IGNORE_FILTERS_4_PLP_CALLS, inPMFColNames", definedFunction));
  // * // some output examples:
  // return( (t) => ( t['INHERIT_MODEL'] === 'dn' ) )
  // return( (t) => ( t['INHERIT_MODEL'] === 'ar' ) )


  // return( (t) => ( (t['GNOMAD_WG_FAF95_POPMAX']===undefined) || ( t['GNOMAD_WG_FAF95_POPMAX'] < this.value ) ) )
  // return( (t) => ( (t['GNOMAD_EX_FAF95_POPMAX']===undefined) || ( t['GNOMAD_EX_FAF95_POPMAX'] < this.value ) ) )
  // return( (t) => ( (t['OMIM_PHENOTYPES']===undefined) || ( t['OMIM_PHENOTYPES'] !== null ) ) )
  // return( (t) => ( (t['LOC_IN_GENE']===undefined) || ( (  t['LOC_IN_GENE'] !== 'UNKNOWN'  &&  t['LOC_IN_GENE'] !== '3_prime_UTR'  &&  t['LOC_IN_GENE'] !== '5_prime_UTR'  &&  t['LOC_IN_GENE'] !== 'splice_region_3_prime_UTR'  &&  t['LOC_IN_GENE'] !== 'splice_region_5_prime_UTR'  &&  t['LOC_IN_GENE'] !== 'extended_intronic_splice_region'  &&  t['LOC_IN_GENE'] !== '3_prime_UTR;5_prime_UTR'  &&  t['LOC_IN_GENE'] !== 'splice_site_UTR'  &&  t['LOC_IN_GENE'] !== '5_prime_UTR;3_prime_UTR'  ) ) ) )
  // return( (t) => ( (t['EFFECT']===undefined) || ( t['EFFECT'] !== 'synonymous SNV' ) ) )
  // return( (t) => ( (t['IGM_CLINICAL_EXOME_ALT_SAMPLE_COUNT']===undefined) || ( t['IGM_CLINICAL_EXOME_ALT_SAMPLE_COUNT'] < this.value ) ) )
  // return( (t) => ( (t['QUAL']===undefined) || ( t['QUAL'] > this.value ) ) )
  // return( (t) => ( (t['CLINVAR_CLNSIG']===undefined) || ( (t['CLINVAR_CLNSIG']) ? ( (  t['CLINVAR_CLNSIG'].toLowerCase().includes('pathogenic')  ||  t['CLINVAR_CLNSIG'].toLowerCase().includes('likely_pathogenic')  )  &&  ( (t['CLINVAR_CLNSIG']!==undefined && t['CLINVAR_CLNSIG']!==null) ? !t['CLINVAR_CLNSIG'].toLowerCase().includes('conflicting') : true)  ) : false ) ) )
  // return( (t) => ( (t['CHROMOSOME']===undefined) || ( t['CHROMOSOME'] === 'X' ) ) )
  // return( (t) => ( (t['EFFECT']===undefined) || ( (t['EFFECT']) ? ( (  t['EFFECT'].toLowerCase() !== 'synonymous snv'  &&  t['EFFECT'].toLowerCase() !== 'nonsynonymous snv'  &&  t['EFFECT'].toLowerCase() !== 'nonframeshift deletion'  )  ) : true ) ) )
  // return( (t) => ( (t['GENE']===undefined) || ( (t['GENE']) ? ( t['GENE'].toLowerCase().startsWith('mt-')  ) : false ) ) )
  
  // * // simplified version! if (filter.filter_by[0].operation === '='){definedFunction = Function("return( (t) => (t['"+filter.column_id+"'] === '"+filter.filter_by[0].selTerms+"'))");}

}

// ? only used in App.test.js
function aaPosition2relativeX_test(aaPosition,visibleWindow , proteinBoxLength){
  if(!aaPosition) return 0;
    let visibleWindowLength=visibleWindow[1]-visibleWindow[0];
    return( (proteinBoxLength*( (aaPosition-visibleWindow[0])/visibleWindowLength) || 0 ));
}

export {GetSignedURL, GetSampleRank, GetHeatedQualityScore, VanexCNVGenotypeCell, VanexSVGenotypeCell, VanexSVQUALCell,
  EditableCell, VanexGenotypeCell, EditableTextArea, VanexNoteCell, VanexTagCell, VanexReviewCell, VanexSegmentSizeCell, VanexMrPhenePhenoTermsCell, VanexArrayCell,
  sortIntColumn, sortFloatColumn, sortColumnByArrayLength,
  AnalysisTypeOptions, CaseStatusOptions, ConstructFilterFunctions, CalcDamagingScores,
  aaPosition2relativeX_test, prettySize, separateBy1000
}

