import React, {useState, useEffect, useRef } from 'react'; // Component, useMemo // const { useState, useEffect, useCallback, useRef } = React; 
import CanvasBox from "./CanvasBox";
import SegmentTrack from "./SegmentTrack";
import Axis from "./Axis";
import CHROMOSOMES_LEN from '../../resources/chromosomes_length.json';
// import tmpGeneData from "./varScan.CNV.cbs.chr21.infile";


//? --------------------------------------------- helper functions ----------------------------------
// ? readTextFile is only needed for local testing
// var cnvInfileText='';
// const readTextFile = (file) => {
//   cnvInfileText='';
//   let rawFile = new XMLHttpRequest();
//   rawFile.open("GET", file, false);
//   rawFile.onreadystatechange = function ()
//   {
//     if(rawFile.readyState === 4)
//     {
//       if(rawFile.status === 200 || rawFile.status === 0)
//       {
//         cnvInfileText=rawFile.responseText;
//       }
//     }
//   }
//   rawFile.send(null);
// }


//? --------------------------------------------- CnvPlotter ----------------------------------

const CnvPlotter = (props) => {

  const mainDivRef = useRef(null);
  const SVG_boxRef = useRef();
  // const Canvas_boxRef = useRef();

  const SVG_x = 0;
  const SVG_y = 0;
  // const [SVG_coordinates, setSVG_coordinates] = useState({left:0, top:0});
  // const [svgBoxCoordinates, setSvgBoxCoordinates] = useState([0,0]);
  // const [Canvas_coordinates, setCanvas_coordinates] = useState({left:0, top:0});
  
  // const SVG_width = 1600; //1500; // 1740;
  // const SVG_width = '100%'; //1500; // 1740;
  const [SVG_width, setSVG_width] = useState(1350);  // * 1450
  const [SVG_height, setSVG_height] = useState(400); // * 270

  // const SVG_Margin= [0, 0, 0, 0];

  // const [dataPoints, setDataPoints] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  // const [structureLength, setStructureLength] = useState(46697701);
  const [structureLength, setStructureLength] = useState(1);
  // const [tracksTotalHeight, setTracksTotalHeight] = useState(200);

  // const [visibleWindow, setVisibleWindow] = useState([1, 46697701, -3, 3]); // xMin, xMax, yMin, yMax
  const [visibleWindow, setVisibleWindow] = useState([1, 1, -3, 3]); // xMin, xMax, yMin, yMax
  // const [zoomInOrOut, setZoomInOrOut] = useState('in');
  // const [zoomlevel, setZoomLevel] = useState(1);

  const [trackOffset_x, setTrackOffset_x] = useState(SVG_x);
  const [trackOffset_y, setTrackOffset_y] = useState(SVG_y);

  const [initial_pageX, setInitial_pageX] = useState(0);
  const [pageX, setPageX] = useState(0);
  const [isDraggingSVG, setIsDraggingSVG] = useState(false);


  const yAxisWidth = 30;
  const xAxisHeight = 25;

  // const xAxisMargin = [0, 0, 0, 0];
  // const yAxisMargin = [0, 0, 0, 0];// [5, 5, 5, 5];
  const xAxisMargin = [4, 30, 0, 0];
  const yAxisMargin = [10, 4, 0, 0];// [5, 5, 5, 5];
  // *  Top, Right, Bottom, Left

  // const trackMargin =  [0, 0, 0, 0];//[5, 10, 5, 5]; // props.margin_x || [5, 10];

  const track_x = trackOffset_x+yAxisMargin[3]+yAxisWidth+yAxisMargin[1]+xAxisMargin[3]; // +xAxisMargin;
  const track_y = trackOffset_y+yAxisMargin[0]; // +xAxisHeight
  const track_width  = SVG_width -yAxisMargin[3]-yAxisWidth -yAxisMargin[1]-xAxisMargin[3]-xAxisMargin[1];
  const track_height = SVG_height-yAxisMargin[0]-xAxisHeight-yAxisMargin[2]-xAxisMargin[0]-xAxisMargin[2];

  const xAxisWidth = track_width;
  const yAxisHeight = track_height;
  const centerY = (yAxisHeight/2)+SVG_y+yAxisMargin[0];

  // const [tracksHeight, setTracskHeight] = useState(250);
  // const [topTracksHeight, setTopTracskHeight] = useState(25);
  // const [bottomTracskHeight, setBottomTracskHeight] = useState(25);
  // const minTotalTrackHeight=150;


  // const logRatioThresholdForCanvasData = 1;
  // const logRatioThresholdForSVGData = 0.5;

  const memoizedDataPoints = React.useMemo(() => {
    // if(!!dataPoints  )
    // readTextFile(tmpGeneData);
    // return(parse_infile_data(cnvInfileText));

    return(props.plotInfileData);
  }, [props.plotInfileData]);
    // }, [])

  const memoizedSegmentsData = React.useMemo(() => {
    if(!!props.plotSegmentsData  )
      return(props.plotSegmentsData);
      // return(props.plotSegmentsData.filter(seg => (seg.SEG_MEAN > logRatioThresholdForSVGData || seg.SEG_MEAN < -logRatioThresholdForSVGData) ));
    else
      return [];
  }, [props.plotSegmentsData]);

  // const memoizedDataPoints = React.useMemo(() => {
  //   // if(!!dataPoints  )
  //   return(dataPoints);
  // }, [dataPoints]);

  // const memoizedSegmentsData = React.useMemo(() => {
  //   if(!!props.segments_data  )
  //     return(props.segments_data.filter(seg => (seg.SEG_MEAN > 0.25 || seg.SEG_MEAN < -0.25) ));
  //   else
  //     return [];
  // }, [props.segments_data]);


  // useEffect(() => {
  //     setSVG_height(tracksTotalHeight+5); //! SVG_height needs to get calculated as the sum of all tracks+50! 
  // }, [tracksTotalHeight])


  useEffect(() => {
    // console.log("props.plotInfileData:", props.plotInfileData);
    // console.log("props.chromosome", props.chromosome);
    // console.log("props.chromosome_LEN", CHROMOSOMES_LEN[`chr${props.chromosome}`]);
    let strLen = 1;
    if(!! CHROMOSOMES_LEN[`chr${props.selectedSegment.CHROMOSOME}`]) strLen=CHROMOSOMES_LEN[`chr${props.selectedSegment.CHROMOSOME}`];
    // console.log("strLen", strLen);
    setStructureLength(strLen);
  }, [props.plotInfileData, props.selectedSegment.CHROMOSOME])


  useEffect(() => {
    // console.log("structureLength", structureLength);
    setVisibleWindow([1, structureLength, -3, 3]);
  }, [structureLength])


  // useEffect( () => {
  //   if(mainDivRef.current){
  //     // console.log(mainDivRef.current);
  //     // console.log(mainDivRef.current.offsetWidth);
  //     // let height = mainDivRef.current.offsetHeight;
  //     setSVG_width(mainDivRef.current.offsetWidth);
  //   }
  // }, [mainDivRef]);


  // useEffect(() => {
  //   if(!!SVG_boxRef.current){
  //     let svgBox = SVG_boxRef.current.getBoundingClientRect();
  //     const {left, top} = svgBox;
  //     setSVG_coordinates({left:left, top:top});
  //   }
  //   // if(!!Canvas_boxRef.current){
  //   //   let canvasBox = Canvas_boxRef.current.getBoundingClientRect();
  //   //   const {left, top} = canvasBox;
  //   //   setCanvas_coordinates({left:left, top:top}); 
  //   // }
  // }, [SVG_boxRef.current, Canvas_boxRef.current]);


  const renderCanvas = () => {
    let canvasWidth=100;
    if(mainDivRef.current){
      // let height = mainDivRef.current.offsetHeight;
      canvasWidth=(mainDivRef.current.offsetWidth);
    }

    return (
      <CanvasBox 
        x={trackOffset_x}
        y={trackOffset_y}
        width={SVG_width}
        height={SVG_height}
        track_x={track_x}
        track_y={track_y}
        track_width={track_width}
        track_height={track_height}
        data_points={memoizedDataPoints}
        // data_points={memoizedDataPoints.filter(k => (k.LOG_RATIO > -logRatioThresholdForCanvasData && k.LOG_RATIO < logRatioThresholdForCanvasData) )}
        visible_window={visibleWindow}
        // segments_data={props.segments_data}
        // segments_data={props.segments_data.filter(seg => (seg.SEG_MEAN < 0.25 && seg.SEG_MEAN > -0.25) )}
        // className='mt-2'
        // id='myCanvas_main'
      />);
    // const canvas = Canvas_boxRef.current;
    // const context = canvas.getContext('2d');
    // context.fillStyle = '#000000'
    // context.fillRect(0, 0, context.canvas.width, context.canvas.height)
    // return(
    //   <canvas 
    //     ref={Canvas_boxRef}
    //     x={0}
    //     y={0}
    //     width={SVG_width}
    //     height={SVG_height}
    //     className='mt-2'
    //     id='myCanvas_main'
    //     />
    // );
  }


  const handleMouseDown = (e) => {
    setIsDraggingSVG(true);
    setInitial_pageX(e.pageX);
  };
  
  const handleMouseLeave = (e) => {
    setIsDraggingSVG(false);
    setTrackOffset_x(0);
  }

  const handleMouseUp = (e) => {
    const windowSize=visibleWindow[1]-visibleWindow[0]+1;
    const panSize = Math.round(windowSize*trackOffset_x/SVG_width);
    // console.log(SVG_width, windowSize, trackOffset_x, panSize)
    let from=visibleWindow[0]-panSize;
    let to=visibleWindow[1]-panSize;
    if (from<1) from=1;
    if (from>structureLength-windowSize) from=structureLength-windowSize;
    if(to<windowSize) to=windowSize;
    if(to>structureLength) to=structureLength;
    setVisibleWindow([from, to, -3, 3]);
    setIsDraggingSVG(false);
    setTrackOffset_x(0);
  };
  
  const handleMouseMove = (e) => {
    e.preventDefault();
    const diffX = Math.abs(pageX - e.pageX);
    // const diffY = Math.abs(pageY - e.pageY);
    if(isDraggingSVG && (diffX > 5 ) ){ //|| diffY > 5
      // //!!!!!!!! const proteinBoxLength=SVG_width-trackMargin[0]-trackMargin[1];
      // const proteinBoxLength=SVG_width-20;
      // const single_aa_width = proteinBoxLength / (visibleWindow[1]-visibleWindow[0]+1);
      // const pan_by_aa = Math.round((3*(e.pageX-initial_pageX))/single_aa_width);
      // setTrackOffset_x(pan_by_aa*single_aa_width);
      setTrackOffset_x(1.5*(e.pageX-initial_pageX));
      setPageX(e.pageX);
      // setPageY(e.pageY);
    }
  };


  // const handleClick = (e) => {
  //   if (e.detail === 1) {
  //     // it was a single click
  //   } else if (e.detail === 2) {
  //     // it was a double click
  //   }
  // }

  const handleDoubleClick = (e) => {
    var rect = e.target.getBoundingClientRect();
    // var x = e.clientX - rect.left; //x position within the element.
    // var y = e.clientY - rect.top;  //y position within the element.
    // console.log("Left? : " + x + " ; Top? : " + y + ".");
    handleZoom(10,e, "in", e.clientX - rect.left - yAxisWidth);
  };

  // const handleDrag = (e) => {
  //   // e.preventDefault();
  //  console.log(e);
  // }


  const renderSVGandTracks = () => {
    return (
            <svg 
              xmlns='http://www.w3.org/2000/svg'
              ref={SVG_boxRef}
              x={trackOffset_x}
              y={trackOffset_y}
              // x={SVG_x}
              // y={SVG_y}
              width={SVG_width}
              height={SVG_height}
              className='svg-element'
              id='CNVPlot_main'
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              // onClick={handleClick}
              onDoubleClick={handleDoubleClick}
            >
              {(isLoading ? null : 
                <g>
                  {/* <rect
                    // // fill="#F9F9F9"
                    // fill="#FFFFFF"
                    // stroke="#ededed"
                    // fill="yellow"
                    stroke="black"
                    strokeWidth="1"
                    fill="none"
                    x={SVG_x}
                    y={SVG_y}
                    width={SVG_width}
                    height={SVG_height}
                  /> */}
                  <SegmentTrack
                    // x={SVG_x}
                    // y={SVG_y}
                    // width={SVG_width}
                    // height={SVG_height}
                    x={track_x}
                    y={track_y}
                    width={track_width}
                    height={track_height}
                    // SVG_coordinates={SVG_coordinates}
                    visibleWindow={visibleWindow}
                    dataPoints={memoizedDataPoints}
                    segments_data={memoizedSegmentsData}
                    selectedSegment={props.selectedSegment}
                    // selectedView={selectedView}
                    // set_structure_length={setStructureLength}
                    // setTrackHeight={setTracksTotalHeight}
                    // addToLegends={legendToAdd => setLegends(prvLegends => ({...prvLegends, 'DomainLegend':legendToAdd}))}
                  />
                  <Axis 
                    x={SVG_x+yAxisMargin[3]+yAxisWidth +yAxisMargin[1]+xAxisMargin[3]}
                    y={SVG_y+yAxisMargin[0]+yAxisHeight+yAxisMargin[2]+xAxisMargin[0]}
                    width={xAxisWidth}
                    height={xAxisHeight}
                    margin={xAxisMargin}
                    visibleWindow={visibleWindow}
                  />
                  <Axis
                    orientation="y"
                    x={SVG_x+yAxisMargin[3]}
                    y={SVG_y+yAxisMargin[0]}
                    width={yAxisHeight}
                    height={yAxisWidth}
                    margin={yAxisMargin}
                    visibleWindow={visibleWindow}
                  />
                  {/*//! this is moved to canvas!  */}
                  {/* <line
                    stroke="#c9c9c9"
                    strokeWidth="1"
                    x1={track_x}
                    y1={centerY}
                    x2={track_width}
                    y2={centerY}
                  /> */}
                </g>
              )}
            </svg>
         );
  }
    




  // * segments_data
  // {
  //   "CHROMOSOME": "7",
  //   "LOC_START": 5843242,
  //   "LOC_END": 5847569,
  //   "SEG_SIZE": 4327,
  //   "SEG_MEAN": -0.9138,
  //   "NUM_MARK": 5,
  //   "CENTROMERE_OVERLAPS": null,
  //   "ENTREZ_IDS": [],
  //   "GENE_SYMBOLS": [],
  //   "MIM_NUMBERS": [
  //       ""
  //   ],
  //   "RD0386_P_S-2023-009960_GT": 100,
  //   "RD0386_M_S-2022-025806_GT": 0,
  //   "RD0386_F_S-2022-025805_GT": 89,
  //   "ROW_INDEX": "0",
  //   "MR_PHENE_PHENOTYPE_TERMS": null,
  //   "VANEX_TAGS": false,
  //   "VANEX_NOTES": {
  //       "CURRENT_USER_NOTE": "",
  //       "UPDATED_AT": "",
  //       "ALL_USERS_NOTES": []
  //   },
  //   "VANEX_REVIEWS": {
  //       "REVIEW_VALUE": null,
  //       "DENOVO_ASSESSMENT": null,
  //       "UPDATED_AT": null
  //   }
  // }
  // console.log('segments_data: ', props.segments_data);
  // console.log('memoizedDataPoints: ', memoizedDataPoints);
  // return (
  //   <div className="">sss
  //   </div>
  // );



  const visibleWindowMaxZoom = 10000;

  const handlePan = (panL, e) => {
    let from=visibleWindow[0];
    let to=visibleWindow[1];
    const windowSize=visibleWindow[1]-visibleWindow[0]+1;
    if( (panL<0 && from>1 ) || (panL>0 && to<structureLength) ){
      switch(Math.abs(panL)) {
        case 3:
          // from+=Math.sign(panL)*windowSize;
          // to+=Math.sign(panL)*windowSize;
          from+=Math.sign(panL)*Math.floor(0.8*windowSize);
          to+=Math.sign(panL)*Math.floor(0.8*windowSize);
          break;
        case 10:
          from+=Math.sign(panL)*3*windowSize;
          to+=Math.sign(panL)*3*windowSize;
          break;
        default:
          if(to-from <= visibleWindowMaxZoom+3){
            from+=panL;
            to+=panL;
          } else {
            from+=Math.sign(panL)*Math.floor(0.1*windowSize);
            to+=Math.sign(panL)*Math.floor(0.1*windowSize);
          }
      }
      if (from<1) from=1;
      if (from>structureLength-windowSize) from=structureLength-windowSize;
      if(to<windowSize) to=windowSize;
      if(to>structureLength) to=structureLength;
      setVisibleWindow([from, to, -3, 3]);
    }
  }
  

  const handleZoom = (zoomL, e, zoomInOrOut="in", clickedPosition=null) => {
    const visWinSize = visibleWindow[1]-visibleWindow[0]+1;
    // console.log(clickedPosition)
    // console.log( track_width)
    // console.log( clickedPosition/track_width)
    // console.log( (visWinSize*clickedPosition/track_width))
    // console.log(Math.round(visWinSize*clickedPosition/track_width))
    // const winProportionSize=(!!clickedPosition ? Math.round(visWinSize*clickedPosition/track_width) : (visWinSize/zoomL));
    
    const winProportionSize= (visWinSize/zoomL);
    const halfZoomLevel=(zoomL-1)/2;
    let from=visibleWindow[0];
    let to=visibleWindow[1];

    if(!!clickedPosition){
      from=visibleWindow[0]+Math.round(visWinSize*clickedPosition/track_width)-Math.round(winProportionSize);
      to=visibleWindow[0]+Math.round(visWinSize*clickedPosition/track_width)+Math.round(winProportionSize);
    } else {
      if(zoomInOrOut==='in'){
        // console.log(Math.round(halfZoomLevel*winProportionSize));
        from+=Math.round(halfZoomLevel*winProportionSize);
        to-=Math.round(halfZoomLevel*winProportionSize);
      } else {
        from-=Math.round(halfZoomLevel*winProportionSize);
        to+=Math.round(halfZoomLevel*winProportionSize);
      }
    }
    if (from<1) from=1
    if(to>structureLength) to=structureLength;
    if(to-from < visibleWindowMaxZoom){
      let toAdd = Math.round((visibleWindowMaxZoom-(to-from))/2);
      from-=toAdd;
      to+=toAdd;
    }
    // console.log([from, to]);
    setVisibleWindow([from, to, -3, 3]);
  }



  return (
      <div>
        <div className="d-flex">
            <button title="Move hundred steps backward" className="btn btn-control btn-outline-secondary btn-sm m-0 p-0 minim ms-2" style={{margin:"0px", marginLeft:"0.25rem"}} onClick={e => handlePan(-10, e)}>{'<<<'}</button>
            <button title="Move ten steps backward" className="btn btn-control btn-outline-secondary btn-sm m-0 p-0 minim" onClick={e => handlePan(-3, e)}>{'<<'}</button>
            <button title="Move one step backward" className="btn btn-control btn-outline-secondary btn-sm m-0 p-0 minim" onClick={e => handlePan(-1, e)}>{'<'}</button>
            <button title="Move one step forward" className="btn btn-control btn-outline-secondary btn-sm m-0 p-0 minim" onClick={e => handlePan(1, e)}>{'>'}</button>
            <button title="Move ten steps forward" className="btn btn-control btn-outline-secondary btn-sm m-0 p-0 minim" onClick={e => handlePan(3, e)}>{'>>'}</button>
            <button title="Move hundred steps forward" className="btn btn-control btn-outline-secondary btn-sm m-0 p-0 minim" onClick={e => handlePan(10, e)}>{'>>>'}</button>
            
            <span className="fw-bold ms-3 me-1" >zoom in</span>
            <button title="1.5x zoom (in/out)" className="btn btn-control btn-outline-secondary btn-sm  m-0 p-0 minim btn-zoom" onClick={e => handleZoom(1.5, e)}>1.5x</button>
            <button title="3x zoom (in/out)" className="btn btn-control btn-outline-secondary btn-sm  m-0 p-0 minim btn-zoom" onClick={e => handleZoom(3, e)}>3x</button>
            <button title="10x zoom (in/out)" className="btn btn-control btn-outline-secondary btn-sm  m-0 p-0 minim btn-zoom" onClick={e => handleZoom(10, e)}>10x</button>
            
            <span className="fw-bold ms-3 me-1" >zoom out</span>
            <button title="1.5x zoom (in/out)" className="btn btn-control btn-outline-secondary btn-sm  m-0 p-0 minim btn-zoom" onClick={e => handleZoom(1.5, e, "out")}>1.5x</button>
            <button title="3x zoom (in/out)" className="btn btn-control btn-outline-secondary btn-sm  m-0 p-0 minim btn-zoom" onClick={e => handleZoom(3, e, "out")}>3x</button>
            <button title="10x zoom (in/out)" className="btn btn-control btn-outline-secondary btn-sm  m-0 p-0 minim btn-zoom" onClick={e => handleZoom(10, e, "out")}>10x</button>

            <button title="Reset zoom" className="btn btn-control btn-outline-secondary btn-sm  m-0 p-0 minim d-flex align-items-center justify-content-center ms-3" onClick={e => setVisibleWindow([1, structureLength, -3, 3])}><i className="fas fa-expand-arrows-alt"></i></button>

            <span className="fw-bold ms-3">Tip: Double click on the plot to zoom in!</span>
        </div>
        <div  
          // style={{border:'1px solid black', margin:'.5em', width:SVG_width+4}}
          style={{border:'1px solid black', margin:'0.2em 1em 0.2em 0.5em', position:'relative'}}
          className="chart-div"
          ref = {mainDivRef}>
          {(isLoading ? 
            <div className="loading-div d-flex flex-column align-items-center py-4 my-4">
              {(errorMsg!=='' ? 
                <div className="my-2">
                  <span className="fs-3">{errorMsg}</span>
                </div>              
              : 
              <div className="my-2">
                <span className="mx-3" role="status" aria-hidden="true"></span>
                <span className="fs-3">Loading...</span>
              </div>
              )}
            </div>
          
          : null)}
          <div className="" style={{height:SVG_height}}>
          {renderCanvas()}
          {renderSVGandTracks()}
          {/* {(isLoading ? null : 
            <div className="d-flex flex-column px-2 m-0">
              {Object.entries(legends).map( ([key, lgnd]) =>  <div key={key}>{lgnd}</div>)}
            </div>
          )} */}
          </div>
          <div className="d-flex flex-row flex-grow-1 justify-content-center mt-2" style={{position:'relative', width:SVG_width}}>
            <span className="text-small" style={{position:'absolute', left:'0px', bottom:'0px'}}>
              {/* {'Updated on: [ClinVar:2023-06-04],'} {(!!geneData && !!geneData.DB_INFO ? `[${geneData.DB_INFO.GENOME_ASSEMBLY}:${geneData.DB_INFO.UPDATED_ON}]` : '')} */}
            </span>
            {/* <span className="p-0 m-0" ><img src={CnDyPlot_logo} className="CnDyPlot-logo" alt="logo" /></span> */}
            <svg xmlns="http://www.w3.org/2000/svg"
              width="61mm"
              height="11mm"
              viewBox="0 0 62.000001 11"
            >
              <g style={{fill:'#1e497d'}}>
                <path
                  d="m 7.2648323,7.2949769 q 0,0.096463 -0.1266071,0.2532141 -1.1093188,1.3203305 -2.9481352,1.3203305 -1.8810188,0 -3.0506267,-1.284157 Q 0,6.3424097 0,4.4493331 0,2.6165455 1.1153477,1.3384174 2.2789266,1.527369e-7 4.0755408,1.527369e-7 q 1.9533656,0 3.0626844,1.1997523472631 0.1205782,0.132636 0.1205782,0.2351274 0,0.1145492 -0.4220235,0.8500758 -0.4159945,0.7355266 -0.5245148,0.8259602 -0.048231,0.048231 -0.1205782,0.048231 -0.030144,0 -0.3014453,-0.2411562 Q 5.5646807,2.6286034 5.2511775,2.4658229 4.7748939,2.2246666 4.2624369,2.2246666 q -0.8862493,0 -1.4167931,0.6631797 -0.4883414,0.6149485 -0.4883414,1.5192845 0,0.9163937 0.4883414,1.5373711 0.5305438,0.6752375 1.4167931,0.6752375 0.5245148,0 0.9947695,-0.2290984 0.3074743,-0.1507227 0.6270063,-0.4220235 0.2652719,-0.2290984 0.2954164,-0.2290984 0.060289,0 0.1205781,0.066318 0.096463,0.1085203 0.5245149,0.7536133 0.4401102,0.6692086 0.4401102,0.7355265 z"
                  id="path113" />
                <path
                  d="m 13.932803,5.6430566 q 0,0.4642258 0.05426,1.3806196 0.05426,0.9163937 0.05426,1.3745906 0,0.2411563 -0.211012,0.259243 -0.747584,0.05426 -1.712209,0.05426 -0.114549,0 -0.156752,-0.3436477 -0.03617,-0.3496766 -0.09043,-0.3557055 -0.04823,0.024116 -0.126607,0.1024914 -0.675238,0.6812665 -1.446938,0.6812665 -1.1756367,0 -1.9473367,-0.9284516 -0.7294977,-0.8681626 -0.7294977,-2.0739439 0,-1.3926773 0.7536133,-2.2427532 0.7837578,-0.8802203 2.1462911,-0.8802203 0.946538,0 1.495168,0.6631797 0.02412,0.030145 0.0422,0.030145 0.05426,0 0.120578,-0.2893875 0.07235,-0.2954164 0.168809,-0.2954164 0.22307,0 0.850076,0.1266071 0.596862,0.1205781 0.856105,0.2049828 0.09043,0.036173 0.09043,0.096462 0,0.024116 -0.01809,0.126607 -0.192925,1.0912321 -0.192925,2.3090712 z M 11.991495,5.67923 q 0,-0.512457 -0.325561,-0.8440469 -0.319532,-0.3376187 -0.838018,-0.3376187 -0.518486,0 -0.8380182,0.3315898 -0.319532,0.3315899 -0.319532,0.8500758 0,0.4883414 0.3376192,0.838018 0.337618,0.3496766 0.819931,0.3496766 0.512457,0 0.838018,-0.3315899 Q 11.991495,6.197716 11.991495,5.67923 Z"
                  id="path115" />
                <path
                  d="m 23.241434,0.38585017 q 0,0.68729533 -0.06029,2.06791493 -0.05426,1.3745906 -0.05426,2.0679149 v 3.9730493 q 0,0.1627805 -0.174838,0.1627805 -0.144694,0 -0.440111,0.012058 -0.289387,0.012058 -0.434081,0.012058 -0.156751,0 -0.470254,-0.024116 -0.313504,-0.024116 -0.470255,-0.024116 -0.10852,0 -0.259243,-0.1989539 -0.434081,-0.584804 -1.953366,-2.5924298 -0.415994,-0.5606883 -1.26607,-1.6519204 -0.02412,-0.036173 -0.09043,-0.072347 -0.04823,0.042202 -0.04823,0.1326359 0,0.6993531 0.03617,2.0980594 0.0422,1.3987063 0.0422,2.0980595 0,0.2110117 -0.162781,0.2110117 h -2.086001 q -0.174839,0 -0.174839,-0.1868961 0,-0.6511219 0.06029,-1.9654235 0.06632,-1.3143016 0.06632,-1.9714524 0,-0.6933242 -0.06029,-2.0739438 -0.05426,-1.3806196 -0.05426,-2.06791493 0,-0.13263594 0.235128,-0.13263594 0.349676,0 0.862133,-0.0482312 0.120578,-0.0120578 0.476284,-0.0783758 0.301445,-0.05426015 0.476283,-0.05426015 0.07235,0 0.22307,0.20498282 l 2.248782,3.08077114 q 0.301445,0.4159946 0.910365,1.2178391 0.150723,0.1868961 0.259243,0.1868961 0.07838,0 0.07838,-0.4702547 0,-0.6330351 -0.07235,-1.9051344 -0.06632,-1.2720992 -0.06632,-1.91116333 0,-0.22306954 0.126607,-0.22306954 0.361734,0 1.091232,-0.0301445 0.729498,-0.0301445 1.091232,-0.0301445 0.114549,0 0.114549,0.1868961 z"
                  id="path117" />
                <path
                  d="m 32.230532,4.3830151 q 0,1.7724985 -1.139463,3.0747423 -1.006827,1.163579 -2.827557,1.223868 -0.349677,0.012058 -1.923221,0.012058 -0.295417,0 -0.892278,-0.018087 -0.590833,-0.018087 -0.88625,-0.018087 -0.174838,0 -0.174838,-0.1386648 0,-0.6631798 0.04823,-1.9895392 0.05426,-1.3323883 0.05426,-1.995568 0,-0.6933242 -0.05426,-2.0739438 -0.04823,-1.3866485 -0.04823,-2.07394383 0,-0.12660704 0.174838,-0.15072267 0.428053,-0.0482312 1.404736,-0.0482312 1.856903,0 2.405533,0.0542602 1.428851,0.14469376 2.236725,0.72346877 0.759642,0.54260162 1.199752,1.51928442 0.422023,0.9224227 0.422023,1.8991055 z m -2.309071,0 q 0,-1.0188852 -0.608919,-1.6639781 -0.614949,-0.6692087 -1.627805,-0.6692087 -0.928452,0 -0.928452,0.2471852 0,0.3557055 -0.01206,1.0731454 -0.01206,0.7114109 -0.01206,1.0671164 0,0.3496766 0.01206,1.0550586 0.01206,0.6993532 0.01206,1.0550586 0,0.259243 0.964625,0.259243 1.043001,0 1.639863,-0.6933242 0.560688,-0.6571508 0.560688,-1.7302962 z"
                  id="path119" />
                <path
                  d="m 39.043197,4.3588995 q 0,1.1696079 -0.07235,1.4228219 -0.09043,0.3014454 -1.320331,2.1824642 -0.192925,0.2954164 -0.99477,1.3685617 -0.886249,1.1816657 -1.006827,1.2479837 -0.144694,0.09043 -1.850874,0.09043 -0.259243,0 -0.349677,-0.06029 -0.07838,-0.247185 -0.198954,-0.584803 -0.09646,-0.2110122 -0.192925,-0.4280528 -0.138664,-0.3135031 -0.138664,-0.4159945 0,-0.1145493 0.102491,-0.1145493 1.676036,0 1.676036,-0.7656711 0,-0.066318 -0.819931,-1.1997524 -0.850076,-1.1635789 -0.934481,-1.4710531 -0.04823,-0.1748383 -0.04823,-1.2479837 0,-0.2170406 0.01206,-0.6511219 0.01206,-0.4401101 0.01206,-0.6571508 0,-0.1567515 0.198953,-0.1567515 0.307475,0 0.928452,0.018087 0.627006,0.018087 0.934481,0.018087 0.126607,0 0.126607,0.1868961 0,0.1688094 -0.01809,0.5124571 -0.01809,0.3376187 -0.01809,0.5064281 0,0.7596422 0.02412,1.030943 0.01809,0.2110117 0.361734,0.7837578 0.361735,0.5968618 0.524515,0.5968618 0.138665,0 0.470255,-0.5908329 0.307474,-0.5606883 0.33159,-0.7536133 0.06029,-0.5305437 0.06029,-1.1515211 0,-0.1627805 -0.03014,-0.4943703 -0.03014,-0.3315899 -0.03014,-0.5003993 0,-0.1145492 0.126607,-0.1145492 0.307474,0 0.916393,-0.024116 0.614949,-0.024116 0.922423,-0.024116 0.156752,0 0.211012,0.1567515 0.0844,0.2773297 0.0844,1.2841571 z"
                  id="path121" />
                <path
                  d="m 47.0315,3.424419 q 0,1.4589954 -0.765671,2.3633313 -0.819932,0.9585962 -2.26084,0.9585962 -0.241157,0 -0.729498,-0.024116 -0.482313,-0.030145 -0.723469,-0.030145 -0.259243,0 -0.259243,0.2230695 0,0.2773297 0.05426,0.8259602 0.05426,0.5486305 0.05426,0.8199313 0,0.1386648 -0.126607,0.1386648 -0.367763,0 -1.103289,-0.024116 -0.735527,-0.018087 -1.10329,-0.018087 -0.174839,0 -0.174839,-0.1748383 0,-0.6692086 0.01809,-2.0076258 0.02412,-1.3444462 0.02412,-2.0136548 0,-1.8930766 -0.162781,-3.97907823 l -0.006,-0.0844047 q -0.01206,-0.11454922 0.180868,-0.13866485 0.470254,-0.0602891 2.073943,-0.0602891 0.946539,0 1.814701,0.0241156 1.428851,0.0422024 2.303042,0.89830711 Q 47.0315,2.0015971 47.0315,3.424419 Z m -2.405534,0.096463 q 0,-1.4047352 -1.368562,-1.4047352 -0.964625,0 -1.012856,0.1085203 -0.0422,0.078376 -0.0422,1.3926774 0,0.1868961 0.01206,0.5546594 0.01809,0.3617344 0.01809,0.5486305 0,0.1205781 1.085203,0.1205781 1.308273,0 1.308273,-1.3203305 z"
                  id="path123" />
                <path
                  d="m 50.009777,5.0281081 q 0,1.6458915 0.01206,2.5261118 0,0.1386649 0.01809,0.4762836 0.01809,0.3074743 0.01809,0.4762836 0,0.1266071 -0.150722,0.1507227 -0.271301,0.048231 -0.976683,0.048231 -0.723469,0 -0.922423,-0.048231 -0.132636,-0.030145 -0.150722,-0.1627805 0,-0.018087 0,-0.2592429 0,-0.5365727 0.01206,-1.6036892 0.01206,-1.0731453 0.01206,-1.6036891 0,-2.5321407 0.006,-2.3090711 -0.01809,-1.3323884 -0.150723,-2.2970134 -0.01206,-0.066318 -0.01206,-0.10249141 0,-0.13263594 0.156751,-0.13263594 h 2.15232 q 0.150722,0 0.138665,0.12660704 l -0.006,0.10852031 q -0.156752,2.6044876 -0.156752,4.6060845 z"
                  id="path125" />
                <path
                  d="m 56.912877,5.6912878 q 0,1.3745907 -0.844047,2.2306954 -0.838018,0.8561047 -2.20658,0.8561047 -1.326359,0 -2.182464,-0.8440469 -0.856105,-0.8440469 -0.856105,-2.1704063 0,-1.3987063 0.819931,-2.2668688 0.819932,-0.8741914 2.218638,-0.8741914 1.362533,0 2.20658,0.8561047 0.844047,0.8500758 0.844047,2.2126086 z m -1.977482,0 q 0,-0.4762836 -0.295416,-0.838018 -0.295417,-0.3677633 -0.765671,-0.3677633 -0.476284,0 -0.783758,0.3737922 -0.289388,0.3496766 -0.289388,0.8319891 0,0.4883415 0.289388,0.838018 0.307474,0.3737922 0.783758,0.3737922 0.470254,0 0.777729,-0.3737922 0.283358,-0.3496765 0.283358,-0.838018 z"
                  id="path127" />
                <path
                  d="m 61.675711,6.8910402 q 0,0.1808672 -0.04823,0.5486305 -0.0422,0.3677633 -0.0422,0.5426016 0,0.1688094 -0.03617,0.2170406 -0.03617,0.048231 -0.198953,0.1386649 -0.777729,0.4280523 -1.724268,0.4280523 -1.742354,0 -1.742354,-1.3323883 0,-0.3979078 0.03014,-1.1937235 0.03014,-0.8018445 0.03014,-1.1997524 0,-0.2652718 -0.307474,-0.2652718 -0.02412,0 -0.07838,0 -0.05426,0.00603 -0.07838,0.00603 -0.229099,0 -0.229099,-0.1627805 0,-0.096463 0.01206,-0.2954164 0.01809,-0.1989539 0.01809,-0.2954164 0,-0.1266071 -0.01809,-0.3858501 -0.01206,-0.2592429 -0.01206,-0.38585 0,-0.1205781 0.38585,-0.1326359 0.217041,-0.00603 0.235128,-0.1205781 0.03617,-0.2290985 0.02412,-0.7234688 -0.02412,-0.7777289 -0.02412,-0.7415555 0,-0.69935315 0.132635,-0.69935315 0.162781,0 0.464226,0.0723469 0.343648,0.0783758 0.476284,0.0904336 0.307474,0.042202 0.928451,0.1145492 0.150723,0.012058 0.150723,0.1024915 0,0.2411562 -0.0422,0.7294976 -0.03617,0.4883415 -0.03617,0.7355266 0,0.2954164 0.174839,0.2954164 0.211011,0 0.639064,-0.018087 0.428052,-0.018087 0.645093,-0.018087 0.132636,0 0.132636,0.090434 0,0.1386648 -0.03015,0.4159945 -0.03014,0.2773297 -0.03014,0.4159946 0,0.1446937 0.006,0.4220234 0.006,0.2773297 0.006,0.4220235 0,0.126607 -0.174838,0.126607 -0.120578,0 -0.403937,-0.00603 -0.283358,-0.012058 -0.403936,-0.012058 h -0.452168 q -0.09043,0 -0.09043,0.2652719 0,0.1446937 0,0.4280523 0.006,0.2893876 0.006,0.4280524 0,0.5064281 0.114549,0.6993531 0.162781,0.259243 0.639064,0.259243 0.150723,0 0.428053,-0.066318 0.283358,-0.072347 0.397907,-0.072347 0.126607,0 0.126607,0.1326359 z"
                  id="path129" />
              </g>
            </svg>
            {/* <div style={{fontSize:'xx-small', padding:'0px 4px'}}>v{packageJson.version}</div> */}
          </div>

          {/* <div className="d-flex flex-row flex-grow-1 border flex-center"  style={{width:SVG_width+4}}>
            <span className="xxx fw-semibold text-light p-1 pb-0" >VaNe<img src={CanDyPlot_logo} className="vanex-navbar-logo" alt="logo" /></span><span>Dynamic Plots</span>
          </div> */}
        </div>
      </div>
  );
}


export default CnvPlotter;
