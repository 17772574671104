import React, { useEffect, useState } from "react";
import { useTable, useSortBy, useFlexLayout, useColumnOrder, useFilters,
  //  useGlobalFilter, useAsyncDebounce, useRowSelect, useBlockLayout, 
   useResizeColumns,  usePagination } from "react-table";
// import { VariableSizeGrid } from "react-window";
import {GetHeatedQualityScore} from '../HelperFunctions'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

// const IndeterminateCheckbox = React.forwardRef(
//   ({ indeterminate, ...rest }, ref) => {
//     const defaultRef = React.useRef()
//     const resolvedRef = ref || defaultRef

//     React.useEffect(() => {
//       resolvedRef.current.indeterminate = indeterminate
//     }, [resolvedRef, indeterminate])

//     return <input type="checkbox" ref={resolvedRef} {...rest} />
//   }
// );

const getCustomCellProps = (cell, inPMFSampleColNames) => {
  if (cell.column.useMultipleColors===true && cell.value!==null && cell.value!==undefined && typeof(cell.value)==='number' ){
    let value = (cell.value > 1000) ? 1 : cell.value/1000;
    // let h = (1.0 - value) * 240;
    let h = ( value) * 240;
    let colorVal= "hsl(" + h + ", 100%, 50%)";
    return {
      style: {
        fontWeight: 'bolder',
        color:'black',
        backgroundColor: colorVal,
      },
    }
  }
  // if (cell.column.Header=='Qual'){
  if (cell.column.useHeated===true ){  // && cell.value
    let threshold = cell.column.heatedThresholdValue || 120; // 1200 is for Qual!
    return {
      style: {
        backgroundColor: GetHeatedQualityScore(cell.value, threshold)
      }
    }
  }
  if (cell.column.useReverseHeated===true ){ // && cell.value
    let threshold = cell.column.heatedThresholdValue || 120; // 1200 is for Qual!
    return {
      style: {
        backgroundColor: GetHeatedQualityScore(cell.value, threshold, true)
      }
    }
  }
  if (cell.column.useGenotypeColor===true ){ //&& cell.value!==null && cell.value!==undefined 
    let altPercent;
    try {
      if (cell.column.use_GTinsteadOf_Alt_Percent===true )
        altPercent = cell.row.values[`${cell.column.id.slice(0,-3)}_GT`];
      else
        altPercent = cell.row.values[`${cell.column.id.slice(0,-3)}_Alt_Percent`];
    } catch(e){ altPercent=0 }
    if(altPercent===undefined || altPercent===null) altPercent=0

    // * show altPercent bar with the proper bg-color
    let callAtThisPosition = '#F9F9F9'; // 'transparent';// ref 'green'
    if(cell.value==='het') callAtThisPosition='#FEC7CE' //'pink')
    if(cell.value==='hom') callAtThisPosition='#FC8696' //'red')
    if(cell.value==='ref') callAtThisPosition='#C7EFD0' //'green')

    if(altPercent < 1) altPercent=0;

    return {
      style: {
        // * this means, 0-80%=callAtThisPosition, 78-82%=black, 80-98%=transparent, 96-100%=black
        // background: `linear-gradient(to top, white 70%, #000000 70% calc(70% + 1px), white calc(70% + 1px) 90%,  #000000 90% calc(90% + 1px), white calc(90% + 1px) 100%)`,

        // * this means, 0 to 80%=callAtThisPosition, 1 to 82%+1px=black, 80 to 100%-1px=transparent, 1 to 100%=black, and #F86579 is more red than what we used for 'hom'
        background: `linear-gradient(to right, ${callAtThisPosition} 80%, #000000 1%, #000000 calc(80% + 1px), transparent 1%, transparent calc(100% - 1px), #000000 1%, #000000 100%), linear-gradient(to top, #F86579 ${altPercent}%, white 0%, white 100%)`,
        // * this means, 0-80%=callAtThisPosition, 78-82%=black, 80-98%=transparent, 96-100%=black
        // background: `linear-gradient(to right, ${callAtThisPosition} 80%, #000000 78%, #000000 82%, transparent 80%, transparent 98%, #000000 96%, #000000 100%), linear-gradient(to top, #fc8696 ${altPercent}%, white 0%, white 100%)`,
        // background: `linear-gradient(to right, ${callAtThisPosition} 80%, #000000 78%, #000000 82%, transparent 80%, transparent 98%, #000000 96%, #000000 100%), linear-gradient(to bottom, #fc8696 50%, #C7EFD0 50%, #C7EFD0)`,
        // background: 'linear-gradient(to right, rgba(255, 255, 255, 0) 75%, #A3EF69 100%, #A3EF69) , ',
        // background: 'linear-gradient(to bottom, #fc8696 50%, #C7EFD0 50%, #C7EFD0), linear-gradient(to right, rgba(255, 255, 255, 0) 75%, #A3EF69 100%) , ',
        // background: 'linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 75%, #A3EF69 100%) , linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 75%, #A3EF69 100%)',
      },
    }

    // // * show only background color if there is no altPercent
    // if(altPercent===undefined || altPercent===null){
    //   let cellClassName = (cell.value==='ref' ? 'bg-green' : (cell.value==='het' ? 'bg-pink' : (cell.value==='hom' ? 'bg-red' : '') ));
    //   return { className : cellClassName }
    // }
  }

  if (cell.column.useGainLossColor===true ){ //&& cell.value!==null && cell.value!==undefined 
    const bgColor = (cell.value==="LOSS" ? "#FC8696" : (cell.value==="GAIN" ? "#486EB7": "transparent"));
    const fgColor = (bgColor==="transparent" ? "black": "white");
    return {
      style: {
        backgroundColor: bgColor,
        color:fgColor,
      },
    }
  }

  if (cell.column.withCNVOverlapSchematic===true ){
    let overlapSegData = cell.row.values[`${cell.column.id.slice(0,-3)}_OL`];
    if(!!inPMFSampleColNames && inPMFSampleColNames.length > 0)
      inPMFSampleColNames = inPMFSampleColNames.map(k => k.slice(0,-3))
    const colIndex = inPMFSampleColNames.findIndex(t => cell.column.id.includes(t));
    const bottomBandColorSet = ['#707070','#f97306', '#126BC5', '#6db23f','#9e4fff', '#3fb2a3','#f89865','#cad103'];
    const topBandColor = '#494949'; // '#707070'
    // const bottomBandColor = '#F86579';

    const bottomBandColor = (colIndex!==null && colIndex >= 0 ? bottomBandColorSet[colIndex % bottomBandColorSet.length] : '#f89865');
    const bandHeight = '.2em'; // '10%';
    const topBandStart_y = '.3em'; // '8%';
    const bottomBandStart_y = '.57em'; // '22%';
    // console.log(cell)
    // console.log(inPMFSampleColNames)
    // console.log(inPMFSampleColNames.findIndex(t => cell.column.id.includes(t)))

    if(!!overlapSegData && !!overlapSegData.SEG_SIZE){
      // let overlapValue = Math.min(cell.row.values.LOC_END, overlapSegData.LOC_END) - Math.max(cell.row.values.LOC_START, overlapSegData.LOC_START);
      // let percent = Math.round(100*(overlapValue / (cell.row.values.LOC_END - cell.row.values.LOC_START)));
      // let totalLength = cell.row.values.SEG_SIZE + overlapSegData.SEG_SIZE;
      // let overlapPercentOfCell = Math.round(100*overlapValue/totalLength);
      // let topBandStart = Math.round(overlapPercentOfCell/2);
      // let bottomBandStart = 100-Math.round(overlapPercentOfCell/2);

      const totalLength = Math.max(cell.row.values.LOC_END, overlapSegData.LOC_END) - Math.min(cell.row.values.LOC_START, overlapSegData.LOC_START);
      const topBandWidth =  100 * cell.row.values.SEG_SIZE / totalLength;
      let topBandStart_x = 100*(cell.row.values.LOC_START-Math.min(cell.row.values.LOC_START, overlapSegData.LOC_START))/totalLength;
      topBandStart_x =  ( (100-topBandWidth) > 0 ? 100*topBandStart_x/(100-topBandWidth) : 0);

      const bottomBandWidth =  100 * overlapSegData.SEG_SIZE / totalLength;
      let bottomBandStart_x = 100*(overlapSegData.LOC_START-Math.min(cell.row.values.LOC_START, overlapSegData.LOC_START))/totalLength;
      bottomBandStart_x = ( (100-bottomBandWidth) > 0 ? 100*bottomBandStart_x/(100-bottomBandWidth) : 0);
      
      return {
        style: {
          background: `linear-gradient(${topBandColor}, ${topBandColor}) ${topBandStart_x}% ${topBandStart_y} / ${topBandWidth}% ${bandHeight} no-repeat, 
                      linear-gradient(${bottomBandColor}, ${bottomBandColor}) ${bottomBandStart_x}% ${bottomBandStart_y} / ${bottomBandWidth}% ${bandHeight} no-repeat`
        },
      }
    // } else {
    //   return {
    //     style: {
    //       background: `linear-gradient(${topBandColor}, ${topBandColor}) 0% ${topBandStart_y} / 100% ${bandHeight} no-repeat`,
    //     },
    //   }
    }
  }

  return({});
}


export default function Table({data, columns, hiddenColumnsIds, initialSortBy, setSortBy, //columnOrder, 
  updateVariantTableData, getTabName, sendFeedback, //getCaseID, 
  setColumnsSpec, displayVariantInfo, currentVariantId,
  preparedDataFromVariantTable, trigerExportPreparation, 
  initialPageIndex, reportCurrentPageIndex, // initialPageSize, reportCurentPageSize,
  setDataForFilterSelectionModal, variantFilterObj, PMFSampleColNames, DoNotSortColumns}) {


  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      // fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
      endsWith: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .endsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  // // Define a default UI for filtering
  // function DefaultColumnFilter({
  //   column: { filterValue, preFilteredRows, setFilter },
  // }) {
  //   const count = preFilteredRows.length

  //   return (
  //     <input
  //       value={filterValue || ''}
  //       onChange={e => {
  //         setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
  //       }}
  //       placeholder={`Search ${count} records...`}
  //     />
  //   )
  // }

  // const [tableColumnOrder, setTableColumnOrder] = useState(['VANEX_TAGS','VANEX_NOTES','CHROMOSOME','POSITION','REF','ALT','QUAL']);
  // const defaultColumnOrder = ['VANEX_TAGS','VANEX_NOTES','CHROMOSOME','POSITION','REF','ALT','QUAL','P_GT','M_GT','F_GT','S_GT','B_GT','X_GT']; //'FILTER','QUAL'
  const defaultColumnOrder_partA = ['VANEX_TAGS','CHROMOSOME','POSITION','REF','ALT','QUAL',
                                    // * CNV analysis columns
                                    'LOC_START','LOC_END','SEG_SIZE','CNV_TYPE','SEG_MEAN'];
  const defaultColumnOrder_partB = ['MAX_AD','GENE','MANE_HGVS','HGVS','LOC_IN_GENE','DIST_TO_CODING_REGION','DIST_TO_SPLICE_SITE','EFFECT','VANEX_NOTES','VANEX_REVIEWS',
                                    'PHENOTYPE_RANKING','PHENOTYPE_TERMS','CLINVAR_CLNSIG','OMIM_PHENOTYPES','GNOMAD_WG_FAF95_POPMAX','GNOMAD_EX_FAF95_POPMAX','GNOMAD_WG_AC','GNOMAD_EX_AC',
                                    // * CNV analysis columns
                                    'NUM_MARK','ENTREZ_IDS','GENE_SYMBOLS','MIM_NUMBERS','MR_PHENE_PHENOTYPE_TERMS','MAX_RECURRENT_OVERLAP','RECURRENT_OVERLAP_SIZE','CENTROMERE_OVERLAP']; //'FILTER','QUAL','EPILEPSY_GENE_LIST'

  const defaultColumn = React.useMemo( () => ({
    minWidth: 35,
    width: 100,
    maxWidth: 800
    // Filter: DefaultColumnFilter, Cell: EditableCell,
  }), []);

  const [prevHiddenColumns, setPrevHiddenColumns] = useState(hiddenColumnsIds || []);

  // const [filterPanel, setFilterPanel] = useState("");
  // const [filterInput, setFilterInput] = useState("");

  // const [controlledPageIndex, setControlledPage] = React.useState(0);
  // const [controlledSortBy, setControlledSortBy] = React.useState([]);
  
  // const [hiddenColumnsID, setHiddenColumnsID] = useState(['index','STOP_POSITION','INHERIT_MODEL','ENTREZ_ID','DIST_TO_SPLICE_SITE', //'IMPACT',      
  // 'DIST_TO_CODING_REGION','MANE_HGVS','HGVS','INFORMATION',
  // 'IGM_CLINICAL_EXOME_ALT_SAMPLE_COUNT','IGM_CLINICAL_EXOME_TOTAL_SAMPLE_COUNT',
  // 'IGM_CLINICAL_EXOME_ALT_SAMPLE_FRACTION',
  // 'GNOMAD_WG_FAF95_POPMAX','GNOMAD_EX_FAF95_POPMAX','GNOMAD_WG_AC','GNOMAD_EX_AC','GNOMAD_EX_NHOMALT','GNOMAD_WG_NHOMALT',
  // 'GNOMAD_WG_AF_POPMAX','GNOMAD_EX_AF_POPMAX',
  // 'INTERVAR','MIM_NUMBER','OMIM_PHENOTYPES','ORPHANET_DISPLAY_SUMMARY',
  // 'CLINVAR_CLNSIG','CLINVAR_CLNDN','CLINVAR_CLNREVSTAT_CRITERIA','CLINVAR_ID','CLINVAR_CLNSIGCONF','CLINVAR_CLNREVSTAT',
  // 'COSMIC_IDS','COSMIC_SITES','MGI_ID','HPO_ID','HPO_LABEL',
  // 'DBSNP_ID','DBNSFP_CADD_RAW','DBNSFP_CADD_PHRED', 'SAMPLES']);

  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    visibleColumns,
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    // setColumnOrder,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    // pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    // setFilter,
    // allColumns,
    // toggleHideColumn,
    // getToggleHideAllColumnsProps,
    // toggleHideAllColumns,
    // resetResizing,
    // preGlobalFilteredRows,
    // setGlobalFilter,
    setHiddenColumns,
    state,
    state: { pageIndex, pageSize, sortBy },
  } = useTable({
    columns,
    data,
    // manualPagination: true,
    // autoResetPage: false,    // use the skipPageReset option to disable page resetting temporarily
    // manualSortBy: true,
    autoResetSortBy: false,
    // pageCount: controlledPageCount,
    initialState: { 
      pageIndex: initialPageIndex || 0, //((initialPageIndex > -1 ||  ( (initialPageIndex+1) <= Math.ceil(data.length/50) ) ) ? initialPageIndex : 0), // || 0, > pageCount) ?
      pageSize: 50,
      columnOrder: ( (!!PMFSampleColNames && PMFSampleColNames.length > 0 && !DoNotSortColumns) ?
                      [...defaultColumnOrder_partA, ...PMFSampleColNames, ...defaultColumnOrder_partB]:
                      [...defaultColumnOrder_partA, ...defaultColumnOrder_partB]),
      hiddenColumns: hiddenColumnsIds || [],
      sortBy: initialSortBy || []
    }, // Array<ColumnId: String>
    // useControlledState: state => {
    //   console.log(state);
    //   return React.useMemo(
    //     () => ({
    //       ...state,
    //       sortBy: controlledSortBy,
    //     }),
    //     [state, controlledSortBy]
    //   )
    // },

    defaultColumn, // Be sure to pass the defaultColumn option

    filterTypes,

    // updateVariantTableData (+ getTabName and sendFeedback) isn't part of the API, but anything we put into these options will automatically
    // be available on the instance. That way we can call this function from our cell renderer!
    updateVariantTableData, getTabName, sendFeedback //getCaseID,
  },
  useColumnOrder,
  useResizeColumns,
  useFlexLayout, // useBlockLayout,
  useFilters,
  useSortBy,
  usePagination
  // useGlobalFilter
  );


  // function shuffle(arr) {
  //   arr = [...arr]
  //   const shuffled = []
  //   while (arr.length) {
  //     const rand = Math.floor(Math.random() * arr.length)
  //     shuffled.push(arr.splice(rand, 1)[0])
  //   }
  //   return shuffled
  // }

  
  // useEffect(() => {
  //   if(!!rows[0] && !!rows[0]['values']['SAMPLES'] ){
  //     let sampleColNames = [];
  //     sampleColNames=Object.keys(rows[0]['values']['SAMPLES']).map( i => `${i}_GT`);  // ? _GT comes from parquet data!
  //     setColumnOrder([...defaultColumnOrder_partA, ...sampleColNames, ...defaultColumnOrder_partB]);
  //   }
  // }, []);

  
  // useEffect(() => {
  //   if(!!PMFSampleColNames && PMFSampleColNames.length > 0 && !DoNotSortColumns){
  //     // ? no need to sort anymore! 
  //     setColumnOrder([...defaultColumnOrder_partA, ...PMFSampleColNames, ...defaultColumnOrder_partB]);
  //   }
  // }, [PMFSampleColNames]);


  useEffect(() => {
    if (!!reportCurrentPageIndex) reportCurrentPageIndex(pageIndex);
  }, [pageIndex, reportCurrentPageIndex]);

  // useEffect(() => {
  //   if(pageIndex > pageCount) gotoPage(pageCount-1);
  // }, [data])

  //  // Store new sort state in reducer and call API to fetch new data from server
  //  useEffect(() => {
  //   onChangeSort(sortBy);
  // }, [onChangeSort, sortBy]);

  useEffect(() => {
    if(!!setSortBy) setSortBy(state.sortBy);
    //  else console.log("no setSortBy function found");
    
    // setControlledSortBy(state.sortBy);
    // console.log("sorted", state.sortBy);
  }, [sortBy, setSortBy, state.sortBy]);

  // // Listen for column resizing
  useEffect(() => {
    //TODO: fix if not found any!
    // console.log(JSON.stringify(state, null, 2));
    if (state.columnResizing.isResizingColumn === null) {
      let colId = state.columnResizing.headerIdWidths[0][0];
      let colWidth = state.columnResizing.columnWidths[colId];
      // console.log('columnResizing', colId , '=', colWidth);
      // columns.find(t => t.accessor==colId).width = colWidth;
      // console.log('xx', columns.find(t => t.accessor==colId).width);
      if(!!setColumnsSpec) setColumnsSpec(colId, colWidth);
    }
  }, [state.columnResizing, setColumnsSpec]);
  // }, [JSON.stringify(state.columnResizing.columnWidths)])
  

  useEffect(() => {    
    if(!!hiddenColumnsIds && hiddenColumnsIds.length >0 &&
       !!prevHiddenColumns && prevHiddenColumns.length >0 &&
        prevHiddenColumns.join() != hiddenColumnsIds.join() )
    {
      setHiddenColumns(hiddenColumnsIds);
      setPrevHiddenColumns(hiddenColumnsIds);
    }
  }, [hiddenColumnsIds, prevHiddenColumns, setPrevHiddenColumns, setHiddenColumns]);

  useEffect(() => {
    if(trigerExportPreparation===true){
      // var header = visibleColumns.filter(col => col.id!=="VANEX_TAGS").map(k => k.Header).join('\t')+"\n";
      var header = visibleColumns.filter(col => col.id!=="VANEX_TAGS").map(k => (k.id==="VANEX_NOTES" ? k.Header +"\t." : k.Header)).join('\t')+"\n";

      var rowData = rows.map(variantCallInfo => 
        visibleColumns.filter(col => col.id!=="VANEX_TAGS").map(column => column.id)
          .map(colId => {
            if(colId==="VANEX_NOTES"){
              // console.log(variantCallInfo.values[colId]);
              return(variantCallInfo.values[colId].CURRENT_USER_NOTE.replace('\n','') + "\t [" + 
                variantCallInfo.values[colId].ALL_USERS_NOTES.filter(nt => nt.IS_SET_BY_OTHERS===true).map(x => " «" + x.USERNAME + "»: " + x.VANEX_NOTES.replace('\n','') ).join("],  [") +
              "]")
            }
            if(colId==="VANEX_REVIEWS"){
              return(variantCallInfo.values[colId].REVIEW_VALUE)
            }
            return(variantCallInfo.values[colId]);
          }).join('\t')
      ).join("\n")

      preparedDataFromVariantTable(header + rowData);
    }
  }, [trigerExportPreparation]);


  const handleKeyPress = (event) => {
    const UP_ARROW = 38;
    const DOWN_ARROW = 40;
    // const LEFT_ARROW = 37;
    // const RIGHT_ARROW=39;
    // const TOTAL_CELLS_BY_ROW = (your_number_of_cols);
    // let idx = e.target.parentElement.parentElement.cellIndex;
    // console.log(event.key);

    // if (e.keyCode === ArrowUp) {
      if (event.keyCode === UP_ARROW && !!displayVariantInfo) {
        if (event.target.previousElementSibling) {
          event.target.previousElementSibling.focus();
          displayVariantInfo(event.target.previousElementSibling.lastChild.getAttribute("variantindex"));
          // event.preventDefault();
          // console.log(event.target.previousElementSibling.lastChild.getAttribute("variantindex"));
        }
      }
      
      if (event.keyCode === DOWN_ARROW  && !!displayVariantInfo) {
        if (event.target.nextElementSibling) {
          event.target.nextElementSibling.focus();
          displayVariantInfo(event.target.nextElementSibling.lastChild.getAttribute("variantindex"));
          // event.preventDefault();
          // console.log(event.target.nextElementSibling.lastChild.getAttribute("variantindex"));
          // event.target.parentElement.parentElement.nextElementSibling.childNodes[0].childNodes[0].focus();
      }
    }
  }


  // const handleFilterChange = e => {
  //   const value = e.target.value || undefined;
  //   // setFilter("FILTER", value);
  //   setFilterInput(value);
  // };

  // const handleFiltering = (event, columnId) => {
  //   // console.log(columnId);
  //   // console.log(event.target);
  //   let x =
  //     <div className="bg-blue">
  //       yaah{columnId}
  //     </div>;
  //   setDataForFilterSelectionModal(x);
  //   // console.log(event.target.attributes.column_data);
  //   // console.log(event.target.previousElementSibling.lastChild);
  //   // event.preventDefault();
  //   // console.log(event.target.previousElementSibling.lastChild.getAttribute("variantindex"));
  // }

  const getMinLengthNeeded = (colHeader) => {
    let minLen = (colHeader==="Tag" ? 5: 15);
    if(!!colHeader && colHeader!=="Tag" && colHeader.length > 0){
      minLen=colHeader.length*7;
    }
    return minLen;
  }

  const isFilterApplied = (column) => {
    let result = false;
    if(variantFilterObj !== undefined){
      // console.log(variantFilterObj);
      if(!!PMFSampleColNames && PMFSampleColNames.length >0){
        if( column.id === PMFSampleColNames[0]){
          // if( (!!variantFilterObj.REMOVE_PROBAND_REF && !!variantFilterObj.REMOVE_PROBAND_REF.isApplied && variantFilterObj.REMOVE_PROBAND_REF.isApplied) ){
          //   return(true);
          // }
          if( variantFilterObj.REMOVE_PROBAND_REF !== undefined && variantFilterObj.REMOVE_PROBAND_REF !== null && variantFilterObj.REMOVE_PROBAND_REF.isApplied ===true ){
            return(true);
          }
          return(false);
          // console.log('-----------------------------');
        }
      }

      // Object.values(variantFilterObj).every( (filterObj) => {
      //   if (filterObj.columnId === column.id){
      //     if( (!!filterObj.tabFilter && !filterObj.tabFilter) || (!!filterObj.isApplied && filterObj.isApplied) ){
      //       result = true;
      //     }
      //     return(false);
      //   }
      //   return(true);
      // });

      Object.values(variantFilterObj).forEach( (filterObj) => {
        if( (filterObj !== undefined && filterObj !== null) ){
          if (filterObj.columnId === column.id && filterObj.isApplied ===true){
            result = true;
          }
          // if( (!!filterObj.tabFilter && !filterObj.tabFilter) || (!!filterObj.isApplied && filterObj.isApplied) ){
          //   result = true;
          // }
        }
      });
    }
    
    return result;
  }

  // ? --------------------------------------------- Main Renders ----------------------------------

  return (
    <React.Fragment>
      <div className="flex-grow-1" style={{marginBottom:(data.length!==0 ? '5rem' : '0px')}}>
        <table className="variant-table flex-grow-1" {...getTableProps()}>
          <thead className="sticky-header">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  // console.log(column)
                  return(
                  // Add the sorting props to control sorting.
                  <th {...column.getHeaderProps({
                      style: column.columnStyle,
                    })} 
                  className={
                    (column.isSorted //! maybe use font awesome? <i className="fas fa-sort text-gray"></i>  <i className="fas fa-sort-up text-gray"></i>  <i className="fas fa-sort-down text-gray"></i>
                      ? column.isSortedDesc
                        ? "sort-desc"
                        : "sort-asc"
                      : "")+(
                        (isFilterApplied(column))
                        ? " bg-orange"
                        : ""
                      )
                    }>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 30, hide: 50 }}
                        overlay={
                          (column.width < getMinLengthNeeded(column.Header) 
                          ? 
                            <Tooltip id="overlay-example" style={{position:"fixed"}}>
                              {column.Header || ''}
                            </Tooltip> 
                          : <></> )
                        }
                      >
                      <div className="d-flex flex-row">
                        <div {...column.getHeaderProps(column.getSortByToggleProps())}
                        // className={" " + ( (column.addMargin) && "ms-4")}
                        style={{flex:'1 auto'}}
                        >
                        {column.render('Header')}              
                        </div>
                        <i className="fas fa-filter filter-icon" onClick={(e, selColumn=column) => (!!setDataForFilterSelectionModal) ? setDataForFilterSelectionModal(e, selColumn) : console.log("no setDataForFilterSelectionModal function found")}></i>
                        {/* {column.addFilter ? ""
                          // <i className="fas fa-filter filter-icon ps-1" onClick={(e, columnId=column.id) => handleFiltering(e, columnId)}></i>
                          // {console.log(column.id)}{console.log(column.Header)}
                          // <input style={{fontSize:'0.5rem'}} // <input className="form-control form-control-sm "
                          // value={filterInput}
                          // onChange={handleFilterChange}
                          // placeholder={"Search ..."}
                          // /> 
                          : null} */}
                        <div>{!!column.Filter ? column.render('Filter') : null}</div>
                        {/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                      </div>
                    </OverlayTrigger>  
                    {/* <div {...column.getResizerProps()}
                        className={`variant-table-resizer ${
                          column.isResizing ? 'isResizing' : ''
                        }`}
                      /> */}
                      {/* Use column.getResizerProps to hook up the events correctly */}
                  {column.canResize && (
                    <div
                      {...column.getResizerProps()}
                      className={`variant-table-resizer ${
                        column.isResizing ? 'isResizing' : ''
                      }`}
                    />
                  )}
                  </th>
                )}
                )}
                <th></th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, ind) => {
              prepareRow(row); // This line is necessary to prepare the rows and get the row props from react-table dynamically
              // Each row can be rendered directly as a string using the react-table render method
              return (
                <tr {...row.getRowProps()} 
                className={ (currentVariantId===row.values.ROW_INDEX) ? 'blue' : '' } 
                tabIndex="0"
                onKeyDown ={handleKeyPress}
                onClick={() => (!!displayVariantInfo ? displayVariantInfo(row.values.ROW_INDEX) : null) }>
                  {row.cells.map(cell => {
                    return (
                    // <td {...cell.getCellProps()}
                      <td
                        // Return an array of prop objects and react-table will merge them appropriately
                        {...cell.getCellProps([
                          {
                            className: cell.column.className,
                            style: cell.column.style,
                          },
                          // getColumnProps(cell.column),
                          getCustomCellProps(cell, PMFSampleColNames),
                          // ( (cell.column.useHeated==='true') ? getCustomCellProps(cell) : null),
                        ])}
                      >
                        {/* //! or maybe use   cell.value.toString()  instead of JSON.stringify(cell.value) ? to get "[object object]"" as an output? */}
                        { ( cell.column.renderDefinedCell===true || !(typeof(cell.value)==="object" && cell.value!== null) ? cell.render("Cell") :JSON.stringify(cell.value))
                          // (cell.column.id!=='VANEX_NOTES','VANEX_REVIEWS','MR_PHENE_PHENOTYPE_TERMS','ENTREZ_IDS','GENE_SYMBOLS','MIM_NUMBERS' && typeof(cell.value)==="object" && cell.value!== null) ? JSON.stringify(cell.value) : cell.render("Cell")
                        }
                      </td>);
                    // }
                  })}
                  <td variantindex={row.values.ROW_INDEX}></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {(data.length===0) ? <h2 className="d-flex flex-grow-1 align-items-top justify-content-center m-1">No Data</h2> : ""}

      {/* //? --------------------------------------------- pagination ---------------------------------- */}
      <div className="pagination">
        {/* <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          &nbsp;{'<'}&nbsp;
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          &nbsp;{'>'}&nbsp;
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button> */}
        
        {/* <button className="border border-secondary" style={{margin:'0px 0px 0px 1px'}} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button className="border border-secondary" style={{margin:'0px 0px 0px 1px'}} onClick={() => previousPage()} disabled={!canPreviousPage}>
          &nbsp;{'<'}&nbsp;
        </button>{' '}
        <button className="border border-secondary" style={{margin:'0px 0px 0px 1px'}} onClick={() => nextPage()} disabled={!canNextPage}>
          &nbsp;{'>'}&nbsp;
        </button>{' '}
        <button className="border border-secondary" style={{margin:'0px 0px 0px 1px'}} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button> */}

        <button className="btn btn-secondary border border-secondary py-0 px-2 fw-bold" style={{margin:'0px 0px 0px 1px'}} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button className="btn btn-secondary border border-secondary py-0 px-2 fw-bold" style={{margin:'0px 0px 0px 1px'}} onClick={() => previousPage()} disabled={!canPreviousPage}>
          &nbsp;{'<'}&nbsp;
        </button>{' '}
        <button className="btn btn-secondary border border-secondary py-0 px-2 fw-bold" style={{margin:'0px 0px 0px 1px'}} onClick={() => nextPage()} disabled={!canNextPage}>
          &nbsp;{'>'}&nbsp;
        </button>{' '}
        <button className="btn btn-secondary border border-secondary py-0 px-2 fw-bold" style={{margin:'0px 0px 0px 1px'}} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>

        <span className="mx-2 pt-1">
          Page{' '}
          <strong>
            {pageIndex + 1} of {(pageCount > 0) ? pageCount : 1}
          </strong>{' '}
        </span>
        <span className="pe-2">
          | Go to page:{' '}
          <input
            type="number"
            min={1}
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            style={{ width: '50px' }}
          />
        </span>{' '}

        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[25, 50, 100, 200, 500, 1000, 5000].map(tmpPageSize => (
            <option key={tmpPageSize} value={tmpPageSize}>
              Show {tmpPageSize}
            </option>
          ))}
        </select>

        {/* <DropdownButton title={pageSize} size="sm" variant="secondary" onSelect={ (eventKey, e) => setPageSize(Number(eventKey))} id="page-size-dropdown" >
         {[25, 50, 100, 200, 500, 1000, 5000].map(tmpPageSize => (
            <Dropdown.Item key={tmpPageSize} eventKey={tmpPageSize}>Show {tmpPageSize}</Dropdown.Item>
          ))}
        </DropdownButton> */}

        <span className="px-2 pt-1"> per page of the total <strong>{data.length}</strong> variants</span>
      </div>
    </React.Fragment>
  );
}