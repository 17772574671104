import { Auth } from 'aws-amplify';
import React, { // Component, useMemo, useRef,
  useState, useEffect, useCallback } from 'react'; // useRef // const { useState, useEffect, useRef } = React; 
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

// import { Formik, Form, Field, FieldArray } from 'formik'; //, ErrorMessage

// function escapeRegexCharacters(str) {
//     return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
// }

const MRPHENE_ENDPOINT_BASE = process.env.REACT_APP_MRPHENE_API_URI;

const SubjectChooserModal = (props) => {

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pullingSubjectInfo, setPullingSubjectInfo] = useState(false);
  const [projects, setProjects] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [visibleSubjects, setVisibleSubjects] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  
  const [subjectProject, setSubjectProject] = useState('Unfiltered');
  const [activeItemId, setActiveItemId] = useState('');
  const [activeProjectId, setActiveProjectId] = useState('');

  // const [suggestions, setSuggestions] = useState([]);
  // const [suggestionValue, setSuggestionValue] = useState('');

  useEffect(() => {
    let subject_list = subjects;
    if(subjectProject!==undefined && subjectProject!==null && subjectProject !== 'Unfiltered'){
      subject_list = subjects.filter(t => String(t['project']).toLowerCase().includes(subjectProject.toLowerCase()));
    }
    if(searchValue!==undefined && searchValue!==null && searchValue.trim() !== ''){
      subject_list = subject_list.filter(t => String(t['id']).toLowerCase().includes(searchValue.toLowerCase()));
    }
    setVisibleSubjects(subject_list);
  }, [subjects, searchValue, subjectProject])

  function loadSubjects() {
    // var endpoint = ENDPOINT_BASE + "/subject_list";
    // let endpoint = "https://48kvfzyklf.execute-api.us-east-2.amazonaws.com/Staging/subject_list"
    // let endpoint = "https://zei6w9c562.execute-api.us-east-2.amazonaws.com/Staging/subject_list"
    let endpoint = MRPHENE_ENDPOINT_BASE + "subject_list"
    console.log(endpoint);
    
    setShowModal(true);
    setIsLoading(true);

    Auth.currentSession().then(data => {
      var id_token = data.getIdToken().getJwtToken();

      fetch(endpoint, {
        mode: 'cors',
        headers: {
          'Authorization': id_token                
        }
      })
      .then(result => result.json())
      .then(result => {
        var subjects = [];
        let projects = new Set();

        for (var i = 0; i < result.length; i++) {
          subjects.push({
            id: decodeURIComponent(result[i]['subject_id']),
            project: result[i]['igm_project']
          });

          projects.add(result[i]['igm_project']);
        }

        subjects.sort( (elA,elB) => (elA.id > elB.id) ? 1 : ((elB.id > elA.id) ? -1 : 0));
        // console.log(subjects);

        // setSuggestions(subjects);
        setSubjects(subjects);
        setProjects([...projects]);
        setIsLoading(false);

      }).catch(e => {
        console.log("Failed to load subject id list");
        setIsLoading(false);
      })
    });
  }

  function chooseSubject() {
    props.callbackLoadingMrPheneSubjectData(activeItemId, activeProjectId);
    setShowModal(false);
    return;

    // let endpoint = `${MRPHENE_ENDPOINT_BASE}subject/${activeItemId}`
    // // let endpoint = `${MRPHENE_ENDPOINT_BASE}gene_list/${activeItemId}`
    // console.log(endpoint);
    // setPullingSubjectInfo(true);
    // Auth.currentSession().then(data => {
    //   var id_token = data.getIdToken().getJwtToken();
    //   fetch(endpoint, {
    //     mode: 'cors',
    //     headers: {
    //       'Authorization': id_token                
    //     }
    //   })
    //   .then(result => result.json())
    //   .then(result => {
    //     console.log(result);
    //     if(!!result && !!result.genes && Object.values(result.genes).length >0){
    //       // debugger;
    //       props.callbackLoadingMrPheneSubjectData(activeItemId, activeProjectId, result);
    //       setShowModal(false);
    //       setPullingSubjectInfo(false);
    //     }
    //     else
    //       throw Error(`Found no genes in the Mr. Phene profile.`);
    //   }).catch(e => {
    //     console.log("Failed to load subject id list. " + String(e));
    //     setIsLoading(false);
    //     setPullingSubjectInfo(false);
    //   })
    // });

  }

  return (
    <>
      {/* <div className="m-0 ms-1 p-0 px-1 tab-menu-button cursor-pointer" onClick={loadSubjects}>
        <i className="fas fa-id-badge fs-6"></i><span className="fs-6 fw-bold ms-1 p-0">Choose Subject</span>
      </div> */}
      {/* <div className="m-0 ms-1 p-0 px-1 tab-menu-button cursor-pointer" onClick={loadSubjects}>
        <i className="fas fa-id-badge fs-6"></i><span className="fs-6 fw-bold ms-1 p-0">Choose Subject</span>
      </div> */}
      {/* <button type="button" className="analysis-action-button btn btn-sm fw-bold bg-blue text-light shadow-sm no-white-space-wrap"  
        onClick={loadSubjects}><i className="fas fa-id-badge fs-6"></i><span className="fs-6 fw-bold ms-1 p-0 ">Choose Subject</span>
      </button> */}
      <Button variant="outline-success" size="sm" className="shadow-sm no-white-space-wrap" onClick={loadSubjects}>
        <i className="fas fa-id-badge fs-6"></i><span className="fs-6 fw-bold ms-2 p-0 ">Choose Subject</span>
      </Button>


      {/* //? --------------------------------------------- Subject Modal ---------------------------------- */}
      <Modal show={showModal} onHide={()=>setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Choose Subject</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(isLoading)
          ?
            <div className="d-flex flex-row mt-2">
              <span className="mx-3 spinner-border spinner-border" role="status" aria-hidden="true"></span><span className="fs-5">Loading...</span>
            </div>
          : 
          <>
            <DropdownButton size="sm" title={subjectProject} onSelect={(evtKey, evt) => setSubjectProject(evtKey)}>
              <Dropdown.Item as="button" eventKey="Unfiltered">Unfiltered</Dropdown.Item>
              {projects.map(project => {
                return <Dropdown.Item as="button" key={project} eventKey={project}>{project}</Dropdown.Item>
              })}
            </DropdownButton>
            <input className="w-100 m-1" type="text" placeholder="Search Subject" value={searchValue}  onChange={(e)=>setSearchValue(e.target.value)} />
            <div className="scroll-div fw-normal p-2" style={{maxHeight:'50VH'}}>
              { visibleSubjects.map( (subject, index) => (
                <div className={`px-1 no-white-space-wrap cursor-pointer ${ (activeItemId === subject.id) ? 'bg-blue text-light' : '' }`} key={index} onClick={ ()=>setActiveItemId(subject.id)}>
                  {`${subject.id} (${subject.project})`}
                </div>
              ))}
            </div>
          </>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={chooseSubject} disabled={pullingSubjectInfo}>
            Choose
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

}

export default SubjectChooserModal