import React from "react";

const SegmentTrack = (props) => {
  
  const memoizedSegmentsData = React.useMemo(() => {
    if(!props.segments_data) return([]);

    let tmpDatapoints =( props.segments_data.map( (datapoint, datapointIndex) => {

      let x_pos= -1;
      let percentX = 0;
      percentX = (datapoint.LOC_START- props.visibleWindow[0]+1 ) / (props.visibleWindow[1]-props.visibleWindow[0]);
      x_pos = Math.round(percentX * props.width );


      let x2_pos= -1;
      let percentX2 = 0;
      percentX2 = (datapoint.LOC_END - props.visibleWindow[0]+1 ) / (props.visibleWindow[1]-props.visibleWindow[0]);
      x2_pos = Math.round(percentX2 * props.width );

      let percentY = 0;
      let y_AxisLength = Math.abs(props.visibleWindow[3] - props.visibleWindow[2]);
      //* needs to get rounded to the nearest pixel!
      percentY=Math.abs(props.visibleWindow[3] - datapoint.SEG_MEAN )/y_AxisLength; // props.height
      let y_pos = Math.round(props.height*percentY);

      return(
        {
          X1_POS:x_pos,
          X2_POS:x2_pos,
          Y_POS:y_pos,
          LOC_START:datapoint.LOC_START,
          LOC_END:datapoint.LOC_END,
          SEG_MEAN:datapoint.SEG_MEAN,
          // IS_SELECTED:false
        }
      );
      }) 
    );
    return(tmpDatapoints);
  }, [props.segments_data, props.visibleWindow]);


  const renderSegments= () => {

    let lollipops= memoizedSegmentsData.map( (datapoint, datapointIndex) => {
      const isSelected=(datapoint.LOC_START===props.selectedSegment.LOC_START && datapoint.LOC_END===props.selectedSegment.LOC_END);
      if(isFinite(datapoint.X1_POS) && isFinite(datapoint.X2_POS)){
        let segLen = datapoint.X2_POS-datapoint.X1_POS;
        return ( 
          <g key={datapointIndex}>
            {(isSelected ? 
              <circle
                fill="none"
                stroke="red"
                strokeWidth="2"
                opacity="1"
                r={Math.max(10,(segLen/2)+5)}
                cx={props.x+datapoint.X1_POS+(segLen/2)}
                cy={props.y+datapoint.Y_POS}
              />
            : null)}

            <rect
              fill={(isSelected? "red" :"blue")}
              stroke={(isSelected? "red" :"none")}
              strokeWidth="1"
              opacity="1"
              x={props.x+datapoint.X1_POS}
              y={props.y+datapoint.Y_POS}
              width={datapoint.X2_POS-datapoint.X1_POS}
              height={2}
            >
              {/* <animate id="animation"
                attributeName="stroke-width"
                dur="2s"
                values="0.5; 1.5; 2.5; 3.5; 4.5; 3.5; 2.5; 1.5; 1"
                keyTimes="0; 0.125; 0.25; 0.375; 0.5; 0.625; 0.75; 0.875; 1"
                repeatCount="indefinite"
              /> */}
            </rect>
          </g>
        );
      }
      else return(<g key={datapointIndex}></g>)
    });
    return(<g>
      {lollipops}
    </g>);

  }

  return(
    <g>
      {renderSegments()}

    </g>
  );

}

export default SegmentTrack;
