import React, { useRef, useEffect } from 'react' // , useState

export const UNCHECKED = 0
export const CHECKED = 1
export const INDETERMINATE = -1 

const IndeterminateCheckbox = (props) => {
  const { value, ...otherProps } = props
  const checkRef = useRef();

  useEffect(() => {
    checkRef.current.checked = value === CHECKED
    checkRef.current.indeterminate = value === INDETERMINATE
  }, [value])

  return (
    <input
      type="checkbox"
      ref={checkRef}
      {...otherProps}
    />
  )
}

export default IndeterminateCheckbox