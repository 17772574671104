import React from 'react';
import {Amplify, Auth, Hub } from 'aws-amplify';
// import * as JWT from 'jwt-decode';
// import logo from './logo.svg';
import logo from './logo.png';
import './App.css';

// import { Collapse, Popover, Toast, Tooltip, Alert, Modal, Dropdown } from 'bootstrap'; // const { Collapse, Popover, Toast, Tooltip, Alert, Modal, Dropdown } = bootstrap
// import { useState, useEffect, useRef }  from 'react'; //const { useState, useEffect, useRef } = React; 
// import SplitPane, { Pane } from 'react-split-pane';
// import Pane from 'react-split-pane/lib/Pane';
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Switch, Route } from 'react-router-dom'; // BrowserRouter
import { withRouter } from 'react-router';

import { Toaster } from 'react-hot-toast';

import variantFormatDefinition from './resources/OutputDefinitionRareDiseaseGRCh38.json';
import XlsxVariantViewer from './components/XlsxVariantViewer';
// import ParquetVariantViewer from './components/ParquetVariantViewer';
import InitialView from './components/InitialView';
import ProjectSpecificApp from './components/ProjectSpecificApp';
import DynamicPlots from './components/DynamicPlots';
import Info from './components/Info';
// import TempComp from './components/TempComp';
// import RdApp from './rare-disease/RdApp';
// import UserPane from './components/UserPane'
// import VariantViewer from './components/VariantViewer'

// import myData from './data.json';
// import caseData from './caseData.json';


// ToDo: check Roboto font 


// Setup the AWS Amplify Auth global 
Amplify.configure({
  Auth: {
    region: 'us-east-2',
    userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_URI,
      redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_URI,
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
});

// var request;

// var ENDPOINT_BASE = process.env.REACT_APP_API_URI;
// var APP_ENV = process.env.REACT_APP_ENV;
// var APP_ENV = process.env.NODE_ENV;


class VaNexApp extends React.Component {

  constructor(props) {
    super(props);

    console.log('VaNex v' + process.env.REACT_APP_VERSION + '; env='+process.env.REACT_APP_ENV)

    this.handleAuthChange = this.handleAuthChange.bind(this);
    this.setXlsxFile = this.setXlsxFile.bind(this);
    // this.setParquetURI = this.setParquetURI.bind(this);

    this.state = {
      userName: "",
      emailAddress: null,
      vanexGroups: [],
      isDoneLoading: false,
      // isXlsxProcessed: false,
      xlsxFile: null,
      // signedUrl: null,
      // parquetURI: null,
      isPowerUser: false,
      isAdmin: false
    };

    //* event chain:
    //* App.js:67 signIn
    //* App.js:71 ** in signIn
    //* App.js:67 cognitoHostedUI
    //* App.js:108 ** in cognitoHostedUI
    //* App.js:67 customOAuthState
    //* App.js:111 ** in customOAuthState
    //* App.js:114 /rd
    //* App.js:76 ------currentAuthenticatedUser-----
    //* App.js:99 bye bye!
    //* App.js:67 oAuthSignOut
    // let the Hub module listen on Auth events
    Hub.listen('auth', async ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          console.log("** in signIn");

          // Auth.currentUserInfo().then(userInfo => {
          //   console.log(userInfo.attributes.email);
          // });

          // Auth.currentSession().then(userInfo => {
          //   console.log(userInfo.accessToken.payload.username);
          // });
          
          // This is where we enter after coming in from the OAuthButton Cognito login event
          // We need the currentAuthenticatedUser though to get the JWT token, which has the
          // encoded data we are interested in.
          // TODO: it gets logged in and check the group, then we force to logout if it's not part of the VaNex group, but there should a better way to do this!


          // const userGroups = user.attributes["custom:user_groups"]; // 
          // the array of groups that the user belongs to: custom:user_groups: "[GGRAzCognitoIGM, GGRAzCognitoIGMVanex]"
          // const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
          // const groups = user.attributes["custom:user_groups"].slice(1,-1).replace(/ /g, '').split(',');
          // const groups = user.attributes["custom:user_groups"];
          const userGroups = data.signInUserSession.idToken.payload["custom:user_groups"]; //? could not prevent the sign-in process
          if(userGroups.includes('GGRAzCognitoIGMVanex') || userGroups.includes('GGHAzCognitoIGMVanex')){
            Auth.currentAuthenticatedUser().then(user => {
              console.log('------currentAuthenticatedUser-----');
              console.log(user)
              let userIsPowerUser = (userGroups.includes("GGRAzCognitoIGMVanexPowerUsers") || userGroups.includes("GGHAzCognitoIGMVanexPowerUsers"));
              let userIsAdmin = (userGroups.includes("GGRAzCognitoIGMVanexAdmins") || userGroups.includes("GGHAzCognitoIGMVanexAdmins"));
              let emailAddress = user.username.replace("AzureAD_", "");
              // let id_decoded = JWT(user.signInUserSession.idToken.jwtToken);
              this.setState({
                authState: 'signedIn',
                authData: user,
                authError: null,
                userName: this.parseUsersEmail(emailAddress),
                emailAddress: emailAddress,
                isPowerUser: userIsPowerUser,
                isAdmin: userIsAdmin,
                vanexGroups: userGroups,
                loggedIn: true
              });
            });
          }
          else {
            console.log("bye bye!")
            Auth.signOut();
          }
          break;
        case 'signIn_failure':
          console.log("** in signIn_failure");
          // TODO: fix this:
          // this.setState({authState: 'signIn', authData: null, authError: data});
          break;
        case 'cognitoHostedUI':
          console.log("** in cognitoHostedUI");
          break;
        case 'customOAuthState':
          console.log("** in customOAuthState");
          // We have received back from Cognito some state information that we ourselves sent it.
          const paramMap = this.parseQueryString(decodeURIComponent(data));
          console.log(paramMap["url"])
          // this.props.history.push(paramMap["url"]);
          // this.setState({
          //   pushToUrl: paramMap["url"]
          // });
          setTimeout(() => {this.props.history.push(paramMap["url"])}, 500);
          // setTimeout(() => {this.pushToURL(paramMap["url"])}, 1000);
          break;
        // case 'oAuthSignOut':
        //   break;
        default:
          console.log("Hub event: " + event);
          break;
      }
    });

  }


  // pushToURL(url) {
  //   console.log(url);
  //   this.props.history.push(url);
  //   // if (typeof this.state.pushToUrl !== 'undefined') {
  //   //   if (this.state.loggedIn && this.state.pushToUrl.length > 0){
  //   //     this.props.history.push(this.state.pushToUrl);
  //   //   }
  //   // }
  // }


  parseQueryString(queryString) {
    // Strip the leading ? and tokenize
    const tokens = queryString.substring(1).split("&");

    const paramMap = {};
    tokens.forEach(token => {
      const keyValueArray = token.split("=");
      paramMap[keyValueArray[0]] = keyValueArray[1];
    });

    return paramMap;
  }

  componentDidMount() {
    
    //TODO: load user settings and history
    // this.props.history.listen((location, action) => {
    // });

    // const { attributes } = Auth.currentAuthenticatedUser();
    // debugger;
    // const groups = 'xx';//data.signInUserSession.idToken.payload["custom:user_groups"]; //? could not prevent the sign-in process
    // if(groups.includes('GGRAzCognitoIGMVanex')===false){

    // Check the current user when the App component is loaded. If
    // it is go ahead and set our state with the store auth data.
    Auth.currentAuthenticatedUser().then(user => {

      const userGroups = user.attributes["custom:user_groups"]; // e.g. [GGRAzCognitoIGMResearch, GGRAzCognitoIGM, GGRAzCognitoIGMVanex, GGRAzCognitoIGMVanexPowerUsers, GGRAzCognitoIGMVanexAdmins]   {hospital: GGHAzCognitoIGMVanex, GGHAzCognitoIGMVanexPowerUsers, GGHAzCognitoIGMVanexAdmins]
      if(userGroups.includes('GGRAzCognitoIGMVanex') || userGroups.includes('GGHAzCognitoIGMVanex')){

        // console.log('inside currentAuthenticatedUser');
        // let id_decoded = JWT(user.signInUserSession.idToken.jwtToken);
        let userIsPowerUser = (userGroups.includes("GGRAzCognitoIGMVanexPowerUsers") || userGroups.includes("GGHAzCognitoIGMVanexPowerUsers"));
        let userIsAdmin = (userGroups.includes("GGRAzCognitoIGMVanexAdmins") || userGroups.includes("GGHAzCognitoIGMVanexAdmins"));
        let emailAddress = user.username.replace("AzureAD_", "");
        this.setState({
          isDoneLoading: true,
          loggedIn: true,
          userName: this.parseUsersEmail(emailAddress),
          emailAddress: emailAddress,
          isPowerUser: userIsPowerUser,
          isAdmin: userIsAdmin, //(emailAddress==='Mohammad.Marhabaie@nationwidechildrens.org'),
          vanexGroups: userGroups
        });

  //?      this.props.history.push('/rd');
        // this.loadUserSettings(emailAddress)
      }
      this.setState({isDoneLoading: true});
    }).catch(e => {
      console.log('catch: user is not logged in: '+ e);
      // The user isn't logged in yet, but they may have been coming
      // from a URL to take them directly to a subject (and maybe version).
      // In that case we want to redirect them to AWS Cognito for authentication,
      // but send along a customState so we can load the subject where
      // they were trying to go automatically when they get redirected back.
      const { userSnapshot } = this.props;
      if (this.state.loggedIn === false) {
        if (userSnapshot && this.state.loggedIn === false) {
          Auth.federatedSignIn({provider: 'AzureAD', customState: '?url=' + userSnapshot+'&redirecting=true'});
        }
      }
      this.setState({isDoneLoading: true});
    });
  }

  parseUsersEmail(emailAddress) {
    let regex = /([a-zA-Z0-9]+)\.([a-zA-Z0-9]+)@nationwidechildrens\.org/;

    let match = emailAddress.match(regex);

    if (match) {
      return match[1] + ' ' + match[2];
    } else {
      return emailAddress;
    }
  }

  loadUserSettings(emailAddress) {
    console.log('loadUserSettings');

    // Auth.currentSession().then(session_data => {
    //   let id_token = session_data.getIdToken().getJwtToken();

    //   fetch(ENDPOINT_BASE + '/user_settings/' + emailAddress, {
    //     method: 'GET',
    //     mode: 'cors',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'Authorization': id_token
    //     }
    //   }).then(result => result.json())
    //   .then(result_json => {

    //     let data_sources = result_json['data_sources']
    //     let selected_data_source = result_json['selected_data_source']

    //     if (selected_data_source == null) {
    //       selected_data_source = "All Types"
    //     }

    //     this.setState({
    //       searchType: selected_data_source,
    //       selectedTypes: data_sources 
    //     });
    //   }).catch(e => {
    //     console.log("Caught exception in loadUserSettings");
    //     console.log(e);
    //   })    
    // })
  }

  handleAuthChange(e) {
    // This code looks weird, but if we immediately set logged in = true when login occurs,
    // the subject id box appears right before the user is redirected to the AD login,
    // which is kinda distracting.  So instead, we'll just wait until re-rendering happens.
    // However, when they log out, we want to immediately set the state.
    if (!e.loggedIn) {
      this.setState({
        loggedIn: e.loggedIn
      });
    }
  }

  setXlsxFile(xlsxFileData) {
    // console.log(file);
    this.setState({ xlsxFile:xlsxFileData});
    // this.props.history.push('/xlsx');
  }

  // setParquetURI(parquetURI) {
  //   this.setState({ signedUrl:signedUrl});
  //   // console.log(parquetURI);
  //   // this.setState({ parquetURI:parquetURI});
  //   // this.props.history.push('/parquet');
  // }

  // getParams(location) {
  //   // If we hid our original query parameters in session storage pull
  //   // them out now. Otherwise use whatever was passed in as part of the URL.
  //   let queryParams = sessionStorage.getItem("queryParams");
  //   if (queryParams == null) {
  //     queryParams = location.search;
  //   } else {
  //     sessionStorage.removeItem("queryParams");
  //   }

  //   const searchParams = new URLSearchParams(queryParams);
  //   return {
  //     code: searchParams.get("code") || "",
  //     state: searchParams.get("state") || "",
  //     // subjectId: searchParams.get("subject") || "",
  //     // subjectSnapshot: searchParams.get("snapshot") || ""
  //     // redirecting: searchParams.get("redirecting") || "",
  //   };
  // }

  render(){
    // console.log("AppRender: is User loggedIn? "+this.state.loggedIn);
    // console.log(this.getParams(this.props.location));
    // http://localhost:3001/?code=9e5b065b-f3af-4d7b-a2c3-c878767df155&state=rC1nouSKauF1dsM45AlAuzUubRcAJweL-3f75726c3d2f
    // if(this.getParams(this.props.location)["code"]!=='' && this.getParams(this.props.location)["state"]!==''){
    //   return(
    //     <React.Fragment>
    //       <div className="text-center m-5">
    //         <span className="mt-5 spinner-border " role="status" aria-hidden="true"></span>
    //         <h1 className="mt-3 mb-5">Checking credentials...</h1>
    //         <div className="m-5">If you are not redirected <a href="/">click here</a> to go back to the main page.</div>
    //       </div>
    //     </React.Fragment>
    //   );
    // }
    // else{
      // return()
    if(!this.state.isDoneLoading)
      return(
        <div className="initial-view-wrapper pt-0">
          <div className={`container-fluid ${(process.env.REACT_APP_ENV!=='prod') ? 'bg-dark' : 'bg-dark-blue'}`}>
            <div className="container initial-view-width-lim p-4 mx-auto mb-3 mt-5 text-center text-light">
            <div className="display-4 fw-normal d-flex flex-row justify-content-center align-items-start">VaNe<img src={logo} className="vanex-logo" alt="logo" /><span className="initial-view-version">v {process.env.REACT_APP_VERSION}</span>{(process.env.REACT_APP_ENV!=='prod') ? <span className="text-center"> (non-Prod)</span> : null}</div>
            </div>
          </div>
          <div className="container-fluid mb-5 white-ter d-flex flex-row justify-content-center align-items-center pt-5">
            <div className="mx-3 spinner-border" role="status" aria-hidden="true"></div>
            <div className="fs-3">Loading...</div>
          </div>
        </div>
      );
    else
      return (
        <React.Fragment>
          <Toaster 
            // position="top-center"
            position="top-right"
            reverseOrder={false}
            toastOptions={{
              className: '',
              style: {
                padding: '.6em',
                // color: '#713200',
                // border: '1px solid #713200',
                // border: '2px solid #333',
                border: '2px solid #b9def0',
                borderRadius: '10px',
                background: '#203556',
                // background: '#344777',
                // background: '#225FB3',
                // background: '#333',
                color: '#fff',
                // border: '1px solid #344777',
                // boxShadow: '5px 5px 5px #666',
                boxShadow: '0 0 8px #000, 5px 5px 5px #666',
                minWidth: '20rem',
                overflowWrap:'anywhere', // !! important in here!
              },
              success: {
                style: {
                  borderColor: '#C7EFD0',   // #a3f0b5 // green
                },
              },
              error: {
                style: {
                  borderColor: '#FC8696',   // red
                },
              },
            }}
            containerStyle={{
              top: 80,
              // left: 20,
              // bottom: 20,
              // right: 20,
            }}
          />
          {(process.env.REACT_APP_ENV!=='prod') ? <div className="text-center bg-danger"><a className="fw-bold text-light " href="https://vanex.igmdev.org/">You are using the non-Prod version (click here to go to the Production website)</a></div> : null}
          <Switch>
            <Route path="/xlsx">
              <XlsxVariantViewer 
                xlsxFile={this.state.xlsxFile}
                // isXlsxProcessed={this.state.isXlsxProcessed}
                // setXlsxProcessed={(value) => this.setState({isXlsxProcessed:value})}
                variantFormatDefinition2={variantFormatDefinition}
                />
            </Route>
            {/* <Route path={["/parquet/:analysisType/run/:jobRunId" , "/parquet/:analysisType/data/:uuid" , "/parquet"]} >
              <ParquetVariantViewer 
                // setParquetURI={this.setParquetURI} 
                signedUrl={this.state.signedUrl} 
                loggedIn={this.state.loggedIn}
                // handleAuthChange={this.handleAuthChange}
                userName={this.state.userName}
                userEmailAddress={this.state.emailAddress}
                isPowerUser={this.state.isPowerUser}
                isAdmin={this.state.isAdmin}
                vanexGroups={this.state.vanexGroups}
                />
            </Route> */}
            {/* <Route path={["/rd/:caseId" , "/rd"]}> */}
            <Route path={"/rd"}>
              <ProjectSpecificApp
                project="rare_disease"
                projectBaseURL="rd"
                projectTitle="Rare Disease"
                loggedIn={this.state.loggedIn}
                handleAuthChange={this.handleAuthChange}
                userName={this.state.userName}
                userEmailAddress={this.state.emailAddress}
                isPowerUser={this.state.isPowerUser}
                isAdmin={this.state.isAdmin}
                vanexGroups={this.state.vanexGroups}
                />
            </Route>
            {/* <Route path={["/bh/:caseId" , "/bh"]}> */}
            <Route path={"/bh"}>
              <ProjectSpecificApp
                project="behavioral_health"
                projectBaseURL="bh"
                projectTitle="Behavioral Health"
                loggedIn={this.state.loggedIn}
                handleAuthChange={this.handleAuthChange}
                userName={this.state.userName}
                userEmailAddress={this.state.emailAddress}
                isPowerUser={this.state.isPowerUser}
                isAdmin={this.state.isAdmin}
                vanexGroups={this.state.vanexGroups}
                />
            </Route>
            <Route path={"/ep"}>
              <ProjectSpecificApp
                project="epilepsy"
                projectBaseURL="ep"
                projectTitle="Epilepsy"
                loggedIn={this.state.loggedIn}
                handleAuthChange={this.handleAuthChange}
                userName={this.state.userName}
                userEmailAddress={this.state.emailAddress}
                isPowerUser={this.state.isPowerUser}
                isAdmin={this.state.isAdmin}
                vanexGroups={this.state.vanexGroups}
                />
            </Route>
            <Route path={"/imm"}>
              <ProjectSpecificApp
                project="immunology"
                projectBaseURL="imm"
                projectTitle="Immunology"
                loggedIn={this.state.loggedIn}
                handleAuthChange={this.handleAuthChange}
                userName={this.state.userName}
                userEmailAddress={this.state.emailAddress}
                isPowerUser={this.state.isPowerUser}
                isAdmin={this.state.isAdmin}
                vanexGroups={this.state.vanexGroups}
                />
            </Route>
            <Route path={"/arm"}>
              <ProjectSpecificApp
                project="arm"
                projectBaseURL="arm"
                projectTitle="ARM"
                loggedIn={this.state.loggedIn}
                handleAuthChange={this.handleAuthChange}
                userName={this.state.userName}
                userEmailAddress={this.state.emailAddress}
                isPowerUser={this.state.isPowerUser}
                isAdmin={this.state.isAdmin}
                vanexGroups={this.state.vanexGroups}
                />
            </Route>
            <Route path={"/rgs"}>
              <ProjectSpecificApp
                project="rGS"
                projectBaseURL="rgs"
                projectTitle="rGS"
                loggedIn={this.state.loggedIn}
                handleAuthChange={this.handleAuthChange}
                userName={this.state.userName}
                userEmailAddress={this.state.emailAddress}
                isPowerUser={this.state.isPowerUser}
                isAdmin={this.state.isAdmin}
                vanexGroups={this.state.vanexGroups}
                />
            </Route>
            <Route path={"/cfm"}>
              <ProjectSpecificApp
                project="craniofacial"
                projectBaseURL="cfm"
                projectTitle="Craniofacial"
                loggedIn={this.state.loggedIn}
                handleAuthChange={this.handleAuthChange}
                userName={this.state.userName}
                userEmailAddress={this.state.emailAddress}
                isPowerUser={this.state.isPowerUser}
                isAdmin={this.state.isAdmin}
                vanexGroups={this.state.vanexGroups}
                />
            </Route>
            <Route path={"/cardiac"}>
              <ProjectSpecificApp
                project="cardiac"
                projectBaseURL="cardiac"
                projectTitle="COURAGE"
                loggedIn={this.state.loggedIn}
                handleAuthChange={this.handleAuthChange}
                userName={this.state.userName}
                userEmailAddress={this.state.emailAddress}
                isPowerUser={this.state.isPowerUser}
                isAdmin={this.state.isAdmin}
                vanexGroups={this.state.vanexGroups}
                />
            </Route>
            <Route path={"/paradigm"}>
              <ProjectSpecificApp
                project="paradigm"
                projectBaseURL="paradigm"
                projectTitle="PARADIGM"
                loggedIn={this.state.loggedIn}
                handleAuthChange={this.handleAuthChange}
                userName={this.state.userName}
                userEmailAddress={this.state.emailAddress}
                isPowerUser={this.state.isPowerUser}
                isAdmin={this.state.isAdmin}
                vanexGroups={this.state.vanexGroups}
                />
            </Route>
            <Route path={"/training"}>
              <ProjectSpecificApp
                project="training"
                projectBaseURL="training"
                projectTitle="Training"
                loggedIn={this.state.loggedIn}
                handleAuthChange={this.handleAuthChange}
                userName={this.state.userName}
                userEmailAddress={this.state.emailAddress}
                isPowerUser={this.state.isPowerUser}
                isAdmin={this.state.isAdmin}
                vanexGroups={this.state.vanexGroups}
                />
            </Route>
            <Route path={"/genomic_services"}>
              <ProjectSpecificApp
                project="genomic_services"
                projectBaseURL="genomic_services"
                projectTitle="Genomic Services"
                loggedIn={this.state.loggedIn}
                handleAuthChange={this.handleAuthChange}
                userName={this.state.userName}
                userEmailAddress={this.state.emailAddress}
                isPowerUser={this.state.isPowerUser}
                isAdmin={this.state.isAdmin}
                vanexGroups={this.state.vanexGroups}
                />
            </Route>
            <Route path={["/dynamicplots/:geneName", "/dynamicplots"]}>
              <DynamicPlots 
                loggedIn={this.state.loggedIn}
                userName={this.state.userName}
                userEmailAddress={this.state.emailAddress}
              />
            </Route>
            <Route path={["/info/:geneName"]}>
              <Info 
                loggedIn={this.state.loggedIn}
                userName={this.state.userName}
                userEmailAddress={this.state.emailAddress}
              />
            </Route>
            {/* <Route path={["/tmp"]}>
              <TempComp 
                loggedIn={this.state.loggedIn}
                userName={this.state.userName}
                userEmailAddress={this.state.emailAddress}
              />
            </Route> */}
            <Route path={"/"}>
            {/* <h1 onClick={this.renderIGV}>Click to load IGV</h1>
            <div id="igv-div"></div> */}
              <InitialView
                projects={["rare_disease","behavioral_health","epilepsy"]}
                loggedIn={this.state.loggedIn}
                userName={this.state.userName}
                userEmailAddress={this.state.emailAddress}
                setParquetURI={this.setParquetURI}
                setXlsxFile={this.setXlsxFile}
                />
            </Route>
          </Switch>
        </React.Fragment>
      );
    // }
  }
}


// class VaNexApp extends React.Component {

//   constructor(props) {
//     super(props);
//   }

//   render(){
//     return (
//       <React.Fragment>
//         <Toaster/>
//           <Switch>
//             <Route path={"/rd"}>
//             <h1>rd</h1>
//             </Route>
//             <Route path={"/bh"}>
//             <h1>bh</h1>
//             </Route>
//             <Route path={"/"}>
//             <h1>/</h1>
//             </Route>
//           </Switch>
//       </React.Fragment>
//     );
//   }
// }

export default withRouter(VaNexApp);
