import React, { useState, useEffect } from 'react'; 
import toast from 'react-hot-toast';

// * OmimInfo Component
const OmimInfo = (props) =>{

  const [clinicalSynopsisMimNumbers, setClinicalSynopsisMimNumbers] = useState([]);
  const [clinicalSynopsisTable, setClinicalSynopsisTable] = useState(null);
  const [isLoadingClinicalSynopsisTable, setIsLoadingClinicalSynopsisTable] = useState(false);
  
  useEffect(() => {
    setClinicalSynopsisTable(null);
    setIsLoadingClinicalSynopsisTable(false);
    if(!!props.mimNumbers)
      setClinicalSynopsisMimNumbers(props.mimNumbers);
    else
      setClinicalSynopsisMimNumbers(extractMimNumberFromOmimPhenotypes(props.omimPhenotypes));
  }, [props.omimNumber, props.omimPhenotypes]);
  
  const apiUrl = "https://api.omim.org/api/"
  const apiKey = "SR0b8fk4Tpurlz_JgAEzvg"
  
  
  // const callOmimApi = (requestString) => {
  //   let urlToFetch = `${apiUrl}${requestString}&apiKey=${apiKey}&format=json`;
  //   // let urlToFetch = `${apiUrl}clinicalSynopsis?mimNumber=${mimNumbers.join('&mimNumber=')}&apiKey=${apiKey}&format=xml`;
  //   console.log(urlToFetch);
  //   fetch(urlToFetch, {method: 'GET'})
  //   .then(result => result.json())
  //   .then(result_json => {
  //     console.log(result_json);
  //     return('xxx');
  //   }).catch(e => {
  //     // setIsLoadingCaseList(false);
  //     toast.error("Failed to load OMIM data.");
  //     return '';
  //   });
  // }
  
  
  const extractMimNumberFromOmimPhenotypes = (omimPhenotypes) => {
    let terms = [];
    if(typeof (omimPhenotypes) === 'string'){
      terms = omimPhenotypes.split(';');
      terms.forEach( (thisTerm,i) => {
        let matches = thisTerm.match(/, (\d{3,}) /);
        if (matches) {
          terms[i]=matches[1];
        } else {
          terms[i]="";
        }
      });
    }
    // console.log(terms.join('&mimNumber='));
    return terms;
  }
  
  const parseSynopsisEntery = (entery) => {
    if(!!entery && typeof entery==='string'){
      entery = entery.replaceAll(/{.*}/g,"") // ! fix this
      return(entery.split(';').map( (ent, i) => <div key={i}>{`- ${ent.trim()}`}</div> ));
    } else { return(entery);}
  }
  
  const showClinicalSynopsis = () => {
    if(clinicalSynopsisMimNumbers.filter(mn => !!mn).length<=0){
      toast.error('No MIM number found');
      return;
    }
    // let urlToFetch = `${apiUrl}clinicalSynopsis?mimNumber=${extractMimNumberFromOmimPhenotypes().join('&mimNumber=')}&include=clinicalSynopsis&apiKey=${apiKey}&format=json`;
    let urlToFetch = `${apiUrl}clinicalSynopsis?mimNumber=${clinicalSynopsisMimNumbers.filter(mn => !!mn).join('&mimNumber=')}&include=clinicalSynopsis&apiKey=${apiKey}&format=json`;
    // console.log(urlToFetch);
  
    setIsLoadingClinicalSynopsisTable(true);
    fetch(urlToFetch, {method: 'GET'})
    .then(result => result.json())
    .then(result_json => {
      console.log(result_json); // console.log(JSON.stringify(result_json));
      let mimNumbers = [];
      let mergedSynopsis = {};
      result_json.omim.clinicalSynopsisList.forEach( itemSet => {
        mimNumbers.push(itemSet.clinicalSynopsis.mimNumber);
        Object.keys(itemSet.clinicalSynopsis).forEach( itemKey => {
          if(itemKey!=='mimNumber' && itemKey!=='prefix' && itemKey!=='oldFormat'){
            if(!mergedSynopsis[itemKey]){
              mergedSynopsis[itemKey] = {}
            }
            mergedSynopsis[itemKey][itemSet.clinicalSynopsis.mimNumber]=String(itemSet.clinicalSynopsis[itemKey]);
          }
        });
  
        if(!! itemSet.clinicalSynopsis.oldFormat){
          Object.keys(itemSet.clinicalSynopsis.oldFormat).forEach( itemKey => {
            if(itemKey.toLowerCase()!=='mimnumber' && itemKey.toLowerCase()!=='prefix' ){
              let newKey = itemKey.toLowerCase();
              switch(newKey){
                case 'lab':
                  newKey='laboratoryAbnormalities';
                  break;
                case 'heme':
                  newKey='hematology';
                  break;
                case 'misc':
                  newKey='miscellaneous';
                  break;
                //! case 'xxxx':  //! need to add more oldformat keys
                //!   newKey='xxxxx';
                //!   break;
                default:
                  break;
              }
              if(!mergedSynopsis[newKey]){
                mergedSynopsis[newKey] = {}
              }
              mergedSynopsis[newKey][itemSet.clinicalSynopsis.mimNumber]=String(itemSet.clinicalSynopsis.oldFormat[itemKey]);
            }
          });
        }
      });
      Object.keys(mergedSynopsis).forEach( itemKey => {
        mimNumbers.forEach( mimNum =>{
          if(!mergedSynopsis[itemKey][mimNum]) mergedSynopsis[itemKey][mimNum]="";
        })
      });
  
      // * order the synopsis based on what we prefer!
      const defaultSynopsisOrder_partA = ['prefix','preferredTitle','inheritance'];
      const defaultSynopsisOrder_partB = ['laboratoryAbnormalities','miscellaneous','molecularBasis'];
      let synopsisNotInDefault = [];
      Object.keys(mergedSynopsis).forEach( itemKey => ((defaultSynopsisOrder_partA.indexOf(itemKey) < 0) ? synopsisNotInDefault.push(itemKey) : null ) );
      let synopsisOrder=[...defaultSynopsisOrder_partA, ...synopsisNotInDefault, ...defaultSynopsisOrder_partB]
    
      let orderedMergedSynopsis = Object.keys(mergedSynopsis).sort((A,B) => (synopsisOrder.indexOf(A) - synopsisOrder.indexOf(B)) ).reduce(
        (obj, key) => { 
          obj[key] = mergedSynopsis[key]; 
          return obj;
        }, 
        {}
      );
      mergedSynopsis = orderedMergedSynopsis;
      // console.log(mergedSynopsis);
  
      setClinicalSynopsisTable(
        <table className="clinical-synopsis mt-2">
          <thead>
            <tr >
              <td className="fw-bold">{'NUMBER'}</td>
            {Object.keys(mergedSynopsis[Object.keys(mergedSynopsis)[0]]).map( (itemKey, ind3) => 
              <td className="fw-bold" key={ind3}><a href={`https://www.omim.org/clinicalSynopsis/${itemKey}`} target="_blank" rel="noreferrer"  >{itemKey}</a></td>
            )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(mergedSynopsis).map( (itemKey, ind1) => 
            <tr key={ind1}>
              {/* <td style={{maxWidth:'20rem', border:'1px solid gray'}}>{itemKey.split(/(?=[A-Z])/).slice(0,-1).join(' ')}({itemKey.split(/(?=[A-Z])/).slice(-1)})</td> */}
              <td className="fw-bold">{itemKey.split(/(?=[A-Z])/).join(' ').toUpperCase()}</td>
              {Object.values(mergedSynopsis[itemKey]).map( (item,ind2) => <td key={ind2} >{parseSynopsisEntery(item)}</td>)}
            </tr>
            )}
          </tbody>
        </table>
        // <div className="d-flex flex-column">
        //   {/* {Object.keys(mergedSynopsis).map( (itemKey,ind1) => <div key={ind1} className="d-flex flex-row" >
        //   <div key={ind1} style={{maxWidth:'20rem', border:'1px solid gray'}}>{itemKey}</div>
        //   {Object.values(mergedSynopsis[itemKey]).map( (item,ind2) => <div key={ind2} style={{maxWidth:'20rem', border:'1px solid gray'}}>{parseSynopsisEntery(item)}</div>)}
        //   </div>)} */}
        //   {/* <div className="d-flex flex-row">
        //     <div>NUMBER</div>
        //     {result_json.omim.clinicalSynopsisList.map( (item,ind) => <div key={ind}>{item.clinicalSynopsis.mimNumber}</div>)}
        //   </div>
        //   <div className="d-flex flex-row">
        //     <div>TITLE</div>
        //     {result_json.omim.clinicalSynopsisList.map( (item,ind) => <div key={ind}>{item.clinicalSynopsis.preferredTitle}</div>)}
        //   </div> */}
        // {/* </div> */}
      );
      setIsLoadingClinicalSynopsisTable(false);
    }).catch(e => {
      // setIsLoadingCaseList(false);
      setIsLoadingClinicalSynopsisTable(false);
      toast.error("Failed to load OMIM data.");
    });
  
    // try{
    //   // callOmimApi(`entry?mimNumber=${props.omimNumber}&include=clinicalSynopsis` );
    //   callOmimApi(`clinicalSynopsis?mimNumber=${extractMimNumberFromOmimPhenotypes().join('&mimNumber=')}&include=clinicalSynopsis` )
    //   .then( z => console.log(z));
    //   // debugger;
    //   // setClinicalSynopsisTable(synopsis.omim.clinicalSynopsisList);
    // } catch(e){
    //   toast.error('Error: ' + e);
    // }
  }
  
  if(clinicalSynopsisMimNumbers.length===0)
    return(<></>);
  
  if(!!clinicalSynopsisTable) 
    return(<div className="d-flex flex-row flex-nowrap align-items-center justify-content-center">{clinicalSynopsisTable}</div>);
    
  return(
    <div style={{position:'relative', width:'100%'}}>
      <div className="clinical-synopsis-overlay opaque " ></div>
      {(isLoadingClinicalSynopsisTable) ?
        <div className="clinical-synopsis-overlay text-center fw-bold pt-3 cursor-pointer"><span className="bg-white text-dblue"><span className="mx-2 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading Clinical Synopsis</span></div>
      :
        <div className="clinical-synopsis-overlay text-center fw-bold pt-3 cursor-pointer" onClick={showClinicalSynopsis}><span className="bg-white text-dblue"><i className="fas fa-arrow-down me-2"></i>Show Clinical Synopsis</span></div>
      }
      <table className="clinical-synopsis mt-2">
        <tbody>
          <tr>
            <td className="fw-bold">{'NUMBER'}</td>
            {clinicalSynopsisMimNumbers.map( (itemKey, ind3) => 
              <td className="fw-bold" key={ind3}><a href={`https://www.omim.org/clinicalSynopsis/${itemKey}`} target="_blank" rel="noreferrer"  >{itemKey}</a></td>
            )}
            </tr>
        </tbody>
      </table>
    </div>
  );
  
  // return(
  //   <div className="w-100 d-flex flex-row flex-nowrap align-items-center justify-content-center">
  //     { <button className="btn btn-primary" onClick={showClinicalSynopsis}>Clinical Synopsis</button>}
  //   </div>
  // );
  
  //<div className="d-flex flex-column justify-content-center w-100">
  // return(
  //   <div className="d-flex flex-row flex-nowrap align-items-center justify-content-center">
  //     {(!!clinicalSynopsisTable) ? clinicalSynopsisTable : <button className="btn btn-primary" onClick={showClinicalSynopsis}>Clinical Synopsis</button>}
  //   </div>
  // );
  }



export default OmimInfo;